import styled from "styled-components";
import { currentColors } from "../../../shared/colors";
import OrigInput from "../../components/FinalInput";
import OrigSelect from "../../components/FinalSelect";
import OrigTextarea from "../../components/FinalTextarea";

export const Select = styled(OrigSelect)`
    select {
        border: 1px solid ${currentColors.text};
        color: ${currentColors.text};
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: white;
    }

    & small.error {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }

    &.error select {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export const Textarea = styled(OrigTextarea)`
    textarea {
        border: 1px solid ${currentColors.text};
        color: ${currentColors.text};
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: white;
        font-size: 1rem;
    }

    & small.error {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }

    &.error textarea {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export const Input = styled(OrigInput)`
    input {
        border: 1px solid ${currentColors.text};
        color: ${currentColors.text};
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: white;
        font-size: 1rem;
    }

    & small.error {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }

    &.error input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;
