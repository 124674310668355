import type { JSX } from "react";
import styled from "styled-components";
import GroupTaskIcon from "../../../shared/components/icons/GroupTaskIcon";
import t from "../../../shared/translations";
import type { ITask } from "../query";
import { Box } from "../stylings";

interface IProps {
    task: ITask;
}

const H3 = styled.h3`
    display: grid;
    grid-template-columns: 26px 1fr;
    align-items: center;
    gap: 0.5rem;
    svg {
        width: 26px;
        height: 26px;
    }
`;

function PeerFeedbackInfo({ task }: IProps): JSX.Element {
    const path_name = task.learningPathItem.learningPath.pathname;
    const task_name = task.shortTitle;
    const feedback_task_name = task.feedbackTask.shortTitle;
    return (
        <Box>
            <H3>
                <GroupTaskIcon />
                {t("player.task.heading-peer-feedback")}
            </H3>
            <p>{t("player.task.info-peer-feedback-connected", { task_name, feedback_task_name })}</p>
            <p>{t("player.task.info-peer-feedback-assign", { path_name, feedback_task_name })}</p>
            <p>{t("player.task.info-peer-feedback-name-shown")}</p>
        </Box>
    );
}

export default PeerFeedbackInfo;
