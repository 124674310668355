import { animated, useSpring } from "@react-spring/web";
import { produce } from "immer";
import { type JSX, memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Doc from "../../../shared/components/Doc";
import InfoIcon from "../../../shared/components/icons/InfoIcon";
import t from "../../../shared/translations";
import Modal from "../../components/Modal";
import { type Dispatch, openModal } from "../../state";
import { Button } from "../stylings";
import type { IFormValues } from "./AnswerForm";

interface IProps {
    allValues: IFormValues[];
    currValue: IFormValues;
    index: number;
    invert: boolean;
    saveIncomplete: (values: IFormValues[]) => Promise<void>;
}

const Grid = styled.div`
    display: grid;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
    grid-template-columns: 1fr auto 1fr;
    gap: 0.5rem;
`;

const StyledInfo = styled(InfoIcon)`
    width: 32px;
    height: 32px;
    stroke-width: 1.5px;
    cursor: pointer;
    justify-self: end;
`;

const SaveButton = styled(Button)`
    margin: 0;
`;

function TextfieldSave({ allValues, currValue, index, invert, saveIncomplete }: IProps): JSX.Element {
    const dispatch = useDispatch<Dispatch>();
    const [savingAnswers, setSavingAnswers] = useState(false);
    const [saved, setSaved] = useState(false);

    const props = useSpring({ opacity: saved ? 1 : 0, config: { friction: 160, tension: 120 }, reset: saved });

    const showDocs = () => {
        dispatch(openModal("Task/Info/MultiQuestion"));
    };
    const save = async (): Promise<void> => {
        setSavingAnswers(true);
        setSaved(false);
        const values = produce(allValues, (draft) => {
            draft[index] = currValue;
        });
        try {
            await saveIncomplete(values);
        } catch (err) {
            console.error(err);
            return;
        } finally {
            setSavingAnswers(false);
        }
        setSaved(true);
    };
    const handleClick = (event: React.FormEvent<HTMLButtonElement>): boolean => {
        event.preventDefault();
        void save();
        return false;
    };

    useEffect(() => {
        if (!saved) {
            return;
        }
        const timer = setTimeout(() => {
            setSaved(false);
        }, 4000);
        return () => clearTimeout(timer);
    }, [saved]);

    return (
        <Grid>
            <StyledInfo onClick={showDocs} />
            <SaveButton
                className="save-answer"
                type="button"
                onClick={handleClick}
                disabled={savingAnswers}
                $invert={invert}
            >
                {t("player.task.button-save")}
            </SaveButton>
            <animated.span style={{ textAlign: "left", ...props }}>{t("player.task.info-saved")}</animated.span>
            <Modal modal={"Task/Info/MultiQuestion"}>
                <Doc path={"player/task/multi-question"} />
            </Modal>
        </Grid>
    );
}

export default memo(TextfieldSave);
