import type { TaskIcon } from "../../shared/types";

export interface IArgs {
    id: string;
    submitType: "submit" | "feedback";
}

export const query = `
    query($id: String!, $submitType: String!) {
       flipper(id: $id, submitType: $submitType) {
            lockedTasks {
                id
                icon
                shortTitle
            }
            animations
            finishMessage
            icon
            milesEarned
            pipId
            milesCheckpoint
            milesGoal
            showMilesText
            passedCheckpoint
            passedGoal
            redirectUrl
        }
    }
`;

export type AnimationType = "task" | "unlock" | "milestone" | "redirect";

export interface IData {
    flipper: {
        lockedTasks: {
            id: string;
            icon: TaskIcon;
            shortTitle: string;
        }[];
        animations: AnimationType[];
        finishMessage: string | null;
        icon: TaskIcon;
        milesEarned: string;
        pipId: string | null;
        milesCheckpoint: string;
        milesGoal: string;
        showMilesText: boolean;
        passedCheckpoint: boolean;
        passedGoal: boolean;
        redirectUrl: string;
    };
}
