import { memo } from "react";
import type { ComponentPropsWithoutRef, JSX } from "react";
import { currentColors } from "../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    white?: boolean;
}

function AiIcon({ white, ...props }: IProps): JSX.Element {
    return (
        <svg
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="1.5"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <title>AI</title>
            <g fill="none" stroke={white ? "white" : currentColors.text} strokeWidth="3">
                <path d="m71 35.105v29.79c0 3.369-2.736 6.105-6.105 6.105h-29.79c-3.369 0-6.105-2.736-6.105-6.105v-29.79c0-3.369 2.736-6.105 6.105-6.105h29.79c3.369 0 6.105 2.736 6.105 6.105z" />
                <path d="m50 29v-15.364" />
                <path d="m59.439 29v-9.797l8.62-2.455" />
                <path d="m41 28v-10l-6.336-2.44" />
                <path d="m29 50h-16" />
                <path d="m71 50h15.741" />
                <path d="m50 71v15.35" />
                <path d="m64 71 .106 8.125" />
                <path d="m29 65h-8" />
                <path d="m29 40h-10l-1.194-7.805" />
                <path d="m71 40h8.704l4.813-6.863" />
                <path d="m71 60h10l2.344 7.157" />
                <path d="m41 71v9h-10.007" />
                <circle cx="17" cy="65" r="4" />
                <circle cx="9" cy="50" r="4" />
                <circle cx="17.286" cy="28.226" r="4" />
                <circle cx="30.925" cy="14.148" r="4" />
                <circle cx="50" cy="9.636" r="4" />
                <circle cx="71.893" cy="15.607" r="4" />
                <circle cx="86.741" cy="29.788" r="4" />
                <circle cx="90.741" cy="50" r="4" />
                <circle cx="84.541" cy="71" r="4" />
                <circle cx="64.106" cy="83.125" r="4" />
                <circle cx="50" cy="90.35" r="4" />
                <circle cx="26.993" cy="80" r="4" />
            </g>
            <path
                d="m58.707 63.776-11.111-27.552h-4.255l-11.07 27.552h3.924l2.437-6.114h13.714l2.437 6.114zm-7.394-9.17h-11.689l5.865-14.829z"
                fillRule="nonzero"
                fill={white ? "white" : currentColors.text}
            />
            <path
                d="m62.301 36.224h3.428v27.551h-3.428z"
                fillRule="nonzero"
                fill={white ? "white" : currentColors.text}
            />
        </svg>
    );
}

export default memo(AiIcon);
