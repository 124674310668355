import type { JSX } from "react";
import { Navigate, useMatch } from "react-router-dom";
import { useQuery } from "urql";
import FeedbackTaskDescription from "../../shared/components/FeedbackTaskDescription";
import Loader from "../../shared/components/Loader";
import ShowError from "../components/ShowError";
import Feedback from "./Feedback";
import RequestAnswer from "./RequestAnswer";
import { type IArgs, type IData, query } from "./queries";

function ProvideFeedback(): JSX.Element {
    const {
        params: { id },
    } = useMatch({ path: "/provide-feedback/:id/" });
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: { id },
    });

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }

    const feedbackTask = result.data.feedbackTask;
    if (!feedbackTask) {
        return <Navigate to="/landing" replace={true} />;
    }

    const submittedFeedbackTask = feedbackTask.submittedFeedbackTask;
    const task = submittedFeedbackTask || feedbackTask;
    const submittedTask = submittedFeedbackTask?.selectedSubmittedTaskVariant.submittedTask;
    const pathname = feedbackTask.learningPathItem.learningPath.pathname;
    return (
        <>
            <FeedbackTaskDescription
                shortTitle={task.shortTitle}
                title={task.title}
                instructions={task.instructions}
                icon={task.icon}
                miles={task.miles}
                deadline={feedbackTask.deadline}
                finishDatetime={
                    submittedFeedbackTask?.status === "finished" ? submittedFeedbackTask.finishDatetime : null
                }
            />
            {submittedFeedbackTask === null && <RequestAnswer id={id} pathname={pathname} />}
            {!!submittedTask && (
                <Feedback
                    submittedTask={submittedTask}
                    pathname={pathname}
                    submittedFeedbackTaskId={submittedFeedbackTask?.id}
                />
            )}
        </>
    );
}

export default ProvideFeedback;
