import type { JSX } from "react";
import styled from "styled-components";
import { currentColors } from "../colors";

interface IProps {
    statement?: string;
    leftLabel: string;
    rightLabel: string;
    selected: number | null;
}

interface ICircle {
    $size: number;
    $checked?: boolean;
}

export const Circle = styled.div<ICircle>`
    position: relative;
    border: 1px solid ${currentColors.text};
    border-radius: 100%;
    width: ${({ $size }) => $size}px;
    height: ${({ $size }) => $size}px;
    &:after {
        content: "";
        position: absolute;
        display: ${({ $checked }) => ($checked ? "block" : "none")};
        top: 3px;
        left: 3px;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        border-radius: 100%;
        background: ${currentColors.likert};
    }
`;

export const Grid = styled.div`
    display: grid;
    margin: 0;
    margin-bottom: 2rem;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    grid-template-areas: 
        "statement statement"
        "circles circles"
        "leftLabel rightLabel";

    @media (min-width: 530px) {
        grid-template-columns: 1fr auto 1fr;
        grid-template-areas: 
            "statement statement statement"
            "leftLabel circles rightLabel";
    }
`;

export const CircleRow = styled.div`
    grid-area: circles;
    width: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
    gap: 1rem;
    grid-template-columns: repeat(5, 1fr);
    @media (min-width: 700px) {
        gap: 2rem;
    }
`;

interface ILabel {
    $area: "leftLabel" | "rightLabel";
}

export const Label = styled.div<ILabel>`
    grid-area: ${({ $area }) => $area};
    text-align: ${({ $area }) => ($area === "rightLabel" ? "right" : "left")};
    align-self: center;

    @media (min-width: 530px) {
        text-align: ${({ $area }) => ($area === "rightLabel" ? "left" : "right")};
    }
`;

export const Statement = styled.div`
    font-size: 1.1rem;
    grid-area: statement;
    text-align: center;
`;

function LikertPreview({ statement, leftLabel, rightLabel, selected }: IProps): JSX.Element {
    return (
        <Grid>
            {statement && <Statement>{statement}</Statement>}
            <Label $area="leftLabel">{leftLabel}</Label>
            <CircleRow>
                <Circle $size={20} $checked={selected === 1} />
                <Circle $size={25} $checked={selected === 2} />
                <Circle $size={30} $checked={selected === 3} />
                <Circle $size={35} $checked={selected === 4} />
                <Circle $size={40} $checked={selected === 5} />
            </CircleRow>
            <Label $area="rightLabel">{rightLabel}</Label>
        </Grid>
    );
}

export default LikertPreview;
