import type { JSX } from "react";
import Confetti from "react-confetti";
import styled, { keyframes } from "styled-components";
import TrophyIcon from "../../shared/components/icons/TrophyIcon";
import useWindowSize from "../../shared/hooks/useWindowSize";
import t from "../../shared/translations";
import Banner from "../performance/Banner";
import type { IData } from "./query";

interface IProps {
    data: IData["flipper"];
}

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const P = styled.p`
    text-align: center;
    font-size: 1.8rem;
    margin-top: 1rem;
    animation: ${fadeIn} 1.5s ease 1 forwards;
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
    width: 14rem;
    height: 14rem;
    margin: 0 auto;

    svg {
        width: 14rem;
        height: 14rem;
    }

    > * {
        position: absolute;
        width: 14rem;
        height: 14rem;
    }

    > *:last-child {
        animation: ${fadeIn} 2.5s ease 1s 1 forwards;
        z-index: 1;
        opacity: 0;
    }
`;

function ConfettiAward({ data }: IProps): JSX.Element {
    const { width, height } = useWindowSize();
    return (
        <>
            <Confetti width={width} height={height} />
            <P>
                {data.passedGoal
                    ? t("player.flipper.heading-goal", { miles: data.milesGoal })
                    : t("player.flipper.heading-checkpoint", { miles: data.milesCheckpoint })}
            </P>
            {data.passedGoal ? (
                <Center>
                    <TrophyIcon thin />
                    <TrophyIcon thin active />
                </Center>
            ) : (
                <Center>
                    <Banner />
                    <Banner active />
                </Center>
            )}
        </>
    );
}

export default ConfettiAward;
