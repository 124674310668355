import type { ComponentPropsWithoutRef, JSX } from "react";
import { currentColors } from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function RocketIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path fill="white" d="m26 46.5 l12.5 -0.5 l8 -13 l -11 1z" />
            <path fill="white" d="m56 60 l2 14 l10.5 -13.5 l-1.5 -10z" />
            <path
                d="m83.3 10.3c-.3-.3-.8-.4-1.2-.3-.9.2-21.8 4.7-31.9 16.6-1.4 1.6-2.8 3.4-4.2 5.4l-10 .5c-.4 0-.8.2-1.1.5l-10.7 12.7c-.4.5-.5 1.1-.2 1.6s.8.8 1.3.8h.1l10.1-.5c-4.4 7.1-7.6 12.9-7.9 13.3-.4.6-.2 1.4.3 1.9l12.2 10.2c.3.2.6.4 1 .4.3 0 .6-.1.9-.3.5-.3 6.2-4.7 12.9-10.1l1.3 11c .1.6.5 1.1 1.1 1.3.1 0 .3.1.4.1.4 0 .9-.2 1.1-.5l10.7-12.7c.3-.3.4-.7.3-1.1l-1.2-10.1c1.8-1.7 3.4-3.4 4.6-4.9 10.3-12.3 10.6-33.6 10.6-34.5.1-.6-.1-1-.5-1.3zm-45.8 34.3-8.7.4 8.1-9.6 6.9-.3c-2.2 3.1-4.3 6.3-6.3 9.5zm21.3 25.7-1.2-9.6 0 0c2.8-2.4 5.7-4.8 8.3-7.3l.9 7.3z"
                fill={currentColors.text}
            />
            <path
                d="m71 44c-7.2 8.5-25.5 22.6-29.9 26l-10.2-8.6c2.6-4.6 13.2-22.9 21.6-32.9 7.7-9.1 23-13.7 28.3-15.1-.3 5.4-1.9 21.3-9.8 30.6z"
                fill={active ? currentColors.icon4 : "white"}
            />
            <path
                d="m71.3 24.9c-3.1-2.6-7.6-2.2-10.2.9-1.2 1.5-1.8 3.4-1.7 5.3.2 1.9 1.1 3.7 2.5 4.9 1.4 1.1 3 1.7 4.7 1.7 2.1 0 4.1-.9 5.5-2.6 2.7-3.1 2.3-7.6-.8-10.2z"
                fill={currentColors.text}
            />
            <path
                d="m69.9 33.1c-1.5 1.8-4.2 2-6 .5-.9-.7-1.4-1.8-1.5-2.9s.3-2.2 1-3.1c.8-1 2-1.5 3.2-1.5 1 0 1.9.3 2.7 1 1.9 1.6 2.1 4.3.6 6z"
                fill="white"
            />
            <g fill={currentColors.text}>
                <path d="m26.8 68.1 0 0c .7.6.8 1.5.3 2.1l-8.5 10.1c-.5.6-1.4.7-2 .2l-.1-.1c-.6-.5-.7-1.4-.2-2l8.5-10.1c.5-.6 1.4-.7 2-.2z" />
                <path d="m31.6 72.2 0 0c .6.5.7 1.4.2 2l-12.2 14.4c-.5.6-1.4.7-2 .2h0c-.6-.5-.7-1.4-.2-2l12.1-14.4c.6-.7 1.5-.7 2.1-.2z" />
                <path d="m36.2 76.1 0 0c .7.6.8 1.5.3 2.1l-8.5 10c-.5.6-1.4.7-2 .2l-.1-.1c-.6-.5-.7-1.4-.2-2l8.5-10.1c.5-.5 1.4-.6 2-.1z" />
            </g>
        </svg>
    );
}

export default RocketIcon;
