import type { JSX } from "react";
import { Navigate, useMatch } from "react-router-dom";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import TaskDescription from "../../shared/components/TaskDescription";
import t from "../../shared/translations";
import type { TaskIcon } from "../../shared/types";
import LockedModal, { useLockedModal } from "../LockedModal";
import ShowError from "../components/ShowError";
import WithTasksSidebar from "../tasksSidebar";

interface IArgs {
    id: string;
}

const query = `
    query($id:String!) {
        event(id:$id) {
            id
            icon
            shortTitle
            title
            inspiration {
                id
                mediaType
                src
            }
            media {
                id
                mediaType
                src
                name
                logo
            }
            eventStart
            eventEnd
            objectives
            description
            learningPathItem {
                id
            }
        }
    }
`;

interface IEvent {
    id: string;
    icon: TaskIcon;
    shortTitle: string;
    title: string | null;
    inspiration: {
        id: string;
        mediaType: string;
        src: string;
    } | null;
    media: {
        id: string;
        mediaType: string;
        src: string;
        name: string;
        logo: string;
    }[];
    eventStart: string;
    eventEnd: string;
    objectives: string | null;
    description: string | null;
    learningPathItem: {
        id: string;
    };
}

interface IData {
    event: IEvent;
}

function Event(): JSX.Element {
    const {
        params: { id },
    } = useMatch({ path: "/event/:id/" });
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: { id },
    });
    const { openLockedModal, ...lockedProps } = useLockedModal();

    let main = <div />;
    if (result.fetching) {
        main = <Loader />;
    } else if (result.error) {
        main = <ShowError />;
    } else if (!result.data.event) {
        main = <Navigate to="/landing" replace={true} />;
    } else {
        const event = result.data.event;
        main = (
            <TaskDescription
                shortTitle={event.shortTitle}
                objectives={event.objectives}
                inspiration={event.inspiration}
                title={event.title}
                icon={event.icon}
                miles={null}
                media={event.media}
                eventStart={event.eventStart}
                eventEnd={event.eventEnd}
                taskVariants={[]}
                invert={false}
                description={event.description}
            />
        );
    }
    return (
        <>
            <WithTasksSidebar
                openLockedModal={openLockedModal}
                activeItem={result.data?.event?.learningPathItem?.id}
                mainLabel={t("player.event.tab-event")}
                big={true}
            >
                {main}
            </WithTasksSidebar>
            <LockedModal {...lockedProps} />
        </>
    );
}

export default Event;
