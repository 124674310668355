import type { ComponentPropsWithoutRef } from "react";
import { currentColors } from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function PlantIcon({ active, ...props }: IProps) {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path fill="white" d="m9.5 87 h69 c-4 -25, -63 -26, -69 0" />
            <path
                d="m91.3 21.3c-2.2-1.4-25-4.3-32.7-.5-4.1 2-7.3 5.2-9.1 9-1.8 3.9-1.8 8-.1 11.4.4.7.8 1.4 1.3 2.1-3.7 2.7-7.7 6.9-10.1 13.2-.8-4.6-2.7-8.2-5.6-10.7 3.9-4.2 4.4-11.3.9-17.4-1.9-3.4-5.4-5.2-9.1-7.1-4.2-2.1-8.6-4.3-11.3-9.1-.4-.7-1.4-1-2.1-.6-1.1.6-1.7 4.9-1.8 12.7-.1 5.5.3 12.9 2.4 16.6 2 3.5 5 6.1 8.4 7.5 1.6.6 3.2.9 4.7.9 1.9 0 3.7-.5 5.4-1.4.1 0 .1-.1.1-.1 2.8 2.1 4.5 5.6 5.1 10.2.4 3.4.7 5.9.9 8.5-16.4 1.4-29.2 9.7-30.6 20.4-.1.4.1.9.4 1.2s.7.5 1.1.5h69.1c.4 0 .8-.2 1.1-.5s.4-.8.4-1.2c-1.5-11.8-17-20.7-36-20.7-.8 0-1.5 0-2.2 0 .5-10.7 6-17 10.7-20.5l.3-.3c1.6 1.3 3.5 2.2 5.7 2.7 1.2.3 2.5.4 3.8.4 3 0 6-.7 8.8-2.1 4-2 6.2-5.8 8.6-9.9 2.7-4.7 5.6-9.6 11.3-12.4.5-.2.9-.8.9-1.3.1-.6-.2-1.1-.7-1.5zm-47.3 47.9c16.2 0 30.1 7.1 32.7 16.4h-65.4c2.6-9.3 16.5-16.4 32.7-16.4z"
                fill={currentColors.text}
            />
            <path
                d="m31.1 45.3c-2.2 1.2-4.8 1.3-7.5.3-2.8-1.1-5.2-3.3-6.9-6.2-2.5-4.4-2.1-16.8-1.6-22.7 3 3.6 6.9 5.5 10.4 7.3 3.4 1.7 6.4 3.3 7.9 5.9 3.3 5.8 2.3 12.7-2.3 15.4z"
                fill={active ? currentColors.icon3 : "white"}
            />
            <path
                d="m77.2 35c-2.3 3.9-4.2 7.2-7.3 8.8-3.5 1.7-7.3 2.2-10.7 1.5-3.3-.7-5.8-2.6-7.1-5.2s-1.2-5.8.2-8.8c1.5-3.2 4.2-5.9 7.7-7.6 2.4-1.2 6.6-1.6 11-1.6 5.8 0 12.1.7 15.8 1.3-4.6 3.1-7.2 7.6-9.6 11.6z"
                fill={active ? currentColors.icon3 : "white"}
            />
        </svg>
    );
}

export default PlantIcon;
