import { type JSX, memo } from "react";
import styled from "styled-components";
import { currentColors } from "../colors";

type IData = { type: "percent"; value: number } | { type: "count"; current: number; max: number };

interface IProps {
    data: IData;
    className?: string;
}

const Outer = styled.div`
    position: relative;
    background-color: ${currentColors.background};
    margin: 1rem auto;
    width: 270px;
    height: 27px;
    z-index: 0;
    border: 1px solid ${currentColors.progress};
`;

const Inner = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: ${currentColors.progress};
    height: 25px;
    transition: width 1s;
    z-index: 2;
    overflow: hidden;
`;

const TextOuter = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 268px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    color: ${currentColors.text};
    z-index: 1;
`;

const TextInner = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 268px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    color: ${currentColors.background};
    z-index: 3;
`;

function formatted(data: IData): [string, number] {
    if (data.type === "percent") {
        return [`${data.value.toFixed(0)}%`, (data.value / 100) * 270];
    }
    if (data.type === "count") {
        return [`${data.current} / ${data.max}`, (data.current / data.max) * 270];
    }
}

function ProgressBar({ data, className }: IProps): JSX.Element {
    const [text, width] = formatted(data);
    return (
        <Outer className={className}>
            <TextOuter>{text}</TextOuter>
            <Inner style={{ width }}>
                <TextInner>{text}</TextInner>
            </Inner>
        </Outer>
    );
}

export default memo(ProgressBar);
