import type { JSX } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import t from "../../shared/translations";
import ShowError from "../components/ShowError";
import Thread from "./Thread";
import { type IThreadArgs, type IThreadData, threadQuery } from "./queries";

function ThreadLoader(): JSX.Element {
    const { id } = useParams();
    const [result] = useQuery<IThreadData, IThreadArgs>({
        query: threadQuery,
        variables: { id },
    });

    let main = null;
    if (result.fetching) {
        main = <Loader />;
    } else if (result.error) {
        main = <ShowError />;
    } else {
        const { aiThreadItems, standaloneAiChatbot } = result.data.standaloneAiThread;
        main = <Thread id={id} threadItems={aiThreadItems} chatbot={standaloneAiChatbot} />;
    }

    return (
        <>
            {main}
            <p style={{ marginTop: "1rem" }}>
                <Link to="..">{t("player.ai-chatbots-thread.link-back")}</Link>
            </p>
        </>
    );
}

export default ThreadLoader;
