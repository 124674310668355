import { FORM_ERROR, type ValidationErrors } from "final-form";
import arrayMutators from "final-form-arrays";
import createDecorator from "final-form-focus";
import { type JSX, useCallback, useMemo } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";
import { postJson } from "../api/base";
import { currentColors } from "../shared/colors";
import getInt from "../shared/getInt";
import t from "../shared/translations";
import Input from "./Input";
import QuestionsArray from "./QuestionsArray";
import type { IQuestion } from "./index";

interface IProps {
    questions: IQuestion[];
    anonymous: boolean;
    id: string;
    setFinished: (finished: boolean) => void;
    preview?: boolean;
}

interface IFormValues {
    firstName: string;
    lastName: string;
    answers: string[];
}

function validate(values: IFormValues, anonymous: boolean, preview: boolean): ValidationErrors {
    const errors: ValidationErrors = {};
    if (preview) {
        return errors;
    }
    if (!anonymous && !values.firstName) {
        errors.firstName = t("shared.collect-feedback.form-error-first-name-required");
    }
    if (!anonymous && !values.lastName) {
        errors.lastName = t("shared.collect-feedback.form-error-last-name-required");
    }
    errors.answers = new Array(values.answers.length);
    values.answers.forEach((answer, index) => {
        if (!answer) {
            errors.answers[index] = t("shared.collect-feedback.form-error-answer-required");
        }
    });
    return errors;
}

const NameRow = styled.div`
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    gap: 1rem;
    margin-bottom: 0.5rem;
    input {
        margin-bottom: 0;
    }
    small.error {
        margin-bottom: 0;
    }
`;

const Info = styled.div`
    font-size: 0.9rem;
    margin-bottom: 1rem;
    text-align: center;
`;

const Center = styled.div`
    text-align: center;
`;

const SubmitError = styled.span`
    color: white;
    padding: 5px 8px;
    background-color: ${currentColors.error};
`;

const H4 = styled.h4`
    margin-bottom: 0;
`;

const focusOnErrors = createDecorator();

function QuestionsForm({ questions, anonymous, id, setFinished, preview }: IProps): JSX.Element {
    const initialValues = useMemo((): IFormValues => {
        const values: IFormValues = {
            firstName: "",
            lastName: "",
            answers: questions.map(() => ""),
        };
        return values;
    }, [questions]);

    const onSubmit = useCallback(
        async (values: IFormValues): Promise<ValidationErrors | undefined> => {
            const args = {
                id,
                firstName: values.firstName,
                lastName: values.lastName,
                answers: values.answers.map((ans, idx) => ({
                    ...questions[idx],
                    textAnswer: questions[idx].type === "text" ? ans : null,
                    likertAnswer: questions[idx].type === "likert" ? getInt(ans) : null,
                })),
            };
            try {
                await postJson("/collect-feedback/submit", args);
            } catch {
                return { [FORM_ERROR]: "ERROR" };
            }
            setFinished(true);
        },
        [questions, id, setFinished],
    );

    return (
        <Form<IFormValues>
            onSubmit={onSubmit}
            validate={(v) => validate(v, anonymous, preview)}
            initialValues={initialValues}
            mutators={{ ...arrayMutators }}
            decorators={[focusOnErrors]}
        >
            {({ handleSubmit, submitting, submitError }) => (
                <form onSubmit={handleSubmit}>
                    {!anonymous && (
                        <>
                            <NameRow>
                                <H4>{t("shared.collect-feedback.form-heading-name")}</H4>
                                <Input
                                    name="firstName"
                                    placeholder={t("shared.collect-feedback.form-label-first-name")}
                                />
                                <Input
                                    name="lastName"
                                    placeholder={t("shared.collect-feedback.form-label-last-name")}
                                />
                            </NameRow>
                            <Info>{t("shared.collect-feedback.form-info-name-connected-to-answer")}</Info>
                        </>
                    )}
                    <QuestionsArray name="answers" questions={questions} />
                    {!preview && (
                        <Center>
                            <button type="submit" disabled={submitting}>
                                {t("shared.collect-feedback.form-button-submit")}
                            </button>
                            {submitError && (
                                <>
                                    <br />
                                    <SubmitError>{t("shared.collect-feedback.form-error-submit")}</SubmitError>
                                </>
                            )}
                        </Center>
                    )}
                </form>
            )}
        </Form>
    );
}

export default QuestionsForm;
