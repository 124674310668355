import type { ComponentPropsWithoutRef, JSX } from "react";
import { currentColors } from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
    white?: boolean;
}

function PlayButtonIcon({ active, white, ...props }: IProps): JSX.Element {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="50"
                cy="50"
                r="37"
                fill={white ? "transparent" : "white"}
                stroke={white ? "white" : currentColors.text}
            />
            <path
                d="m50 11.5c-21.2 0-38.5 17.3-38.5 38.5s17.3 38.5 38.5 38.5 38.5-17.3 38.5-38.5-17.3-38.5-38.5-38.5zm0 74c-19.6 0-35.5-15.9-35.5-35.5s15.9-35.5 35.5-35.5 35.5 15.9 35.5 35.5-15.9 35.5-35.5 35.5z"
                fill={white ? "white" : currentColors.text}
            />
            <path
                d="m40.5 67.1c-.3 0-.5-.1-.8-.2-.5-.3-.8-.8-.8-1.3v-31.2c0-.5.3-1 .8-1.3s1-.3 1.5 0l27 15.6c.5.3.8.8.8 1.3s-.3 1-.8 1.3l-27 15.6c-.2.1-.4.2-.7.2z"
                fill={white ? "white" : currentColors.text}
            />
            <path d="m42 37v26l22.5-13z" fill={active ? currentColors.icon3 : "white"} />
        </svg>
    );
}

export default PlayButtonIcon;
