import type { DoInGroupType, ShareAnswersType, SubmittedTaskStatus, TaskIcon, VariantType } from "../../shared/types";

export interface IArgs {
    id: string;
}

export const query = `
    query($id:String!) {
        pathInPath(id:$id) {
            id
            icon
            name
            url
            showGroup
            objectives
            description
            learningPathItem {
                id
                miles
                earnedMiles
                learningPath {
                    id
                    pathGroup {
                        id
                        members {
                            id
                            person {
                                id
                                firstName
                                lastName
                                profilePicSrc
                            }
                        }
                    }
                }
                tasks {
                    id
                    locked
                    doInGroup
                    icon
                    miles
                    shortTitle
                    deadline
                    url
                    shareAnswers
                    taskVariants {
                        id
                        variantType
                    }
                    submittedTask {
                        id
                        url
                        status
                    }
                }
            }
        }
    }
`;

export interface ITask {
    id: string;
    locked: boolean;
    doInGroup: DoInGroupType;
    icon: TaskIcon;
    shortTitle: string;
    deadline: string;
    miles: number;
    url: string;
    shareAnswers: ShareAnswersType;
    taskVariants: {
        id: string;
        variantType: VariantType;
    }[];
    submittedTask: {
        id: string;
        url: string;
        status: SubmittedTaskStatus;
    } | null;
}

interface IMember {
    id: string;
    person: {
        id: string;
        firstName: string;
        lastName: string;
        profilePicSrc: string;
    };
}

export interface IPathInPath {
    id: string;
    icon: TaskIcon;
    name: string;
    url: string;
    showGroup: boolean;
    objectives: string;
    description: string;
    learningPathItem: {
        id: string;
        miles: number;
        earnedMiles: number;
        tasks: ITask[];
        learningPath: {
            id: string;
            pathGroup: {
                id: string;
                members: IMember[];
            };
        };
    };
}

export interface IData {
    pathInPath: IPathInPath;
}

export function taskFinished(task: ITask): boolean {
    return task.submittedTask?.status === "accepted";
}

export function taskNeedsGroup(task: ITask): boolean {
    return task.doInGroup !== "none" || task.taskVariants.some((v) => v.variantType === "peer-feedback");
}

export function taskSharingAnswers(task: ITask): boolean {
    return task.shareAnswers !== "no_one";
}
