import type { CSSProperties, JSX } from "react";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import TrophyIcon from "../../shared/components/icons/TrophyIcon";
import t from "../../shared/translations";

interface IProps {
    miles: number;
    milesGoal: number;
}

const Text = styled.div`
    text-align: center;
    margin-top: 1rem;
`;

const Outer = styled.div`
    position: relative;
    background-color: ${currentColors.background};
    max-width: 800px;
    height: 27px;
    z-index: 0;
    border: 1px solid ${currentColors.border};
`;

const Inner = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: ${currentColors.progress};
    height: 25px;
    transition: width 1s;
    z-index: 2;
    overflow: hidden;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 27px;
    gap: 4px;
    max-width: calc(800px - 29px);
    margin: 0 auto 1.5rem auto;
`;

function PathProgress({ miles, milesGoal }: IProps): JSX.Element {
    const style: CSSProperties = {
        width: `${(Math.min(miles, milesGoal) / milesGoal) * 100}%`,
    };
    return (
        <div>
            <Text>{t("player.landing.miles-progress", { miles, milesGoal })}</Text>
            <Row>
                <div>
                    <Outer>
                        <Inner style={style} />
                    </Outer>
                </div>
                <TrophyIcon active={miles >= milesGoal} />
            </Row>
        </div>
    );
}

export default PathProgress;
