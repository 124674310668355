import type { ComponentPropsWithoutRef, JSX } from "react";
import { currentColors } from "../../../shared/colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    done: boolean;
}

function StatusBadge({ done, ...props }: IProps): JSX.Element {
    return (
        <svg viewBox="0 0 100 30" xmlns="http://www.w3.org/2000/svg" {...props}>
            <title>Status badge</title>
            <rect
                x="0"
                y="0"
                width="100"
                height="30"
                fill={done ? currentColors.statusGreen : currentColors.statusRed}
                rx="6"
                ry="6"
            />
            <text x="50" textAnchor="middle" y="22" fill="white" fontSize="18">
                {done ? "DONE" : "NOT DONE"}
            </text>
        </svg>
    );
}

export default StatusBadge;
