import { useState } from "react";
import type { JSX } from "react";
import { Bar, BarChart, Cell, ResponsiveContainer, Text, XAxis, YAxis } from "recharts";
import styled from "styled-components";
import { chartColor, currentColors } from "../colors";
import maxTextWidth from "../maxTextWidth";

interface IProps {
    answers: number[];
    options: {
        number: number;
        content: string;
        answerCount: number;
    }[];
}

interface IDatum {
    value: number;
    label: string;
    selected: boolean;
}

const FONT = '400 14px / 1.5 "Proxima Nova", Verdana, sans-serif';

function Tick({ answers, payload, ...props }: any): JSX.Element {
    const value: string = payload.value;
    const number = Number.parseInt(value.split(".")[0], 10);
    const bold = answers.includes(number);
    return (
        <Text {...props} fontWeight={bold ? "bold" : "normal"} fontSize={14} maxLines={2}>
            {value}
        </Text>
    );
}

const margins = { top: 5, right: 20, bottom: 5, left: 0 };

const Container = styled.div`
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
`;

function PollBarChart({ answers, options }: IProps): JSX.Element {
    const [width, setWidth] = useState(300);
    const data: IDatum[] = options.map((opt) => ({
        label: `${opt.number}. ${opt.content}`,
        value: opt.answerCount,
        selected: answers.includes(opt.number),
    }));
    const maxLabelSize = maxTextWidth(
        data.map((datum) => datum.label),
        FONT,
    );
    const yWidth = Math.min(maxLabelSize + 40, width / 2);
    const height = 70 + 32 * options.length;
    return (
        <Container>
            <ResponsiveContainer width="100%" height={height} onResize={(w) => setWidth(w)}>
                <BarChart data={data} layout="vertical" margin={margins}>
                    <YAxis dataKey="label" type="category" width={yWidth} tick={<Tick answers={answers} />} />
                    <XAxis type="number" />
                    <Bar
                        dataKey="value"
                        fill={currentColors.pollSelected}
                        isAnimationActive={false}
                        label={{ position: "right", fill: currentColors.text, fontSize: 14 }}
                    >
                        {data.map((datum, index) => (
                            <Cell key={index} fill={datum.selected ? currentColors.pollSelected : chartColor(2)} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </Container>
    );
}

export default PollBarChart;
