// LearningPath query for all data needed for calendar

import type {
    LearningPathItemType,
    SubmittedFeedbackTaskStatus,
    SubmittedTaskStatus,
    TimeEstimateType,
    VariantType,
} from "../../shared/types";

export const query = `
    query {
        currentPerson {
            id
            calendarShowStartDate
            calendarShowFinished
            learningPaths(filter:"ongoing") {
                id
                pathname
                learningPathItems {
                    id
                    type
                    event {
                        id
                        shortTitle
                        eventStart
                        eventEnd
                        url
                    }
                    feedbackTask {
                        id
                        shortTitle
                        callToAction
                        deadline
                        miles
                        url
                        submittedFeedbackTask {
                            id
                            status
                        }
                    }
                    tasks {
                        id
                        url
                        miles
                        shortTitle
                        callToAction
                        deadline
                        unlockDate
                        timeEstimate
                        taskVariants {
                            id
                            variantType
                            eventStart
                            eventEnd
                        }
                        submittedTask {
                            id
                            status
                        }
                    }
                    taskChoice {
                        id
                        url
                        name
                        unlockDate
                        callToAction
                        deadline
                        complete
                    }
                }
            }
        }
    }
`;

export interface ILearningPathItem {
    id: string;
    type: LearningPathItemType;
    event: {
        id: string;
        shortTitle: string;
        eventStart: string;
        eventEnd: string;
        url: string;
    } | null;
    feedbackTask: {
        id: string;
        shortTitle: string;
        callToAction: string;
        deadline: string | null;
        url: string;
        miles: number;
        submittedFeedbackTask: {
            id: string;
            status: SubmittedFeedbackTaskStatus;
        } | null;
    } | null;
    tasks: {
        id: string;
        url: string;
        miles: number;
        shortTitle: string;
        callToAction: string;
        deadline: string | null;
        unlockDate: string | null;
        timeEstimate: TimeEstimateType | null;
        taskVariants: {
            id: string;
            variantType: VariantType;
            eventStart: string | null;
            eventEnd: string | null;
        }[];
        submittedTask: {
            id: string;
            status: SubmittedTaskStatus;
        } | null;
    }[];
    taskChoice: {
        id: string;
        url: string;
        name: string;
        callToAction: string;
        deadline: string | null;
        unlockDate: string | null;
        complete: boolean;
    } | null;
}

export interface IData {
    currentPerson: {
        id: string;
        calendarShowStartDate: boolean;
        calendarShowFinished: boolean;
        learningPaths: {
            id: string;
            pathname: string;
            learningPathItems: ILearningPathItem[];
        }[];
    };
}

export type IPerfsArgs = {
    data: {
        calendarShowStartDate: boolean;
        calendarShowFinished: boolean;
    };
};

export const perfsMutation = `
    mutation($data:PlayerCurrentPersonCalendarPrefsData!) {
        currentPersonUpdateCalendarPerfs(data:$data) {
            currentPerson {
                id
                calendarShowStartDate
                calendarShowFinished
            }
            error
        }
    }
`;

export type IPerfsData = {
    currentPersonUpdateCalendarPerfs: {
        currentPerson: {
            id: string;
            calendarShowStartDate: boolean;
            calendarShowFinished: boolean;
        };
        error: string | null;
    };
};
