export const query = `
    query {
       terms {
            terms
            settingsTerms
        }
    }
`;

export interface IData {
    terms: {
        terms: string;
        settingsTerms: string | null;
    };
}
