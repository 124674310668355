import { type ReactNode, createContext, useCallback, useContext, useMemo } from "react";
import { useQuery } from "urql";
import { currentColors } from "../../shared/colors";

const query = `
    query {
        currentPerson {
            unreadMessages
        }
    }
`;

interface IData {
    currentPerson: {
        unreadMessages: number;
    };
}

interface IUnreadContext {
    unreadMessages: number;
    refetch: VoidFunction | undefined;
}
const defafaultValue: IUnreadContext = {
    unreadMessages: 0,
    refetch: undefined,
};

const UnreadMessagesContext = createContext<IUnreadContext>(defafaultValue);
UnreadMessagesContext.displayName = "UnreadMessagesContext";

interface IProps {
    children: ReactNode;
}

export function UnreadMessagesProvider({ children }: IProps): JSX.Element {
    const [result, refetchMessages] = useQuery<IData>({ query });

    const refetch = useCallback(() => {
        refetchMessages({ requestPolicy: "cache-and-network" });
    }, [refetchMessages]);

    const value = useMemo(
        () => ({
            unreadMessages: result.data?.currentPerson?.unreadMessages ?? 0,
            refetch,
        }),
        [result.data?.currentPerson?.unreadMessages, refetch],
    );

    return <UnreadMessagesContext.Provider value={value}>{children}</UnreadMessagesContext.Provider>;
}

export function useUnreadMessages(): ReactNode | null {
    const { unreadMessages } = useContext(UnreadMessagesContext);
    if (unreadMessages === 0) {
        return null;
    }
    return (
        <>
            <circle cx="20" cy="30" r="20" fill={currentColors.unread} stroke="white" strokeWidth="2" />
            <text x="20" y="38" fill="white" textAnchor="middle" fontSize={22}>
                {unreadMessages}
            </text>
        </>
    );
}

export function useRefetchUnreadMessages(): VoidFunction | undefined {
    const { refetch } = useContext(UnreadMessagesContext);
    return refetch;
}
