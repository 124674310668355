import { getJson } from "../base";

export interface Person {
    id: number;
    first_name: string;
    last_name: string;
    profile_pic_src: string;
    path_memberships: number[];
}

export interface IPathGroup {
    id: number;
    members: Person[];
}

export interface Message {
    id: number;
    datetime: string;
    message: string;
    path_group: number;
    person: number;
}

interface ChatData {
    path_group: IPathGroup;
    current_id: number;
    history: Message[];
}

export async function getData(id: number, signal?: AbortSignal): Promise<ChatData> {
    const url = `/api/player/chat-data/${id}`;
    return getJson(url, signal) as Promise<ChatData>;
}
