import * as Sentry from "@sentry/browser";
import { useCallback } from "react";
import type { JSX } from "react";
import styled from "styled-components";
import { useMutation, useQuery } from "urql";
import { currentColors } from "../../shared/colors";
import Loader from "../../shared/components/Loader";
import RenderMark from "../../shared/components/RenderMark";
import t from "../../shared/translations";
import ShowError from "../components/ShowError";
import AcceptForm from "./AcceptForm";
import { type IAcceptData, type IData, acceptMutation, query } from "./query";

const Wrapper = styled.main`
    padding: 0 15px;
    display: grid;
    gap: 0.5rem;
    max-width: 600px;
    width: 100%;
    max-height: 100vh;
`;

const TermsContent = styled.section`
    overflow-y: auto;
    padding: 5px;
    border: 1px solid ${currentColors.grey3};
    border-radius: 5px;
`;

function AcceptTerms(): JSX.Element {
    const [result] = useQuery<IData>({ query });
    const [acceptResult, executeMutation] = useMutation<IAcceptData>(acceptMutation);

    const onSubmit = useCallback(async () => {
        if (acceptResult.fetching) {
            return;
        }
        const result = await executeMutation();
        if (result.error) {
            console.error(result.error);
            Sentry.captureMessage(result.error.toString());
        } else if (result.data.currentUserAcceptTerms?.error) {
            console.error(result.data.currentUserAcceptTerms.error);
            Sentry.captureMessage(result.data.currentUserAcceptTerms.error);
        } else {
            window.location.href = result.data.currentUserAcceptTerms.redirectUrl;
        }
    }, [acceptResult, executeMutation]);

    if (result.fetching) {
        return (
            <Wrapper>
                <Loader />
            </Wrapper>
        );
    }

    if (result.error) {
        return (
            <Wrapper>
                <ShowError />
            </Wrapper>
        );
    }

    const terms = result.data.terms;

    return (
        <Wrapper>
            <header>
                <h1 className="small-only-text-center">{t("player.onboarding.heading")}</h1>
            </header>
            <TermsContent>
                {terms.settingsTerms && <RenderMark content={terms.settingsTerms} />}
                <RenderMark content={terms.terms} />
            </TermsContent>
            <section>
                <AcceptForm onSubmit={onSubmit} />
            </section>
        </Wrapper>
    );
}

export default AcceptTerms;
