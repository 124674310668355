import type { JSX, PropsWithChildren } from "react";
import useWindowSize from "../../shared/hooks/useWindowSize";
import type { OpenLockedModal } from "../LockedModal";
import Tabs, { type ITab, useTabState } from "./Tabs";
import TasksSidebar from "./TasksSidebar";
import { BIG_MOBILE_CUTOFF, SMALL_MOBILE_CUTOFF } from "./stylings";
import { DesktopRow, useTwoColStyle } from "./useTwoColStyle";
export { BIG_MOBILE_CUTOFF, SMALL_MOBILE_CUTOFF } from "./stylings";

interface IProps {
    openLockedModal: OpenLockedModal;
    mainLabel: string;
    activeItem?: string;
    startTab?: ITab;
    big: boolean;
}

function WithTasksSidebar({
    openLockedModal,
    children,
    activeItem,
    startTab,
    mainLabel,
    big,
}: PropsWithChildren<IProps>): JSX.Element {
    const { width } = useWindowSize();
    const { showMain, showSidebar, sidebarTab, ...tabsProps } = useTabState(big, startTab);
    const [mainColRef, taskColStyle] = useTwoColStyle(big);
    const cutoff = big ? BIG_MOBILE_CUTOFF : SMALL_MOBILE_CUTOFF;
    if (width < cutoff) {
        return (
            <>
                <Tabs {...tabsProps} mainLabel={mainLabel} big={big} />
                {showMain && <div>{children}</div>}
                {showSidebar && (
                    <TasksSidebar tab={sidebarTab} openLockedModal={openLockedModal} activeItem={activeItem} />
                )}
            </>
        );
    }
    return (
        <DesktopRow>
            <div style={taskColStyle}>
                <Tabs {...tabsProps} mainLabel={mainLabel} big={big} />
                <TasksSidebar tab={sidebarTab} openLockedModal={openLockedModal} activeItem={activeItem} />
            </div>
            <div ref={mainColRef} style={{ minWidth: 0, position: "relative", minHeight: "100%" }}>
                {children}
            </div>
        </DesktopRow>
    );
}

export default WithTasksSidebar;
