import type {
    DoInGroupType,
    ItemLockedType,
    LearningPathItemType,
    SubmittedTaskStatus,
    TaskIcon,
    VariantType,
} from "../../shared/types";

export interface IArgs {
    id: string;
}

export const query = `
    query($id:String!) {
        learningPath(id:$id) {
            id
            learningPathItems {
                id
                type
                locked
                lockedType
                title
                miles
                earnedMiles
                icon
                url
                complete
                active
                undecided
                redo
                callToAction
                deadline
                finishDatetime
                shareAnswers
                pathInPath {
                    id
                }
                taskChoice {
                    id
                }
                event {
                    id
                    eventStart
                }
                feedbackTask {
                    id
                }
                tasks {
                    id
                    doInGroup
                    icon
                    miles
                    shortTitle
                    taskVariants {
                        id
                        variantType
                    }
                    submittedTask {
                        id
                        status
                    }
                }
            }
            customItems: learningPathItems(filter:"custom") {
                id
                type
                title
                miles
                earnedMiles
                icon
                url
                complete
                active
                undecided
                redo
                finishDatetime
            }
        }
    }
`;

export interface IData {
    learningPath: {
        id: string;
        learningPathItems: {
            id: string;
            type: LearningPathItemType;
            miles: [number, number];
            earnedMiles: number;
            icon: TaskIcon;
            url: string;
            grouped: boolean;
            shareAnswers: boolean;
            pathInPath: {
                id: string;
            } | null;
            taskChoice: {
                id: string;
            } | null;
            event: {
                id: string;
                eventStart: string;
            } | null;
            locked: boolean;
            lockedType: ItemLockedType;
            title: string;
            active: boolean;
            undecided: boolean;
            redo: boolean;
            callToAction: string;
            finishDatetime: string | null;
            complete: boolean;
            deadline: string;
            feedbackTask: {
                id: string;
            } | null;
            tasks: {
                id: string;
                doInGroup: DoInGroupType;
                icon: TaskIcon;
                miles: number;
                shortTitle: string;
                taskVariants: {
                    id: string;
                    variantType: VariantType;
                }[];
                submittedTask: {
                    id: string;
                    status: SubmittedTaskStatus;
                };
            }[];
        }[];
        customItems: {
            id: string;
            type: "submitted-task-only";
            title: string;
            miles: number;
            earnedMiles: number;
            icon: TaskIcon;
            url: string;
            complete: boolean;
            active: boolean;
            undecided: boolean;
            redo: boolean;
            finishDatetime: string;
        }[];
    };
}
