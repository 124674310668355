import { Link } from "react-router-dom";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import OrigInput from "../components/FinalInput";

export const NavButtonAnchor = styled.a`
    display: inline-block;
    background-color: transparent;
    border: 1px solid ${currentColors.text};
    border-radius: 0;
    color: ${currentColors.text};
    margin: 0 5px 0.2rem 5px;
    font-size: 0.9rem;
    padding: 0.25rem 0.85rem;
    transition: none;
    line-height: 22px;

    &:hover, &:focus, &:active, &:visited {
        background-color: transparent;
        color: ${currentColors.text};
    }
`;

export const ButtonLink = styled(Link)`
    cursor: pointer;
    margin: 0 0 1.25rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    padding: 1rem 2rem 1.0625rem 2rem;
    font-size: 1rem;
    background-color: ${currentColors.button};
    color: white;
    line-height: normal;

    &:hover, &:focus, &:active, &:visited {
        background-color: ${currentColors.button};
        color: white;
    }
`;

interface ICenter {
    $gap?: string;
}

export const Center = styled.div<ICenter>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ $gap }) => $gap ?? "1rem"};

    > * {
        margin: 0;
    }
`;

export const NavSelect = styled.select`
    display: inline-block;
    width: auto;
    line-height: 22px;
    position: relative;
    border: 1px solid ${currentColors.grey4};
    padding: 0;
    margin: 0 5px 0.2rem 5px;
    display: inline-block;
    width: auto;
    background-color: transparent;
    border-radius: 0;
    color: ${currentColors.text};
    font-size: 0.9rem;
    padding: 0.25rem 0.85rem;
    padding-right: 1.2rem;
    height: 32px;
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;

    &:focus, &:active, &:visited {
        background-color: white;
        border: 1px solid ${currentColors.grey4};
    }
`;

export const PulseBox = styled(Link)`
    border: 1px solid ${currentColors.border};
    background-color: white;
    width: 300px;
    max-width: 100%;
    display: block;
    text-align: center;
    padding: 1.0rem 0.6rem;
    color: ${currentColors.button};

    &:hover, &:focus, &:active {
        color: ${currentColors.button};
    }
`;

export const PulseHeading = styled.h3`
    display: grid;
    justify-content: center;
    align-items: center;
    color: ${currentColors.border};
    grid-template-columns: auto auto;
    gap: 0.5rem;
    font-size: 1.1rem;
    text-align: left;
    svg {
        width: 2rem;
        height: 2rem;
    }
`;

export const Input = styled(OrigInput)`
    input {
        border: 1px solid ${currentColors.text};
        color: ${currentColors.text};
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: white;
        font-size: 1rem;
    }

    & small.error {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }

    &.error input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

interface IMaw {
    $max?: string;
}

export const Maw = styled.div<IMaw>`
    max-width: ${({ $max }) => $max ?? "900px"};
    margin: 0 auto;
`;

export const CopyBox = styled.div`
    display: grid;
    border: 1px solid ${currentColors.text};
    padding: 1rem;
    gap: 1rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    p {
        margin: 0;
    }
`;

export const CopyRow = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: stretch;
    input {
        margin: 0;
    }
    button {
        padding: 0.5rem 0.9rem;
        margin: 0;
        white-space: nowrap;
    }
`;

export const AnswerGrid = styled.div`
    display: grid;
    gap: 1rem;
    margin-bottom: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: min-content;
`;

interface IAnswerBox {
    $span: number;
}

export const AnswerBox = styled.div<IAnswerBox>`
    display: grid;
    border: 1px solid ${currentColors.text};
    padding: 1rem;
    border-radius: 1rem;
    align-content: start;
    @supports (grid-template-rows: subgrid) {
        grid-template-rows: subgrid;
        grid-row: span ${({ $span }) => $span};
    }
    h3 {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
    > div {
        font-size: 1.1rem;
        padding-bottom: 1rem;
        border-bottom: solid 1px ${currentColors.grey4};
        &:last-child {
            border-bottom: none;
        }
    }
`;

export const QrCode = styled.div`
    svg {
        width: 360px;
        height: 360px;
    }
`;

export const QrHeading = styled.h2`
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    grid-template-columns: auto auto;

    svg {
        width: 3rem;
        height: 3rem;
    }
`;

export const BigLink = styled.div`
    font-size: 2.3rem;
    white-space: nowrap;
    text-align: center;
`;

export const Button = styled.button`
    margin-bottom: 0;
`;

export const InputRow = styled.div`
    display: grid;
    align-items: center;
    gap: 0.5rem;
    grid-template-columns: 1fr auto;

    svg {
        width: 1rem;
        height: 1rem;
    }
`;

export const SuggestedList = styled.ul`
    li {
        cursor: pointer;
        margin-bottom: 0.5rem
    }
`;

export const SmallButton = styled.button`
    margin-bottom: 0;
    padding: 0.3rem 0.6rem 0.303rem 0.6rem;;
    font-size: 0.9rem;
`;

export const ButtonAnchor = styled.a`
    display: inline-block;
    background-color: ${currentColors.button};
    border-radius: 0;
    color: white;
    padding: 1rem 2rem 1.0625rem 2rem;
    transition: none;

    &:hover, &:focus, &:active, &:visited {
        background-color: ${currentColors.button};
        color: white;
    }
`;

export const ButtonRow = styled.div`
    display: flex;
     gap: 2px;
     justify-content: flex-start;
`;
