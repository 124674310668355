import { type JSX, useCallback, useState } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";
import { useMutation } from "urql";
import { currentColors } from "../../shared/colors";
import t from "../../shared/translations";
import { Button } from "../task/stylings";
import FinalTextarea from "./FinalTextarea";

interface IArgs {
    data: {
        submittedTaskId: string;
        content: string;
    };
}

const mutation = `
    mutation ($data: PlayerSubmittedTaskRespondFeedbackData!) {
        submittedTaskRespondFeedback(data: $data) {
           error
           submittedTask {
              id
           }
        }
    }
`;

interface IData {
    submittedTaskRespondFeedback: {
        error: string | null;
        submittedTask: {
            id: string;
        } | null;
    };
}

interface IFormValues {
    content: string;
}

function validate(values: IFormValues): any {
    const errors: any = {};
    if (!values.content) {
        errors.content = t("player.task.response-form-error");
    }
    return errors;
}

const defaultValues: IFormValues = {
    content: "",
};

interface IProps {
    id: string;
    invert: boolean;
}

export const Center = styled.div`
    text-align: center;
`;

export const Textarea = styled(FinalTextarea)`
    textarea {
        border: 1px solid ${currentColors.text};
        color: ${currentColors.text};
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: white;
        font-size: 1rem;
    }

    & small.error {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        border: 1px solid ${currentColors.text};
        border-top: none;
    }

    &.error textarea {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

function ResponseForm({ id, invert }: IProps): JSX.Element {
    const [respondResult, executeRespond] = useMutation<IData, IArgs>(mutation);
    const [error, setError] = useState<string | null>(null);

    const onSubmit = useCallback(
        async (values: IFormValues) => {
            setError(null);
            const result = await executeRespond({
                data: {
                    submittedTaskId: id,
                    content: values.content,
                },
            });
            if (result.error) {
                console.error(result.error);
                setError(t("player.error.error"));
            } else if (result.data?.submittedTaskRespondFeedback.error) {
                console.error(result.data?.submittedTaskRespondFeedback.error);
                setError(result.data?.submittedTaskRespondFeedback.error);
            }
        },
        [id, executeRespond],
    );

    return (
        <Form onSubmit={onSubmit} validate={validate} initialValues={defaultValues}>
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <Textarea name="content" placeholder={t("player.task.response-form-placeholder")} />
                    <Center>
                        <Button type="submit" disabled={submitting} $invert={invert}>
                            {t("player.task.response-form-submit")}
                        </Button>
                    </Center>
                    {error && <div>{error}</div>}
                </form>
            )}
        </Form>
    );
}

export default ResponseForm;
