import { produce } from "immer";
import type { JSX } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMutation } from "urql";
import { useImmerReducer } from "use-immer";
import * as UploadAPI from "../../../api/Upload";
import getRelativeUrl from "../../../shared/getRelativeUrl";
import t from "../../../shared/translations";
import { type ICurrentPerson, useCurrentPerson } from "../../components/CurrentPerson";
import { type Dispatch, type State, cancelTaskEditAnswer } from "../../state";
import type {
    IIncompleteAnswer,
    ISaveIncompleteMutationData,
    ISubmitArgs,
    ISubmitData,
    ISubmittedTask,
    ITask,
    ITaskQuestion,
    ITaskThreadItem,
    ITaskVariant,
} from "../query";
import { type ISaveIncompleteArgs, saveIncompleteMutation, submitMutation } from "../query";
import AnswerForm, { type IFormValues as ITaskFormValues } from "./AnswerForm";
import OutsideFeedbackForm, { type IFormValues as IFeedbackFormValues } from "./OutsideFeedbackForm";
import OutsideFeedbackInfo from "./OutsideFeedbackInfo";
import OutsideFeedbackPreview from "./OutsideFeedbackPreview";

interface IProps {
    task: ITask;
    shownTask: ISubmittedTask | ITask;
    redo: boolean;
    variant: ITaskVariant;
    invert: boolean;
}

type IPage = "answers" | "outsideFeedbackForm" | "outsideFeedbackPreview";

interface IState {
    page: IPage;
    ansIdx: number;
    numAnswers: number;
    taskValues: ITaskFormValues[];
    feedbackValue: IFeedbackFormValues;
    showSubmitError: boolean;
    errorToShow: string | null;
}

function withSavedAnswer(question: ITaskQuestion, index: number, incompleteAnswers: IIncompleteAnswer[]): string {
    const saved = incompleteAnswers.find(
        (answer) => answer.questionContent === question.questionContent && index === answer.part,
    );
    return saved?.answerContent ?? "";
}

function createInitialState([variant, current, editAnswer, pathname]: [
    ITaskVariant,
    ICurrentPerson,
    ITaskThreadItem | null,
    string,
]): IState {
    let outsideFeedbackDescription = "";
    if (variant.variantType === "outside-feedback") {
        const first_name = current.firstName;
        const last_name = current.lastName;
        const path_name = pathname ?? "";
        outsideFeedbackDescription = t("player.task.default-outside-feedback", { first_name, last_name, path_name });
    }
    let taskAnswers: ITaskFormValues[] = null;
    if (editAnswer) {
        taskAnswers = editAnswer.taskAnswers.map((answer, i) => ({
            answerType: answer.answerType,
            questionContent: answer.questionContent,
            answerContent: answer.answerContent,
            file: null,
            checkboxAnswers: answer.checkboxAnswers.map((ans) => ({ ...ans })),
            taskLikertAnswers: answer.taskLikertAnswers.map((ans) => ({
                statement: ans.statement,
                leftLabel: ans.leftLabel,
                rightLabel: ans.rightLabel,
                value: ans.value,
            })),
            requireAllCheckmarks: variant.taskQuestions[i].requireAllCheckmarks,
            showPollResults: true,
            impactAnswers:
                answer.impactTrackerAnswerSet?.impactLikertAnswers?.map((ans) => ({
                    statement: ans.statement,
                    leftLabel: ans.leftLabel,
                    rightLabel: ans.rightLabel,
                    value: ans.value,
                })) ?? [],
        }));
    } else {
        taskAnswers = variant.taskQuestions.map((question, index) => ({
            answerType: question.answerType,
            questionContent: question.questionContent,
            answerContent: withSavedAnswer(question, index, variant.incompleteAnswers),
            file: null,
            checkboxAnswers: question.checkboxOptions.map((opt) => ({
                content: opt.content,
                checked: false,
            })),
            taskLikertAnswers: question.taskLikertStatements.map((stmnt) => ({
                statement: stmnt.statement,
                leftLabel: stmnt.leftLabel,
                rightLabel: stmnt.rightLabel,
                value: null,
            })),
            requireAllCheckmarks: question.requireAllCheckmarks,
            showPollResults: true,
            impactAnswers:
                question.impactTracker?.impactLikertStatements?.map((stmnt) => ({
                    statement: stmnt.statement,
                    leftLabel: stmnt.leftLabel,
                    rightLabel: stmnt.rightLabel,
                    value: 5,
                })) ?? [],
        }));
    }
    return {
        page: "answers",
        ansIdx: 0,
        numAnswers: taskAnswers.length,
        taskValues: taskAnswers,
        feedbackValue: { outsideFeedbackDescription },
        showSubmitError: false,
        errorToShow: null,
    };
}

type IAction =
    | { type: "GO-TO-ANSWER"; values: ITaskFormValues; index: number; direction: "forward" | "back" }
    | { type: "START-SUBMIT"; taskValues: ITaskFormValues[]; feedbackValue: IFeedbackFormValues }
    | { type: "SET-ERROR"; to: boolean; error?: string }
    | { type: "GO-TO-OUTSIDE-FEEDBACK-FORM"; values: ITaskFormValues; index: number }
    | { type: "BACK-TO-ANSWERS"; value: IFeedbackFormValues }
    | { type: "GO-TO-OUTSIDE-FEEDBACK-PREVIEW"; value: IFeedbackFormValues }
    | { type: "BACK-TO-OUTSIDE-FEEDBACK-FORM" };

function reducer(draft: IState, action: IAction): void {
    switch (action.type) {
        case "GO-TO-ANSWER": {
            draft.taskValues[action.index] = action.values;
            const nextIdx = action.direction === "forward" ? action.index + 1 : action.index - 1;
            draft.ansIdx = nextIdx;
            return;
        }
        case "START-SUBMIT":
            draft.taskValues = action.taskValues;
            draft.feedbackValue = action.feedbackValue;
            draft.showSubmitError = false;
            draft.errorToShow = null;
            return;
        case "SET-ERROR":
            draft.showSubmitError = action.to;
            if (action.error) {
                draft.errorToShow = action.error;
            }
            return;
        case "GO-TO-OUTSIDE-FEEDBACK-FORM":
            draft.page = "outsideFeedbackForm";
            draft.taskValues[action.index] = action.values;
            return;
        case "BACK-TO-OUTSIDE-FEEDBACK-FORM":
            draft.page = "outsideFeedbackForm";
            return;
        case "BACK-TO-ANSWERS":
            draft.page = "answers";
            draft.feedbackValue = action.value;
            return;
        case "GO-TO-OUTSIDE-FEEDBACK-PREVIEW":
            draft.page = "outsideFeedbackPreview";
            draft.feedbackValue = action.value;
            return;
        default:
            throw new Error("Unknown action");
    }
}

function AnswerWizard({ task, shownTask, redo, variant, invert }: IProps): JSX.Element {
    const dispatch = useDispatch<Dispatch>();
    const current = useCurrentPerson();
    const pathname = task.learningPathItem.learningPath.pathname;
    const editAnswer = useSelector((state: State) => state.task.editAnswer);
    const [submitResult, executeSubmit] = useMutation<ISubmitData, ISubmitArgs>(submitMutation);
    const [state, localDispatch] = useImmerReducer(
        reducer,
        [variant, current, editAnswer, pathname],
        createInitialState,
    );
    const [saveIncompleteResult, executeSaveIncomplete] = useMutation<ISaveIncompleteMutationData, ISaveIncompleteArgs>(
        saveIncompleteMutation,
    );
    const navigate = useNavigate();

    const submit = async (taskValues: ITaskFormValues[], feedbackValue: IFeedbackFormValues): Promise<void> => {
        const filenames = new Array<string>(taskValues.length);
        localDispatch({ type: "START-SUBMIT", taskValues, feedbackValue });

        for (let i = 0, iMax = taskValues.length; i < iMax; i++) {
            const answer = taskValues[i];
            if (answer.answerType === "file") {
                try {
                    const filename = await UploadAPI.uploadFile(answer.file, "task-answer");
                    filenames[i] = filename;
                } catch (err) {
                    console.error(err);
                    localDispatch({ type: "SET-ERROR", to: true });
                    return;
                }
            }
        }
        const taskAnswers = taskValues.map((ans, i) => ({
            answerType: ans.answerType,
            questionContent: ans.questionContent,
            answerContent: ans.answerContent,
            fileAnswer: ans.file
                ? {
                      filename: filenames[i],
                      name: ans.file?.name,
                  }
                : null,
            checkboxAnswers: ans.checkboxAnswers,
            showPollResults: false,
            surveyGizmoRespId: null,
            taskLikertAnswers: ans.taskLikertAnswers.map((lkrt) => ({
                statement: lkrt.statement,
                leftLabel: lkrt.leftLabel,
                rightLabel: lkrt.rightLabel,
                value: lkrt.value,
            })),
            impactValues: ans.impactAnswers.map((impct) => impct.value),
        }));
        const result = await executeSubmit({
            data: {
                taskAnswers,
                taskId: task.id,
                variantId: variant.id,
                taskThreadItemId: editAnswer?.id ?? null,
                outsideFeedbackDescription: feedbackValue.outsideFeedbackDescription || null,
            },
        });
        if (result.error) {
            console.error(result.error);
            localDispatch({ type: "SET-ERROR", to: true });
        } else if (result.data.submitTask.error) {
            console.error(result.data.submitTask.error);
            localDispatch({ type: "SET-ERROR", to: true });
        } else {
            navigate(getRelativeUrl(result.data.submitTask.redirect));
        }
    };

    const saveIncomplete = async (values: ITaskFormValues[]): Promise<void> => {
        await executeSaveIncomplete({
            data: {
                submittedTaskId: shownTask.discriminant === "submitted_task" ? shownTask.id : null,
                variantId: shownTask.discriminant === "task" ? variant.id : null,
                answers: values
                    .map((ans, idx) => ({ idx, ...ans }))
                    .filter((ans) => ans.answerType === "textbox" && !!ans.answerContent)
                    .map((ans) => ({
                        questionContent: ans.questionContent,
                        part: ans.idx,
                        answerContent: ans.answerContent,
                    })),
            },
        });
    };

    const answerNext = async (values: ITaskFormValues, index: number): Promise<void> => {
        const isFinalAnswer = index === state.numAnswers - 1;
        if (isFinalAnswer) {
            if (variant.variantType === "outside-feedback") {
                localDispatch({ type: "GO-TO-OUTSIDE-FEEDBACK-FORM", index, values });
            } else {
                const taskValues = produce(state.taskValues, (draft) => {
                    draft[index] = values;
                });
                await submit(taskValues, state.feedbackValue);
            }
        } else {
            localDispatch({ type: "GO-TO-ANSWER", index, values, direction: "forward" });
        }
    };

    const answerBack = (values: ITaskFormValues, index: number): void => {
        localDispatch({ type: "GO-TO-ANSWER", index, values, direction: "back" });
    };

    const cancel = () => {
        dispatch(cancelTaskEditAnswer());
    };

    const backToAnswers = (value: IFeedbackFormValues): void => {
        localDispatch({ type: "BACK-TO-ANSWERS", value });
    };

    const toOutsideFeedbackPreview = (value: IFeedbackFormValues): void => {
        localDispatch({ type: "GO-TO-OUTSIDE-FEEDBACK-PREVIEW", value });
    };

    const feedbackFormSubmit = async (value: IFeedbackFormValues): Promise<void> => {
        await submit(state.taskValues, value);
    };

    const feedbackPreviewSubmit = async (): Promise<void> => {
        await submit(state.taskValues, state.feedbackValue);
    };

    const backToOutsideFeedbackForm = () => {
        localDispatch({ type: "BACK-TO-OUTSIDE-FEEDBACK-FORM" });
    };

    const showOutsideFeedbackPreview = variant.variantType === "outside-feedback" && !redo;
    const final =
        state.ansIdx === state.numAnswers - 1 &&
        (variant.variantType !== "outside-feedback" ||
            (!showOutsideFeedbackPreview && variant.variantType === "outside-feedback"));

    if (state.page === "answers") {
        return (
            <>
                {variant.variantType === "outside-feedback" && <OutsideFeedbackInfo redo={redo} />}
                <AnswerForm
                    index={state.ansIdx}
                    key={state.ansIdx}
                    invert={invert}
                    editAnswer={editAnswer}
                    initialValues={state.taskValues[state.ansIdx]}
                    onSubmit={answerNext}
                    goBack={state.ansIdx === 0 ? null : answerBack}
                    cancel={cancel}
                    final={final}
                    allValues={state.taskValues}
                    saveIncomplete={saveIncomplete}
                />
                {state.showSubmitError && <div>{state.errorToShow || t("player.task.error-submit-answer")}</div>}
                {variant.variantType === "collect-feedback" && !redo && (
                    <p style={{ textAlign: "center" }}>{t("player.task.info-collect-feedback-survey-close")}</p>
                )}
            </>
        );
    }
    if (state.page === "outsideFeedbackForm") {
        return (
            <>
                <OutsideFeedbackForm
                    redo={redo}
                    invert={invert}
                    initialValues={state.feedbackValue}
                    back={backToAnswers}
                    preview={showOutsideFeedbackPreview ? toOutsideFeedbackPreview : undefined}
                    onSubmit={feedbackFormSubmit}
                />
                {state.showSubmitError && <div>{state.errorToShow || t("player.task.error-submit-answer")}</div>}
            </>
        );
    }
    if (state.page === "outsideFeedbackPreview") {
        return (
            <>
                <OutsideFeedbackPreview
                    taskValues={state.taskValues}
                    feedbackValue={state.feedbackValue}
                    shownTask={shownTask}
                    variant={variant}
                    back={backToOutsideFeedbackForm}
                    invert={invert}
                    submit={feedbackPreviewSubmit}
                />
                {state.showSubmitError && <div>{state.errorToShow || t("player.task.error-submit-answer")}</div>}
            </>
        );
    }
}

export default AnswerWizard;
