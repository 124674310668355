import type { JSX } from "react";
import styled from "styled-components";
import { currentColors } from "../../../shared/colors";
import t from "../../../shared/translations";
import type { ITaskVariant } from "../query";
import { Bold, Box } from "../stylings";

interface IProps {
    variant: ITaskVariant;
}

const BlueBox = styled(Box)`
    background-color: ${currentColors.background};
`;

function Info({ variant }: IProps): JSX.Element {
    return (
        <BlueBox>
            <h3>{t("player.task.heading-collect-feedback-setup-instructions")}</h3>
            <ul>
                <li>{t("player.task.info-collect-feedback-title-description")}</li>
                {variant.suggestedQuestions.length > 0 && variant.allowTranslateCollectedFeedback && (
                    <li>{t("player.task.info-collect-feedback-edit-questions")}</li>
                )}
                {variant.allowAddQuestions && <li>{t("player.task.info-collect-feedback-add-questions")}</li>}
                <li>{t("player.task.info-collect-feedback-save")}</li>
                <li>{t("player.task.info-send-out-link")}</li>
                {variant.anonymousCollectedFeedback ? (
                    <li>{t("player.task.info-collect-feedback-anonymous")}</li>
                ) : (
                    <li>{t("player.task.info-collect-feedback-not-anonymous")}</li>
                )}
            </ul>
            {variant.allowAccessCollectedFeedback ? (
                <p>
                    <Bold>{t("player.task.info-collect-feedback-learning-coach-can-see-feedback")}</Bold>
                </p>
            ) : (
                <p>
                    <Bold>{t("player.task.info-collect-feedback-learning-coach-cannot-see-feedback")}</Bold>
                </p>
            )}
        </BlueBox>
    );
}

export default Info;
