import type { ComponentPropsWithoutRef, JSX } from "react";
import { currentColors } from "../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    locked?: boolean;
}

function LockIcon({ locked = true, ...props }: IProps): JSX.Element {
    return (
        <svg
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            {locked ? (
                <path
                    d="m68 40v-9.5c0-9.934-8.066-18-18-18s-18 8.066-18 18h-10.5c0-15.73 12.77-28.5 28.5-28.5s28.5 12.77 28.5 28.5h-5.25 5.25v9.86c6.58 1.482 11.5 7.366 11.5 14.39v29.5c0 8.141-6.609 14.75-14.75 14.75h-51.5c-8.141 0-14.75-6.609-14.75-14.75v-29.5c0-7.376 5.425-13.494 12.5-14.579v-9.671h10.5v9.5zm-13.616 24.691 3.616 25.309h-16l3.616-25.309c-2.177-1.43-3.616-3.894-3.616-6.691 0-4.415 3.585-8 8-8s8 3.585 8 8c0 2.797-1.439 5.261-3.616 6.691z"
                    fill={currentColors.taskLocked}
                />
            ) : (
                <path
                    d="m66.699 40 .56-.801c5.689-8.144 3.696-19.375-4.448-25.064-8.145-5.689-19.375-3.696-25.065 4.448l-8.607-6.013c9.007-12.895 26.79-16.051 39.685-7.043 11.347 7.927 15.153 22.649 9.765 34.854 6.535 1.514 11.411 7.377 11.411 14.369v29.5c0 8.141-6.609 14.75-14.75 14.75h-51.5c-8.141 0-14.75-6.609-14.75-14.75v-29.5c0-7.376 5.425-13.494 12.5-14.579l1-.171zm-12.315 24.691 3.616 25.309h-16l3.616-25.309c-2.177-1.43-3.616-3.894-3.616-6.691 0-4.415 3.585-8 8-8s8 3.585 8 8c0 2.797-1.439 5.261-3.616 6.691zm-30.686-44.333 5.441-7.788 8.607 6.013-5.44 7.788z"
                    fill={currentColors.taskUnlocked}
                />
            )}
        </svg>
    );
}

export default LockIcon;
