import type { ComponentPropsWithoutRef, JSX } from "react";
import { currentColors } from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function CrossroadIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg
            {...props}
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m13.522 44.294c.925-3.469 1.85-6.938 2.775-10.408h19.379c.525-4.628 1.049-9.257 1.574-13.886h11.25c-.023 1.717-.046 3.435-.069 5.152h3.138c-.023-1.717-.046-3.435-.069-5.152h11.25c.525 4.629 1.049 9.258 1.574 13.886h19.379c.925 3.47 1.85 6.939 2.775 10.408-2.735 0-5.471 0-8.207 0 .204.99.409 1.98.614 2.97h8.386c1.059 3.972 2.118 7.944 3.177 11.916-7.753 0-15.505 0-23.258 0 .787 6.94 1.573 13.88 2.36 20.82-5.75 0-11.5 0-17.25 0-.038-2.844-.076-5.688-.114-8.532-1.457 0-2.915 0-4.372 0-.038 2.844-.076 5.688-.114 8.532-5.75 0-11.5 0-17.25 0 .787-6.94 1.573-13.88 2.36-20.82-7.753 0-15.505 0-23.258 0 1.059-3.972 2.118-7.944 3.177-11.916h8.386c.205-.99.41-1.98.614-2.97-2.736 0-5.472 0-8.207 0zm38.19-8.416c-1.141 0-2.283 0-3.424 0-.027 2.081-.055 4.162-.083 6.243h3.59c-.028-2.081-.056-4.162-.083-6.243zm.152 11.386c-.014-.99-.027-1.98-.04-2.97-1.216 0-2.432 0-3.648 0-.013.99-.026 1.98-.04 2.97zm11.181 0c-.093-.99-.185-1.98-.278-2.97-2.735 0-5.471 0-8.207 0 .033.99.066 1.98.099 2.97zm-11.151 2.284c-1.263 0-2.525 0-3.788 0-.032 2.383-.064 4.765-.095 7.148h3.978c-.031-2.383-.063-4.765-.095-7.148zm23.264-2.284c-.179-.99-.357-1.98-.535-2.97-2.736 0-5.472 0-8.208 0 .119.99.238 1.98.357 2.97zm-23.557-19.708c-1.067 0-2.135 0-3.202 0-.025 1.892-.05 3.784-.075 5.676h3.352c-.025-1.892-.05-3.784-.075-5.676zm-6.26 19.708c.033-.99.066-1.98.099-2.97-2.736 0-5.472 0-8.207 0-.093.99-.185 1.98-.278 2.97zm6.693 12.755c-1.356 0-2.712 0-4.068 0-.034 2.606-.069 5.212-.104 7.818h4.276c-.035-2.606-.07-5.212-.104-7.818zm-18.806-12.755c.119-.99.238-1.98.357-2.97-2.736 0-5.472 0-8.208 0-.178.99-.356 1.98-.535 2.97z"
                fill={currentColors.text}
            />
            <path
                d="m52.186 71.468c.038 2.844.076 5.688.114 8.532-1.533 0-3.067 0-4.6 0 .038-2.844.076-5.688.114-8.532zm-.152-11.449c.034 2.606.069 5.212.104 7.818-1.425 0-2.851 0-4.276 0 .035-2.606.07-5.212.104-7.818zm-.14-10.471c.032 2.383.064 4.765.095 7.148-1.326 0-2.652 0-3.978 0 .031-2.383.063-4.765.095-7.148zm-.182-13.67c.027 2.081.055 4.162.083 6.243-1.197 0-2.393 0-3.59 0 .028-2.081.056-4.162.083-6.243zm-30.597 11.386c-2.795 0-5.59 0-8.386 0 .265-.99.529-1.98.793-2.97h8.207c-.204.99-.409 1.98-.614 2.97zm12.113 0c-2.795 0-5.59 0-8.386 0 .179-.99.357-1.98.535-2.97h8.208c-.119.99-.238 1.98-.357 2.97zm12.113 0c-2.795 0-5.59 0-8.386 0 .093-.99.185-1.98.278-2.97h8.207c-.033.99-.066 1.98-.099 2.97zm6.523 0c-1.243 0-2.485 0-3.728 0 .014-.99.027-1.98.04-2.97h3.648c.013.99.026 1.98.04 2.97zm35.407 0c-2.796 0-5.591 0-8.386 0-.205-.99-.41-1.98-.614-2.97h8.207c.264.99.528 1.98.793 2.97zm-12.113 0c-2.796 0-5.591 0-8.386 0-.119-.99-.238-1.98-.357-2.97h8.208c.178.99.356 1.98.535 2.97zm-12.113 0c-2.796 0-5.591 0-8.386 0-.033-.99-.066-1.98-.099-2.97h8.207c.093.99.185 1.98.278 2.97zm-11.545-27.264c.023 1.717.046 3.435.069 5.152-1.046 0-2.092 0-3.138 0 .023-1.717.046-3.435.069-5.152zm.101 7.556c.025 1.892.05 3.784.075 5.676-1.117 0-2.235 0-3.352 0 .025-1.892.05-3.784.075-5.676z"
                fill={active ? currentColors.icon4 : "white"}
            />
        </svg>
    );
}

export default CrossroadIcon;
