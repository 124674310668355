import { type JSX, memo } from "react";
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer } from "recharts";
import { currentColors } from "../../../shared/colors";

interface IProps {
    values: number[];
}

const chars = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

function SpiderChart({ values }: IProps): JSX.Element {
    const data = values.map((value, i) => ({ label: chars[i], value: value * 100 }));
    return (
        <ResponsiveContainer width="100%" height={300}>
            <RadarChart outerRadius={100} data={data}>
                <PolarGrid />
                <PolarAngleAxis dataKey="label" />
                <PolarRadiusAxis angle={90} domain={[0, 100]} tickCount={6} tick={false} />
                <Radar
                    dataKey="value"
                    stroke={currentColors.teamScan}
                    fill={currentColors.teamScan}
                    fillOpacity={0.6}
                    isAnimationActive={false}
                />
            </RadarChart>
        </ResponsiveContainer>
    );
}

export default memo(SpiderChart);
