import { type JSX, useState } from "react";
import styled from "styled-components";
import t from "../../shared/translations";
import type { OpenLockedModal } from "../LockedModal";
import Task from "./Task";
import type { IData } from "./query";
import { SMALL_MOBILE_CUTOFF } from "./stylings";

type ILearningPathItem = IData["learningPath"]["learningPathItems"][0];

interface IProps {
    path: IData["learningPath"];
    openLockedModal: OpenLockedModal;
    activeItem?: string;
}

const Wrapper = styled.div`
    overflow-x: hidden;
    max-height: calc(100% - 50px);
    margin-bottom: 1rem;
    @media (min-width: ${SMALL_MOBILE_CUTOFF}px) {
        overflow-y: auto;
    }
`;

const Input = styled.input`
    &[type=checkbox] {
        margin-bottom: 0px;
        margin-top: 12px;
    }
`;

type IDateExtractors = "callToAction" | "deadline";

function makeSorter(extractor: IDateExtractors): (itemA: ILearningPathItem, itemB: ILearningPathItem) => number {
    return (itemA, itemB) => {
        const dateA = itemA[extractor] ?? "9999-99-99";
        const dateB = itemB[extractor] ?? "9999-99-99";
        return dateA.localeCompare(dateB);
    };
}

function AllTasks({ path, openLockedModal, activeItem }: IProps): JSX.Element {
    const [checked, setChecked] = useState<boolean>(false);
    const all = [...path.learningPathItems];
    all.sort(makeSorter(checked ? "deadline" : "callToAction"));
    return (
        <>
            <label>
                <Input type="checkbox" checked={checked} onChange={(e) => setChecked(e.currentTarget.checked)} />{" "}
                {t("player.tasks-sidebar.label-sort-deadlines")}
            </label>
            <Wrapper>
                {all.map((item) => (
                    <Task key={item.id} item={item} openLockedModal={openLockedModal} activeItem={activeItem} />
                ))}
            </Wrapper>
        </>
    );
}

export default AllTasks;
