import type { ComponentPropsWithoutRef, JSX } from "react";
import { currentColors } from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function SunflowerSeedIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg
            {...props}
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="1.5"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m50 81.447c-27.224.177-19.809-52.855 0-62.687 19.809 9.832 27.224 62.864 0 62.687z"
                fill={active ? currentColors.icon4 : "white"}
            />
            <path
                d="m50 81.447c-27.224.177-19.809-52.855 0-62.687 19.809 9.832 27.224 62.864 0 62.687z"
                fill="none"
                stroke={currentColors.text}
                strokeWidth="3"
            />
            <path
                d="m50 18.894c-5.074 23.6-10.077 49.905 0 62.688-20.233-12.783-10.188-39.088 0-62.688zm0 62.688c10.077-12.783 5.074-39.088 0-62.688 10.188 23.6 20.233 49.905 0 62.688z"
                fill={currentColors.text}
            />
        </svg>
    );
}

export default SunflowerSeedIcon;
