import { type JSX, memo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import OrigLockIcon from "../../shared/components/icons/LockIcon";
import t from "../../shared/translations";
import { type Dispatch, type ICalendarEvent, calendarSelectEvent } from "../state";
import { breakpoints, fontSizes } from "./breakpoints";

interface IProps {
    event: ICalendarEvent;
}

interface IWrapper {
    $color: string;
    $backgroundColor: string;
    $borderColor: string;
}

const Wrapper = styled.div<IWrapper>`
    border-radius: 8px;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 1px 2px;
    background-color: ${(props) => props.$backgroundColor};
    color: ${(props) => props.$color};
    cursor: pointer;
    border: 1px solid ${(props) => props.$borderColor};

    @container (min-width: ${breakpoints.sm}) {
        padding: 2px 3px;
    }
    @container (min-width: ${breakpoints.md}) {
        padding: 3px 4px;
        white-space: wrap;
    }
    @container (min-width: ${breakpoints.lg}) {
        padding: 3px 4px;
    }
`;

const LockIcon = styled(OrigLockIcon)`
    height: ${fontSizes.xs};
    width: ${fontSizes.xs};
    @container (min-width: ${breakpoints.sm}) {
        height: ${fontSizes.xs};
        width: ${fontSizes.xs};
    }
    @container (min-width: ${breakpoints.md}) {
        height: ${fontSizes.xs};
        width: ${fontSizes.xs};
    }
    @container (min-width: ${breakpoints.lg}) {
        height: ${fontSizes.xs};
        width: ${fontSizes.xs};
    }
`;

function colorsNlable(event: ICalendarEvent): {
    color: string;
    backgroundColor: string;
    borderColor: string;
    label: string;
    showUnlock: boolean;
} {
    switch (event.type) {
        case "event":
            return {
                color: "white",
                backgroundColor: currentColors.calendarEvent,
                borderColor: currentColors.calendarEvent,
                label: `${event.event.shortTitle} | ${t("player.calendar.label-event")}`,
                showUnlock: false,
            };
        case "feedback-task-call-to-action":
            return {
                color: event.feedbackTask.complete ? currentColors.calendarStartDate : "white",
                backgroundColor: event.feedbackTask.complete ? "white" : currentColors.calendarStartDate,
                borderColor: currentColors.calendarStartDate,
                label: `${event.feedbackTask.shortTitle} | ${t("player.calendar.label-start-date")}`,
                showUnlock: false,
            };
        case "feedback-task-deadline":
            return {
                color: event.feedbackTask.complete ? currentColors.calendarDeadline : "white",
                backgroundColor: event.feedbackTask.complete ? "white" : currentColors.calendarDeadline,
                borderColor: currentColors.calendarDeadline,
                label: `${event.feedbackTask.shortTitle} | ${t("player.calendar.label-deadline")}`,
                showUnlock: false,
            };
        case "task-unlock":
            return {
                color: currentColors.calendarUnlock,
                backgroundColor: "white",
                borderColor: currentColors.calendarUnlock,
                label: `${event.task.shortTitle} | ${t("player.calendar.label-unlock-date")}`,
                showUnlock: true,
            };
        case "task-call-to-action":
            return {
                color: event.task.complete ? currentColors.calendarStartDate : "white",
                backgroundColor: event.task.complete ? "white" : currentColors.calendarStartDate,
                borderColor: currentColors.calendarStartDate,
                label: `${event.task.shortTitle} | ${t("player.calendar.label-start-date")}`,
                showUnlock: false,
            };
        case "task-deadline":
            return {
                color: event.task.complete ? currentColors.calendarDeadline : "white",
                backgroundColor: event.task.complete ? "white" : currentColors.calendarDeadline,
                borderColor: currentColors.calendarDeadline,
                label: `${event.task.shortTitle} | ${t("player.calendar.label-deadline")}`,
                showUnlock: false,
            };
        case "task-event":
            return {
                color: "white",
                backgroundColor: currentColors.calendarEvent,
                borderColor: currentColors.calendarEvent,
                label: `${event.event.shortTitle} | ${t("player.calendar.label-event")}`,
                showUnlock: false,
            };
        case "task-choice-unlock":
            return {
                color: currentColors.calendarUnlock,
                backgroundColor: "white",
                borderColor: currentColors.calendarUnlock,
                label: `${event.taskChoice.name} | ${t("player.calendar.label-unlock-date")}`,
                showUnlock: true,
            };
        case "task-choice-call-to-action":
            return {
                color: event.taskChoice.complete ? currentColors.calendarStartDate : "white",
                backgroundColor: event.taskChoice.complete ? "white" : currentColors.calendarStartDate,
                borderColor: currentColors.calendarStartDate,
                label: `${event.taskChoice.name} | ${t("player.calendar.label-start-date")}`,
                showUnlock: false,
            };
        case "task-choice-deadline":
            return {
                color: event.taskChoice.complete ? currentColors.calendarDeadline : "white",
                backgroundColor: event.taskChoice.complete ? "white" : currentColors.calendarDeadline,
                borderColor: currentColors.calendarDeadline,
                label: `${event.taskChoice.name} | ${t("player.calendar.label-deadline")}`,
                showUnlock: false,
            };
    }
}

function CalendarEvent({ event }: IProps): JSX.Element {
    const dispatch = useDispatch<Dispatch>();

    const handleClick = (): void => {
        dispatch(calendarSelectEvent(event.id));
    };

    const { color, backgroundColor, borderColor, label, showUnlock } = colorsNlable(event);

    return (
        <Wrapper onClick={handleClick} $color={color} $backgroundColor={backgroundColor} $borderColor={borderColor}>
            {showUnlock && <LockIcon />} {label}
        </Wrapper>
    );
}

export default memo(CalendarEvent);
