import type { ComponentPropsWithoutRef } from "react";
import { currentColors } from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function CampfireIcon({ active, ...props }: IProps) {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m23.5 60 l28 7 l24 -6.5 q10 3, 6 13 q5 10,-6 13 l-25 -6 l-27 7 q-12 -7,-3 -14 q-8 -6,3 -13.5z"
                fill="white"
            />
            <path
                d="m84.4 75.4c-.4-.8-.9-1.4-1.6-1.9.5-.5.9-1 1.2-1.6.9-1.9.8-4.3-.3-6.7-1.9-4.1-6.2-6.6-9.7-5.7l-22.9 5.6-25.2-6.3c-3.5-.9-7.8 1.6-9.7 5.7-1.1 2.3-1.2 4.8-.3 6.7.5 1.1 1.3 1.9 2.3 2.4-1.2.5-2.2 1.4-2.8 2.6-.9 1.9-.8 4.3.3 6.7 1.7 3.5 5.1 5.9 8.3 5.9.5 0 26.8-6.5 27.4-6.7 0 0 22.8 5.7 23 5.8.5.1 1 .2 1.5.2 3.2 0 6.6-2.4 8.3-5.9 1-2.4 1.1-4.9.2-6.8zm-9.7-13c2.1-.5 5 1.3 6.3 4.1.7 1.5.8 3 .3 4.1-.3.6-.8 1-1.4 1.2l-1.2.2h-.1l-21.3-5.3zm-49.9 23.3c-2.1.5-5-1.3-6.3-4.1-.7-1.5-.8-3-.3-4.1.3-.7.8-1.1 1.5-1.2.1 0 3.9-1 3.9-1h .1c.1 0 .1 0 .2-.1h.1l21.4 5.4c-7.7 1.8-20.5 5-20.6 5.1zm56.6-4.9c-1.3 2.7-4.2 4.6-6.3 4.1-.2-.1-55-13.8-55-13.8-.7-.2-1.2-.6-1.5-1.2-.5-1.1-.4-2.6.3-4.1 1.3-2.7 4.2-4.6 6.3-4.1 0 0 12.2 3.1 25.1 6.3.2.1.5.2.7.2 14.2 3.6 29 7.3 29.2 7.3.7.2 1.2.6 1.5 1.2.5 1.1.4 2.6-.3 4.1z"
                fill={currentColors.text}
            />
            <path
                d="m49.9 58.6c-9.8 0-17.8-8-17.9-17.9-.2-13.4 10.6-20.4 11-20.7.5-.3 1.2-.3 1.7 0s .7.9.6 1.5c0 0-.5 2.8.6 7.6.1.5.2.9.3 1.3.1-1.7.3-3.7.8-5.8 3-11.6 9-15.2 9.3-15.3.5-.3 1.2-.3 1.6.1.5.4.7.9.6 1.5 0 0-1 4.8 1.2 10.3l0 0c1 2.5 2.3 4.6 3.6 6.7 2.3 3.9 4.6 7.6 4.6 12.6-.1 10.1-8.1 18.1-18 18.1z"
                fill={currentColors.text}
            />
            <path
                d="m42.2 24.6c-2.9 2.7-7.3 8.2-7.2 16.1.1 8.2 6.8 14.9 14.9 14.9 8.2 0 14.9-6.7 14.9-14.9 0-4.2-1.9-7.4-4.1-11.1-1.3-2.2-2.7-4.4-3.8-7.1l0 0c-1.2-3.1-1.6-5.9-1.7-8.1-1.7 2-3.9 5.5-5.3 11-1.2 5.4-.6 10.1-.6 10.2.1.5-.2 1.1-.6 1.4s-1 .4-1.5.2c-.4-.2-2.9-1.5-4.2-7.2-.5-2.2-.7-4-.8-5.4z"
                fill={active ? currentColors.icon4 : "white"}
            />
        </svg>
    );
}

export default CampfireIcon;
