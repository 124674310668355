import type {
    AiMessageRoleType,
    AnswerType,
    CollectFeedbackQuestionType,
    ContentType,
    DoInGroupType,
    LearningPathItemType,
    PollChartType,
    ShareAnswersType,
    SubmittedTaskStatus,
    TaskIcon,
    TaskThreadItemSubtype,
    TaskThreadItemType,
    TeamScanStatusType,
    TimeEstimateType,
    VariantType,
} from "../../shared/types";

export interface IArgs {
    id: string;
}

export const query = `
    query($id:String!) {
        submittedTask(id:$id) {
            id
            status
            url
            shortTitle
            objectives
            inspiration {
                mediaType
                src
            }
            title
            icon
            miles
            timeEstimate
            finishDatetime
            showSharedAnswers
            feedbacker {
                id
                firstName
                lastName
                profilePicSrc
            }
            media {
                id
                mediaType
                src
                name
                logo
            }
            task {
                id
            }
            submittedTaskVariant {
                id
                variantType
                variantTitle
                description
                hoursQuestions
                eventStart
                eventEnd
                taskQuestions {
                    id
                    answerType
                    questionContent
                    quizId
                    pollId
                    surveyGizmoUrl
                    requireAllCheckmarks
                    showPollResults
                    checkboxOptions {
                        id
                        content
                    }
                }
                teamScan {
                    id
                    status
                }
                pulse {
                    id
                    question1
                    question2
                    link
                    pulseAnswers {
                        id
                        answer1
                        answer2
                    }
                }
                feedbackRequest {
                    id
                    url
                }
                taskAiThread {
                    id
                    aiThreadItems {
                        id
                        role
                        content
                    }
                }
            }
            learningPathItem {
                id
                type
                miles
                earnedMiles
                tasks {
                    id
                    locked
                    icon
                    doInGroup
                    shortTitle
                    miles
                    deadline
                    url
                    shareAnswers
                    taskVariants {
                        id
                        variantType
                    }
                    submittedTask {
                        id
                        url
                        status
                    }
                }
                pathInPath {
                    id
                    icon
                    url
                    showGroup
                }
                taskChoice {
                    id
                    icon
                    url
                    name
                    complete
                }
                learningPath {
                    id
                    open
                    pathGroup {
                        id
                        members {
                            id
                            person {
                                id
                                firstName
                                lastName
                                profilePicSrc
                            }
                        }
                    }
                }
            }
            collectFeedback {
                id
                anonymous
                collectFeedbackAnswerSets {
                    id
                    firstName
                    lastName
                    submitted
                    collectFeedbackAnswers {
                        id
                        nr
                        type
                        question
                        translation
                        leftLabel
                        rightLabel
                        leftTranslation
                        rightTranslation
                        textAnswer
                        likertAnswer
                    }
                }
            }
            thread {
                id
                type
                subtype
                contentType
                content
                person {
                    id
                    firstName
                    lastName
                    profilePicSrc
                }
                taskAnswers {
                    id
                    answerType
                    questionContent
                    answerContent
                    checkboxAnswers {
                        id
                        content
                        checked
                    }
                    taskLikertAnswers {
                        id
                        statement
                        leftLabel
                        rightLabel
                        value
                    }
                    showPollResults
                    quizAnswerSet {
                        id
                        results
                        answers {
                            id
                            answer
                            answeredQuestion {
                                id
                                number
                                levelNumber
                                description
                                correctAnswer
                                explanation
                                quizOptions {
                                    id
                                    letter
                                    content
                                }
                            }
                        }
                    }
                    pollAnswerSet {
                        id
                        answers {
                            id
                            answers
                            question {
                                id
                                number
                                content
                                chartType
                                options {
                                    id
                                    number
                                    content
                                    answerCount
                                }
                            }
                        }
                    }
                    taskAnswerFile {
                        id
                        logoSrc
                        src
                        name
                    }
                    impactTrackerAnswerSet {
                        id
                        impactLikertAnswers {
                            id
                            statement
                            leftLabel
                            rightLabel
                            value
                        }
                    }
                }
            }
            sharedAnswers {
                id
                taskId
                type
                people {
                    id
                    firstName
                    lastName
                    profilePicSrc
                }
                taskGroups 
                pathGroups 
                read
                taskAnswers {
                    id
                    answerType
                    questionContent
                    answerContent
                    checkboxAnswers {
                        id
                        content
                        checked
                    }
                    taskLikertAnswers {
                        id
                        statement
                        leftLabel
                        rightLabel
                        value
                    }
                    showPollResults
                    quizAnswerSet {
                        id
                        results
                        answers {
                            id
                            answer
                            answeredQuestion {
                                id
                                number
                                levelNumber
                                description
                                correctAnswer
                                explanation
                                quizOptions {
                                    id
                                    letter
                                    content
                                }
                            }
                        }
                    }
                    pollAnswerSet {
                        id
                        answers {
                            id
                            answers
                            question {
                                id
                                number
                                content
                                chartType
                                options {
                                    id
                                    number
                                    content
                                    answerCount
                                }
                            }
                        }
                    }
                    taskAnswerFile {
                        id
                        logoSrc
                        src
                        name
                    }
                    impactTrackerAnswerSet {
                        id
                        impactLikertAnswers {
                            id
                            statement
                            leftLabel
                            rightLabel
                            value
                        }
                    }
                }
            }
        }
    }
`;

export interface IPerson {
    id: string;
    firstName: string;
    lastName: string;
    profilePicSrc: string;
}

interface IQuizAnswer {
    id: string;
    answer: string;
    answeredQuestion: {
        id: string;
        number: number;
        levelNumber: number;
        description: string;
        correctAnswer: string;
        explanation: string | null;
        quizOptions: {
            id: string;
            letter: string;
            content: string;
        }[];
    };
}

export interface IQuizAnswerSet {
    id: string;
    results: [number, number];
    answers: IQuizAnswer[];
}

interface IPollAnswer {
    id: string;
    answers: number[];
    question: {
        id: string;
        number: number;
        content: string;
        chartType: PollChartType;
        options: {
            id: string;
            number: number;
            content: string;
            answerCount: number;
        }[];
    };
}

export interface IPollAnswerSet {
    id: string;
    answers: IPollAnswer[];
}

export interface ITaskAnswerFile {
    id: string;
    src: string;
    logoSrc: string;
    name: string;
}

export interface ITaskAnswer {
    id: string;
    answerType: AnswerType;
    questionContent: string | null;
    answerContent: string | null;
    checkboxAnswers: {
        id: string;
        content: string;
        checked: boolean;
    }[];
    taskLikertAnswers: {
        id: string;
        statement: string;
        leftLabel: string;
        rightLabel: string;
        value: number;
    }[];
    showPollResults: boolean;
    quizAnswerSet: IQuizAnswerSet;
    pollAnswerSet: IPollAnswerSet;
    taskAnswerFile: ITaskAnswerFile | null;
    impactTrackerAnswerSet: {
        id: string;
        impactLikertAnswers: {
            id: string;
            statement: string;
            leftLabel: string;
            rightLabel: string;
            value: number;
        }[];
    } | null;
}

export interface ISharedAnswer {
    id: string;
    type: TaskThreadItemType;
    people: IPerson[] | null;
    taskAnswers: ITaskAnswer[];
    taskGroups: string[] | null;
    pathGroups: string[] | null;
    read: boolean;
    taskId: string;
}

export interface ITaskThreadItem {
    id: string;
    type: TaskThreadItemType;
    subtype: TaskThreadItemSubtype;
    contentType: ContentType;
    content: string | null;
    person: IPerson;
    taskAnswers: ITaskAnswer[];
}

interface IMedia {
    id: string | number;
    mediaType: string;
    src: string;
    name: string;
    logo: string | null;
}

interface ICollectFeedbackAnswerSet {
    id: string;
    firstName: string;
    lastName: string;
    submitted: string;
    collectFeedbackAnswers: {
        id: string;
        nr: number;
        type: CollectFeedbackQuestionType;
        question: string;
        translation: string | null;
        leftLabel: string | null;
        rightLabel: string | null;
        leftTranslation: string | null;
        rightTranslation: string | null;
        textAnswer: string | null;
        likertAnswer: number | null;
    }[];
}

interface ITaskQuestion {
    id: string;
    answerType: AnswerType;
    quizId: string | null;
    pollId: string | null;
    questionContent: string;
    surveyGizmoUrl: string;
    requireAllCheckmarks: boolean;
    checkboxOptions: {
        id: string;
        content: string;
    }[];
}

interface IPulse {
    id: string;
    question1: string;
    question2: string | null;
    link: string;
    pulseAnswers: {
        id: string;
        answer1: string;
        answer2: string | null;
    }[];
}

export interface ITaskAiThread {
    id: string;
    aiThreadItems: {
        id: string;
        role: AiMessageRoleType;
        content: string;
    }[];
}

interface ISubmittedTaskVariant {
    id: string;
    variantType: VariantType;
    variantTitle: string;
    description: string;
    hoursQuestions: string;
    eventStart: string | null;
    eventEnd: string | null;
    taskQuestions: ITaskQuestion[];
    teamScan: {
        id: string;
        status: TeamScanStatusType;
    } | null;
    pulse: IPulse | null;
    feedbackRequest: IFeedbackRequest | null;
    taskAiThread: ITaskAiThread | null;
}

interface IMember {
    id: string;
    person: {
        id: string;
        firstName: string;
        lastName: string;
        profilePicSrc: string;
    };
}

export interface IFeedbackRequest {
    id: string;
    url: string;
}

interface ILearningPathItem {
    id: string;
    type: LearningPathItemType;
    miles: number;
    earnedMiles: number;
    tasks: {
        id: string;
        locked: boolean;
        doInGroup: DoInGroupType;
        shortTitle: string;
        icon: TaskIcon;
        miles: number;
        deadline: string;
        url: string;
        shareAnswers: ShareAnswersType;
        taskVariants: {
            id: string;
            variantType: VariantType;
        }[];
        submittedTask: {
            id: string;
            url: string;
            status: SubmittedTaskStatus;
        };
    }[];
    pathInPath: {
        id: string;
        icon: TaskIcon;
        url: string;
        showGroup: boolean;
    } | null;
    taskChoice: {
        id: string;
        icon: TaskIcon;
        url: string;
        name: string;
        complete: boolean;
    } | null;
    learningPath: {
        id: string;
        open: boolean;
        pathGroup: {
            id: string;
            members: IMember[];
        };
    };
}

export interface ISubmittedTask {
    id: string;
    status: SubmittedTaskStatus;
    url: string;
    shortTitle: string;
    objectives: string | null;
    inspiration: {
        mediaType: string;
        src: string;
    } | null;
    task: {
        id: string;
    };
    title: string | null;
    finishDatetime: string;
    showSharedAnswers: boolean;
    icon: TaskIcon;
    miles: number;
    timeEstimate: TimeEstimateType | null;
    media: IMedia[];
    submittedTaskVariant: ISubmittedTaskVariant;
    feedbacker: IPerson | null;
    learningPathItem: ILearningPathItem | null;
    collectFeedback: {
        id: string;
        anonymous: boolean;
        collectFeedbackAnswerSets: ICollectFeedbackAnswerSet[];
    } | null;
    thread: ITaskThreadItem[];
    sharedAnswers: ISharedAnswer[];
}

export interface IData {
    submittedTask: ISubmittedTask;
}

// Mark read mutaion

export interface IMarkReadArgs {
    ids: string[];
}

export const markReadMutation = `
    mutation($ids:[String!]!) {
        submittedTaskMarkReads(ids:$ids) {
            error        }
    }
`;

export interface IMarkReadData {
    submittedTaskMarkReads: {
        error: string | null;
    };
}
