import type { JSX } from "react";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import PulseIcon from "../../shared/components/icons/taskIcons/PulseIcon";
import { formatLong, parseDate } from "../../shared/dateFns";
import t from "../../shared/translations";
import ShowError from "../components/ShowError";
import { ButtonLink } from "../task/stylings";
import PulseInfo from "./PulseInfo";
import { type IListData, listQuery } from "./queries";
import { Center, PulseBox, PulseHeading } from "./stylings";

function Landing(): JSX.Element {
    const [result] = useQuery<IListData>({
        query: listQuery,
    });

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }

    return (
        <>
            <h1>{t("player.pulse-landing.heading")}</h1>
            <Center>
                <PulseInfo />
                <ButtonLink to="setup">{t("player.pulse-landing.button-create")}</ButtonLink>
                {result.data.pulseList.map((pulse) => (
                    <PulseBox key={pulse.id} to={`../${pulse.id}/results`}>
                        <PulseHeading>
                            <PulseIcon />
                            {pulse.name || t("player.pulse-landing.heading-pulse")}
                        </PulseHeading>
                        <div>{formatLong(parseDate(pulse.createdDatetime))}</div>
                    </PulseBox>
                ))}
            </Center>
        </>
    );
}

export default Landing;
