import type { ComponentPropsWithoutRef, JSX } from "react";
import { currentColors } from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function StarsIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg
            {...props}
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="1.5"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m39.226 28.138 2.08 7.025c.189.637.774 1.074 1.438 1.074h7.098l-5.858 4.67c-.478.381-.677 1.013-.503 1.599l2.165 7.312-5.485-4.372c-.547-.437-1.323-.437-1.87 0l-5.485 4.372 2.165-7.312c.173-.586-.026-1.218-.503-1.599l-5.859-4.67h7.098c.665 0 1.25-.437 1.439-1.074z"
                fill={active ? currentColors.icon4 : "white"}
            />
            <path
                d="m39.226 22.856 3.518 11.881h11.386l-9.211 7.343 3.518 11.882-9.211-7.343-9.212 7.343 3.519-11.882-9.212-7.343h11.386z"
                fill="none"
                stroke={currentColors.text}
                strokeWidth="3"
            />
            <path
                d="m66.995 52.521 1.395 4.712c.189.637.774 1.075 1.439 1.075h4.882s-4.066 3.241-4.066 3.241c-.478.38-.677 1.013-.503 1.598l1.48 5-3.692-2.943c-.547-.437-1.323-.437-1.87 0l-3.692 2.943 1.48-5c.174-.585-.025-1.218-.503-1.598l-4.066-3.241h4.882c.665 0 1.25-.438 1.438-1.075z"
                fill={active ? currentColors.icon4 : "white"}
            />
            <path
                d="m66.995 47.239 2.834 9.569h9.17l-7.419 5.913 2.834 9.569-7.419-5.914-7.419 5.914 2.834-9.569-7.419-5.913h9.17z"
                fill="none"
                stroke={currentColors.text}
                strokeWidth="3"
            />
            <path
                d="m71.666 19.046.889 3.25c.179.652.771 1.104 1.447 1.104h3.525l-3.061 2.642c-.438.378-.62.974-.467 1.532l1.037 3.793-2.39-2.063c-.563-.486-1.397-.486-1.96 0l-2.39 2.063 1.038-3.793c.152-.558-.029-1.154-.467-1.532l-3.061-2.642h3.525c.676 0 1.268-.452 1.446-1.104z"
                fill={active ? currentColors.icon4 : "white"}
            />
            <path
                d="m71.666 13.36 2.336 8.54h7.559l-6.115 5.278 2.335 8.54-6.115-5.278-6.115 5.278 2.336-8.54-6.115-5.278h7.559z"
                fill="none"
                stroke={currentColors.text}
                strokeWidth="3"
            />
            <path
                d="m27.732 65.87 1.515 4.094c.151.41.475.734.886.886l4.093 1.515-4.093 1.515c-.411.152-.735.475-.886.886l-1.515 4.094-1.515-4.094c-.152-.411-.476-.734-.886-.886l-4.094-1.515 4.094-1.515c.41-.152.734-.476.886-.886z"
                fill={active ? currentColors.icon4 : "white"}
            />
            <path
                d="m27.732 61.548 2.921 7.895 7.896 2.922-7.896 2.921-2.921 7.896-2.922-7.896-7.895-2.921 7.895-2.922z"
                fill="none"
                stroke={currentColors.text}
                strokeWidth="3"
            />
            <path
                d="m54.062 79.258 1.071 2.893c.152.411.476.734.886.886l2.894 1.071-2.894 1.071c-.41.152-.734.475-.886.886l-1.071 2.894-1.07-2.894c-.152-.411-.476-.734-.886-.886l-2.894-1.071 2.894-1.071c.41-.152.734-.475.886-.886z"
                fill={active ? currentColors.icon4 : "white"}
            />
            <path
                d="m54.062 74.935 2.478 6.696 6.695 2.477-6.695 2.478-2.478 6.695-2.477-6.695-6.695-2.478 6.695-2.477z"
                fill="none"
                stroke={currentColors.text}
                strokeWidth="3"
            />
            <path
                d="m19.783 13.214 1.151 3.11c.152.411.476.735.886.887l3.111 1.151-3.111 1.151c-.41.152-.734.475-.886.886l-1.151 3.111-1.151-3.111c-.152-.411-.476-.734-.886-.886l-3.111-1.151 3.111-1.151c.41-.152.734-.476.886-.887z"
                fill={active ? currentColors.icon4 : "white"}
            />
            <path
                d="m19.783 8.892 2.558 6.912 6.912 2.558-6.912 2.558-2.558 6.912-2.558-6.912-6.912-2.558 6.912-2.558z"
                fill="none"
                stroke={currentColors.text}
                strokeWidth="3"
            />
            <path
                d="m12.235 45.904.107.291c.152.41.476.734.886.886l.291.108s-.291.107-.291.107c-.41.152-.734.476-.886.886l-.107.291s-.108-.291-.108-.291c-.152-.41-.476-.734-.886-.886l-.291-.107s.291-.108.291-.108c.41-.152.734-.476.886-.886z"
                fill={active ? currentColors.icon4 : "white"}
            />
            <path
                d="m12.235 41.581 1.514 4.093 4.093 1.515-4.093 1.514-1.514 4.093-1.515-4.093-4.093-1.514 4.093-1.515z"
                fill="none"
                stroke={currentColors.text}
                strokeWidth="3"
            />
            <path
                d="m80.59 81.258.327.884c.152.41.475.734.886.886l.884.327-.884.328c-.411.152-.734.475-.886.886l-.327.884-.328-.884c-.152-.411-.475-.734-.886-.886l-.884-.328.884-.327c.411-.152.734-.476.886-.886z"
                fill={active ? currentColors.icon4 : "white"}
            />
            <path
                d="m80.59 76.935 1.734 4.686 4.686 1.734-4.686 1.734-1.734 4.686-1.734-4.686-4.687-1.734 4.687-1.734z"
                fill="none"
                stroke={currentColors.text}
                strokeWidth="3"
            />
            <path
                d="m50 14.214.108.291c.152.411.475.734.886.886l.291.108s-.291.108-.291.108c-.411.152-.734.475-.886.886l-.108.291s-.108-.291-.108-.291c-.152-.411-.475-.734-.886-.886l-.291-.108s.291-.108.291-.108c.411-.152.734-.475.886-.886z"
                fill={active ? currentColors.icon4 : "white"}
            />
            <g fill="none" stroke={currentColors.text} strokeWidth="3">
                <path d="m50 9.892 1.514 4.092 4.093 1.515-4.093 1.514-1.514 4.093-1.514-4.093-4.093-1.514 4.093-1.515z" />
                <path d="m83.696 8.892.908 2.454 2.454.908-2.454.909-.908 2.454-.909-2.454-2.454-.909 2.454-.908z" />
                <path d="m87.421 46.07.908 2.455 2.455.908-2.455.908-.908 2.455-.908-2.455-2.455-.908 2.455-.908z" />
                <path d="m44.393 60.428.908 2.454 2.454.908-2.454.908-.908 2.455-.909-2.455-2.454-.908 2.454-.908z" />
                <path d="m14.189 82.543.908 2.454 2.454.908-2.454.908-.908 2.455-.909-2.455-2.454-.908 2.454-.908z" />
            </g>
            <path d="m34.499 16.536h-1.55c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5h1.55v-1.55c0-.828.672-1.5 1.5-1.5.827 0 1.5.672 1.5 1.5v1.55h1.55c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5h-1.55v1.55c0 .828-.673 1.5-1.5 1.5-.828 0-1.5-.672-1.5-1.5z" />
            <path d="m8.766 33.882h-1.551c-.827 0-1.5-.672-1.5-1.5s.673-1.5 1.5-1.5h1.551v-1.55c0-.828.672-1.5 1.5-1.5.827 0 1.5.672 1.5 1.5v1.55h1.55c.827 0 1.5.672 1.5 1.5s-.673 1.5-1.5 1.5h-1.55v1.55c0 .828-.673 1.5-1.5 1.5-.828 0-1.5-.672-1.5-1.5z" />
            <path d="m12.222 64.801h-1.55c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5h1.55v-1.55c0-.828.673-1.5 1.5-1.5.828 0 1.5.672 1.5 1.5v1.55h1.55c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5h-1.55v1.55c0 .828-.672 1.5-1.5 1.5-.827 0-1.5-.672-1.5-1.5z" />
            <path d="m57.275 46.95h-1.55c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5h1.55v-1.55c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5v1.55h1.55c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5h-1.55v1.55c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5z" />
            <path d="m87.032 33.882h-1.551c-.827 0-1.5-.672-1.5-1.5s.673-1.5 1.5-1.5h1.551v-1.55c0-.828.672-1.5 1.5-1.5.827 0 1.5.672 1.5 1.5v1.55h1.55c.827 0 1.5.672 1.5 1.5s-.673 1.5-1.5 1.5h-1.55v1.55c0 .828-.673 1.5-1.5 1.5-.828 0-1.5-.672-1.5-1.5z" />
            <path d="m86.154 69.351h-1.55c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5h1.55v-1.55c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5v1.55h1.55c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5h-1.55v1.55c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5z" />
            <path d="m34.68 91.275h-1.55c-.828 0-1.5-.672-1.5-1.5 0-.827.672-1.5 1.5-1.5h1.55v-1.55c0-.827.672-1.5 1.5-1.5s1.5.673 1.5 1.5v1.55h1.55c.828 0 1.5.673 1.5 1.5 0 .828-.672 1.5-1.5 1.5h-1.55v1.551c0 .827-.672 1.5-1.5 1.5s-1.5-.673-1.5-1.5z" />
        </svg>
    );
}

export default StarsIcon;
