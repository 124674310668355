import Modal from "react-modal";
import styled from "styled-components";
import { currentColors } from "../colors";

Modal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,0.45)";

const StyledModal = styled(Modal)`
    position: absolute;
    top: 4vw;
    left: 4vw;
    right: 4vw;
    max-height: calc(100vh - 8vw);
    overflow: auto;
    border-radius: 3px;
    padding: 2.2rem 1rem 1rem 1rem;
    border: solid 1px ${currentColors.grey4};
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
    background: white;
`;

const CloseCross = styled.span`
    color: ${currentColors.grey3};
    cursor: pointer;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1;
    position: absolute;
    top: .625rem;
    right: 1.375rem;
    text-decoration: none;
    content: '×';
`;

const Wrapper = styled.div`
    max-width: 1410px;
    margin: 0 auto;
    padding-bottom: 2rem;
`;

const Modl: React.FC<Modal.Props> = ({ children, ...props }) => {
    return (
        <StyledModal {...props}>
            <Wrapper>{children}</Wrapper>
            <CloseCross onClick={(e) => props.onRequestClose(e)}>×</CloseCross>
        </StyledModal>
    );
};

export default Modl;
