import once from "lodash-es/once";
import type {
    AnswerType,
    AppRatingType,
    ItemLockedType,
    Language,
    TaskIcon,
    TaskLockedType,
    TaskTheme,
    VariantType,
} from "./types";

export interface Settings {
    logo: number;
    logo_src: string | null;
    default_admin: number;
    default_yellow_cutoff: number;
    default_red_cutoff: number;
    title: string;
    primary_color: string;
    secondary_color: string;
    alt_primary_color: string;
    alt_secondary_color: string;
    default_organisation: number | null;
    builder_base_price: number;
    builder_feedback_price: number;
    builder_price_range: number;
    ai_settings: {
        edit_personalities: string[];
        feedback_personalities: string[];
        summary_personalities: string[];
        reflect_personalities: string[];
        standalone_personalities: string[];
    };
}

export interface JSData {
    sentry_data: {
        id: string | null;
    };
    current_person: {
        id: string;
        is_admin: boolean;
        locale: Language;
    } | null;
    selected: {
        path_id: string | null;
        pm_id: string | null;
        path_group_id: string | null;
    };
    task_data: {
        variant_types: VariantType[];
        answer_types: AnswerType[];
        task_icons: TaskIcon[];
        task_themes: TaskTheme[];
        theme_colors: Record<TaskTheme, string>;
    };
    version: string;
    features: {
        sentry: boolean;
        diplomas: boolean;
        tracking: boolean;
        custom_tasks: boolean;
        inspiration: boolean;
        shopify: boolean;
        learning_coach: boolean;
        team_scan: boolean;
        quickscan: boolean;
        cpd: boolean;
        cpd_price: boolean;
        spent_miles: boolean;
        strategy_star: boolean;
        pulse: boolean;
        ai: boolean;
    };
    analysis_modules: string[];
    formats: {
        image_formats: string[];
        audio_formats: string[];
        file_formats: string[];
        answer_formats: string[];
        max_upload_size: number;
    };
    settings: Settings;
    languages: Language[];
    copy_to_submitted: boolean;
    avatar_path: string;
    quiz_required_all_correct: boolean;
    player_logo_src: string;
    show_individuals_in_tracking: boolean;
    allow_lc_access_collected_feedback: boolean;
    show_secondary_logo: boolean;
    google_api_key: string;
    faq_link: string | null;
    min_password_length: number;
    current_banner_notice: string | null;
    task_locked_types: TaskLockedType[];
    item_locked_types: ItemLockedType[];
    app_rating_types: AppRatingType[];
    sentry_dsn: string | null;
    report_defaults: Record<Language, Record<string, string>>;
}

export const loadJSData = once((): JSData => {
    const elem = document.getElementById("js-data");
    const data = JSON.parse(elem.innerHTML) as JSData;
    return data;
});
