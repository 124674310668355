import { Link } from "react-router-dom";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import OrigInput from "../components/FinalInput";

export const ButtonAnchor = styled.a`
    display: inline-block;
    background-color: transparent;
    border: 1px solid ${currentColors.text};
    border-radius: 0;
    color: ${currentColors.text};
    margin: 0;
    font-size: 0.9rem;
    padding: 0.25rem 0.85rem;
    transition: none;
    line-height: 22px;

    &:hover, &:focus, &:active, &:visited {
        background-color: transparent;
        color: ${currentColors.text};
    }
`;

export const ButtonLink = styled(Link)`
    cursor: pointer;
    margin: 0 0 1.25rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    padding: 1rem 2rem 1.0625rem 2rem;
    font-size: 1rem;
    background-color: ${currentColors.button};
    color: white;
    line-height: normal;

    &:hover, &:focus, &:active, &:visited {
        background-color: ${currentColors.button};
        color: white;
    }
`;

export const NavSelect = styled.select`
    display: inline-block;
    width: auto;
    line-height: 22px;
    position: relative;
    border: 1px solid ${currentColors.grey4};
    padding: 0;
    margin: 0;
    display: inline-block;
    width: auto;
    background-color: transparent;
    border-radius: 0;
    color: ${currentColors.text};
    font-size: 0.9rem;
    padding: 0.25rem 0.85rem;
    padding-right: 1.2rem;
    height: 32px;
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;

    &:focus, &:active, &:visited {
        background-color: white;
        border: 1px solid ${currentColors.grey4};
    }
`;

export const Input = styled(OrigInput)`
    input {
        border: 1px solid ${currentColors.text};
        color: ${currentColors.text};
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: white;
        font-size: 1rem;
    }

    & small.error {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }

    &.error input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

interface IMaw {
    $max?: string;
}

export const Maw = styled.div<IMaw>`
    max-width: ${({ $max }) => $max ?? "900px"};
    margin: 0 auto;
`;

export const Button = styled.button`
    margin-bottom: 0;
`;

export const SmallButton = styled.button`
    margin-bottom: 0;
    padding: 0.3rem 0.6rem 0.303rem 0.6rem;;
    font-size: 0.9rem;
`;

export const ChatbotButton = styled(Button)`
    display: block;
    border: 3px solid ${currentColors.button};
    border-radius: 10px;
    color: ${currentColors.text};
    color: ${currentColors.text};
    padding: 1rem;
    background-color: white;
    text-align: left;
    
    &:hover, &:focus {
        color: ${currentColors.text};
        background-color: white;
    }
    
    h3 {
        margin: 0;
        display: grid;
        grid-template-columns: 50px 1fr;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
    }

    svg {
        width: 50px;
        height: 50px;
    }
    
`;

interface IColumn {
    $maxWidth?: string;
}

export const Column = styled.div<IColumn>`
    display: grid;
    gap: 1rem;
    max-width: ${({ $maxWidth }) => $maxWidth ?? "auto"};
`;

export const ThreadLink = styled(Link)`
    display: block;
    cursor: pointer;
    border: 1px solid ${currentColors.text};
    padding: 0.5rem;
`;

export const PrevHeading = styled.h2`
    font-size: 1.6rem;
    margin: 0;
`;
