import Linkify from "linkify-react";
import { type JSX, type MouseEvent, memo } from "react";
import styled from "styled-components";
import { useMutation } from "urql";
import { currentColors } from "../../shared/colors";
import { formatYMD, parseDate } from "../../shared/dateFns";
import t from "../../shared/translations";
import { useRefetchUnreadMessages } from "../components/UnreadMessages";
import PrevThread from "./PrevThread";
import {
    type IMessage,
    type IToggleArchiveArgs,
    type IToggleArchiveData,
    type IToggleReadArgs,
    type IToggleReadData,
    toggleArchiveMutataion,
    toggleReadMutation,
} from "./query";

const MessageWrapper = styled.div`
    border: 1px solid ${currentColors.text};
    margin: 0 0 1px 0;
    border-radius: 0.5rem;
    padding: 0.5rem;
    container-type: inline-size;
`;

interface IMessageHead {
    $unread: boolean;
}

const MessageHead = styled.div<IMessageHead>`
    cursor: pointer;
    font-weight: ${({ $unread }) => ($unread ? "bold" : "normal")};
    display: grid;
    gap: 8px;
    padding: 4px;
    grid-template-columns: 100px 1fr;
    grid-template-areas: 
        "date sender"
        "subject subject";

    @container (min-width: 800px) {
        grid-template-columns: 100px minmax(150px, auto) 1fr;
        grid-template-areas: 
            "date sender subject";
    }
`;

const HeaderDate = styled.div`
    grid-area: date;
`;

const HeaderSender = styled.div`
    grid-area: sender;
`;

const HeaderSubject = styled.div`
    grid-area: subject;
`;

const Body = styled.div`
    margin: 0.5rem 0 0 0;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
`;

const Actions = styled.div`
    margin: 0.5rem 0 0 0;
`;

const FakeLink = styled.span`
    color: ${currentColors.link};
    cursor: pointer;

    &:hover {
        color: ${currentColors.link};
    }
`;

interface IProps {
    message: IMessage;
    open: boolean;
    toggleOpen: (id: string) => void;
    openReply: (message: IMessage) => void;
}

function Message({ message, open, toggleOpen, openReply }: IProps): JSX.Element {
    const [readResult, executeRead] = useMutation<IToggleReadData, IToggleReadArgs>(toggleReadMutation);
    const [archiveResult, executeArchive] = useMutation<IToggleArchiveData, IToggleArchiveArgs>(toggleArchiveMutataion);
    const refetchUnread = useRefetchUnreadMessages();

    const clickHeader = async () => {
        toggleOpen(message.id);
        if (!open && !message.read && !readResult.fetching) {
            await executeRead({ id: message.id, read: true });
            refetchUnread?.();
        }
    };

    const clickArchive = (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        if (!archiveResult.fetching) {
            void executeArchive({ id: message.id, archived: true });
        }
    };

    const clickMarkUnread = async (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        if (!archiveResult.fetching) {
            await executeRead({ id: message.id, read: false });
            refetchUnread?.();
        }
    };

    const clickReply = (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        openReply(message);
    };

    const actionLinks = [];
    if (!message.archived) {
        actionLinks.push(
            <FakeLink key="archive" onClick={clickArchive}>
                {t("player.messages.button-archive-message")}
            </FakeLink>,
        );
        actionLinks.push(" | ");
        actionLinks.push(
            <FakeLink key="unread" onClick={clickMarkUnread}>
                {t("player.messages.button-mark-unread-message")}
            </FakeLink>,
        );
    }
    if (message.canReply) {
        if (actionLinks.length > 0) {
            actionLinks.push(" | ");
        }
        actionLinks.push(
            <FakeLink key="reply" onClick={clickReply}>
                {t("player.messages.button-reply")}
            </FakeLink>,
        );
    }

    return (
        <MessageWrapper>
            <MessageHead $unread={!message.read} onClick={clickHeader}>
                <HeaderDate>{formatYMD(parseDate(message.datetime))}</HeaderDate>
                <HeaderSender>{message.senderName}</HeaderSender>
                <HeaderSubject>{message.subject}</HeaderSubject>
            </MessageHead>
            {open && (
                <Body>
                    <PrevThread id={message.id} />
                    <div>
                        <Linkify options={{ nl2br: true }}>{message.content}</Linkify>
                    </div>
                    <Actions>{actionLinks}</Actions>
                </Body>
            )}
        </MessageWrapper>
    );
}

export default memo(Message);
