import { type ComponentPropsWithoutRef, type JSX, memo } from "react";

type IProps = ComponentPropsWithoutRef<"svg">;

function CalendarIcon(props: IProps): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
            <path
                d="m33.5 54.2c0 1.6-1.3 2.8-2.8 2.8h-6.7c-1.6 0-2.8-1.3-2.8-2.8v-4.9c0-1.6 1.3-2.8 2.8-2.8h6.7c1.6 0 2.8 1.3 2.8 2.8z"
                fill="white"
            />
            <path d="m30.6 45h-6.7c-2.4 0-4.3 1.9-4.3 4.3v4.9c0 2.4 1.9 4.3 4.3 4.3h6.7c2.4 0 4.3-1.9 4.3-4.3v-4.9c.1-2.4-1.9-4.3-4.3-4.3zm1.4 9.2c0 .7-.6 1.3-1.3 1.3h-6.7c-.7 0-1.3-.6-1.3-1.3v-4.9c0-.7.6-1.3 1.3-1.3h6.7c.7 0 1.3.6 1.3 1.3z" />
            <path
                d="m56.1 54.2c0 1.6-1.3 2.8-2.8 2.8h-6.7c-1.6 0-2.8-1.3-2.8-2.8v-4.9c0-1.6 1.3-2.8 2.8-2.8h6.7c1.6 0 2.8 1.3 2.8 2.8z"
                fill="white"
            />
            <path d="m53.3 45h-6.7c-2.4 0-4.3 1.9-4.3 4.3v4.9c0 2.4 1.9 4.3 4.3 4.3h6.7c2.4 0 4.3-1.9 4.3-4.3v-4.9c0-2.4-1.9-4.3-4.3-4.3zm1.3 9.2c0 .7-.6 1.3-1.3 1.3h-6.7c-.7 0-1.3-.6-1.3-1.3v-4.9c0-.7.6-1.3 1.3-1.3h6.7c.7 0 1.3.6 1.3 1.3z" />
            <path
                d="m78.8 54.2c0 1.6-1.3 2.8-2.8 2.8h-6.7c-1.6 0-2.8-1.3-2.8-2.8v-4.9c0-1.6 1.3-2.8 2.8-2.8h6.7c1.6 0 2.8 1.3 2.8 2.8z"
                fill="white"
            />
            <path d="m75.9 45h-6.7c-2.4 0-4.3 1.9-4.3 4.3v4.9c0 2.4 1.9 4.3 4.3 4.3h6.7c2.4 0 4.3-1.9 4.3-4.3v-4.9c.1-2.4-1.9-4.3-4.3-4.3zm1.4 9.2c0 .7-.6 1.3-1.3 1.3h-6.7c-.7 0-1.3-.6-1.3-1.3v-4.9c0-.7.6-1.3 1.3-1.3h6.7c.7 0 1.3.6 1.3 1.3z" />
            <path
                d="m33.5 73.5c0 1.6-1.3 2.8-2.8 2.8h-6.7c-1.6 0-2.8-1.3-2.8-2.8v-4.9c0-1.6 1.3-2.8 2.8-2.8h6.7c1.6 0 2.8 1.3 2.8 2.8z"
                fill="white"
            />
            <path d="m30.6 64.4h-6.7c-2.4 0-4.3 1.9-4.3 4.3v4.9c0 2.4 1.9 4.3 4.3 4.3h6.7c2.4 0 4.3-1.9 4.3-4.3v-4.9c.1-2.4-1.9-4.3-4.3-4.3zm1.4 9.1c0 .7-.6 1.3-1.3 1.3h-6.7c-.7 0-1.3-.6-1.3-1.3v-4.9c0-.7.6-1.3 1.3-1.3h6.7c.7 0 1.3.6 1.3 1.3z" />
            <path
                d="m56.1 73.5c0 1.6-1.3 2.8-2.8 2.8h-6.7c-1.6 0-2.8-1.3-2.8-2.8v-4.9c0-1.6 1.3-2.8 2.8-2.8h6.7c1.6 0 2.8 1.3 2.8 2.8z"
                fill="white"
            />
            <path d="m53.3 64.4h-6.7c-2.4 0-4.3 1.9-4.3 4.3v4.9c0 2.4 1.9 4.3 4.3 4.3h6.7c2.4 0 4.3-1.9 4.3-4.3v-4.9c0-2.4-1.9-4.3-4.3-4.3zm1.3 9.1c0 .7-.6 1.3-1.3 1.3h-6.7c-.7 0-1.3-.6-1.3-1.3v-4.9c0-.7.6-1.3 1.3-1.3h6.7c.7 0 1.3.6 1.3 1.3z" />
            <path
                d="m78.8 73.5c0 1.6-1.3 2.8-2.8 2.8h-6.7c-1.6 0-2.8-1.3-2.8-2.8v-4.9c0-1.6 1.3-2.8 2.8-2.8h6.7c1.6 0 2.8 1.3 2.8 2.8z"
                fill="white"
            />
            <path d="m75.9 64.4h-6.7c-2.4 0-4.3 1.9-4.3 4.3v4.9c0 2.4 1.9 4.3 4.3 4.3h6.7c2.4 0 4.3-1.9 4.3-4.3v-4.9c.1-2.4-1.9-4.3-4.3-4.3zm1.4 9.1c0 .7-.6 1.3-1.3 1.3h-6.7c-.7 0-1.3-.6-1.3-1.3v-4.9c0-.7.6-1.3 1.3-1.3h6.7c.7 0 1.3.6 1.3 1.3z" />
            <path d="m80.7 21.6h-10.2v-3c0-2.4-1.9-4.3-4.3-4.3h-.8c-2.4 0-4.3 1.9-4.3 4.3v3h-22.1v-3c0-2.4-1.9-4.3-4.3-4.3h-.8c-2.4 0-4.3 1.9-4.3 4.3v3h-10.3c-5.5 0-10 4.5-10 10v44.6c0 5.5 4.5 10 10 10h61.4c5.5 0 10-4.5 10-10v-44.6c0-5.5-4.5-10-10-10zm-16.7-3c0-.7.6-1.3 1.3-1.3h.8c.7 0 1.3.6 1.3 1.3v7.5c0 .7-.6 1.3-1.3 1.3h-.8c-.7 0-1.3-.6-1.3-1.3zm-31.5 0c0-.7.6-1.3 1.3-1.3h.8c.7 0 1.3.6 1.3 1.3v7.5c0 .7-.6 1.3-1.3 1.3h-.8c-.7 0-1.3-.6-1.3-1.3zm-20.2 13c0-3.8 3.1-7 7-7h10.2v1.5c0 2.4 1.9 4.3 4.3 4.3h.8c2.4 0 4.3-1.9 4.3-4.3v-1.5h22.1v1.5c0 2.4 1.9 4.3 4.3 4.3h.8c2.4 0 4.3-1.9 4.3-4.3v-1.5h10.2c3.9 0 7 3.1 7 7v4.7h-75.3zm75.4 44.6c0 3.9-3.1 7-7 7h-61.4c-3.9 0-7-3.1-7-7v-36.9h75.4z" />
        </svg>
    );
}

export default memo(CalendarIcon);
