import { type JSX, memo } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { currentColors } from "../../../shared/colors";
import { formatYMD, parseDate } from "../../../shared/dateFns";
import t from "../../../shared/translations";
import type { ITeamScan } from "./Landing";

interface IProps {
    teamScan: ITeamScan;
}

const box = css`
    border: 1px solid ${currentColors.border};
    background-color: white;
    width: 100%;
    max-width: 600px;
    display: block;
    text-align: center;
    padding: 1.6rem 0.6rem;
    color: ${currentColors.button};

    &:hover, &:focus, &:active {
        color: ${currentColors.button};
    }
`;

const LinkBox = styled(Link)`
    ${box}
`;

const LinkAnchor = styled.a`
    ${box}
`;

function TeamScanBox({ teamScan }: IProps): JSX.Element {
    let pathName = null;
    if (teamScan?.submittedTaskVariant?.submittedTask?.learningPathItem?.learningPath) {
        pathName = (
            <>
                <br />
                {teamScan?.submittedTaskVariant?.submittedTask?.learningPathItem?.learningPath?.pathname}
            </>
        );
    }

    switch (teamScan.status) {
        case "setup-survey":
            return (
                <LinkBox to={`setup/${teamScan.id}`}>
                    {t("player.team-scan-landing.box-create")}
                    {pathName}
                </LinkBox>
            );
        case "do-survey":
            return (
                <LinkAnchor href={teamScan.myLink}>
                    {t("player.team-scan-landing.box-answer")}
                    {pathName}
                </LinkAnchor>
            );
        case "waiting-for-responses": {
            const [complete, total] = teamScan.progress;
            return (
                <LinkBox to={`progress/${teamScan.id}`}>
                    {t("player.team-scan-landing.box-progress", { complete, total })}
                    {pathName}
                </LinkBox>
            );
        }
        case "reporting":
            return (
                <LinkBox to={`report/${teamScan.id}`}>
                    {t("player.team-scan-landing.box-result", { date: formatYMD(parseDate(teamScan.reportDatetime)) })}
                    {pathName}
                </LinkBox>
            );
        default:
            return null;
    }
}

export default memo(TeamScanBox);
