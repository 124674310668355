import type { CSSProperties, JSX } from "react";
import { useDispatch } from "react-redux";
import { currentColors } from "../../shared/colors";
import t from "../../shared/translations";
import Modal from "../components/Modal";
import { type Dispatch, openModal } from "../state";
import Send from "./Send";

interface IProps {
    color?: string;
    padding?: string;
}

function SendButton({ color, padding }: IProps): JSX.Element {
    const dispatch = useDispatch<Dispatch>();

    const openSend = () => {
        dispatch(openModal("Messages/Send"));
    };

    const style: CSSProperties = {
        backgroundColor: color ?? currentColors.button,
        padding,
    };

    return (
        <>
            <button onClick={openSend} type="button" style={style}>
                {t("player.messages.button-send-message")}
            </button>
            <Modal modal="Messages/Send">
                <Send />
            </Modal>
        </>
    );
}

export default SendButton;
