import type { CSSProperties, JSX } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Field } from "react-final-form";
import styled from "styled-components";
import { currentColors } from "../../../shared/colors";
import t from "../../../shared/translations";
import type { ITeamScanSurvey } from "./Progress";
import StatusBadge from "./StatusBadge";

interface IProps {
    teamScanSurvey: ITeamScanSurvey;
    showCopied: VoidFunction;
    edit: (id: string) => void;
}

const style: CSSProperties = {
    color: currentColors.link,
};

const Row = styled.div`
    display: grid;
    grid-template-columns: 80px 1fr 1fr;
    grid-template-areas:
        "name name name"
        "email email email"
        "status resend copy";
    gap: 10px;
    &:nth-of-type(even) {
        background-color: ${currentColors.background};
    }
    padding: 5px 15px;
    margin: 0 -15px;
    @media (min-width: 500px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-areas:
            "name name name email email email"
            "status status resend resend copy copy";
    }
    @media (min-width: 800px) {
        grid-template-columns: 2fr 3fr 80px 1fr 1fr;
        grid-template-areas:
            "name  email status resend copy";
        margin: 0;
        padding: 5px;
    }
`;

const Name = styled.div`
    grid-area: name;
`;

const Email = styled.div`
    grid-area: email;
`;

const Status = styled.div`
    grid-area: status;
    display: grid;
    align-items: center;
    svg {
        width: 80px;
        height: 24px;
    }
`;

const Resend = styled.div`
    grid-area: resend;
    input {
        margin-bottom: 0;
    }
    label {
        font-size: 1rem;
    }
`;

const Copy = styled.div`
    grid-area: copy;
    cursor: pointer;
`;

function ProgressRow({ teamScanSurvey, showCopied, edit }: IProps): JSX.Element {
    return (
        <Row>
            <Name>
                {teamScanSurvey.firstName} {teamScanSurvey.lastName}
            </Name>
            <Email>
                {teamScanSurvey.email}{" "}
                <span className="fake-link" onClick={() => edit(teamScanSurvey.id)} style={style}>
                    {t("player.team-scan-progress.link-edit-email")}
                </span>
            </Email>
            <Status>
                <StatusBadge done={!!teamScanSurvey.datetime} />
            </Status>
            <Resend>
                {!teamScanSurvey.datetime && (
                    <label>
                        <Field name={`tqs${teamScanSurvey.id}`} component="input" type="checkbox" />{" "}
                        {t("player.team-scan-progress.label-resend")}
                    </label>
                )}
            </Resend>
            <Copy>
                {!teamScanSurvey.datetime && (
                    <CopyToClipboard text={teamScanSurvey.link} onCopy={showCopied}>
                        <span className="fake-link" style={style}>
                            {t("player.team-scan-progress.link-copy-link")}
                        </span>
                    </CopyToClipboard>
                )}
            </Copy>
        </Row>
    );
}

export default ProgressRow;
