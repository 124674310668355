import * as Sentry from "@sentry/browser";
import { type JSX, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useMutation, useQuery } from "urql";
import { currentColors } from "../../shared/colors";
import StarIcon from "../../shared/components/icons/StarIcon";
import t from "../../shared/translations";
import Modal from "../components/Modal";
import { useSelectedIds } from "../components/SelectedIds";
import { type Dispatch, closeModal, openModal } from "../state";
import RatingForm, { type IFormValues } from "./RatingForm";

interface IArgs {
    id: string;
}

const query = `
query($id:String!) {
    pathMembership(id:$id) {
        id
        hideAppRating
    }
}
`;

interface IData {
    pathMembership: {
        id: string;
        hideAppRating: boolean;
    };
}

interface IRateArgs {
    data: {
        id: string;
        rating: number | null;
        feedback: string | null;
    };
}

const rateMutation = `
    mutation($data:PathMembershipRatePathData!) {
        pathMembershipRatePath(data:$data) {
            error
            pathMembership {
                id
            }
        }
    }
`;

interface IRateData {
    pathMembershipRatePath: {
        error: string | null;
        pathMembership: {
            id: string;
        } | null;
    };
}

const dismissMutation = `
    mutation {
        pathMembershipDismissRating {
            error
            pathMembership {
                id
            }
        }
    }
`;

interface IDismissData {
    pathMembershipDismissRating: {
        error: string | null;
        pathMembership: {
            id: string;
        } | null;
    };
}

interface IProps {
    popup: boolean;
    includeFeedback: boolean;
    style?: React.CSSProperties;
}

const Button = styled.button`
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid ${currentColors.border};
    background: ${currentColors.background};
    color: ${currentColors.button};
    font-size: 0.85rem;
    padding: 0.5rem 1rem .5325rem 1rem;
    &:hover, &:focus, &:active {
        color: ${currentColors.button};
        background: ${currentColors.background};
    }
    svg {
        width: 1.5rem;
        height: 1.5rem;
        margin: -4px 0;
    }
`;

function RatingButton({ popup, includeFeedback, style }: IProps): JSX.Element {
    const id = useSelectedIds().pmId;
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: { id },
    });
    const [_rateResult, executeRate] = useMutation<IRateData, IRateArgs>(rateMutation);
    const [dismissResult, executeDismiss] = useMutation<IDismissData, IArgs>(dismissMutation);
    const dispatch = useDispatch<Dispatch>();
    const [dismissed, setDismissed] = useState(false);

    useEffect(() => {
        if (result.fetching) {
            return;
        }
        if (result.error) {
            return;
        }
        if (!result.data.pathMembership) {
            return;
        }
        const hideAppRating = result.data.pathMembership.hideAppRating;
        if (hideAppRating) {
            return;
        }
        if (!popup) {
            return;
        }
        if (dismissed) {
            return;
        }
        dispatch(openModal("Landing/Rate"));
    }, [popup, result, dismissed]);

    const openRate = () => {
        dispatch(openModal("Landing/Rate"));
    };

    const dismiss = () => {
        setDismissed(true);
        if (dismissResult.fetching) {
            return;
        }
        if (popup) {
            void executeDismiss({ id });
        }
    };

    const submit = async (values: IFormValues) => {
        const data = { id, rating: values.rating || null, feedback: values.feedback || null };
        const rateResult = await executeRate({ data });
        if (rateResult.error) {
            console.error(rateResult.error);
            Sentry.captureMessage(rateResult.error.toString());
        } else if (rateResult.data.pathMembershipRatePath?.error) {
            console.error(rateResult.data.pathMembershipRatePath.error);
            Sentry.captureMessage(rateResult.data.pathMembershipRatePath.error);
        } else {
            setDismissed(true);
            dispatch(closeModal());
        }
    };

    if (result.fetching) {
        return null;
    }
    if (result.error) {
        return null;
    }
    if (!result.data.pathMembership) {
        return;
    }
    return (
        <>
            <Button onClick={openRate} type="button" style={style}>
                <StarIcon fill={currentColors.rating} active stroke={currentColors.rating} />
                {t("player.app-rating.button-rate-app")}
            </Button>
            <Modal modal="Landing/Rate" onClose={dismiss}>
                <RatingForm includeFeedback={includeFeedback} submit={submit} />
            </Modal>
        </>
    );
}

export default RatingButton;
