import { type ReactNode, createContext, useCallback, useContext, useMemo } from "react";
import { useQuery } from "urql";
import { currentColors } from "../../shared/colors";

const query = `
    query {
        currentPerson {
            unreadInspirations
        }
    }
`;

interface IData {
    currentPerson: {
        unreadInspirations: number;
    };
}

interface IUnreadContext {
    unreadInspirations: number;
    refetch: VoidFunction | undefined;
}
const defafaultValue: IUnreadContext = {
    unreadInspirations: 0,
    refetch: undefined,
};

const UnreadInspirationsContext = createContext<IUnreadContext>(defafaultValue);
UnreadInspirationsContext.displayName = "UnreadInspirationsContext";

interface IProps {
    children: ReactNode;
}

export function UnreadInspirationsProvider({ children }: IProps): JSX.Element {
    const [result, refetchMessages] = useQuery<IData>({ query });

    const refetch = useCallback(() => {
        refetchMessages({ requestPolicy: "cache-and-network" });
    }, [refetchMessages]);

    const value = useMemo(
        () => ({
            unreadInspirations: result.data?.currentPerson?.unreadInspirations ?? 0,
            refetch,
        }),
        [result.data?.currentPerson?.unreadInspirations, refetch],
    );

    return <UnreadInspirationsContext.Provider value={value}>{children}</UnreadInspirationsContext.Provider>;
}

export function useUnreadInspirations(): ReactNode | null {
    const { unreadInspirations } = useContext(UnreadInspirationsContext);
    if (unreadInspirations === 0) {
        return null;
    }
    return (
        <>
            <circle cx="20" cy="30" r="20" fill={currentColors.unread} stroke="white" strokeWidth="2" />
            <text x="20" y="38" fill="white" textAnchor="middle" fontSize={22}>
                {unreadInspirations}
            </text>
        </>
    );
}

export function useRefetchUnreadInspirations(): VoidFunction | undefined {
    const { refetch } = useContext(UnreadInspirationsContext);
    return refetch;
}
