import type { JSX } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import ProfileNav from "../ProfileNav";
import Header from "./Header";
import Terms from "./Terms";
import Landing from "./landing/Landing";
import Progress from "./progress/Progress";
import Resend from "./progress/Resend";
import SendReminderSuccess from "./progress/SendReminderSuccess";
import Report from "./report/Report";
import ShareReport from "./report/ShareReport";
import ShareReportSuccess from "./report/ShareReportSuccess";
import Setup from "./setup/Setup";
import Theory from "./theory/Theory";

const Main = styled.div`
    flex-grow: 1;
    padding: 0 10px;
    width: 100%;
    max-width: 1440px;
`;

function TeamScans(): JSX.Element {
    return (
        <>
            <Header />
            <Main>
                <Routes>
                    <Route path="/theory" element={<Theory />} />
                    <Route path="/profile/*" element={<ProfileNav />} />
                    <Route path="/setup/:id" element={<Setup />} />
                    <Route path="/progress/:id/resend" element={<Resend />} />
                    <Route path="/progress/:id/resend-success" element={<SendReminderSuccess />} />
                    <Route path="/progress/:id" element={<Progress />} />
                    <Route path="/report/:id/share" element={<ShareReport />} />
                    <Route path="/report/:id/share-success" element={<ShareReportSuccess />} />
                    <Route path="/report/:id" element={<Report linkHome />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/" element={<Landing />} />
                </Routes>
            </Main>
        </>
    );
}

export default TeamScans;
