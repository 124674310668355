import type { JSX } from "react";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import t from "../../shared/translations";

interface IProps {
    pathId: string;
}

const ButtonAnchor = styled.a`
    display: inline-block;
    padding: 0.5rem 1rem 0.5325rem;
    margin-bottom: 0.5rem;
    background-color: ${currentColors.button};
    color: white;

    &:hover, &:focus, &:active {
        color: white;
    }
`;

function DownloadAnswers({ pathId }: IProps): JSX.Element {
    return (
        <ButtonAnchor target="_blank" href={`/pdf/download/my-answers/${pathId}/my-answers.pdf`}>
            {t("player.my-answers.button-download-answers")}
        </ButtonAnchor>
    );
}

export default DownloadAnswers;
