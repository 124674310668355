import { sortBy } from "lodash-es";
import { type JSX, useMemo, useState } from "react";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import t from "../../shared/translations";
import type { LearningPathItemType, TaskIcon } from "../../shared/types";
import type { OpenLockedModal } from "../LockedModal";
import CornerPath from "./CornerPath";
import FlatPath from "./FlatPath";
import type { IData } from "./queries";

interface IProps {
    path: IData["learningPath"];
    avatarSrc: string;
    openLockedModal: OpenLockedModal;
}

const Wrapper = styled.div`
    position: relative;
    margin: 0 auto;
    margin-top: 1rem;
    max-width: 100%;
    overflow: hidden;
`;

type PathType = "corner" | "flat";

export interface PathItem {
    id: string;
    type: LearningPathItemType;
    avatarSrc: string;
    miles: [number, number];
    earnedMiles: number;
    icon: TaskIcon;
    url: string;
    active: boolean;
    redo: boolean;
    undecided: boolean;
    complete: boolean;
    pathInPath: boolean;
    eventDate: string;
    locked: boolean;
    lockedTaskId: string | null;
    lockedFeedbackId: string | null;
    lockedTaskChoiceId: string | null;
    grouped: boolean;
    shareAnswers: boolean;
}

const itemDefaults = {
    type: null,
    avatarSrc: null,
    miles: [null, null] as [number, number],
    earnedMiles: null,
    icon: null,
    active: false,
    undecided: false,
    redo: false,
    complete: false,
    pathInPath: false,
    eventDate: null,
    locked: false,
    lockedTaskId: null,
    lockedFeedbackId: null,
    lockedTaskChoiceId: null,
    grouped: false,
    shareAnswers: false,
};

function usePathItems(path: IData["learningPath"], avatarSrc: string): PathItem[] {
    const pathItems = useMemo(() => {
        let acceptedItems: any[] = path.learningPathItems.filter((item) => item.complete);
        acceptedItems = acceptedItems.concat(path.customItems.filter((item) => item.complete));
        acceptedItems = sortBy(acceptedItems, (item) => item.finishDatetime);
        const pathItems: PathItem[] = [];
        for (const item of acceptedItems) {
            pathItems.push({
                ...itemDefaults,
                id: item.id,
                type: item.type,
                miles: item.type === "submitted-task-only" ? [item.earnedMiles, item.earnedMiles] : item.miles,
                earnedMiles: item.earnedMiles,
                icon: item.icon,
                url: item.url,
                complete: true,
                pathInPath: !!item.pathInPath,
                eventDate: item.type === "event" ? item.event.eventStart : null,
                grouped: item.grouped,
                shareAnswers: item.shareAnswers,
            });
        }
        pathItems.push({
            ...itemDefaults,
            id: "avatar",
            avatarSrc,
            url: "/profile",
        });
        let remainingItems = path.learningPathItems.filter((item) => !item.complete);
        remainingItems = sortBy(remainingItems, (item) => item.finishDatetime);
        for (const item of remainingItems) {
            pathItems.push({
                ...itemDefaults,
                id: item.id,
                type: item.type,
                miles: item.miles,
                earnedMiles: item.earnedMiles,
                icon: item.icon,
                url: item.url,
                active: item.active,
                undecided: item.undecided,
                redo: item.redo,
                pathInPath: !!item.pathInPath,
                eventDate: item.type === "event" ? item.event.eventStart : null,
                locked: item.locked,
                lockedTaskId: item.locked && item.taskChoice === null ? item.tasks[0]?.id : null,
                lockedFeedbackId: item.locked ? item.feedbackTask?.id : null,
                lockedTaskChoiceId: item.locked ? item.taskChoice?.id : null,
                grouped: item.grouped,
                shareAnswers: item.shareAnswers,
            });
        }
        return pathItems;
    }, [path, avatarSrc]);
    return pathItems;
}

const ZoomButton = styled.button`
    z-index: 1;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    color: ${currentColors.button};
    border: 1px solid ${currentColors.border};
    background-color: ${currentColors.background};
    padding: 0.5rem 1rem 0.5125rem 1rem;
    font-size: 0.85rem;
    &:active, &:hover, &:focus, &:visited {
        color: ${currentColors.button};
        background-color: ${currentColors.background};
    }
`;

function StreetPath({ path, openLockedModal, avatarSrc }: IProps): JSX.Element {
    const [view, setView] = useState<PathType>("corner");
    const pathItems = usePathItems(path, avatarSrc);
    const toggleView = () => {
        setView((curr) => (curr === "corner" ? "flat" : "corner"));
    };
    return (
        <Wrapper>
            <ZoomButton onClick={toggleView}>
                {view === "corner" ? t("player.landing.button-zoom-out") : t("player.landing.button-zoom-in")}
            </ZoomButton>
            {view === "corner" && <CornerPath openLockedModal={openLockedModal} pathItems={pathItems} />}
            {view === "flat" && <FlatPath openLockedModal={openLockedModal} pathItems={pathItems} />}
        </Wrapper>
    );
}

export default StreetPath;
