import Linkify from "linkify-react";
import { useState } from "react";
import type { ReactElement } from "react";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";

interface IProps {
    message: {
        id: string;
        senderName: string;
        subject: string;
        content: string;
    };
}

const Row = styled.div`
    display: grid;
    grid-template-columns: auto auto auto 1fr;
    gap: 0.25rem 1rem;
    padding: 0.3rem 0;
    margin-bottom: 0.3rem;
    cursor: pointer;
`;

const SmallContent = styled.div`
    color: ${currentColors.grey4};
    white-space: nowrap;
    overflow: hidden;
`;

const Content = styled.div`
    grid-column: 1/-1;
`;

function PrevMessage({ message }: IProps): ReactElement {
    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen((o) => !o);
    };

    return (
        <Row onClick={toggle}>
            <div>{message.senderName}</div>
            <div>{message.subject}</div>
            {open ? (
                <Content>
                    <Linkify options={{ nl2br: true }} tagName="div">
                        {message.content}
                    </Linkify>
                </Content>
            ) : (
                <SmallContent>{message.content}</SmallContent>
            )}
        </Row>
    );
}

export default PrevMessage;
