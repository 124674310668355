import type { FormEvent, JSX } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import ProfileIcon from "../../shared/components/icons/navIcons/ProfileIcon";
import PulseIcon from "../../shared/components/icons/taskIcons/PulseIcon";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import { useCurrentPerson } from "../components/CurrentPerson";
import { useUnreadMessages } from "../components/UnreadMessages";
import PathNav from "../selectPath/PathNav";
import { NavButtonAnchor, NavSelect } from "./stylings";

const HeaderWrapper = styled.header`
    padding: 0 10px 0 10px;
    max-width: 1440px;
    width: 100%;
`;

const MainNav = styled.nav`
    background-color: ${currentColors.header};
    margin-bottom: 0.2rem;
    svg {
        width: 60px;
        height: 60px;
        fill: white;
        margin: 0.2rem 1rem;
    }
`;

const SecondaryNav = styled.nav`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    form {
        min-width: 0;
    }
`;

function Header(): JSX.Element {
    const person = useCurrentPerson();
    const unreadMessages = useUnreadMessages();
    const showAdmin = person.user.role.name === "admin";
    const showLearningCoach =
        loadJSData().features.learning_coach &&
        (person.user.role.name === "admin" || person.user.role.name === "learning-coach");
    const showTracking =
        loadJSData().features.tracking &&
        (person.user.role.name === "admin" ||
            person.user.role.name === "organisation-manager" ||
            person.user.role.name === "team-manager");
    const showViewSwitch = showAdmin || showLearningCoach || showTracking;

    const onViewSwitch = (event: FormEvent<HTMLSelectElement>) => {
        const path = event.currentTarget.value;
        if (path) {
            window.location.href = path;
        }
    };

    return (
        <HeaderWrapper>
            <MainNav>
                <Link to="/pulse">
                    <PulseIcon white />
                </Link>
                <Link to="/pulse/profile">
                    <ProfileIcon unread={unreadMessages} />
                </Link>
            </MainNav>
            <SecondaryNav>
                <PathNav currentTool="pulse" />
                {showViewSwitch && (
                    <NavSelect onChange={onViewSwitch}>
                        <option value="">{t("player.header.secondary-nav-switch-view")}</option>
                        {showAdmin && <option value="/admin">{t("player.header.secondary-nav-admin")}</option>}
                        {showLearningCoach && (
                            <option value="/learning-coach">{t("player.header.secondary-nav-learning-coach")}</option>
                        )}
                        {showTracking && (
                            <option value={`/tracking/${person.organisation.id}`}>
                                {t("player.header.secondary-nav-tracking")}
                            </option>
                        )}
                    </NavSelect>
                )}
                <NavButtonAnchor href="/logout">{t("player.header.secondary-nav-log-out")}</NavButtonAnchor>
            </SecondaryNav>
        </HeaderWrapper>
    );
}

export default Header;
