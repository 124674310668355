import { type CSSProperties, type FocusEvent, type JSX, useRef, useState } from "react";
import { type UseFieldConfig, useField } from "react-final-form";
import styled from "styled-components";
import { currentColors } from "../shared/colors";

interface IOwnProps {
    name: string;
    className?: string;
}

type IProps = IOwnProps & UseFieldConfig<string>;

const Wrapper = styled.div`
    textarea {
        border: 1px solid ${currentColors.text};
        color: ${currentColors.text};
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: white;
        font-size: 1rem;
        transition: height 0.2s;
        min-height: 45px;
    }

    & small.error {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        border: 1px solid ${currentColors.text};
        border-top: none;
    }

    &.error textarea {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

function Textarea({ name, className, ...props }: IProps): JSX.Element {
    const {
        input,
        meta: { touched, error },
    } = useField<any, HTMLTextAreaElement>(name, props);
    const [focus, setFocus] = useState(false);
    const ref = useRef<HTMLTextAreaElement>(null);

    const onFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
        setFocus(true);
        input.onFocus(event);
    };

    const onBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
        setFocus(false);
        input.onBlur(event);
    };

    const style: CSSProperties = {
        height: focus && ref.current ? Math.max(ref.current.scrollHeight + 2, 100) : 45,
    };

    return (
        <Wrapper className={touched && error ? `error ${className}` : className || ""}>
            <textarea {...input} onFocus={onFocus} onBlur={onBlur} style={style} ref={ref} />
            {touched && error && <small className="error">{error}</small>}
        </Wrapper>
    );
}

export default Textarea;
