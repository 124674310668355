import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { TimeEstimateType } from "../../shared/types";

export type IEvent = {
    shortTitle: string;
    pathname: string;
    start: string;
    end: string;
    url: string;
};

export type ITask = {
    shortTitle: string;
    pathname: string;
    unlockDate: string | null;
    callToAction: string;
    deadline: string | null;
    miles: number;
    timeEstimate: TimeEstimateType | null;
    url: string;
    complete: boolean;
};

export type IFeedbackTask = {
    shortTitle: string;
    pathname: string;
    callToAction: string;
    deadline: string | null;
    miles: number;
    url: string;
    complete: boolean;
};

export type ITaskChoice = {
    name: string;
    pathname: string;
    unlockDate: string | null;
    callToAction: string;
    deadline: string | null;
    url: string;
    complete: boolean;
    miles: [number, number];
};

export type ICalendarEvent =
    | {
          id: number;
          type: "event";
          date: string;
          event: IEvent;
          complete: boolean;
      }
    | {
          id: number;
          type: "task-unlock";
          date: string;
          task: ITask;
          complete: boolean;
      }
    | {
          id: number;
          type: "task-call-to-action";
          date: string;
          task: ITask;
          complete: boolean;
      }
    | {
          id: number;
          type: "task-deadline";
          date: string;
          task: ITask;
          complete: boolean;
      }
    | {
          id: number;
          type: "task-event";
          date: string;
          event: IEvent;
          complete: boolean;
      }
    | {
          id: number;
          type: "feedback-task-call-to-action";
          date: string;
          feedbackTask: IFeedbackTask;
          complete: boolean;
      }
    | {
          id: number;
          type: "feedback-task-deadline";
          date: string;
          feedbackTask: IFeedbackTask;
          complete: boolean;
      }
    | {
          id: number;
          type: "task-choice-unlock";
          date: string;
          taskChoice: ITaskChoice;
          complete: boolean;
      }
    | {
          id: number;
          type: "task-choice-call-to-action";
          date: string;
          taskChoice: ITaskChoice;
          complete: boolean;
      }
    | {
          id: number;
          type: "task-choice-deadline";
          date: string;
          taskChoice: ITaskChoice;
          complete: boolean;
      };

interface CalendarState {
    events: {
        ids: number[];
        dict: Record<number, ICalendarEvent>;
    };
    selectedEvent: number | null;
    selectedMonth: string | null;
    calendarShowStartDate: boolean | null;
    calendarShowFinished: boolean | null;
}

const initialState: CalendarState = {
    events: {
        ids: [],
        dict: {},
    },
    selectedEvent: null,
    selectedMonth: new Date().toISOString(),
    calendarShowStartDate: null,
    calendarShowFinished: null,
};

type ICalendarPerfs = {
    calendarShowStartDate: boolean;
    calendarShowFinished: boolean;
};

const calendarSlice = createSlice({
    name: "calendar",
    initialState,
    reducers: {
        loadCalendarData(state, action: PayloadAction<{ events: ICalendarEvent[]; perfs: ICalendarPerfs }>) {
            const { events, perfs } = action.payload;
            state.events.ids = events.map((event) => event.id);
            state.events.dict = events.reduce(
                (acc, event) => {
                    acc[event.id] = event;
                    return acc;
                },
                {} as Record<number, ICalendarEvent>,
            );
            if (state.calendarShowStartDate === null) {
                state.calendarShowStartDate = perfs.calendarShowStartDate;
            }
            if (state.calendarShowFinished === null) {
                state.calendarShowFinished = perfs.calendarShowFinished;
            }
        },
        calendarSelectEvent(state, action: PayloadAction<number | null>) {
            state.selectedEvent = action.payload;
        },
        calendarSelectMonth(state, action: PayloadAction<string>) {
            state.selectedMonth = action.payload;
        },
        calendarUpdatePerfs(state, action: PayloadAction<ICalendarPerfs>) {
            state.calendarShowStartDate = !!action.payload.calendarShowStartDate;
            state.calendarShowFinished = !!action.payload.calendarShowFinished;
        },
    },
});

export const { loadCalendarData, calendarSelectEvent, calendarSelectMonth, calendarUpdatePerfs } =
    calendarSlice.actions;
export default calendarSlice.reducer;
