import type { JSX } from "react";
import { currentColors } from "../../shared/colors";
import t from "../../shared/translations";
import { type ITask, taskFinished, taskRedo, taskUndecied } from "./query";

interface IProps {
    task: ITask;
}

function Status({ task }: IProps): JSX.Element | null {
    if (taskFinished(task)) {
        return <span style={{ color: currentColors.taskFinished }}>{t("player.task-choice.status-accepted")}</span>;
    }
    if (taskUndecied(task)) {
        return <span style={{ color: currentColors.taskActive }}>{t("player.task-choice.status-undecided")}</span>;
    }
    if (taskRedo(task)) {
        return <span style={{ color: currentColors.taskRedo }}>{t("player.task-choice.status-redo")}</span>;
    }
    return null;
}

export default Status;
