import { createRoot } from "react-dom/client";
import Modal from "react-modal";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Provider as URQLProvider, cacheExchange, createClient, fetchExchange } from "urql";
import { AlertProvider } from "../shared/components/Alerts";
import { ConfirmProvider } from "../shared/components/ConfirmDialogue";
import sentryExchange from "../shared/sentryExchange";
import Player from "./Player";
import { CurrentPersonProvider } from "./components/CurrentPerson";
import { SelectedIdsProvider, selectedIdsExchange } from "./components/SelectedIds";
import { UnreadInspirationsProvider } from "./components/UnreadInspirations";
import { UnreadMessagesProvider } from "./components/UnreadMessages";
import { store } from "./state";

export const client = createClient({
    exchanges: [cacheExchange, sentryExchange, selectedIdsExchange, fetchExchange],
    fetchOptions: {
        credentials: "same-origin",
        headers: {
            "X-CSRFToken": __CSRF_TOKEN__,
        },
    },
    requestPolicy: "cache-and-network",
    url: "/graphql/player",
});

// init function
export default function init() {
    const container = document.getElementById("player-page");
    Modal.setAppElement(container);
    const root = createRoot(container);
    root.render(
        <URQLProvider value={client}>
            <ReduxProvider store={store}>
                <AlertProvider>
                    <BrowserRouter>
                        <SelectedIdsProvider>
                            <CurrentPersonProvider>
                                <UnreadMessagesProvider>
                                    <UnreadInspirationsProvider>
                                        <ConfirmProvider>
                                            <Player />
                                        </ConfirmProvider>
                                    </UnreadInspirationsProvider>
                                </UnreadMessagesProvider>
                            </CurrentPersonProvider>
                        </SelectedIdsProvider>
                    </BrowserRouter>
                </AlertProvider>
            </ReduxProvider>
        </URQLProvider>,
    );
}
