import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { calendarSelectEvent } from "./calendarSlice";

export type Modal =
    | null
    | "Calendar/EventDetails"
    | "Landing/Rate"
    | "Messages/Reply"
    | "Messages/Send"
    | "Performance/Info/Activity"
    | "Performance/Info/Biking"
    | "Performance/Info/Chart"
    | "Performance/Info/Checkpoint"
    | "Performance/Info/EndDate"
    | "Performance/Info/Goal"
    | "Performance/Info/PathGroupMilesAvg"
    | "Performance/Info/PathGroupMilesTotal"
    | "Performance/Info/PathGroupSpeedometer"
    | "Performance/Info/Running"
    | "Performance/Info/Speed"
    | "Performance/Info/Status"
    | "Performance/Info/TotalMiles"
    | "Profile/ChangeAvatar"
    | "Profile/ChangePassword"
    | "Profile/ChangeProfilePicture"
    | "Pulse/Download"
    | "Pulse/Rename"
    | "SubmittedTask/AiThread"
    | "SubmittedTask/PollResults"
    | "SubmittedTask/QuizResults"
    | "Task/CollectFeedback/AllResponses"
    | "Task/CollectFeedback/IndividualResponse"
    | "Task/CollectFeedback/Preview"
    | "Task/Info/CollectFeedbackHeading"
    | "Task/Info/CollectFeedbackQuestions"
    | "Task/Info/MultiQuestion"
    | "Task/PollResults"
    | "Task/QuizResults"
    | "TasksSidebar/Info"
    | "TeamScan/Info/ReportSLNetwork"
    | "TeamScan/Info/ReportSLOverview"
    | "TeamScan/Info/ReportTLBDetails"
    | "TeamScan/Info/ReportTLBOverview"
    | "TeamScan/Info/ReportTPSDetails"
    | "TeamScan/Info/ReportTPSOverview"
    | "TeamScan/ProgressEditEmail";

interface ModalState {
    open: Modal;
}

const initialState: ModalState = {
    open: null,
};

const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        openModal(state, action: PayloadAction<Modal>) {
            state.open = action.payload;
        },
        closeModal(state) {
            state.open = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(calendarSelectEvent, (state, action: PayloadAction<number | null>) => {
            state.open = action.payload ? "Calendar/EventDetails" : null;
        });
    },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
