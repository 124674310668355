import type { JSX } from "react";
import styled from "styled-components";
import { useMutation } from "urql";
import { currentColors } from "../../shared/colors";
import RenderMark from "../../shared/components/RenderMark";
import MarkUnreadIcon from "../../shared/components/icons/MarkUnreadIcon";
import HeartIcon from "../../shared/components/icons/teamScanIcons/HeartIcon";
import { formatYMD, parseDate } from "../../shared/dateFns";
import t from "../../shared/translations";
import { useRefetchUnreadInspirations } from "../components/UnreadInspirations";
import {
    type IData,
    type ILikeArgs,
    type ILikeData,
    type IMarkUnreadArgs,
    type IMarkUnreadData,
    likeMutation,
    markUnreadMutation,
} from "./query";

interface IProps {
    inspiration: IData["learningPath"]["inspirations"][0];
}

interface IBox {
    $new: boolean;
}

const Box = styled.div<IBox>`
    border-radius: 1rem;
    margin: 0 auto;
    max-width: 1030px;
    margin-bottom: 1rem;
    padding: 1rem;
    padding-top: 0.5rem;
    border: ${({ $new }) => ($new ? `3px solid ${currentColors.border}` : `1px solid ${currentColors.text}`)};
`;

const InspContent = styled(RenderMark)`
    & > * {
        max-width: 100%;
    }
    & > *:last-child {
        margin-bottom: 0;
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
`;

const Like = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 0.25rem;
    svg {
        cursor: pointer;
        width: 2rem;
        height: 2rem;
    }
`;

const MarkRead = styled.div`
    display: grid;
    place-items: center;
    svg {
        cursor: pointer;
        width: 2rem;
        height: 2rem;
    }
`;

function Inspiration({ inspiration }: IProps): JSX.Element {
    const [LikeResult, executeLike] = useMutation<ILikeData, ILikeArgs>(likeMutation);
    const [markUnreadResult, executeMarkUnread] = useMutation<IMarkUnreadData, IMarkUnreadArgs>(markUnreadMutation);
    const refetchUnread = useRefetchUnreadInspirations();

    const toggleLike = async () => {
        if (LikeResult.fetching) {
            return;
        }
        const to = inspiration.liked ? "unlike" : "like";
        await executeLike({ id: inspiration.id, to });
    };

    const markUnread = async () => {
        if (markUnreadResult.fetching) {
            return;
        }
        await executeMarkUnread({ id: inspiration.id });
        refetchUnread?.();
    };

    return (
        <Box key={inspiration.id} $new={!inspiration.read}>
            <Header>
                <Like>
                    <span>{inspiration.likeCount}</span>
                    <HeartIcon active={inspiration.liked} onClick={toggleLike} />
                </Like>
                {inspiration.created && <span>{formatYMD(parseDate(inspiration.created))}</span>}
                {inspiration.read && (
                    <MarkRead title={t("player.inspirations.title-mark-unread")}>
                        <MarkUnreadIcon onClick={markUnread} />
                    </MarkRead>
                )}
            </Header>
            <InspContent content={inspiration.content} />
        </Box>
    );
}

export default Inspiration;
