import type { ValidationErrors } from "final-form";
import type { JSX } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import Textarea from "../components/FinalTextarea";
import StarInput from "../components/StarInput";
import { Center } from "./stylings";

export interface IFormValues {
    rating: number;
    feedback: string;
}

const defaultValues: IFormValues = {
    rating: 0,
    feedback: "",
};

interface IProps {
    submit: (values: IFormValues) => Promise<void>;
    includeFeedback: boolean;
}

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.rating) {
        errors.subject = t("player.app-rating.form-error-rating-required");
    }
    return errors;
}

const StyledTextarea = styled(Textarea)`
    max-width: 600px;
    margin: 3rem auto 0 auto;
    textarea {
        border: 1px solid ${currentColors.text};
        border-radius: 1rem;
        min-height: 7rem;
    }
`;

const H2 = styled.h2`
    text-align: center;
`;

function RatingForm({ includeFeedback, submit }: IProps): JSX.Element {
    const webapp_title = loadJSData().settings.title;
    return (
        <>
            <H2>{t("player.app-rating.modal-heading", { webapp_title })}</H2>
            <Form<IFormValues> onSubmit={submit} validate={validate} initialValues={defaultValues}>
                {({ handleSubmit, submitting, pristine }) => (
                    <form onSubmit={handleSubmit}>
                        <StarInput name="rating" />
                        {includeFeedback && (
                            <StyledTextarea name="feedback" placeholder={t("player.app-rating.form-label-feedback")} />
                        )}
                        <Center>
                            <button type="submit" disabled={submitting || pristine}>
                                {t("player.app-rating.form-submit")}
                            </button>
                        </Center>
                    </form>
                )}
            </Form>
        </>
    );
}

export default RatingForm;
