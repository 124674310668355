import Linkify from "linkify-react";
import { type CSSProperties, type ComponentPropsWithoutRef, useState } from "react";
import type { JSX } from "react";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import LikertPreview from "../../shared/components/LikertPreview";
import PollResults from "../../shared/components/PollResults";
import QuizResults from "../../shared/components/QuizResults";
import SliderPreview from "../../shared/components/SliderPreview";
import Modal from "../../shared/components/StyledModal";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import type { AnswerType, PollChartType, TaskThreadItemType } from "../../shared/types";

interface Props extends ComponentPropsWithoutRef<"div"> {
    boxStyle?: CSSProperties;
    tti: {
        id: string;
        type: TaskThreadItemType;
        person: {
            id: string;
            firstName: string;
            lastName: string;
            profilePicSrc: string;
        };
        taskAnswers: {
            id: string;
            answerType: AnswerType;
            questionContent: string | null;
            answerContent: string | null;
            checkboxAnswers: {
                id: string;
                content: string;
                checked: boolean;
            }[];
            taskLikertAnswers: {
                id: string;
                statement: string;
                leftLabel: string;
                rightLabel: string;
                value: number;
            }[];
            showPollResults: boolean;
            quizAnswerSet: {
                id: string;
                results: [number, number];
                answers: {
                    id: string;
                    answer: string;
                    answeredQuestion: {
                        id: string;
                        number: number;
                        levelNumber: number;
                        description: string;
                        correctAnswer: string;
                        explanation: string | null;
                        quizOptions: {
                            id: string;
                            letter: string;
                            content: string;
                        }[];
                    };
                }[];
            } | null;
            pollAnswerSet: {
                id: string;
                answers: {
                    id: string;
                    answers: number[];
                    question: {
                        id: string;
                        number: number;
                        content: string;
                        chartType: PollChartType;
                        options: {
                            id: string;
                            number: number;
                            content: string;
                            answerCount: number;
                        }[];
                    };
                }[];
            } | null;
            taskAnswerFile: {
                id: string;
                src: string;
                logoSrc: string;
                name: string;
            };
            impactTrackerAnswerSet: {
                id: string;
                impactLikertAnswers: {
                    id: string;
                    statement: string;
                    leftLabel: string;
                    rightLabel: string;
                    value: number;
                }[];
            } | null;
        }[];
    };
}

const Box = styled.div`
    background-color: ${currentColors.background};
    border: 1px solid ${currentColors.border};
    border-radius: 1rem;
    margin-bottom: 0.8rem;
    padding: 0.6rem;
    @media (min-width: 640px) {
        margin-bottom: 1rem;
        padding: 1rem;
    }
`;

interface ICheckboxAnswer {
    $checked: boolean;
}

const CheckboxAnswer = styled.label<ICheckboxAnswer>`
    color: ${currentColors.text};
    font-size: 1rem;
    cursor: default;
    font-weight: ${({ $checked }) => ($checked ? "bold" : "normal")};
`;

const TextboxAnswer = styled.div`
    margin-bottom: 1rem;
    &:last-child {
        margin-bottom: 0;
    }
`;

const FileLogo = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`;

const Hr = styled.hr`
    border-color: ${currentColors.border};
`;

function TaskThreadItemAnswer({ tti, boxStyle }: Props): JSX.Element | null {
    const [open, setOpen] = useState(false);

    if (tti.type === "answer" || tti.type === "unapproved-answer") {
        const name = tti.person ? `${tti.person.firstName} ${tti.person.lastName}` : "";

        return (
            <Box style={boxStyle}>
                {name && <h4>{t("player.task.heading-submitted-answer", { count: tti.taskAnswers.length, name })}</h4>}
                {tti.taskAnswers.map((answer, index) => {
                    const last = index + 1 === tti.taskAnswers.length;
                    // Handle different answer types
                    switch (answer.answerType) {
                        case "quiz": {
                            const [correct, total] = answer.quizAnswerSet?.results ?? [0, 0];
                            return (
                                <div key={answer.id}>
                                    {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                    <span>{t("player.task.info-quiz-result", { correct, total })}</span>
                                    {!loadJSData().quiz_required_all_correct &&
                                        (answer.quizAnswerSet?.answers?.length ?? 0) > 0 && (
                                            <>
                                                <button type="button" onClick={() => setOpen(true)}>
                                                    {t("player.task.button-quiz-results")}
                                                </button>
                                                <Modal isOpen={open} onRequestClose={() => setOpen(false)}>
                                                    <h4>{t("player.task.heading-quiz-results")}</h4>
                                                    <QuizResults results={answer.quizAnswerSet.answers} />
                                                </Modal>
                                            </>
                                        )}
                                    {!last && <Hr />}
                                </div>
                            );
                        }
                        case "poll": {
                            return (
                                <div key={answer.id}>
                                    {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                    <span>{t("player.task.info-poll")} </span>
                                    {answer.showPollResults && (
                                        <>
                                            <button type="button" onClick={() => setOpen(true)}>
                                                {t("player.task.button-poll-results")}
                                            </button>
                                            <Modal isOpen={open} onRequestClose={() => setOpen(false)}>
                                                <h4>{t("player.task.heading-poll-results")}</h4>
                                                <PollResults answers={answer.pollAnswerSet.answers} />
                                            </Modal>
                                        </>
                                    )}
                                    {!last && <Hr />}
                                </div>
                            );
                        }
                        case "checkbox": {
                            return (
                                <div key={answer.id}>
                                    {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                    {answer.checkboxAnswers.map((ans) => (
                                        <CheckboxAnswer $checked={ans.checked} key={ans.id}>
                                            <input type="checkbox" checked={ans.checked} disabled /> {ans.content}
                                        </CheckboxAnswer>
                                    ))}
                                    {!last && <Hr />}
                                </div>
                            );
                        }
                        case "likert": {
                            return (
                                <div key={answer.id}>
                                    {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                    {answer.taskLikertAnswers.map((ans) => (
                                        <LikertPreview
                                            key={ans.id}
                                            statement={ans.statement}
                                            leftLabel={ans.leftLabel}
                                            rightLabel={ans.rightLabel}
                                            selected={ans.value}
                                        />
                                    ))}
                                    {!last && <Hr />}
                                </div>
                            );
                        }
                        case "impact-tracker": {
                            return (
                                <div key={answer.id}>
                                    {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                    {answer.impactTrackerAnswerSet.impactLikertAnswers.map((ans) => (
                                        <SliderPreview
                                            key={ans.id}
                                            statement={ans.statement}
                                            leftLabel={ans.leftLabel}
                                            rightLabel={ans.rightLabel}
                                            value={ans.value}
                                            showValue
                                        />
                                    ))}
                                    {!last && <Hr />}
                                </div>
                            );
                        }
                        case "textbox": {
                            return (
                                <div key={answer.id}>
                                    {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                    <TextboxAnswer key={answer.id}>
                                        <Linkify options={{ nl2br: true }} tagName="p">
                                            {answer.answerContent}
                                        </Linkify>
                                    </TextboxAnswer>
                                    {!last && <Hr />}
                                </div>
                            );
                        }
                        case "file": {
                            const taskAnswerFile = answer.taskAnswerFile;
                            return (
                                <div key={answer.id}>
                                    {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                    <a href={taskAnswerFile.src} target="_blank" rel="noreferrer">
                                        {taskAnswerFile.logoSrc && <FileLogo src={taskAnswerFile.logoSrc} />}{" "}
                                        {taskAnswerFile.name}
                                    </a>
                                    {!last && <Hr />}
                                </div>
                            );
                        }
                        case "survey-gizmo": {
                            return (
                                <div key={answer.id}>
                                    {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                    <h5>{t("player.task.info-survey-gizmo-answer")}</h5>
                                    {!last && <Hr />}
                                </div>
                            );
                        }
                    }
                })}
            </Box>
        );
    }
    return null;
}

export default TaskThreadItemAnswer;
