import type { JSX } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Text } from "recharts";
import styled from "styled-components";
import { chartColor, currentColors } from "../colors";

interface IProps {
    answers: number[];
    options: {
        number: number;
        content: string;
        answerCount: number;
    }[];
}

interface IDatum {
    value: number;
    label: string;
    selected: boolean;
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    margin: 0 auto;
    max-width: 100%;
    @media (min-width: 768px) {
        grid-template-columns: auto 400px;
        justify-content: center;
    }
`;

const Ol = styled.ol`
    margin-bottom: 0;
    width: fit-content;
`;

interface IListLabel {
    $selected: boolean;
}

const ListLabel = styled.li<IListLabel>`
    font-weight: ${({ $selected }) => ($selected ? "bold" : "normal")};
`;

interface ISwatch {
    $idx: number;
}

const Swatch = styled.span<ISwatch>`
    width: 1rem;
    height: 1rem;
    display: inline-block;
    background-color: ${({ $idx }) => chartColor($idx)};
    margin-bottom: -2px;
`;

function Label({ data, ...props }: any): JSX.Element {
    const datum: IDatum = data[props.index];
    return (
        <Text {...props} fontWeight={datum.selected ? "bold" : "normal"} fill={currentColors.text}>
            {datum.label}
        </Text>
    );
}

function PollPieChart({ answers, options }: IProps): JSX.Element {
    const data: IDatum[] = options.map((opt) => ({
        label: `${opt.number}.`,
        value: opt.answerCount,
        selected: answers.includes(opt.number),
    }));
    return (
        <Grid>
            <Ol>
                {options.map((opt, idx) => (
                    <ListLabel value={`${opt.number}.`} key={opt.number} $selected={answers.includes(opt.number)}>
                        <Swatch $idx={idx} /> {opt.content}
                    </ListLabel>
                ))}
            </Ol>
            <div style={{ maxWidth: 400 }}>
                <ResponsiveContainer width="100%" aspect={1}>
                    <PieChart>
                        <Pie
                            dataKey="value"
                            isAnimationActive={false}
                            data={data}
                            label={<Label data={data} />}
                            startAngle={90}
                            endAngle={-270}
                        >
                            {data.map((_datum, idx) => (
                                <Cell key={idx} fill={chartColor(idx)} />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </Grid>
    );
}

export default PollPieChart;
