import type { CSSProperties, JSX, MouseEvent } from "react";
import { Link, Navigate, useMatch } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "urql";
import { currentColors } from "../../shared/colors";
import Loader from "../../shared/components/Loader";
import t from "../../shared/translations";
import LockedModal, { useLockedModal } from "../LockedModal";
import ShowError from "../components/ShowError";
import WithTasksSidebar from "../tasksSidebar";
import PipDescription from "./PipDescription";
import PipNav from "./PipNav";
import YourGroup from "./YourGroup";
import { type IArgs, type IData, query } from "./query";

const EMPTY_LIST = [];

const Center = styled.div`
    text-align: center;
`;

function PathInPath(): JSX.Element {
    const {
        params: { id },
    } = useMatch({ path: "/path-in-path/:id/" });
    const { openLockedModal, ...lockedProps } = useLockedModal();
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: { id },
    });

    let main = <div />;
    if (result.fetching) {
        main = <Loader />;
    } else if (result.error) {
        main = <ShowError />;
    } else if (!result.data.pathInPath) {
        main = <Navigate to="/landing" replace={true} />;
    } else {
        const pip = result.data.pathInPath;

        const tasks = pip.learningPathItem.tasks;
        const showGroup = pip.showGroup && pip.learningPathItem.learningPath.pathGroup != null;
        const nextTask = tasks.find((task) => task.submittedTask?.status !== "accepted");
        const members = showGroup ? pip.learningPathItem.learningPath.pathGroup.members : EMPTY_LIST;
        const style: CSSProperties = {
            backgroundColor: currentColors.pipButton,
        };
        const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
            if (nextTask.locked) {
                event.preventDefault();
                openLockedModal(nextTask.id, "", "");
            }
        };

        main = (
            <>
                <h1>{pip.name}</h1>
                <PipNav
                    miles={pip.learningPathItem.miles}
                    earnedMiles={pip.learningPathItem.earnedMiles}
                    url={pip.url}
                    tasks={tasks}
                    icon={pip.icon}
                    current=""
                />
                {showGroup && <YourGroup members={members} />}
                <PipDescription
                    url={pip.url}
                    name={pip.name}
                    icon={pip.icon}
                    description={pip.description}
                    tasks={tasks}
                />
                <Center>
                    {nextTask && (
                        <Link className="button" to={nextTask.url} style={style} onClick={onClick}>
                            {t("player.pip.button-next-task")}
                        </Link>
                    )}
                </Center>
            </>
        );
    }

    return (
        <>
            <WithTasksSidebar
                openLockedModal={openLockedModal}
                activeItem={result.data?.pathInPath?.learningPathItem?.id}
                mainLabel={t("player.pip.tab-path-in-path")}
                big={true}
            >
                {main}
            </WithTasksSidebar>
            <LockedModal {...lockedProps} />
        </>
    );
}

export default PathInPath;
