import type { JSX } from "react";
import styled, { keyframes } from "styled-components";
import TaskIcon from "../../shared/components/icons/TaskIcon";
import t from "../../shared/translations";
import type { IData } from "./query";

interface IProps {
    data: IData["flipper"];
}

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const spin = keyframes`
    100% {
        transform: rotateZ(360deg);
    }
`;

const P = styled.p`
    text-align: center;
    font-size: 1.8rem;
    opacity: 0;
    animation: ${fadeIn} 2.5s ease 2s 1 forwards,
               ${fadeOut} 1s ease 5s 1 forwards;
`;

const IconHolder = styled.div`
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto;

    > svg {
        position: absolute;
        max-width: 200px;
        max-height: 200px;
        top: 0;
    }

    > svg:first-child {
        animation: ${spin} 1s ease-in-out 2.5s 1 forwards,
                   ${fadeOut} 1s ease 5s 1 forwards;
    }

    > svg:last-child {
        animation: ${fadeIn} 2.5s ease 1s 1 forwards,
                   ${spin} 1s ease-in-out 2.5s 1 forwards,
                   ${fadeOut} 1s ease 5s 1 forwards;
        z-index: 1;
        opacity: 0;
    }
`;

function TaskAward({ data }: IProps): JSX.Element {
    return (
        <>
            {data.finishMessage && <P>{data.finishMessage}</P>}
            {data.showMilesText && <P>{t("player.flipper.heading", { miles: data.milesEarned })}</P>}
            <IconHolder>
                <TaskIcon icon={data.icon} />
                <TaskIcon icon={data.icon} active />
            </IconHolder>
        </>
    );
}

export default TaskAward;
