import { type FormEvent, type JSX, memo, useState } from "react";
import styled from "styled-components";
import { currentColors } from "../colors";
import { formatYMD, parseDate } from "../dateFns";
import getInt from "../getInt";
import t from "../translations";
import type { TaskIcon as TaskIconType } from "../types";
import RenderMark from "./RenderMark";
import TaskIcon from "./icons/TaskIcon";

interface IProps {
    shortTitle?: string;
    title: string | null;
    instructions?: string;
    icon: TaskIconType;
    miles: number | null;
    deadline?: string;
    finishDatetime?: string;
    variants?: {
        feedbackInstructions: string;
        variantTitle: string | null;
    }[];
}

const Wrapper = styled.div`
    margin: 0 auto;
    margin-bottom: 2rem;
`;

const MainRow = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 
        "InstructionsCol"
        "InfoBox"
    ;
    margin-bottom: 0.5rem;
    gap: 0.5rem;

    @media (min-width: 640px) {
        margin-bottom: 1rem;
        gap: 1rem;
        grid-template-columns: 1fr 270px;
        grid-template-areas: 
            "InstructionsCol InfoBox"
        ;
    }
    @media (min-width: 1000px) {
        grid-template-columns: 1fr 320px;
    }
`;

const InstructionsCol = styled.div`
    grid-area: InstructionsCol;
    border-radius: 1rem;
    border: 1px solid ${currentColors.border};
    padding: 0.6rem;
    @media (min-width: 640px) {
        padding: 1rem;
    }
`;

const InfoBox = styled.section`
    grid-area: InfoBox;
    width: 100%;
    max-width: 100%;
    border-radius: 1rem;
    padding: 0.6rem;
    background-color: ${currentColors.background};
    border: 1px solid ${currentColors.border};
    color: ${currentColors.text};

    @media (min-width: 640px) {
        width: 270px;
        padding: 1rem;
    }
    @media (min-width: 1000px) {
        width: 320px;
    }

    h1, h2, h3, h4, h5, h6 {
        color: ${currentColors.text};
    }

    a {
        color: ${currentColors.text};
        &:hover, &:focus, &:active, &:visited {
            color: ${currentColors.text};
        }
    }
`;

const TaskBadge = styled.div`
    display: grid;
    justify-items: center;
    align-content: start;
    & > svg {
        width: 8rem;
        height: 8rem;
    }
`;

const MilesBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.5rem;
    & > div:first-child {
        font-size: 4rem;
        line-height: 1;
    }
    & > div:last-child {
        font-size: 1.5rem;
    }
`;

const FinishDate = styled.div`
    text-align: center;
    margin-top: 0.5rem;
`;

const Finished = styled.span`
    background-color: ${currentColors.taskFinished};
    color: white;
    padding: 0.2rem 1.5rem;
    display: inline-block;
`;

const Deadline = styled.span`
    background-color: ${currentColors.taskDeadline};
    color: white;
    padding: 0.2rem 1.5rem;
    display: inline-block;
`;

const VariantNav = styled.nav`
    margin: 0.5rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    > * {
        margin: 0;
    }
`;

const Select = styled.select`
    display: inline-block;
    background-color: white;
    border: 1px solid ${currentColors.border};
    color: ${currentColors.text};
    margin: 0;
    font-size: 0.9rem;
    padding: 0.25rem 0.85rem;
    transition: none;
    width: auto;

    &:focus {
        background-color: white;
        border: 1px solid ${currentColors.border};
    }
`;

function FeedbackTaskDescription({
    shortTitle,
    title,
    instructions,
    icon,
    miles,
    deadline,
    finishDatetime,
    variants,
}: IProps): JSX.Element {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const switchVariant = (event: FormEvent<HTMLSelectElement>) => {
        const i = getInt(event.currentTarget.value);
        setSelectedIndex(i);
    };

    const shownVariant = variants ? variants[selectedIndex] : undefined;

    let statusSection = null;
    if (finishDatetime) {
        statusSection = (
            <div>
                {t("shared.task-description.label-status")}{" "}
                <Finished>{t("shared.task-description.status-finished")}</Finished>
            </div>
        );
    } else if (deadline) {
        statusSection = (
            <div>
                {t("shared.task-description.label-deadline")} <Deadline>{deadline}</Deadline>
            </div>
        );
    } else {
        statusSection = (
            <div>
                {t("shared.task-description.label-status")}{" "}
                <Deadline>{t("shared.task-description.status-not-done")}</Deadline>
            </div>
        );
    }

    return (
        <Wrapper>
            {shortTitle && <h1>{shortTitle}</h1>}
            <MainRow>
                <InstructionsCol>
                    {title && <h3>{title}</h3>}
                    {variants?.length > 1 && (
                        <VariantNav>
                            <div>{t("shared.task-description.label-select-variant")}</div>
                            <Select onChange={switchVariant} value={selectedIndex}>
                                {variants.map((variant, i) => (
                                    <option value={i} key={i}>
                                        {variant.variantTitle ?? i + 1}
                                    </option>
                                ))}
                            </Select>
                        </VariantNav>
                    )}
                    {instructions && <RenderMark content={instructions} />}
                    {shownVariant && <RenderMark content={shownVariant.feedbackInstructions} />}
                </InstructionsCol>
                <InfoBox>
                    <TaskBadge>
                        <TaskIcon icon={icon} active={!!finishDatetime} />
                        {miles != null && (
                            <MilesBox>
                                <div>{miles}</div>
                                <div>{t("shared.task-description.label-miles")}</div>
                            </MilesBox>
                        )}
                        {statusSection}
                    </TaskBadge>
                    {finishDatetime && (
                        <FinishDate>
                            {t("shared.task-description.info-finish-date", {
                                finish_date: formatYMD(parseDate(finishDatetime)),
                            })}
                        </FinishDate>
                    )}
                </InfoBox>
            </MainRow>
        </Wrapper>
    );
}

export default memo(FeedbackTaskDescription);
