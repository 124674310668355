import { memo } from "react";

function StreetviewFilters(): JSX.Element {
    return (
        <>
            <filter id="house-1-a" colorInterpolationFilters="sRGB">
                {/* <feColorMatrix type="hueRotate" values="3.4356" /> */}
                <feColorMatrix type="saturate" values="0.6013" />
                <feComponentTransfer>
                    <feFuncR type="linear" slope="1.1612" intercept="0.0000" />
                    <feFuncG type="linear" slope="1.1612" intercept="0.0000" />
                    <feFuncB type="linear" slope="1.1612" intercept="0.0000" />
                </feComponentTransfer>
            </filter>
            <filter id="house-1-b" colorInterpolationFilters="sRGB">
                {/* <feColorMatrix type="hueRotate" values="2.1516" /> */}
                <feColorMatrix type="saturate" values="0.2463" />
                <feComponentTransfer>
                    <feFuncR type="linear" slope="1.0038" intercept="0.0000" />
                    <feFuncG type="linear" slope="1.0038" intercept="0.0000" />
                    <feFuncB type="linear" slope="1.0038" intercept="0.0000" />
                </feComponentTransfer>
            </filter>
            <filter id="house-2-a" colorInterpolationFilters="sRGB">
                {/* <feColorMatrix type="hueRotate" values="-4.6005" /> */}
                <feColorMatrix type="saturate" values="0.4003" />
                <feComponentTransfer>
                    <feFuncR type="linear" slope="1.8794" intercept="0.0000" />
                    <feFuncG type="linear" slope="1.8794" intercept="0.0000" />
                    <feFuncB type="linear" slope="1.8794" intercept="0.0000" />
                </feComponentTransfer>
            </filter>
            <filter id="house-2-b" colorInterpolationFilters="sRGB">
                {/* <feColorMatrix type="hueRotate" values="2.6767" /> */}
                <feColorMatrix type="saturate" values="0.3980" />
                <feComponentTransfer>
                    <feFuncR type="linear" slope="1.5061" intercept="0.0000" />
                    <feFuncG type="linear" slope="1.5061" intercept="0.0000" />
                    <feFuncB type="linear" slope="1.5061" intercept="0.0000" />
                </feComponentTransfer>
            </filter>
            <filter id="house-2-c" colorInterpolationFilters="sRGB">
                {/* <feColorMatrix type="hueRotate" values="3.9490" /> */}
                <feColorMatrix type="saturate" values="0.3099" />
                <feComponentTransfer>
                    <feFuncR type="linear" slope="1.3917" intercept="0.0000" />
                    <feFuncG type="linear" slope="1.3917" intercept="0.0000" />
                    <feFuncB type="linear" slope="1.3917" intercept="0.0000" />
                </feComponentTransfer>
            </filter>
            <filter id="house-2-d" colorInterpolationFilters="sRGB">
                {/* <feColorMatrix type="hueRotate" values="-4.5970" /> */}
                <feColorMatrix type="saturate" values="0.3066" />
                <feComponentTransfer>
                    <feFuncR type="linear" slope="1.4978" intercept="0.0000" />
                    <feFuncG type="linear" slope="1.4978" intercept="0.0000" />
                    <feFuncB type="linear" slope="1.4978" intercept="0.0000" />
                </feComponentTransfer>
            </filter>
            <filter id="house-3-a" colorInterpolationFilters="sRGB">
                {/* <feColorMatrix type="hueRotate" values="-33.0489" /> */}
                <feColorMatrix type="saturate" values="0.1462" />
                <feComponentTransfer>
                    <feFuncR type="linear" slope="1.0674" intercept="0.0000" />
                    <feFuncG type="linear" slope="1.0674" intercept="0.0000" />
                    <feFuncB type="linear" slope="1.0674" intercept="0.0000" />
                </feComponentTransfer>
            </filter>
            <filter id="house-3-b" colorInterpolationFilters="sRGB">
                {/* <feColorMatrix type="hueRotate" values="-45.6678" /> */}
                <feColorMatrix type="saturate" values="0.1438" />
                <feComponentTransfer>
                    <feFuncR type="linear" slope="1.4684" intercept="0.0000" />
                    <feFuncG type="linear" slope="1.4684" intercept="0.0000" />
                    <feFuncB type="linear" slope="1.4684" intercept="0.0000" />
                </feComponentTransfer>
            </filter>
            <filter id="house-3-c" colorInterpolationFilters="sRGB">
                {/* <feColorMatrix type="hueRotate" values="-45.5632" /> */}
                <feColorMatrix type="saturate" values="0.1635" />
                <feComponentTransfer>
                    <feFuncR type="linear" slope="1.3003" intercept="0.0000" />
                    <feFuncG type="linear" slope="1.3003" intercept="0.0000" />
                    <feFuncB type="linear" slope="1.3003" intercept="0.0000" />
                </feComponentTransfer>
            </filter>
            <filter id="house-4-a" colorInterpolationFilters="sRGB">
                {/* <feColorMatrix type="hueRotate" values="-0.8957" /> */}
                <feColorMatrix type="saturate" values="0.7462" />
                <feComponentTransfer>
                    <feFuncR type="linear" slope="0.8684" intercept="0.0000" />
                    <feFuncG type="linear" slope="0.8684" intercept="0.0000" />
                    <feFuncB type="linear" slope="0.8684" intercept="0.0000" />
                </feComponentTransfer>
            </filter>
            <filter id="house-4-b" colorInterpolationFilters="sRGB">
                {/* <feColorMatrix type="hueRotate" values="-0.8444" /> */}
                <feColorMatrix type="saturate" values="0.6643" />
                <feComponentTransfer>
                    <feFuncR type="linear" slope="1.0061" intercept="0.0000" />
                    <feFuncG type="linear" slope="1.0061" intercept="0.0000" />
                    <feFuncB type="linear" slope="1.0061" intercept="0.0000" />
                </feComponentTransfer>
            </filter>
            <filter id="house-4-c" colorInterpolationFilters="sRGB">
                {/* <feColorMatrix type="hueRotate" values="-0.8335" /> */}
                <feColorMatrix type="saturate" values="0.7373" />
                <feComponentTransfer>
                    <feFuncR type="linear" slope="0.8114" intercept="0.0000" />
                    <feFuncG type="linear" slope="0.8114" intercept="0.0000" />
                    <feFuncB type="linear" slope="0.8114" intercept="0.0000" />
                </feComponentTransfer>
            </filter>
        </>
    );
}

export default memo(StreetviewFilters);
