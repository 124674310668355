import type { JSX } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import ProfileNav from "../ProfileNav";
import Terms from "../teamScans/Terms";
import Header from "./Header";
import Landing from "./Landing";
import Results from "./Results";
import Setup from "./Setup";

const Main = styled.div`
    flex-grow: 1;
    padding: 0 10px;
    width: 100%;
    max-width: 1440px;
`;

function PulseRoutes(): JSX.Element {
    return (
        <>
            <Header />
            <Main>
                <Routes>
                    <Route path="/profile/*" element={<ProfileNav />} />
                    <Route path="/setup" element={<Setup />} />
                    <Route path="/:id/results" element={<Results />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/" element={<Landing />} />
                </Routes>
            </Main>
        </>
    );
}

export default PulseRoutes;
