import type { ValidationErrors } from "final-form";
import { type JSX, useCallback, useState } from "react";
import { Field, Form } from "react-final-form";
import { useMutation } from "urql";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";

interface IEditArgs {
    password: string;
    confirmPassword: string;
}

const editPasswordMutation = `
    mutation($password:String!,$confirmPassword:String!) {
        currentPersonEditPassword(password:$password,confirmPassword:$confirmPassword) {
            error
        }
    }
`;

interface IFormValues {
    password: string;
    confirmPassword: string;
}

const initialValues: IFormValues = {
    password: "",
    confirmPassword: "",
};

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.password) {
        errors.password = t("player.replace-password.form-error-new-password");
    } else if (values.password.length < loadJSData().min_password_length) {
        errors.password = t("player.replace-password.form-error-too-short");
    }
    if (values.password !== values.confirmPassword) {
        errors.confirmPassword = t("player.replace-password.form-error-repeat-password");
    }
    return errors;
}

interface IProps {
    next: string;
}

function PasswordForm({ next }: IProps): JSX.Element {
    const [apiError, setApiError] = useState("");
    const [_, executeMutation] = useMutation<any, IEditArgs>(editPasswordMutation);

    const onSubmit = useCallback(
        async (values: IFormValues) => {
            setApiError("");
            const result = await executeMutation(values);
            if (result.error) {
                setApiError(result.error.message);
            } else if (result.data.currentPersonEditPassword.error) {
                setApiError(result.data.currentPersonEditPassword.error);
            } else {
                window.location.href = next;
            }
        },
        [executeMutation, next],
    );

    return (
        <Form onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
            {({ handleSubmit, submitting, pristine }) => (
                <form onSubmit={handleSubmit}>
                    <Field name="password" type="password">
                        {({ input, meta: { touched, error } }) => (
                            <div className="row">
                                <div className="small-12 medium-3 columns small-only-text-center medium-text-right">
                                    <label className="inline">
                                        {t("player.replace-password.form-label-new-password")}
                                    </label>
                                </div>
                                <div className={`small-12 medium-9 columns ${touched && error && "error"}`}>
                                    <input {...input} />
                                    {touched && error && <small className="error">{error}</small>}
                                </div>
                            </div>
                        )}
                    </Field>
                    <Field name="confirmPassword" type="password">
                        {({ input, meta: { touched, error } }) => (
                            <div className="row">
                                <div className="small-12 medium-3 columns small-only-text-center medium-text-right">
                                    <label className="inline">
                                        {t("player.replace-password.form-label-repeat-password")}
                                    </label>
                                </div>
                                <div className={`small-12 medium-9 columns ${touched && error && "error"}`}>
                                    <input {...input} />
                                    {touched && error && <small className="error">{error}</small>}
                                </div>
                            </div>
                        )}
                    </Field>
                    {apiError && <div className="error">{apiError}</div>}
                    <div className="row">
                        <div className="medium-push-3 medium-9 columns small-only-text-center">
                            <button type="submit" disabled={submitting || pristine}>
                                {t("player.replace-password.form-submit")}
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </Form>
    );
}

export default PasswordForm;
