import { type JSX, memo } from "react";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import { useSelectedIds } from "../components/SelectedIds";
import ShowError from "../components/ShowError";
import Goals from "./Goals";
import MyPerf from "./MyPerf";
import PathGroupPerf from "./PathGroupPerf";
import Stats from "./Stats";
import { type IArgs, type IData, query } from "./query";

function Performance(): JSX.Element {
    const id = useSelectedIds().pmId;
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: {
            id,
        },
    });
    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }
    const data = result.data;
    if (!data.pathMembership) {
        return <div />;
    }
    const hasPathGroup = !!data.pathMembership.pathGroup;
    return (
        <>
            <MyPerf data={data} />
            <Goals data={data} />
            <Stats data={data} />
            {hasPathGroup && <PathGroupPerf data={data} />}
        </>
    );
}

export default memo(Performance);
