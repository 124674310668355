import type { JSX } from "react";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import { loadJSData } from "../../shared/jsData";
import type { OpenLockedModal } from "../LockedModal";
import { useSelectedIds } from "../components/SelectedIds";
import ShowError from "../components/ShowError";
import AllTasks from "./AllTasks";
import CustomTasks from "./CustomTasks";
import type { ITab } from "./Tabs";
import { type IArgs, type IData, query } from "./query";

interface IProps {
    tab: ITab;
    openLockedModal: OpenLockedModal;
    activeItem?: string;
}

function TasksSidebar({ tab, openLockedModal, activeItem }: IProps): JSX.Element {
    const { pathId } = useSelectedIds();
    const [result] = useQuery<IData, IArgs>({ query, variables: { id: pathId } });
    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }
    const path = result.data?.learningPath;
    return (
        <>
            {tab === "tasks" && <AllTasks path={path} openLockedModal={openLockedModal} activeItem={activeItem} />}
            {loadJSData().features.custom_tasks && tab === "custom" && (
                <CustomTasks path={path} activeItem={activeItem} />
            )}
        </>
    );
}

export default TasksSidebar;
