import type { JSX } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useQuery } from "urql";
import Flipper from "./Flipper";
import { type IArgs, type IData, query } from "./query";

interface IProps {
    submitType: "submit" | "feedback";
}

function FlipperLoader({ submitType }: IProps): JSX.Element {
    const { id } = useParams();
    const [result] = useQuery<IData, IArgs>({
        query,
        requestPolicy: "network-only",
        variables: { id, submitType },
    });

    if (result.fetching) {
        return <div style={{ flexGrow: 1 }} />;
    }
    if (result.error) {
        return <Navigate to="/landing" />;
    }
    const data = result.data.flipper;
    return <Flipper data={data} />;
}

export default FlipperLoader;
