import type {
    AiMessageRoleType,
    AnswerType,
    CollectFeedbackQuestionType,
    ContentType,
    DoInGroupType,
    Language,
    PollChartType,
    ShareAnswersType,
    SubmittedTaskStatus,
    TaskApprovalStatus,
    TaskIcon,
    TaskThreadItemSubtype,
    TaskThreadItemType,
    TaskType,
    TeamScanStatusType,
    TimeEstimateType,
    VariantType,
} from "../../shared/types";

export interface IArgs {
    id: string;
}

export const query = `
    query($id:String!) {
        task(id:$id) {
            id
            skipDescriptionPage
            type
            discriminant
            shortTitle
            objectives
            inspiration {
                id
                mediaType
                src
            }
            title
            icon
            miles
            timeEstimate
            deadline
            media {
                id
                mediaType
                src
                name
                logo
            }
            shareAnswers
            shareAnswersAnonymously
            finished {
                id
                firstName
                lastName
                profilePicSrc
            }
            doInGroup
            taskGroup {
                id
                number
                name
                members {
                    id
                    firstName
                    lastName
                    profilePicSrc
                }
            }
            requireApproval
            feedbackTask {
                id
                shortTitle
            }
            taskVariants {
                id
                variantType
                variantTitle
                description
                hoursQuestions
                playerAiInstructions
                taskQuestions {
                    id
                    answerType
                    questionContent
                    quizId
                    pollId
                    surveyGizmoUrl
                    requireAllCheckmarks
                    checkboxOptions {
                        id
                        content
                    }
                    taskLikertStatements {
                        id
                        statement
                        leftLabel
                        rightLabel
                    }
                    impactTracker {
                        id
                        impactLikertStatements {
                            id
                            statement
                            leftLabel
                            rightLabel
                        }
                    }
                }
                eventStart
                eventEnd
                incompleteAnswers {
                    id
                    questionContent
                    part
                    answerContent
                }
                suggestedQuestions {
                    id
                    nr
                    type
                    content
                    leftLabel
                    rightLabel
                }
                allowAddQuestions
                allowAccessCollectedFeedback
                allowTranslateCollectedFeedback
                anonymousCollectedFeedback
                collectFeedbackTitle
                collectFeedbackDescription
                pulseSuggestedQuestions {
                    id
                    locale
                    content
                }
            }
            learningPathItem {
                id
                miles
                earnedMiles
                tasks {
                    id
                    locked
                    icon
                    shortTitle
                    miles
                    deadline
                    url
                    doInGroup
                    shareAnswers
                    taskVariants {
                        id
                        variantType
                    }
                    submittedTask {
                        id
                        url
                        status
                    }
                }
                pathInPath {
                    id
                    icon
                    url
                    showGroup
                }
                taskChoice {
                    id
                    icon
                    url
                    name
                    complete
                }
                learningPath {
                    id
                    pathname
                }
            }
            submittedTask {
                id
                discriminant
                shortTitle
                objectives
                inspiration {
                    id
                    mediaType
                    src
                }
                title
                icon
                miles
                timeEstimate
                media {
                    id
                    mediaType
                    src
                    name
                    logo
                }
                shareAnswers
                shareAnswersAnonymously
                doInGroup
                requireApproval
                status
                people {
                    id
                    firstName
                    lastName
                    profilePicSrc
                }
                submittedTaskVariant {
                    id
                    variantType
                    variantTitle
                    description
                    hoursQuestions
                    playerAiInstructions
                    taskQuestions {
                        id
                        answerType
                        questionContent
                        quizId
                        pollId
                        surveyGizmoUrl
                        requireAllCheckmarks
                        checkboxOptions {
                            id
                            content
                        }
                        taskLikertStatements {
                            id
                            statement
                            leftLabel
                            rightLabel
                        }
                        impactTracker {
                            id
                            impactLikertStatements {
                                id
                                statement
                                leftLabel
                                rightLabel
                            }
                        }
                    }
                    eventStart
                    eventEnd
                    incompleteAnswers {
                        id
                        questionContent
                        part
                        answerContent
                    }
                    suggestedQuestions {
                        id
                        nr
                        type
                        content
                        leftLabel
                        rightLabel
                    }
                    allowAddQuestions
                    allowAccessCollectedFeedback
                    allowTranslateCollectedFeedback
                    anonymousCollectedFeedback
                    collectFeedbackTitle
                    collectFeedbackDescription
                    pulseSuggestedQuestions {
                        id
                        locale
                        content
                    }
                    teamScan {
                        id
                        status
                        myLink
                    }
                    pulse {
                        id
                        question1
                        question2
                        link
                        pulseAnswers {
                            id
                            answer1
                            answer2
                        }
                    }
                    taskAiThread {
                        id
                        aiThreadItems {
                            id
                            role
                            content
                        }
                    }
                }
                collectFeedback {
                    id
                    link
                    sendNotification
                    collectFeedbackAnswerSets {
                        id
                        firstName
                        lastName
                        submitted
                        collectFeedbackAnswers {
                            id
                            nr
                            type
                            question
                            translation
                            leftLabel
                            rightLabel
                            leftTranslation
                            rightTranslation
                            textAnswer
                            likertAnswer
                        }
                    }
                }
                taskApprovals {
                    id
                    status
                    membership {
                        id
                        person {
                            id
                        }
                    }
                }
                thread {
                    id
                    type
                    subtype
                    contentType
                    content
                    person {
                        id
                        firstName
                        lastName
                        profilePicSrc
                    }
                    taskAnswers {
                        id
                        answerType
                        questionContent
                        answerContent
                        checkboxAnswers {
                            id
                            content
                            checked
                        }
                        taskLikertAnswers {
                            id
                            statement
                            leftLabel
                            rightLabel
                            value
                        }
                        showPollResults
                        quizAnswerSet {
                            id
                            results
                            answers {
                                id
                                answer
                                answeredQuestion {
                                    id
                                    number
                                    levelNumber
                                    description
                                    correctAnswer
                                    explanation
                                    quizOptions {
                                        id
                                        letter
                                        content
                                    }
                                }
                            }
                        }
                        pollAnswerSet {
                            id
                            answers {
                                id
                                answers
                                question {
                                    id
                                    number
                                    content
                                    chartType
                                    options {
                                        id
                                        number
                                        content
                                        answerCount
                                    }
                                }
                            }
                        }
                        taskAnswerFile {
                            id
                            logoSrc
                            src
                            name
                        }
                        impactTrackerAnswerSet {
                            id
                            impactLikertAnswers {
                                id
                                statement
                                leftLabel
                                rightLabel
                                value
                            }
                        }
                    }
                }
            }
        }
        pathGroupForTask(id:$id) {
            id
            members {
                id
                person {
                    id
                    firstName
                    lastName
                    profilePicSrc
                }
            }
        }
    }
`;

export interface IPerson {
    id: string;
    firstName: string;
    lastName: string;
    profilePicSrc: string;
}

interface IQuizAnswer {
    id: string;
    answer: string;
    answeredQuestion: {
        id: string;
        number: number;
        levelNumber: number;
        description: string;
        correctAnswer: string;
        explanation: string | null;
        quizOptions: {
            id: string;
            letter: string;
            content: string;
        }[];
    };
}

interface IQuizAnswerSet {
    id: string;
    results: [number, number];
    answers: IQuizAnswer[];
}

interface ITaskAnswerFile {
    id: string;
    src: string;
    logoSrc: string;
    name: string;
}

interface IPollAnswer {
    id: string;
    answers: number[];
    question: {
        id: string;
        number: number;
        content: string;
        chartType: PollChartType;
        options: {
            id: string;
            number: number;
            content: string;
            answerCount: number;
        }[];
    };
}

interface IPollAnswerSet {
    id: string;
    answers: IPollAnswer[];
}

interface ITaskAnswer {
    id: string;
    answerType: AnswerType;
    questionContent: string | null;
    answerContent: string | null;
    checkboxAnswers: {
        id: string;
        content: string;
        checked: boolean;
    }[];
    taskLikertAnswers: {
        id: string;
        statement: string;
        leftLabel: string;
        rightLabel: string;
        value: number;
    }[];
    showPollResults: boolean;
    quizAnswerSet: IQuizAnswerSet;
    pollAnswerSet: IPollAnswerSet;
    taskAnswerFile: ITaskAnswerFile | null;
    impactTrackerAnswerSet: {
        id: string;
        impactLikertAnswers: {
            id: string;
            statement: string;
            leftLabel: string;
            rightLabel: string;
            value: number;
        }[];
    } | null;
}

export interface ITaskThreadItem {
    id: string;
    type: TaskThreadItemType;
    subtype: TaskThreadItemSubtype;
    contentType: ContentType;
    content: string | null;
    person: IPerson;
    taskAnswers: ITaskAnswer[];
}

export interface ITaskApproval {
    id: string;
    status: TaskApprovalStatus;
    membership: {
        id: string;
        person: {
            id: string;
        };
    };
}

export interface ITeamScan {
    id: string;
    status: TeamScanStatusType;
    myLink: string;
}

export interface IPulse {
    id: string;
    question1: string;
    question2: string | null;
    link: string;
    pulseAnswers: {
        id: string;
        answer1: string;
        answer2: string | null;
    }[];
}

export interface ICollectFeedbackAnswerSet {
    id: string;
    firstName: string;
    lastName: string;
    submitted: string;
    collectFeedbackAnswers: {
        id: string;
        nr: number;
        type: CollectFeedbackQuestionType;
        question: string;
        translation: string | null;
        leftLabel: string | null;
        rightLabel: string | null;
        leftTranslation: string | null;
        rightTranslation: string | null;
        textAnswer: string | null;
        likertAnswer: number | null;
    }[];
}

export interface ISubmittedTask {
    id: string;
    discriminant: "submitted_task";
    shortTitle: string;
    objectives: string;
    inspiration: {
        id: string;
        mediaType: string;
        src: string;
    } | null;
    title: string | null;
    icon: TaskIcon;
    miles: number;
    timeEstimate: TimeEstimateType | null;
    media: IMedia[];
    shareAnswers: ShareAnswersType;
    shareAnswersAnonymously: boolean;
    doInGroup: DoInGroupType;
    requireApproval: boolean;
    status: SubmittedTaskStatus;
    collectFeedback: {
        id: string;
        link: string;
        sendNotification: boolean;
        collectFeedbackAnswerSets: ICollectFeedbackAnswerSet[];
    } | null;
    thread: ITaskThreadItem[];
    people: IPerson[];
    taskApprovals: ITaskApproval[];
    submittedTaskVariant: ISubmittedTaskVariant;
}

interface IMedia {
    id: string | number;
    mediaType: string;
    src: string;
    name: string;
    logo?: string | null;
}

export interface ISuggestedQuestion {
    id: string;
    nr: number;
    type: CollectFeedbackQuestionType;
    content: string;
    leftLabel: string | null;
    rightLabel: string | null;
}

export interface ITaskQuestion {
    id: string;
    answerType: AnswerType;
    quizId: string | null;
    pollId: string | null;
    questionContent: string;
    surveyGizmoUrl: string;
    requireAllCheckmarks: boolean;
    checkboxOptions: {
        id: string;
        content: string;
    }[];
    taskLikertStatements: {
        id: string;
        statement: string;
        leftLabel: string;
        rightLabel: string;
    }[];
    impactTracker: {
        id: string;
        impactLikertStatements: {
            id: string;
            statement: string;
            leftLabel: string;
            rightLabel: string;
        }[];
    } | null;
}

export interface IIncompleteAnswer {
    id: string;
    questionContent: string;
    part: number;
    answerContent: string;
}

export interface ITaskVariant {
    id: string;
    variantType: VariantType;
    variantTitle: string;
    description: string | null;
    hoursQuestions: string | null;
    taskQuestions: ITaskQuestion[];
    eventStart: string | null;
    eventEnd: string | null;
    incompleteAnswers: IIncompleteAnswer[];
    suggestedQuestions: ISuggestedQuestion[];
    allowAddQuestions: boolean;
    allowAccessCollectedFeedback: boolean;
    allowTranslateCollectedFeedback: boolean;
    anonymousCollectedFeedback: boolean;
    collectFeedbackTitle: string | null;
    collectFeedbackDescription: string | null;
    playerAiInstructions: string | null;
    pulseSuggestedQuestions: {
        id: string;
        locale: Language;
        content: string;
    }[];
}

export interface ISubmittedTaskVariant extends ITaskVariant {
    teamScan: ITeamScan | null;
    pulse: IPulse | null;
    taskAiThread: {
        id: string;
        aiThreadItems: {
            id: string;
            role: AiMessageRoleType;
            content: string;
        }[];
    } | null;
}

interface ILearningPathItem {
    id: string;
    miles: number;
    earnedMiles: number;
    tasks: {
        id: string;
        locked: boolean;
        shortTitle: string;
        icon: TaskIcon;
        miles: number;
        deadline: string;
        url: string;
        shareAnswers: ShareAnswersType;
        doInGroup: DoInGroupType;
        taskVariants: {
            id: string;
            variantType: VariantType;
        }[];
        submittedTask: {
            id: string;
            url: string;
            status: SubmittedTaskStatus;
        };
    }[];
    pathInPath: {
        id: string;
        icon: TaskIcon;
        url: string;
        showGroup: boolean;
    } | null;
    taskChoice: {
        id: string;
        icon: TaskIcon;
        name: string;
        url: string;
        complete: boolean;
    } | null;
    learningPath: {
        pathname: string;
        id: string;
    };
}

export interface ITask {
    id: string;
    skipDescriptionPage: boolean;
    type: TaskType;
    discriminant: "task";
    shortTitle: string;
    objectives: string;
    inspiration: {
        id: string;
        mediaType: string;
        src: string;
    } | null;
    title: string | null;
    icon: TaskIcon;
    miles: number;
    timeEstimate: TimeEstimateType | null;
    deadline: string | null;
    media: IMedia[];
    shareAnswers: ShareAnswersType;
    shareAnswersAnonymously: boolean;
    finished: IPerson[];
    submittedTask: ISubmittedTask | null;
    doInGroup: DoInGroupType;
    taskGroup: {
        id: string;
        number: number;
        name: string | null;
        members: {
            id: string;
            firstName: string;
            lastName: string;
            profilePicSrc: string | null;
        }[];
    } | null;
    requireApproval: boolean;
    taskVariants: ITaskVariant[];
    learningPathItem: ILearningPathItem;
    feedbackTask: {
        id: string;
        shortTitle: string;
    } | null;
}

interface PathMembership {
    id: string;
    person: IPerson;
}

export interface IPathGroup {
    id: string;
    members: PathMembership[];
}

export interface IData {
    task: ITask;
    pathGroupForTask: IPathGroup;
}

// Save incomplete answers mutation

export interface ISaveIncompleteArgs {
    data: {
        answers: {
            questionContent: string | null;
            part: number;
            answerContent: string | null;
        }[];
        variantId: string | null;
        submittedTaskId: string | null;
    };
}

export const saveIncompleteMutation = `
    mutation($data: PlayerIncompleteAnswerSaveData!) {
       incompleteAnswerSave(data: $data) {
            error 
            incompleteAnswers {
                id
                taskVariant {
                    id
                }
            }
        }
    }
`;

export interface ISaveIncompleteMutationData {
    incompleteAnswerSave: {
        error: string | null;
        incompleteAnswers: {
            id: string;
        }[];
    };
}

// Approve answer mutation

export interface IApproveArgs {
    submittedTaskId: string;
}

export const approveTaskMutation = `
    mutation($submittedTaskId: String!) {
       approveGroupTask(submittedTaskId: $submittedTaskId) {
            error 
            status
            redirect
            submittedTask {
                id
            }
        }
    }
`;

export interface IApproveData {
    approveGroupTask: {
        error: string | null;
        status: string | null;
        redirect: string | null;
        submittedTask: {
            id: string;
        } | null;
    };
}

// Submit task mutation

export interface ISubmitArgs {
    data: {
        taskAnswers: {
            answerType: AnswerType;
            questionContent: string | null;
            answerContent: string | null;
            checkboxAnswers: {
                content: string;
                checked: boolean;
            }[];
            taskLikertAnswers: {
                statement: string;
                leftLabel: string;
                rightLabel: string;
                value: number;
            }[];
            fileAnswer: {
                filename: string;
                name: string;
            } | null;
            surveyGizmoRespId: string | null;
            impactValues: number[];
        }[];
        taskId: string;
        variantId: string;
        taskThreadItemId: string | null;
        outsideFeedbackDescription: string | null;
    };
}

export const submitMutation = `
    mutation ($data:PlayerSubmitTaskData!) {
       submitTask(data: $data) {
            error
            redirect
            submittedTask {
                id
            }
        }
    }
`;

export interface ISubmitData {
    submitTask: {
        error: string | null;
        redirect: string | null;
        submittedTask: {
            id: string;
        } | null;
    };
}
