import type { JSX, ReactNode } from "react";
import styled from "styled-components";
import { currentColors } from "../../../shared/colors";

interface IProps {
    children?: ReactNode;
}

const Svg = styled.svg`
    width: 35px;
    height: 35px;
    circle {
        fill: ${currentColors.taskQuestionNumber};
    }
    text {
        line-height: 35px;
        fill: white;
        text-anchor: middle;
        dominant-baseline: central;
        font-size: 62px;
    }
`;

function QuestionNum({ children }: IProps): JSX.Element {
    return (
        <Svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill={currentColors.taskQuestionNumber} />
            <text x="50" y="50">
                {children}
            </text>
        </Svg>
    );
}

export default QuestionNum;
