import type { ComponentPropsWithoutRef, JSX } from "react";
import { currentColors } from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function ArrowsInTriangleIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path d="m74.4 51.5v-22.6l-19.6 11.3z" fill="white" />
            <path
                d="m75.2 27.6c-.5-.3-1-.3-1.5 0l-8.7 5-8.4-14.3c-2.1-3.6-4.9-5.7-7.6-5.7-2 0-3.7 1.1-4.8 3.1l-4.2 7.1c-.4.7-.2 1.6.5 2s1.6.2 2-.5l4.1-7.2c.6-1 1.3-1.6 2.2-1.6 1.6 0 3.5 1.6 5 4.2l8.4 14.3-8.3 4.8c-.5.3-.8.8-.8 1.3s.3 1 .8 1.3l19.6 11.3c.2.1.5.2.8.2s.5-.1.8-.2c.5-.3.8-.8.8-1.3v-22.5c0-.6-.3-1.1-.7-1.3z"
                fill={currentColors.text}
            />
            <path d="m72.9 48.9-15.1-8.7 15.1-8.7z" fill={active ? currentColors.icon1 : "white"} />
            <path
                d="m84.5 66.4-4-7.4c-.4-.7-1.3-1-2-.6s-1 1.3-.6 2l4.1 7.5c.6 1.1.7 2.1.2 3-.8 1.4-3.2 2.3-6.1 2.3h-15.1v-9.8c0-.5-.3-1-.8-1.3s-1-.3-1.5 0l-19.6 11.2c-.5.3-.8.8-.8 1.3s.3 1 .8 1.3l19.6 11.3c.2.1.5.2.8.2s.5-.1.8-.2c.5-.3.8-.8.8-1.3v-9.8h15c4.1 0 7.3-1.4 8.7-3.7.9-1.8.9-3.9-.3-6z"
                fill={currentColors.text}
            />
            <path d="m58 83.3-15.1-8.7 15.1-8.7z" fill={active ? currentColors.icon1 : "white"} />
            <path
                d="m37.3 31.8c-.5-.3-1-.3-1.5 0l-19.6 11.3c-.5.3-.8.8-.8 1.3s.3 1 .8 1.3l8 4.6-8 13.7c-2 3.5-2.4 6.8-1 9.2 1.1 1.9 3.1 2.9 5.7 2.9h9.1c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5h-9.1c-1.1 0-2.4-.2-3.1-1.4-.8-1.4-.4-3.7 1-6.2l7.9-13.7 9 5.2c.2.1.5.2.8.2s.5-.1.8-.2c.5-.3.8-.8.8-1.3v-22.6c-.1-.5-.4-1-.8-1.3z"
                fill={currentColors.text}
            />
            <path d="m35 53.1-15.1-8.7 15.1-8.7z" fill={active ? currentColors.icon1 : "white"} />
        </svg>
    );
}

export default ArrowsInTriangleIcon;
