import type { JSX } from "react";
import { Link } from "react-router-dom";
import t from "../../shared/translations";
import PulseResponse from "./PulseResponse";

function Results(): JSX.Element {
    return (
        <>
            <h1>{t("player.pulse-results.heading")}</h1>
            <PulseResponse />
            <Link to="..">{t("player.pulse-results.link-back")}</Link>
        </>
    );
}

export default Results;
