import { type JSX, type ReactNode, createContext, useContext } from "react";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import type { Role } from "../../shared/types";
import ShowError from "./ShowError";

const query = `
    query {
        currentPerson {
            id
            firstName
            lastName
            profilePicSrc
            user {
                role {
                    name
                }
                pulseRights
                agreedToTerms
                requireNewPassword
                firstTimeLogin
            }
            ongoingPaths: learningPaths(filter:"ongoing") {
                id
                pathname
            }
            currentPath {
                id
                pathname
            }
            organisation {
                id
                name
            }
            teamScans {
                id
            }
            standaloneAiChatbots {
                id
            }
        }
    }
`;

export interface ICurrentPerson {
    id: string;
    firstName: string;
    lastName: string;
    profilePicSrc: string;
    user: {
        role: {
            name: Role;
        };
        pulseRights: boolean;
        agreedToTerms: boolean;
        requireNewPassword: boolean;
        firstTimeLogin: boolean;
    };
    ongoingPaths: {
        id: string;
        pathname: string;
    }[];
    currentPath: {
        id: string;
        pathname: string;
    } | null;
    organisation: {
        id: string;
        name: string;
    };
    teamScans: {
        id: string;
    }[];
    standaloneAiChatbots: {
        id: string;
    }[];
}

interface IData {
    currentPerson: ICurrentPerson;
}

const CurrentPersonContext = createContext<ICurrentPerson>(null);
CurrentPersonContext.displayName = "CurrentPersonContext";

interface IProps {
    children: ReactNode;
}

export function CurrentPersonProvider({ children }: IProps): JSX.Element {
    const [result] = useQuery<IData>({ query });

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }
    return <CurrentPersonContext.Provider value={result.data.currentPerson}>{children}</CurrentPersonContext.Provider>;
}

export function useCurrentPerson(): ICurrentPerson {
    return useContext(CurrentPersonContext);
}
