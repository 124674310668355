import type { JSX } from "react";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import { formatYMD, parseDate } from "../../shared/dateFns";
import t from "../../shared/translations";
import ShowError from "../components/ShowError";
import { type IThreadListData, threadListQuery } from "./queries";
import { Column, PrevHeading, ThreadLink } from "./stylings";

function PrevThreads(): JSX.Element {
    const [result] = useQuery<IThreadListData>({
        query: threadListQuery,
    });

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }

    const threads = result.data.currentPerson.standaloneAiThreads;

    if (threads.length === 0) {
        return <div />;
    }

    return (
        <Column>
            <PrevHeading>{t("player.ai-chatbots-landing.heading-previous")}</PrevHeading>
            {threads.map((thread) => (
                <ThreadLink key={thread.id} to={`../${thread.id}`}>
                    {formatYMD(parseDate(thread.created))} - {thread.standaloneAiChatbot.name}
                </ThreadLink>
            ))}
        </Column>
    );
}

export default PrevThreads;
