import type { JSX } from "react";
import { Navigate, useMatch } from "react-router-dom";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import t from "../../shared/translations";
import LockedModal, { useLockedModal } from "../LockedModal";
import ShowError from "../components/ShowError";
import WithTasksSidebar from "../tasksSidebar";
import SubmittedTaskRoutes from "./SubmittedTaskRoutes";
import { type IArgs, type IData, query } from "./query";

function SubmittedTask(): JSX.Element {
    const {
        params: { id },
    } = useMatch("/submitted-task/:id/*");
    const { openLockedModal, ...lockedProps } = useLockedModal();
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: { id },
    });

    let main = <div />;
    if (result.fetching) {
        main = <Loader />;
    } else if (result.error) {
        main = <ShowError />;
    } else if (!result.data.submittedTask) {
        main = <Navigate to="/landing" replace={true} />;
    } else {
        const task = result.data.submittedTask;

        main = <SubmittedTaskRoutes task={task} />;
    }

    return (
        <>
            <WithTasksSidebar
                openLockedModal={openLockedModal}
                activeItem={result.data?.submittedTask?.learningPathItem?.id}
                mainLabel={t("player.submitted-task.tab-task")}
                big={true}
            >
                {main}
            </WithTasksSidebar>
            <LockedModal {...lockedProps} />
        </>
    );
}

export default SubmittedTask;
