import type { ComponentPropsWithoutRef, ForwardedRef } from "react";
import { forwardRef } from "react";
import styled from "styled-components";
import { currentColors } from "../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    open: boolean;
    size?: string;
}

interface ISvg {
    $size: string;
}

const Svg = styled.svg<ISvg>`
    width: ${({ $size }) => $size};
    height: ${({ $size }) => $size};
    line {
        stroke: ${currentColors.text};
        stroke-width: 20;
        stroke-linecap: round;
    }
`;

const PlusMinus = forwardRef<SVGSVGElement, IProps>(function PlusMinus(
    { open, size = "1.5rem", ...props },
    ref: ForwardedRef<SVGSVGElement>,
) {
    return (
        <Svg viewBox="0 0 100 100" $size={size} ref={ref} {...props}>
            <line x1="10" x2="90" y1="50" y2="50" />
            {!open && <line y1="10" y2="90" x1="50" x2="50" />}
        </Svg>
    );
});

PlusMinus.displayName = "PlusMinus";

export default PlusMinus;
