import { type JSX, memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMutation } from "urql";
import { currentColors } from "../../../shared/colors";
import GroupTaskIcon from "../../../shared/components/icons/GroupTaskIcon";
import getRelativeUrl from "../../../shared/getRelativeUrl";
import { loadJSData } from "../../../shared/jsData";
import t from "../../../shared/translations";
import TaskThreadItemAnswer from "../../components/TaskThreadItemAnswer";
import { type Dispatch, editTaskAnswer } from "../../state";
import { PathGroupPreviewBox } from "../answerSection/PathGroupPreview";
import type { IPathGroup, ISubmittedTask } from "../query";
import { type IApproveArgs, type IApproveData, approveTaskMutation } from "../query";
import { Box } from "../stylings";
import ApprovalItem from "./ApprovalItem";

interface IProps {
    task: ISubmittedTask;
    pathGroup: IPathGroup;
}

const ApprovalsBox = styled(Box)`
    background-color: ${currentColors.background};
    border-color: ${currentColors.border};
`;

const ApproveHeader = styled.h4`
    width: 100%;
    margin-top: 2rem;
    text-align: center;
`;

const ApproveButton = styled.div`
    width: 100%;
    margin-top: 1rem;
    text-transform: uppercase;
    text-align: center;

    button {
        margin-bottom: 0;
    }
`;

const H3 = styled.h3`
    display: grid;
    grid-template-columns: 26px 1fr;
    align-items: center;
    gap: 0.5rem;
    svg {
        width: 26px;
        height: 26px;
    }
`;

function ApprovalSection({ task, pathGroup }: IProps): JSX.Element {
    const [approveResult, executeApprove] = useMutation<IApproveData, IApproveArgs>(approveTaskMutation);
    const dispatch = useDispatch<Dispatch>();
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const approve = async () => {
        setError(null);
        const result = await executeApprove({ submittedTaskId: task.id });
        if (result.error) {
            console.error(result.error);
            setError(t("player.error.error"));
        } else if (result.data?.approveGroupTask.error) {
            console.error(result.data.approveGroupTask.error);
            setError(result.data.approveGroupTask.error);
        } else {
            navigate(getRelativeUrl(result.data?.approveGroupTask.redirect));
        }
    };

    const editAnswer = () => {
        const ttis = task.thread.filter((tti) => tti.type === "unapproved-answer");
        const tti = ttis[ttis.length - 1];
        if (!tti) {
            return;
        }
        dispatch(editTaskAnswer(tti));
    };

    const tti = task.thread.find((item) => item.type === "unapproved-answer");
    const approvers = task.people;
    const currentMembership = pathGroup.members.find((m) => m.person.id === loadJSData().current_person.id);
    const showApproval = !task.taskApprovals.some((appr) => currentMembership.id === appr.membership.id);

    return (
        <>
            <Box>
                <H3>
                    <GroupTaskIcon active />
                    {t("player.task.heading-path-group-task")}
                </H3>
                <p>{t("player.task.info-awaiting-path-group-approval")}</p>
            </Box>
            <ApprovalsBox>
                <TaskThreadItemAnswer tti={tti} boxStyle={{ width: "100%" }} />
                <ApproveHeader>{t("player.task.heading-approve")}</ApproveHeader>
                <PathGroupPreviewBox>
                    {approvers.map((person) => (
                        <ApprovalItem
                            key={person.id}
                            person={person}
                            approval={task.taskApprovals.find((appr) => appr.membership.person.id === person.id)}
                        />
                    ))}
                </PathGroupPreviewBox>
                {showApproval && (
                    <ApproveButton>
                        <button onClick={approve} disabled={approveResult.fetching} type="button">
                            {t("player.task.button-approve")}
                        </button>
                        {error && <div>{error}</div>}
                    </ApproveButton>
                )}
            </ApprovalsBox>
            <div className="text-center">
                <button onClick={editAnswer} type="button">
                    {t("player.task.button-edit")}
                </button>
            </div>
        </>
    );
}

export default memo(ApprovalSection);
