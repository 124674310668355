import { type ComponentPropsWithoutRef, type JSX, type ReactNode, memo } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    unread?: ReactNode;
}

function InspirationIcon({ unread, ...props }: IProps): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
            <path d="M59.1,77.4H40.9c-0.8,0-1.5,0.7-1.5,1.5v9.3c0,0.8,0.7,1.5,1.5,1.5h18.2c0.8,0,1.5-0.7,1.5-1.5v-9.3 C60.6,78.1,59.9,77.4,59.1,77.4z M57.6,86.8H42.4v-6.3h15.2V86.8z" />
            <path d="M61.4,11.9C61.4,11.9,61.3,11.9,61.4,11.9c-3.6-1.1-7.4-1.6-11.4-1.6c-17.7,0-32,11.2-32,25 c0,5.8,5.3,14.3,15.8,25.4c7.7,8.1,15.5,14.7,15.5,14.8c0.3,0.2,0.6,0.4,1,0.4c0.3,0,0.7-0.1,1-0.4c0.1-0.1,7.7-6.7,15.2-14.8 C76.8,49.6,82,41,82,35.3C82,24.6,73.4,15.5,61.4,11.9z M79,35.3C79,40,74.3,47,68.5,53.9c2.5-4.8,3.7-9.5,3.7-14 c0-8.8-2.3-17.1-6.4-23.1C73.7,20.7,79,27.5,79,35.3z M39.9,14.6c1.2-0.4,2.5-0.6,3.8-0.9c-2.3,6-3.7,15.7-3.7,26.2 c0,10.1,2.9,20.8,5.3,27.6C39.9,62.9,30.8,52,30.8,39.9C30.8,29.6,34.2,20.2,39.9,14.6C39.9,14.7,39.9,14.6,39.9,14.6z M60.1,14.6 c5.6,5.4,9.1,15.1,9.1,25.3c0,10.4-7.8,20.5-13.7,26.2c2.2-6.7,4.5-16.7,4.5-26.2c0-10.6-1.4-20.1-3.7-26.2 C57.6,14,58.8,14.3,60.1,14.6z M52.8,13.3C52.8,13.4,52.8,13.4,52.8,13.3c2.6,5.4,4.2,15.6,4.2,26.6c0,12.5-4.3,26.7-6.8,31.4 C47.8,66.7,43,52.9,43,39.9c0-11,1.6-21.3,4.2-26.6c0.9-0.1,1.9-0.1,2.8-0.1C51,13.2,51.9,13.3,52.8,13.3z M34.2,16.8 c-4.1,6.1-6.4,14.3-6.4,23.1c0,4.5,1.1,8.8,2.9,12.8c-5.5-6.5-9.7-13-9.7-17.4C21,27.5,26.3,20.7,34.2,16.8z" />
            {unread}
        </svg>
    );
}

export default memo(InspirationIcon);
