import type { CSSProperties, JSX, MouseEvent } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import OrigMilesCircle from "../../shared/components/MilesCircle";
import { formatYMD, parseDate } from "../../shared/dateFns";
import t from "../../shared/translations";
import type { OpenLockedModal } from "../LockedModal";
import { PAGE_SIZE, SMALL_CUTOFF } from "./CornerPath";
import type { PathItem } from "./StreetPath";
import { AllAnswersLabel, GroupTaskLabel, LockIcon, SizedTaskIcon } from "./stylings";

const MIN_ICON_SIZE = 60;
const MAX_ICON_SIZE = 100;

const POSITIONS: [string, string][] = [
    ["9%", "57%"],
    ["23.5%", "69%"],
    ["40.6666%", "77%"],
    ["59.3333%", "77%"],
    ["76.5%", "69%"],
    ["91%", "57%"],
];

const SMALL_POSITIONS: [string, string][] = [
    ["8.3333%", "57%"],
    ["25%", "69%"],
    ["41.6666%", "77%"],
    ["58.3333%", "77%"],
    ["75%", "69%"],
    ["91.6666%", "57%"],
];

interface IProps {
    item: PathItem;
    width: number;
    index: number;
    openLockedModal: OpenLockedModal;
}

interface IAvatar {
    $scale: number;
}

export const Avatar = styled.a<IAvatar>`
    display: block;
    position: absolute;
    height: auto;
    transform: translate(-50%, -68%);
    img {
        user-select: none;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: bottom;
        transform-origin: bottom;
        transform: scale(${({ $scale }) => $scale});
    }
`;

export const Task = styled(Link)`
    transform: translate(-50%, -50%);
    display: block;
    position: absolute;
    display: grid;
    place-items: center;
    text-align: center;
    color: ${currentColors.text};
    font-size: 0.9rem;
    &:hover, &:focus, &:active, &:visited {
        color: ${currentColors.text};
    }
`;

interface IMilesLabel {
    $topM: boolean;
}

export const MilesLabel = styled.div<IMilesLabel>`
    user-select: none;
    height: 30px;
    font-size: 0.85rem;
    margin-top: ${({ $topM }) => ($topM ? 5 : 0)}px;
`;

interface IIconTainer {
    $size: number;
}

export const IconTainer = styled.div<IIconTainer>`
    position: relative;
    width: ${({ $size }) => $size}px;
    height: ${({ $size }) => $size}px;
`;

export const StatusTainer = styled.div`
    height: 30px;
`;

export const ActiveLabel = styled.span`
    user-select: none;
    display: inline-block;
    border: 2px solid ${currentColors.taskActive};
    padding: 2px 6px;
    color: ${currentColors.taskActive};
    background-color: white;
`;

export const RedoLabel = styled.span`
    user-select: none;
    display: inline-block;
    border: 2px solid ${currentColors.taskRedo};
    padding: 2px 6px;
    color: ${currentColors.taskRedo};
    background-color: white;
`;

interface IMilesCircle {
    $size: number;
}

export const MilesCircle = styled(OrigMilesCircle)<IMilesCircle>`
    position: absolute;
    top: ${({ $size }) => -0.1 * $size}px;
    left: ${({ $size }) => -0.1 * $size}px;
    width: ${({ $size }) => $size + 0.2 * $size}px;
    height: ${({ $size }) => $size + 0.2 * $size}px;
    max-width: ${({ $size }) => $size + 0.2 * $size}px;
    max-height: ${({ $size }) => $size + 0.2 * $size}px;
`;

function useIconSize(containerWidth: number): number {
    if (containerWidth <= MIN_ICON_SIZE * PAGE_SIZE) {
        return MIN_ICON_SIZE;
    }
    if (containerWidth <= MAX_ICON_SIZE * PAGE_SIZE) {
        return Math.floor(containerWidth / PAGE_SIZE);
    }
    return MAX_ICON_SIZE;
}

function usePosition(index: number, width: number): [string, string] {
    if (width >= SMALL_CUTOFF) {
        return POSITIONS[index];
    }
    return SMALL_POSITIONS[index];
}

function useScale(index: number): number {
    switch (index) {
        case 0:
            return 0.66;
        case 1:
            return 0.83;
        case 2:
            return 1.0;
        case 3:
            return 1.0;
        case 4:
            return 0.83;
        case 5:
            return 0.66;
        default:
            return 1.0;
    }
}

export function fillPercent(item: PathItem): number {
    if (item.miles?.[0] > 0) {
        return item.earnedMiles / item.miles[0];
    }
    return 0;
}

export function fmtMiles(item: PathItem): string {
    if (!item.miles?.[0]) {
        return "";
    }
    if (item.miles[0] === item.miles[1]) {
        return item.miles[0].toString();
    }
    return `${item.miles[0]}-${item.miles[1]}`;
}

function CornerItem({ item, width, index, openLockedModal }: IProps): JSX.Element {
    const iconSize = useIconSize(width);
    const [left, top] = usePosition(index, width);
    const style: CSSProperties = { top, left, width: iconSize };
    const avatarScale = useScale(index);
    const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
        if (item.locked) {
            event.preventDefault();
            openLockedModal(item.lockedTaskId, item.lockedFeedbackId, item.lockedTaskChoiceId);
            event.stopPropagation();
        }
    };
    if (item.avatarSrc) {
        return (
            <Avatar href={item.url} style={style} $scale={avatarScale}>
                <img src={item.avatarSrc} alt="avatar" />
            </Avatar>
        );
    }
    style.height = width >= SMALL_CUTOFF ? iconSize + 30 + 30 : iconSize;
    return (
        <Task to={item.url} style={style} onClick={onClick}>
            {width >= SMALL_CUTOFF && (
                <StatusTainer>
                    {item.active && <ActiveLabel>{t("player.landing.active")}</ActiveLabel>}
                    {item.undecided && <ActiveLabel>{t("player.landing.undecided")}</ActiveLabel>}
                    {item.redo && <RedoLabel>{t("player.landing.redo")}</RedoLabel>}
                </StatusTainer>
            )}
            <IconTainer $size={Math.floor(iconSize * avatarScale)}>
                <SizedTaskIcon
                    icon={item.icon}
                    active={item.complete}
                    $size={`${Math.floor(iconSize * avatarScale)}px`}
                />
                {item.type === "path-in-path" && (
                    <MilesCircle
                        color={currentColors.progress}
                        fillPercent={fillPercent(item)}
                        $size={iconSize * avatarScale}
                        thin
                    />
                )}
                {item.locked && <LockIcon $size={iconSize * avatarScale} $absolute={true} />}
                {item.grouped && <GroupTaskLabel active={item.complete} $scale={avatarScale * 1.4} />}
                {item.shareAnswers && <AllAnswersLabel $scale={avatarScale * 1.5} />}
            </IconTainer>
            {width >= SMALL_CUTOFF && item.miles?.[0] > 0 && (
                <MilesLabel $topM={item.type === "path-in-path"}>
                    {t("player.landing.label-street-miles", { miles: fmtMiles(item) })}
                </MilesLabel>
            )}
            {width >= SMALL_CUTOFF && item.type === "event" && (
                <MilesLabel $topM={false}>{formatYMD(parseDate(item.eventDate))}</MilesLabel>
            )}
        </Task>
    );
}

export default CornerItem;
