import type { ValidationErrors } from "final-form";
import { range } from "lodash-es";
import { type JSX, useCallback, useMemo, useState } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";
import { useMutation } from "urql";
import { currentColors } from "../../shared/colors";
import { format, subYears } from "../../shared/dateFns";
import getInt from "../../shared/getInt";
import t from "../../shared/translations";
import Checkbox from "../components/FinalCheckbox";
import Input from "../components/FinalInput";
import Select from "../components/FinalSelect";
import type { ICurrentPerson } from "./Profile";
import { Button, StyledForm } from "./stylins";

const editPersonMutation = `
    mutation($data:PlayerCurrentPersonEditData!) {
        currentPersonEdit(data:$data) {
            error
            currentPerson {
                __typename
            }
        }
    }
`;

interface IEditArgs {
    data: {
        firstName: string;
        lastName: string;
        email: string;
        gender: string;
        title: string | null;
        tel: string | null;
        birthYear: number | null;
        eMailNotifications: boolean;
    };
}

interface IProps {
    person: ICurrentPerson;
}

interface IFormValues {
    firstName: string;
    lastName: string;
    email: string;
    gender: string;
    title: string | null;
    tel: string | null;
    birthYear: number | null;
    eMailNotifications: boolean;
}

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.firstName) {
        errors.firstName = t("player.profile.form-profile-error-first-name");
    }
    if (!values.lastName) {
        errors.lastName = t("player.profile.form-profile-error-last-name");
    }
    if (!values.email) {
        errors.email = t("player.profile.form-profile-error-e-mail");
    } else if (!values.email.includes("@")) {
        errors.email = t("player.profile.form-profile-error-e-mail");
    }
    return errors;
}

const years = range(100).map((_val, idx) => format(subYears(new Date(), idx), "yyyy"));

interface IInfoBox {
    $error: boolean;
}

const InfoBox = styled.div<IInfoBox>`
    color: white;
    background-color: ${({ $error }) => ($error ? currentColors.error : currentColors.success)};
    padding: 5px;
    margin-bottom: 1rem;
    border-radius: 3px;
`;

const Section = styled.section`
    grid-column: 1/-1;
    width: 100%;
`;

function ProfileForm({ person }: IProps): JSX.Element {
    const [_, executeMutation] = useMutation<any, IEditArgs>(editPersonMutation);
    const [apiError, setApiError] = useState("");
    const [apiSuccess, setApiSuccess] = useState(false);

    const onSubmit = useCallback(
        async (values: IFormValues) => {
            const args: IEditArgs = {
                data: values,
            };
            setApiError("");
            setApiSuccess(false);
            const result = await executeMutation(args);
            if (result.error) {
                setApiError(result.error.message);
            } else if (result.data.error) {
                setApiError(result.data.currentPersonEdit.error);
            } else {
                setApiSuccess(true);
            }
        },
        [executeMutation],
    );

    const initialValues = useMemo((): IFormValues => {
        return {
            firstName: person.firstName,
            lastName: person.lastName,
            email: person.user.email,
            gender: person.gender,
            title: person.title,
            tel: person.tel,
            birthYear: person.birthYear,
            eMailNotifications: person.eMailNotifications,
        };
    }, [person]);

    return (
        <Section>
            <Form onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
                {({ handleSubmit, submitting }) => (
                    <StyledForm onSubmit={handleSubmit}>
                        <Input
                            name="firstName"
                            label={t("player.profile.form-profile-label-first-name")}
                            maxLength={128}
                        />
                        <Input
                            name="lastName"
                            label={t("player.profile.form-profile-label-last-name")}
                            maxLength={128}
                        />
                        <Input name="email" label={t("player.profile.form-profile-label-e-mail")} maxLength={256} />
                        <Select name="gender" label={t("player.profile.form-profile-label-gender")}>
                            <option value="male">{t("shared.gender.male")}</option>
                            <option value="female">{t("shared.gender.female")}</option>
                            <option value="unspecified">{t("shared.gender.unspecified")}</option>
                        </Select>
                        <Input name="title" label={t("player.profile.form-profile-label-title")} maxLength={256} />
                        <Input name="tel" label={t("player.profile.form-profile-label-phone")} maxLength={64} />
                        <Select
                            name="birthYear"
                            label={t("player.profile.form-profile-label-birth-year")}
                            parse={getInt}
                        >
                            <option value="" />
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </Select>
                        <Checkbox
                            name="eMailNotifications"
                            label={t("player.profile.form-profile-label-e-mail-notifications")}
                        />
                        {apiError && <InfoBox $error={true}>{apiError}</InfoBox>}
                        {apiSuccess && <InfoBox $error={false}>{t("player.profile.info-profile-saved")}</InfoBox>}
                        <div>
                            <Button type="submit" disabled={submitting}>
                                {t("player.profile.form-profile-submit")}
                            </Button>
                        </div>
                    </StyledForm>
                )}
            </Form>
        </Section>
    );
}

export default ProfileForm;
