import type { JSX } from "react";
import { useResizeDetector } from "react-resize-detector";
import styled from "styled-components";
import { currentColors } from "../../../shared/colors";
import t from "../../../shared/translations";
import WithDocs from "../../components/WithDocs";
import { H3, StyledRenderMark } from "../stylings";
import CentralisationChart from "./CentralisationChart";
import DensityChart from "./DensityChart";
import NetworkGraph from "./NetworkGraph";
import PeopleChart from "./PeopleChart";
import type { IDoc, ITeamScan } from "./Report";

interface IProps {
    teamScan: ITeamScan;
    printing?: boolean;
    showNetwork: boolean;
    docList: IDoc[];
}

const Hr = styled.hr`
    border: 2px solid ${currentColors.border};
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    align-items: center;

    @media (min-width: 600px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const CenterCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const PageBreak = styled.div`
    page-break-after: always;
`;

const H5 = styled.h5`
    font-weight: bold;
`;

function SL({
    teamScan: {
        report: { sna, membersWhoAnswered },
    },
    printing,
    showNetwork,
    docList,
}: IProps): JSX.Element {
    const { width, ref } = useResizeDetector();
    return (
        <div>
            {printing ? (
                <StyledRenderMark
                    content={docList.find((doc) => doc.id === "player.team-scan.report-sl-intro-print").content}
                />
            ) : (
                <StyledRenderMark
                    content={docList.find((doc) => doc.id === "player.team-scan.report-sl-intro").content}
                />
            )}
            {printing && <PageBreak />}
            <H3>{t("shared.team-scan-report.heading-results")}</H3>
            {printing ? (
                <h4>{t("shared.team-scan-report.heading-overview")}</h4>
            ) : (
                <WithDocs
                    component="h4"
                    modal="TeamScan/Info/ReportSLOverview"
                    docPath="player/team-scan/report-sl-overview"
                >
                    {t("shared.team-scan-report.heading-overview")}
                </WithDocs>
            )}
            <Row>
                <CenterCol>
                    <H5>{t("shared.team-scan-report.heading-denstiy")}</H5>
                    <DensityChart percent={sna.density} />
                    <div>
                        {sna.density >= 80 && t("shared.team-scan-report.info-density-high")}
                        {sna.density < 80 && sna.density >= 40 && t("shared.team-scan-report.info-density-medium")}
                        {sna.density < 40 && t("shared.team-scan-report.info-density-low")}
                    </div>
                </CenterCol>
                <CenterCol>
                    <H5>{t("shared.team-scan-report.heading-centralisation")}</H5>
                    <CentralisationChart centralisation={sna.centralisation} />
                    <div>
                        {sna.centralisation <= 20 && t("shared.team-scan-report.info-centralisation-low")}
                        {sna.centralisation <= 60 &&
                            sna.centralisation > 20 &&
                            t("shared.team-scan-report.info-centralisation-medium")}
                        {sna.centralisation > 60 && t("shared.team-scan-report.info-centralisation-high")}
                    </div>
                </CenterCol>
            </Row>
            <Hr />
            <H5>{t("shared.team-scan-report.heading-team-leader")}</H5>
            <Row>
                <PeopleChart fillPortion={sna.teamLeaderLeadership} numMembers={membersWhoAnswered - 1} />
                <div>
                    {t("shared.team-scan-report.info-team-leader-leadership", {
                        precieved: sna.teamLeaderLeadership,
                        total: membersWhoAnswered - 1,
                    })}
                </div>
            </Row>
            <Hr />
            <H5>{t("shared.team-scan-report.heading-team-member")}</H5>
            <Row>
                <PeopleChart fillPortion={sna.teamMemberAverageLeadership} numMembers={membersWhoAnswered - 1} />
                <div>
                    {t("shared.team-scan-report.info-average-member", {
                        avg: sna.teamMemberAverageLeadership.toFixed(1),
                    })}
                </div>
            </Row>
            {showNetwork && (
                <>
                    <Hr />
                    {printing ? (
                        <h4>{t("shared.team-scan-report.heading-leadershi-network")}</h4>
                    ) : (
                        <WithDocs
                            component="h4"
                            modal="TeamScan/Info/ReportSLNetwork"
                            docPath="player/team-scan/report-sl-network"
                        >
                            {t("shared.team-scan-report.heading-leadershi-network")}
                        </WithDocs>
                    )}
                    <div ref={ref}>
                        <NetworkGraph width={width} height={400} links={sna.links} nodes={sna.nodes} />
                    </div>
                </>
            )}
            {printing && <PageBreak />}
            <StyledRenderMark content={docList.find((doc) => doc.id === "player.team-scan.report-sl-outro").content} />
            {printing && <PageBreak />}
        </div>
    );
}

export default SL;
