import * as Sentry from "@sentry/browser";
import App from "./app/index";
import { config, userConfig } from "./sentry.config";
import { initDateLocale } from "./shared/dateFns";
import runOnLoad from "./shared/runOnLoad";

function init(): void {
    Sentry.init(config);
    if (userConfig) {
        Sentry.setUser(userConfig);
    }

    try {
        initDateLocale();

        App();
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
    }
}

runOnLoad(init);
