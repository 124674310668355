import type { ComponentPropsWithoutRef, JSX } from "react";
import { currentColors } from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function WaveIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m90.3 74.6c-.8-.3-1.6 0-2 .8-1.4 3.4-4.8 5.6-8.5 5.6-.4 0-.8 0-1.3-.1.6-2.4 2.4-9.5 2.9-16.2 4.3-.4 7.7-3 9.6-7.4.3-.8 0-1.6-.8-2-.8-.3-1.6 0-2 .8-.8 1.9-2.7 5-6.7 5.5 0-3.6-.2-6.7-.6-9.3 0-.4-3.1-36.5-35.9-36.5h-.9c-16.9.3-24.3 11.6-26 18.3-.5 1.9-1 5.5.9 7.2.7.7 2.1 1.3 4.3.5 1.8-.7 2.3 0 3.1 1.3.5.8 1.1 1.7 2.2 1.9s2.1-.1 3-.4c1.5-.5 2.1-.7 3.1 1.1.9 1.6 2.6 2.4 4.4 2.3 1.9-.2 3.5-1.6 4.2-3.7.1-.1.1-.3.1-.4.1-.2.2-.5.2-.7 1.1 2.3 1.8 5.9 1.1 12.4-.2 1.9-.8 3.7-1.5 5.5-1 .5-1.9.7-3.2.7-3.8 0-7.2-2.3-8.5-5.8-.2-.6-.8-1-1.4-1s-1.2.4-1.4 1c-1.1 3.5-4.6 6-8.5 6-3.8 0-7.2-2.3-8.5-5.8-.3-.8-1.2-1.2-1.9-.8-.8.3-1.2 1.2-.8 1.9 1.8 4.7 6.3 7.7 11.3 7.7 4.1 0 7.9-2.1 10-5.3 2.3 3.1 5.9 5 9.9 5 .5 0 .9 0 1.3-.1-2.8 4.7-6.8 8.6-9.6 10.9-.1-.1-.1-.3-.2-.4-.2-.6-.8-1-1.4-1s-1.2.4-1.4 1c-1.1 3.5-4.6 6-8.5 6-3.8 0-7.2-2.3-8.5-5.8-.3-.8-1.2-1.2-1.9-.8-.8.3-1.2 1.2-.8 1.9 1.8 4.7 6.3 7.7 11.3 7.7 4.1 0 7.9-2.1 10-5.3 2.3 3.1 5.9 5 9.9 5 3.9 0 7.5-1.9 9.8-5 2.3 3.2 6 5.2 10.1 5.2s7.9-2.1 10-5.3c2.3 3.1 5.9 5 9.9 5 4.9 0 9.3-2.9 11.2-7.4 0-.5-.3-1.4-1.1-1.7z"
                fill={currentColors.text}
                fillRule="nonzero"
            />
            <path
                d="m55 28.5c-7.1 1.6-12 6.6-14.4 14.6-.3 1.1-.9 1.7-1.7 1.8-.6.1-1.2-.2-1.4-.7-1.3-2.3-2.8-2.9-4.2-2.9-.9 0-1.8.3-2.4.5-.5.2-1 .3-1.4.3-.1-.1-.3-.4-.4-.6-.8-1.3-2.6-4-6.7-2.5-.6.2-1 .3-1.2.1-.3-.3-.7-1.7 0-4.2 1.5-5.8 8-15.7 23.2-16 11.2-.3 19.8 3.9 25.9 12.2-4.4-3.1-9.5-3.9-15.3-2.6z"
                fill="white"
            />
            <path
                d="m71.3 75.1c-.2-.6-.8-1-1.4-1s-1.2.4-1.4 1c-1.1 3.5-4.6 6-8.5 6-3.8 0-7.3-2.3-8.6-5.8-.2-.6-.8-.9-1.4-1-.6 0-1.2.4-1.4.9-1.4 3.4-4.8 5.6-8.5 5.6-2.6 0-5-1.1-6.7-2.9 4.9-3.9 13.3-12.8 14.4-22.2.8-7.4 0-12.4-2.6-16.2 2.3-4.3 5.8-7.1 10.4-8.2 5-1.1 9.3-.4 12.8 2.1 6 4.3 8.4 12.8 9.2 16.8.1.7.3 1.4.4 2.2v.1c.4 2.8.7 6.3.5 10.4-.2 6.6-2.2 14.4-2.9 17-1.8-.9-3.4-2.6-4.3-4.8z"
                fill={active ? currentColors.icon2 : "white"}
                fillRule="nonzero"
            />
        </svg>
    );
}

export default WaveIcon;
