import { Fragment, memo } from "react";
import type { JSX } from "react";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import { nice_num } from "../../shared/components/Runners";
import t from "../../shared/translations";

type TickProps = {
    loc: number;
    miles: number;
};

function calcTicks(miles: number): TickProps[] {
    const maxTicks = 6;
    const tickRange = nice_num(miles, false);
    const tickSpacing = nice_num(tickRange / (maxTicks - 1), true);
    const niceMax = Math.ceil(miles / tickSpacing) * tickSpacing;
    const numTicks = Math.floor(niceMax / tickSpacing) + 1;
    const tickDistance = 100.0 / Math.floor(niceMax / tickSpacing);
    const ticks: TickProps[] = [];
    for (let i = 0, iMax = numTicks; i < iMax; i++) {
        const loc = i * tickDistance * 1.96 + 9;
        ticks.push({ loc, miles: niceMax - i * tickSpacing });
    }
    return ticks;
}

function calcMinorTicks(ticks: TickProps[]): number[] {
    const minorTicks: number[] = [];
    for (let i = 1, iMax = ticks.length; i < iMax; i++) {
        const dist = (ticks[i].loc - ticks[i - 1].loc) / 2;
        minorTicks.push(ticks[i].loc - dist);
    }
    return minorTicks;
}

function calculateSpeedPos(miles: number, ticks: TickProps[]): number {
    if (ticks.length === 0) {
        return 0;
    }

    if (miles >= ticks[0].miles) {
        return ticks[0].loc;
    }

    const lastTick = ticks[ticks.length - 1];
    if (miles <= lastTick.miles) {
        return lastTick.loc;
    }

    // Find the two ticks that the miles value falls between
    for (let i = 0; i < ticks.length - 1; i++) {
        const upperTick = ticks[i];
        const lowerTick = ticks[i + 1];

        if (miles <= upperTick.miles && miles >= lowerTick.miles) {
            // Calculate the position by linear interpolation
            const milesRange = upperTick.miles - lowerTick.miles;
            const locRange = lowerTick.loc - upperTick.loc;
            const ratio = (miles - lowerTick.miles) / milesRange;
            return lowerTick.loc - ratio * locRange;
        }
    }

    // Fallback (should not reach here if ticks are properly ordered)
    return ticks[0].loc;
}

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: end;
    > div {
        min-width: 0;
        overflow-wrap: break-word;
    }
    > div:first-child {
        text-align: right;
    }
    > div:last-child {
        text-align: left;
    }
`;

interface IProps {
    mySpeed: number;
    ourSpeed: number;
}

function Speedbar({ mySpeed, ourSpeed }: IProps): JSX.Element {
    const maxMiles = Math.max(mySpeed, ourSpeed, 500);
    const ticks = calcTicks(maxMiles);
    const minorTicks = calcMinorTicks(ticks);
    const mySpeedPos = calculateSpeedPos(mySpeed, ticks);
    const ourSpeedPos = calculateSpeedPos(ourSpeed, ticks);
    let tickKey = 0;
    return (
        <Row>
            <div>{t("player.performance.label-speed-me")}</div>
            <svg
                clipRule="evenodd"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="1.5"
                viewBox="0 0 136 213"
                xmlns="http://www.w3.org/2000/svg"
                width="160"
            >
                <defs>
                    <symbol id="speed-marker-me">
                        <path d="m31 8-15.5 8h-15.5l5.167-8-5.167-8h15.5z" fill={currentColors.performance} />
                    </symbol>
                    <symbol id="speed-marker-our">
                        <path d="m0 8 15.5-8h15.5l-5.167 8 5.167 8h-15.5z" fill={currentColors.performance} />
                    </symbol>
                </defs>
                <line x1="33" y1="9" x2="33" y2="205" stroke={currentColors.text} strokeWidth="3" />
                <line x1="103" y1="9" x2="103" y2="205" stroke={currentColors.text} strokeWidth="3" />
                <g fill="none" stroke={currentColors.text} strokeWidth="3">
                    {ticks.map((tick) => (
                        <Fragment key={tickKey++}>
                            <line y1={tick.loc} x1="33" y2={tick.loc} x2="43" />
                            <line y1={tick.loc} x1="93" y2={tick.loc} x2="103" />
                            <text
                                fontSize="16"
                                x="69"
                                y={tick.loc + 6}
                                fill={currentColors.text}
                                stroke="none"
                                textAnchor="middle"
                            >
                                {tick.miles}
                            </text>
                        </Fragment>
                    ))}
                </g>
                <g fill="none" stroke={currentColors.text} strokeWidth="2.37">
                    {minorTicks.map((tick) => (
                        <Fragment key={tickKey++}>
                            <line y1={tick} x1="33" y2={tick} x2="38" />
                            <line y1={tick} x1="98" y2={tick} x2="103" />
                        </Fragment>
                    ))}
                </g>
                <use xlinkHref="#speed-marker-me" y={mySpeedPos - 9} x="1" />
                <use xlinkHref="#speed-marker-our" y={ourSpeedPos - 9} x="104" />
            </svg>
            <div>{t("player.performance.label-speed-our")}</div>
        </Row>
    );
}

export default memo(Speedbar);
