import styled from "styled-components";
import { currentColors } from "../../shared/colors";

export const Button = styled.button`
    background: none;
    color: ${currentColors.text};
    border: 1.5px solid ${currentColors.text};
    font-size: 1rem;
    padding: 0.5rem 0.5rem;
    border-radius: 0.65rem;

    &:hover, &:focus, &:active, &:visited {
        background: none;
        color: ${currentColors.text};
    }

    &[disabled] {
        background: none;
        color: ${currentColors.grey4};
        border-color: ${currentColors.grey4};
    }
`;

export const StyledForm = styled.form`
    label {
        text-indent: 0.5rem;

        small {
            text-indent: 0;
        }
    }
    input, select {
        border: 1px solid ${currentColors.text};
        box-shadow: none;
        border-radius: 0.65rem;
    }

    .error {
        input {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        small.error {
            border-bottom-left-radius: .64rem;
            border-bottom-right-radius: .64rem;
            border: 1px solid ${currentColors.text};
            border-top: none;
        }
    }
`;
