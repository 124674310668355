import type { ComponentPropsWithoutRef, JSX } from "react";
import { currentColors } from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function StarTaskIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg
            {...props}
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="1.5"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m50 15.283 7.542 25.469c.189.637.774 1.074 1.439 1.074h24.773l-20.158 16.07c-.478.381-.677 1.013-.503 1.599l7.627 25.756-19.785-15.772c-.547-.436-1.323-.436-1.87 0l-19.785 15.772 7.627-25.756c.174-.586-.025-1.218-.503-1.599l-20.158-16.07h24.773c.665 0 1.25-.437 1.439-1.074z"
                fill={active ? currentColors.icon4 : "white"}
            />
            <path
                d="m50 10 8.981 30.326h29.061l-23.511 18.743 8.98 30.326-23.511-18.743-23.511 18.743 8.98-30.326-23.511-18.743h29.061z"
                fill="none"
                stroke={currentColors.text}
                strokeWidth="3"
            />
        </svg>
    );
}

export default StarTaskIcon;
