// Terms query

export const query = `
    query {
        terms {
            terms
            settingsTerms
        }
    }
`;

export interface IData {
    terms: {
        terms: string;
        settingsTerms: string | null;
    };
}

// Accept terms mutation

export const acceptMutation = `
    mutation {
        currentUserAcceptTerms {
            error
            redirectUrl
        }
    }
`;

export interface IAcceptData {
    currentUserAcceptTerms: {
        error: string | null;
        redirectUrl: string;
    };
}
