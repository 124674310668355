import type { ComponentPropsWithoutRef } from "react";
import { currentColors } from "../../colors";

const InfoIcon = (props: ComponentPropsWithoutRef<"svg">) => (
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g fill={currentColors.text}>
            <path d="m49.9 87.5c-21.2 0-38.5-17.3-38.5-38.5s17.3-38.5 38.5-38.5 38.5 17.3 38.5 38.5-17.3 38.5-38.5 38.5zm0-74c-19.6 0-35.5 15.9-35.5 35.5s15.9 35.5 35.5 35.5 35.5-15.9 35.5-35.5-15.9-35.5-35.5-35.5z" />
            <path d="m49.9 40.7c-4.3 0-7.9-3.5-7.9-7.9 0-4.3 3.5-7.9 7.9-7.9s7.9 3.5 7.9 7.9-3.5 7.9-7.9 7.9zm0-12.7c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9 4.9-2.2 4.9-4.9-2.2-4.9-4.9-4.9z" />
            <path d="m56.3 73h-12.7c-.8 0-1.5-.7-1.5-1.5v-25.9c0-.8.7-1.5 1.5-1.5h12.7c.8 0 1.5.7 1.5 1.5v25.9c0 .8-.7 1.5-1.5 1.5zm-11.2-3h9.7v-22.9h-9.7z" />
        </g>
    </svg>
);

export default InfoIcon;
