import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import { UrqlGlobalNotice } from "../shared/components/GlobalNotice";
import { loadJSData } from "../shared/jsData";
import Footer from "./Footer";
import Header from "./Header";
import ProfileNav from "./ProfileNav";
import AcceptTerms from "./acceptTerms/AcceptTerms";
import AiChatbotRoutes from "./aiChatbots/AiChatbotRoutes";
import Calendar from "./calendar/Calendar";
import ChatHolder from "./chat/ChatHolder";
import { useCurrentPerson } from "./components/CurrentPerson";
import CustomTaskRoutes from "./customTask/CustomTaskRoutes";
import Event from "./event/Event";
import FirstTimeLogin from "./firstTimeLogin/FirstTimeLogin";
import FlipperLoader from "./flipper/FlipperLoader";
import Inspirations from "./inspiration/Inspirations";
import Landing from "./landing/Landing";
import Performance from "./performance/Performance";
import PathInPath from "./pip/PathInPath";
import ProvideFeedback from "./provideFeedback/ProvideFeedback";
import PulseRoutes from "./pulse/PulseRoutes";
import ReplacePassword from "./replacePassword/ReplacePassword";
import SelectPath from "./selectPath/SelectPath";
import SubmittedTask from "./submittedTask/SubmittedTask";
import Task from "./task/Task";
import TaskChoice from "./taskChoice/TaskChoice";
import TeamScans from "./teamScans/TeamScans";
import Terms from "./terms/Terms";

const Wrapper = styled.main`
    padding: 0 15px;
    flex-grow: 1;
    max-width: 1440px;
    width: 100%;
`;

function Player(): JSX.Element {
    const location = useLocation();
    const person = useCurrentPerson();

    if (person.user.firstTimeLogin) {
        if (location.pathname.startsWith("/first-time-login")) {
            return (
                <>
                    <UrqlGlobalNotice />
                    <Routes>
                        <Route path="/first-time-login/*" element={<FirstTimeLogin />} />
                    </Routes>
                    <Footer />
                </>
            );
        }
        return <Navigate to={`/first-time-login?next=${encodeURIComponent(location.pathname)}`} replace />;
    }

    if (person.user.requireNewPassword) {
        if (location.pathname.startsWith("/replace-password")) {
            return (
                <>
                    <UrqlGlobalNotice />
                    <Routes>
                        <Route path="/replace-password/*" element={<ReplacePassword />} />
                    </Routes>
                    <Footer />
                </>
            );
        }
        return <Navigate to={`/replace-password?next=${encodeURIComponent(location.pathname)}`} replace />;
    }

    if (!person.user.agreedToTerms) {
        if (location.pathname.startsWith("/accept-terms")) {
            return (
                <>
                    <UrqlGlobalNotice />
                    <Routes>
                        <Route path="/accept-terms/*" element={<AcceptTerms />} />
                    </Routes>
                    <Footer />
                </>
            );
        }
        return <Navigate to={`/accept-terms?next=${encodeURIComponent(location.pathname)}`} replace />;
    }

    if (location.pathname === "/") {
        return <Navigate to="/select-path" replace />;
    }

    const isStandaloneTool =
        location.pathname.startsWith("/team-scans") ||
        location.pathname.startsWith("/pulse") ||
        location.pathname.startsWith("/ai-chatbots") ||
        location.pathname.startsWith("/replace-password") ||
        location.pathname.startsWith("/accept-terms") ||
        location.pathname.startsWith("/first-time-login") ||
        location.pathname.startsWith("/select-path");

    if (isStandaloneTool) {
        return (
            <>
                <UrqlGlobalNotice />
                <Routes>
                    {loadJSData().features.team_scan && <Route path="/team-scans/*" element={<TeamScans />} />}
                    {loadJSData().features.pulse && <Route path="/pulse/*" element={<PulseRoutes />} />}
                    {loadJSData().features.ai && <Route path="/ai-chatbots/*" element={<AiChatbotRoutes />} />}
                    <Route path="/replace-password/*" element={<ReplacePassword />} />
                    <Route path="/accept-terms/*" element={<AcceptTerms />} />
                    <Route path="/first-time-login/*" element={<FirstTimeLogin />} />
                    <Route path="/select-path/*" element={<SelectPath />} />
                </Routes>
                <Footer />
            </>
        );
    }

    const isProfile =
        location.pathname.startsWith("/profile") ||
        location.pathname.startsWith("/messages") ||
        location.pathname.startsWith("/my-history");

    if (isProfile) {
        // fall back to plain profile routes without any nesting
        return (
            <>
                <UrqlGlobalNotice />
                <Header />
                <ProfileNav />
                <Footer />
            </>
        );
    }

    return (
        <>
            <UrqlGlobalNotice />
            <Header />
            <Wrapper>
                <Routes>
                    <Route path="/landing" element={<Landing />} />
                    <Route path="/terms" element={<Terms />} />
                    {loadJSData().features.custom_tasks && (
                        <Route path="/custom-task/*" element={<CustomTaskRoutes />} />
                    )}
                    <Route path="/task/*" element={<Task />} />
                    <Route path="/event/*" element={<Event />} />
                    <Route path="/path-in-path/*" element={<PathInPath />} />
                    <Route path="/task-choice/*" element={<TaskChoice />} />
                    <Route path="/submitted-task/*" element={<SubmittedTask />} />
                    <Route path="/provide-feedback/*" element={<ProvideFeedback />} />
                    <Route path="/performance" element={<Performance />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/flipper/:id" element={<FlipperLoader submitType="submit" />} />
                    <Route path="/feedback-flipper/:id" element={<FlipperLoader submitType="feedback" />} />
                    {loadJSData().features.inspiration && <Route path="/inspiration" element={<Inspirations />} />}
                    <Route path="/" element={<Navigate to="/select-path" replace />} />
                </Routes>
            </Wrapper>
            <Footer />
            <ChatHolder />
        </>
    );
}

export default Player;
