import type { FormEvent, JSX } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMutation } from "urql";
import { currentColors } from "../../shared/colors";
import getRelativeUrl from "../../shared/getRelativeUrl";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import { useCurrentPerson } from "../components/CurrentPerson";
import { useSelectedIds } from "../components/SelectedIds";
import { type ISelectArgs, type ISelectData, type ToolType, selectMutation } from "./query";

const Select = styled.select`
    display: inline-block;
    width: auto;
    line-height: 22px;
    position: relative;
    border: 1px solid ${currentColors.grey4};
    padding: 0;
    margin: 0;
    display: inline-block;
    width: auto;
    background-color: transparent;
    border-radius: 0;
    color: ${currentColors.text};
    font-size: 0.9rem;
    padding: 0.25rem 0.85rem;
    padding-right: 1.2rem;
    height: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:focus, &:active, &:visited {
        background-color: white;
        border: 1px solid ${currentColors.grey4};
    }
`;

function isValidInteger(str) {
    return /^-?\d+$/.test(str);
}

interface IProps {
    currentTool: ToolType;
}

function PathNav({ currentTool }: IProps): JSX.Element {
    const person = useCurrentPerson();
    const { pathId: currentPathId } = useSelectedIds();
    const [selectResult, executeSelect] = useMutation<ISelectData, ISelectArgs>(selectMutation);
    const navigate = useNavigate();

    const onPathSwitch = async (event: FormEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        if (!value) {
            return;
        }
        if (currentTool === "path" && value === currentPathId) {
            return;
        }
        if (value === currentTool) {
            return;
        }

        let pathId: string | null = null;
        let tool: ToolType | null = null;
        if (isValidInteger(value)) {
            pathId = value;
            tool = "path";
        } else {
            pathId = null;
            tool = value as ToolType;
        }

        const result = await executeSelect({ tool, pathId });
        if (result.data?.currentPersonSelectPath.redirect) {
            navigate(getRelativeUrl(result.data.currentPersonSelectPath.redirect));
        }
    };

    const jsData = loadJSData();
    const showTeamScan = jsData.features.team_scan && person.teamScans.length > 0;
    const showAiChatbots = jsData.features.ai && person.standaloneAiChatbots.length > 0;
    const showPulse = jsData.features.pulse && person.user.pulseRights;

    // Only show the select if there are other options to choose from
    let optionCount = person.ongoingPaths.filter((p) => p.id !== currentPathId).length;
    if (showTeamScan && currentTool !== "teamScan") {
        optionCount++;
    }
    if (showAiChatbots && currentTool !== "standaloneAiChatbot") {
        optionCount++;
    }
    if (showPulse && currentTool !== "pulse") {
        optionCount++;
    }
    if (optionCount < 1) {
        return null;
    }

    const unavailablePath = person.ongoingPaths.find((p) => p.id === currentPathId) == null;
    const defaultValue = currentTool === "path" ? (unavailablePath ? "" : currentPathId) : currentTool;

    return (
        <Select defaultValue={defaultValue} onChange={onPathSwitch} disabled={selectResult.fetching}>
            {currentTool === "path" && unavailablePath && <option value="" key="" />}
            {person.ongoingPaths.map((p) => (
                <option value={p.id} key={p.id}>
                    {p.pathname}
                </option>
            ))}
            {showTeamScan && <option value="teamScan">{t("player.header.secondary-nav-team-scan")}</option>}
            {showAiChatbots && (
                <option value="standaloneAiChatbot">{t("player.header.secondary-nav-ai-chatbots")}</option>
            )}
            {showPulse && <option value="pulse">{t("player.header.secondary-nav-pulse")}</option>}
        </Select>
    );
}

export default PathNav;
