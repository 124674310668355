import type { JSX } from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { currentColors } from "../../shared/colors";
import NoteIcon from "../../shared/components/icons/NoteIcon";
import t from "../../shared/translations";
import type { ISubmittedTask } from "./query";

interface IProps {
    task: ISubmittedTask;
    unread: number;
}

const Tabs = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 1rem;
    border-bottom: 2px solid ${currentColors.button};
`;

const tabStyle = css`
    padding: 0.5rem 0.9rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    text-decoration: none;
    border-width: 2px;
    border-bottom-width: 0;
    border-style: solid;
    border-color: ${currentColors.button};
    color: ${currentColors.button};
    background-color: white;

    &:hover, &:focus, &:active, &:visited {
        color: ${currentColors.button};
    }
`;

interface ITab {
    $current?: boolean;
}

const LinkTab = styled(NavLink)<ITab>`
    ${tabStyle}
    svg {
        width: 32px;
        height: 32px;
        margin-bottom: -14px;
        margin-top: -1px;
    }
    color: ${({ $current }) => ($current ? "white" : currentColors.button)};
    background-color: ${({ $current }) => ($current ? currentColors.button : "white")};

    &:hover, &:focus, &:active, &:visited {
        color: ${({ $current }) => ($current ? "white" : currentColors.button)};
    }
`;

const UnreadBadge = styled.div`
    border-radius: 50%;
    color: white;
    background-color: ${currentColors.unread};
    width: 20px;
    height: 20px;
    display: grid;
    place-items: center;
    text-align: center;
    font-size: 14px;
    position: absolute;
    top: 3px;
    left: 3px;
`;

function NavTabs({ task, unread }: IProps): JSX.Element {
    if (!task.showSharedAnswers) {
        return null;
    }
    const urlPath = window.location.pathname;
    return (
        <Tabs>
            <LinkTab to={`/submitted-task/${task.id}`} $current={urlPath === `/submitted-task/${task.id}`}>
                {t("player.task-tabs.tab-my-answer")}
            </LinkTab>
            {task.showSharedAnswers && (
                <LinkTab
                    to={`/submitted-task/${task.id}/all-answers`}
                    $current={urlPath === `/submitted-task/${task.id}/all-answers`}
                    style={{ position: "relative" }}
                >
                    {unread > 0 && <UnreadBadge>{unread}</UnreadBadge>}
                    <NoteIcon />
                    {t("player.task-tabs.tab-all-answers")}
                </LinkTab>
            )}
        </Tabs>
    );
}

export default NavTabs;
