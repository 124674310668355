import * as Sentry from "@sentry/browser";

interface JSONSuccessResponse {
    status: "success";
    data: any;
}

interface JSONErrorResponse {
    status: "error";
    error_message: string;
}

type JSONResponse = JSONSuccessResponse | JSONErrorResponse;

function tryParseJSON(body: BodyInit): any {
    try {
        const result = JSON.parse(body as any);
        return result;
    } catch {
        return body;
    }
}

async function fetchJson(url: string, settings: RequestInit): Promise<any> {
    var response;
    try {
        // biome-ignore lint/style/noVar: Use var to host type inference outside the block.
        // biome-ignore lint/correctness/noInnerDeclarations: Use var to host type inference outside the block.
        response = await fetch(url, settings);
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                url,
                settings,
                body: tryParseJSON(settings.body),
            },
        });
        console.error(err);
        throw new Error("Network error");
    }
    if (!response.ok) {
        const text = await response.text();
        const err = new Error(`${response.status}: ${response.statusText}`);
        Sentry.captureException(err, {
            extra: {
                url,
                settings,
                body: tryParseJSON(settings.body),
            },
        });
        console.error(`${err}\n${text}`);
        throw err;
    }
    const json = (await response.json()) as JSONResponse;
    if (json.status !== "success") {
        const err = new Error(json.error_message);
        Sentry.captureException(err, {
            extra: {
                url,
                settings,
                body: json,
            },
        });
        console.error(err);
        throw err;
    }
    return json.data;
}

export async function getJson(url: string, signal?: AbortSignal): Promise<any> {
    const settings: RequestInit = {
        credentials: "same-origin",
        headers: {
            "X-CSRFToken": __CSRF_TOKEN__,
        },
        ...(signal ? { signal } : {}),
    };
    return fetchJson(url, settings);
}

export async function postJson(url: string, data: any, signal?: AbortSignal): Promise<any> {
    const postSettings: RequestInit = {
        credentials: "same-origin",
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "X-CSRFToken": __CSRF_TOKEN__,
            "Content-Type": "application/json",
        },
        ...(signal ? { signal } : {}),
    };
    return fetchJson(url, postSettings);
}

export async function postFile(url: string, data: FormData, signal?: AbortSignal): Promise<any> {
    data.append("_csrf_token", __CSRF_TOKEN__);
    const postSettings: RequestInit = {
        credentials: "same-origin",
        method: "POST",
        body: data,
        headers: {
            "X-CSRFToken": __CSRF_TOKEN__,
        },
        ...(signal ? { signal } : {}),
    };
    return fetchJson(url, postSettings);
}

export async function fetchBlob(url: string, data: any, signal?: AbortSignal): Promise<any> {
    const settings: RequestInit = {
        credentials: "same-origin",
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "X-CSRFToken": __CSRF_TOKEN__,
            "Content-Type": "application/json",
        },
        ...(signal ? { signal } : {}),
    };
    var response;
    try {
        // biome-ignore lint/style/noVar: Use var to host type inference outside the block.
        // biome-ignore lint/correctness/noInnerDeclarations: Use var to host type inference outside the block.
        response = await fetch(url, settings);
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                url,
                settings,
                body: tryParseJSON(settings.body),
            },
        });
        console.error(err);
        throw new Error("Network error");
    }
    if (!response.ok) {
        const err = new Error(`${response.status}: ${response.statusText}`);
        Sentry.captureException(err, {
            extra: {
                url,
                settings,
                body: tryParseJSON(settings.body),
            },
        });
        console.error(err);
        throw err;
    }
    const blob = await response.blob();
    return blob;
}
