import { type ComponentPropsWithoutRef, type JSX, memo } from "react";
import { currentColors } from "../colors";
import StreetviewFilters from "./StreetviewFilters";

type IProps = ComponentPropsWithoutRef<"svg">;

const Y_SCALE = 0.88;

export const ASPECT_RATIO = ((Y_SCALE * 795) / 1010) * 100;

function Streetview(props: IProps): JSX.Element {
    return (
        <svg viewBox={`0 0 1010 ${Y_SCALE * 795}`} xmlns="http://www.w3.org/2000/svg" {...props}>
            <linearGradient id="street-gradient-a" x1="50%" x2="50%" y1="0%" y2="100%">
                <stop offset="0" stopColor="#fff" />
                <stop offset="1" stopColor="#614a2f" />
            </linearGradient>
            <linearGradient id="street-gradient-b" x1="80.644822%" x2="19.355178%" y1="3.094214%" y2="100%">
                <stop offset="0" stopColor="#eee8e0" />
                <stop offset="1" stopColor="#867d70" />
            </linearGradient>
            <linearGradient id="stree-gradient-c" x1="78.663719%" x2="21.336281%" y1="3.094214%" y2="100%">
                <stop offset="0" stopColor="#eee8e0" />
                <stop offset="1" stopColor="#867d70" />
            </linearGradient>
            <StreetviewFilters />
            <g fill="none" fillRule="evenodd" transform={`scale(1 ${Y_SCALE}) translate(0 .573619)`}>
                <path
                    d="m2.37262157 481.348599c24.68625553 53.261263 58.50243293 106.453857 101.44853243 159.577782 42.946099 53.123924 88.757143 103.957258 137.433132 152.5h531.734436c46.458755-51.882339 91.018084-102.999006 133.677988-153.35 42.659904-50.350995 77.104334-104.080869 103.33329-161.189623z"
                    fill="url(#street-gradient-a)"
                    opacity=".498728"
                />
                <path
                    d="m.86925785 794v-27.000039c53.75433915-52.817808 102.22145215-104.287976 145.40133915-154.410505s75.419262-92.741959 96.718125-127.858289v8.651302c-17.998325 35.625577-50.2377 81.998087-96.718125 139.117531-46.4804243 57.119444-94.9475374 110.952777-145.40133915 161.5z"
                    fill="url(#street-gradient-b)"
                    transform="matrix(-1 0 0 1 243.85798 0)"
                />
                <path
                    d="m772.988722 794v-27.000039c53.754339-52.817808 102.221452-104.287976 145.401339-154.410505 43.179888-50.122529 72.377131-92.227101 87.591729-126.313716v12.898316c-11.914061 31.764519-41.111304 76.2065-87.591729 133.325944-46.480424 57.119444-94.947537 110.952777-145.401339 161.5z"
                    fill="url(#stree-gradient-c)"
                />
                <path
                    d="m243 765.426381-54-53.511628 61.797114 53.511628h514.259138l64.753458-56.40978-56.80971 56.40978v28h-530z"
                    fill="#d6d0c8"
                />
                <path
                    d="m15.2145659 434.641971.1052487 8.993137 75.1387229 20.642519c.3578676.098315.730871.129375 1.1000706.091602l9.2154969-.942848v4.37169c0 .45249.303844.848596.740878.965845l178.262378 47.825048c2.067507.55468 4.210654.773846 6.347638.649133l39.611495-2.311716v9.764278c0 1.385103.948259 2.590148 2.294519 2.91587l138.120857 33.417682c3.849962.93148 7.796972 1.40217 11.758014 1.40217h46.464709c6.578183 0 13.126998-.877128 19.473326-2.608197l104.753847-28.57339c1.305131-.355997 2.210541-1.541451 2.210541-2.894263v-10.92415l29.029604 2.662976c4.052725.371769 8.138858.09009 12.102235-.834272l303.421884-70.76581v-15.541765z"
                    fill="#666"
                />
                <g transform="translate(11 256.426381)">
                    <path
                        d="m3.83264776 184.604441c-3.4479665-9.609933-4.56951135-22.768643-3.36463454-39.476128 1.24803527-17.305944 2.49607053-34.556375 3.74410579-51.7512921l23.89008449-16.2587061v-54.2094376c0-.9664011.3498702-1.9001274.9849331-2.6285684l12.7156703-14.5853522c.6907765-.79234662 1.7458759-1.16534323 2.7811561-.9831887l16.779206 2.95225238v44.99516992l24.9142438-19.2830434c-5.0916388 55.0288922-7.6374583 86.1277182-7.6374583 93.2964762 0 7.023018 1.3861596 32.11227 4.1584786 75.267757.070792 1.102288-.7653842 2.053272-1.8676709 2.124083-.2007577.012897-.4023123-.00457-.5978586-.05181l-75.08742259-18.139555c-.65446233-.158123-1.1854536-.63492-1.41283325-1.268657z"
                        fill={currentColors.icon5}
                        filter="url(#house-1-a)"
                    />
                    <path
                        d="m43.1387064 9.93987737v52.67572873l-43.1387064 30.7614148v3.1702825s27.6576057-19.2582097 82.972817-57.7746293c-1.451075 25.3731532-2.8953625 44.631363-4.3328625 57.7746293-2.15625 19.7148996-2.15625 37.1830316 0 62.3167666 1.3661098 15.923682 1.7114373 30.336813 1.0359827 43.239393-.0577701 1.103071.7896341 2.0441 1.8927063 2.101846.0044972.000236.0089951.000456.0134937.000661l55.9683638 2.550997v-173.3808202l-24.900988-30.83020502c-1.041055-1.28894191-2.92989-1.48989425-4.218832-.44883962-.067597.0545968-.13279.11210462-.195395.1723608l-46.3710155 44.63148344v-36.99932723c0-2.05592364-1.558528-3.77704072-3.6043708-3.9803866l-9.6266567-.95683845c-2.7478836-.273125-5.1968948 1.73306317-5.4700198 4.4809467-.0163345.16433946-.0245168.3293873-.0245168.49453655z"
                        fill={currentColors.icon5}
                        filter="url(#house-1-b)"
                    />
                </g>
                <g transform="translate(100 136.426381)">
                    <path
                        d="m181.675271 372.275489-179.68387628-42.544499c-1.21729018-37.94763-1.52161273-69.054969-.91296764-93.322016.60864509-24.267048 2.81116861-53.644955 6.60757056-88.133721l168.32361336-120.5339374 130.567193 28.9596995v307.6603399z"
                        fill={currentColors.icon2}
                        filter="url(#house-2-a)"
                    />
                    <path
                        d="m176.009611 27.7413156c-1.397305 59.4479558-2.095958 113.5038414-2.095958 162.1676584 0 48.663816 2.587206 109.452655 7.761618 182.366515l108.344845-4.7813 12.763771-309.3989014z"
                        fill={currentColors.icon2}
                        filter="url(#house-2-b)"
                    />
                    <path
                        d="m170.949232 10.4610493 3.341744-9.60918917c4.580957 95.88564717 6.871436 155.96477187 6.871436 180.23737287s-3.404393 86.893302-10.21318 187.862102c5.028106-99.649005 7.542159-164.628227 7.542159-194.937667s-2.514053-84.8269793-7.542159-163.5526187z"
                        fill="#fff"
                        transform="matrix(-1 0 0 1 352.111644 0)"
                    />
                    <path
                        d="m186.019653 143.549131c-.244931 4.596936-.367396 10.079676-.367396 16.44822 0 6.811639.288894 15.833552.866682 27.06574.08204 1.594848 1.399082 2.845883 2.996039 2.845883h24.175798c1.104569 0 2-.895431 2-2 0-6.853741 0-13.707481 0-20.561222 0-6.927521 0-13.855043 0-20.782564 0-1.017424-.763828-1.872707-1.774781-1.987279l-25.673935-2.909644c-1.097553-.124407-2.088132.664502-2.212519 1.762057-.004476.039496-.007773.079116-.009888.118809z"
                        fill={currentColors.icon2}
                        filter="url(#house-2-c)"
                    />
                    <g fill={currentColors.icon2} filter="url(#house-2-d)">
                        <path d="m137.111388 83.2725338c-1.588059 11.7495423-2.63881 21.6469152-3.15225 29.6921192l-1.661988 25.091164c-.084339 1.273281-.964916 2.353866-2.194957 2.693507l-27.848286 7.689499c.312631-6.592055.705042-12.871941 1.177231-18.83966.47219-5.967719 1.129306-12.503357 1.971348-19.606913l30.041457-27.5901791c.406757-.373617 1.039374-.3467095 1.412964.0600723.201033.2188947.294289.515866.254481.8103906z" />
                        <path d="m42.1504824 196.783752c.3656788 8.299559.3401403 15.697542-.0766155 22.19395v15.733342c0 .979296-.7091071 1.814557-1.6754201 1.973487l-22.3316717 3.672897c.16794-6.90251.4414583-12.736944.8205548-17.503303.3790965-4.766358.7455502-9.78441 1.0993611-15.054153l20.6676325-11.839904c.4792184-.274583 1.0902817-.108643 1.3648253.370598.0792824.138395.124313.293745.1313336.453086z" />
                        <path d="m104.302049 283.900816c.91591 15.491266 1.703482 27.75299 2.362716 36.785172.695187 9.524786 1.893828 21.019427 3.595923 34.483923l-28.3311457-6.807556c-.7341616-10.48601-1.3287405-20.86224-1.7837365-31.12869-.4217721-9.516791-.6269166-21.17111-.6154333-34.962956.0013639-1.656862 1.3456345-2.998896 3.0024968-2.997517.0950034.00008.1899495.004671.2845171.013761l18.7769156 1.804687c1.471399.141429 2.620503 1.333572 2.707747 2.809176z" />
                    </g>
                    <path
                        d="m176.009611 28.7413156-169.59609596 123.6460564c-.89254497.650721-2.14360888.454683-2.79432944-.437862-.24948165-.342195-.38390436-.754749-.38390436-1.178233v-2.325995c0-1.582914.7495439-3.072335 2.02078294-4.015504l169.03275482-125.4102519c1.131384-.8394062 2.44402-1.4014964 3.832276-1.6410365l87.427164-15.08534932c1.084381-.18710745 2.197951.08077526 3.078594.74059335l88.332511 66.18274797z"
                        fill={currentColors.icon2}
                        filter="url(#house-2-c)"
                    />
                </g>
                <g transform="translate(499 25.426381)">
                    <path
                        d="m186.106462 488.341527-186.106462-9.996416 8.26728128-357.379701 191.90514072-106.8179225 18.131693 83.814433 102.374702 81.3464795 46.140024-11.043676 17.178848 56.851751 55.342512 45.043174 40.3426-3.837895-17.630105 73.562525c-177.566032-51.636047-266.210034-76.629692-265.932006-74.980934 6.676152 39.590873 3.338076 114.070267-10.014228 223.438182z"
                        fill={currentColors.icon4}
                        filter="url(#house-4-a)"
                    />
                    <path
                        d="m222.254961 481.098956 273.8618-59.295976c1.640119-20.175002 2.592959-37.976283 2.858519-53.403843.265561-15.42756-.111256-31.089855-1.130452-46.986885l-16.119854-53.647685c-.317858-1.057847-1.433087-1.657726-2.490934-1.339868-.607738.182611-1.092753.6428-1.307015 1.240115l-11.14815 31.078563-73.051886-51.953015-23.538386-76.517993c-.487151-1.58362-2.165842-2.472482-3.749461-1.985331-.972169.299057-1.726595 1.070331-2.004111 2.048867l-14.219514 50.138726-121.767241-91.331688-21.697426-111.3278224c-.528253-2.7104261-3.153721-4.4794269-5.864147-3.9511738-1.728778.3369331-3.152768 1.5585973-3.74866 3.2160375l-25.663354 71.381065c9.754515 111.0573447 14.631773 183.6702177 14.631773 217.8386197v182.041858z"
                        fill={currentColors.icon4}
                        filter="url(#house-4-b)"
                    />
                    <path
                        d="m353.276883 332.44092c0 25.228842-1.945467 65.752731-5.8364 121.571665l-12.17433 2.618531c5.53335-61.432484 8.300024-103.920812 8.300024-127.464984s-.697222-59.781572-2.091667-108.712199l-109.541663-77.169911c6.212558 65.735952 9.766244 113.242288 10.661056 142.519007s-.332777 90.121442-3.682767 182.53417c1.983895-95.538438 2.34309-156.383161 1.077586-182.53417s-5.11232-78.371038-11.540446-156.660086l121.580409 91.31099c2.165465 49.429149 3.248198 86.758145 3.248198 111.986987z"
                        fill={currentColors.icon4}
                        filter="url(#house-4-c)"
                    />
                    <path
                        d="m393.726989 246.790362 73.051886 51.949709c.810512 13.01647.810512 27.575123 0 43.675958-.810511 16.100836-3.801641 43.649791-8.97339 82.646866 4.614883-50.523947 6.922324-81.690168 6.922324-93.498661 0-11.808494-.677782-22.223578-2.033346-31.245252l-66.506693-45.86776c1.325742 30.284617 1.988612 51.735632 1.988612 64.353047 0 12.617414-1.483131 39.30055-4.449393 80.049409 1.640521-36.197639 2.460781-62.880775 2.460781-80.049409s-.82026-41.17327-2.460781-72.013907z"
                        fill={currentColors.icon4}
                        filter="url(#house-4-c)"
                    />
                    <path
                        d="m172.258953 96.2245459 2.421831.6587351c7.654418 76.340564 11.481628 126.647146 11.481628 150.919748v218.863638c-2.851781-112.533856-4.277672-183.955503-4.277672-214.264943s-3.208596-82.368499-9.625787-156.1771781z"
                        fill="#fff"
                    />
                    <g fill={currentColors.icon4} filter="url(#house-4-c)">
                        <path
                            d="m321.645184 393.682291c.260967 15.701619.74179 27.72485 1.442471 36.069693.747045 8.897015 2.006857 20.929291 3.779436 36.09683l-26.096511-6.271891c-1.508929-10.566975-2.683172-21.449249-3.52273-32.646824-.794737-10.599782-1.290781-21.498257-1.488133-32.695424-.018723-1.063506.79825-1.955624 1.859297-2.030291l20.815988-1.464838c1.652769-.116311 3.086889 1.129238 3.203195 2.782007.003766.053508.006095.107106.006987.160738z"
                            transform="matrix(-1 0 0 1 622.596268 0)"
                        />
                        <path d="m331.28418 282.769498v-20.267052c.000003-2.913622-1.584047-5.596855-4.135091-7.004465-12.576606-6.939493-18.864909-9.185335-18.864909-6.737526v25.863102c0 .799957.476676 1.522963 1.211909 1.838183l19 8.14594c1.0152.435251 2.191022-.034891 2.626273-1.050091.106765-.249024.161818-.517145.161818-.788091z" />
                        <path d="m276.316023 319.275392c-.86269 0-8.75892-2.465899-23.688688-7.397699-1.048829-.346472-2.17994.222909-2.526403 1.271741-.066858.202395-.100932.414176-.100932.627328v28.17764c0 1.040455.797737 1.907121 1.834629 1.993151l22.422126 1.86035c1.10079.091338 2.067197-.72699 2.158529-1.82778.001857-.022388.003337-.044805.004439-.067243.806849-16.424992.772282-24.637488-.1037-24.637488z" />
                    </g>
                </g>
                <g transform="translate(322 .426381)">
                    <path
                        d="m107.072678 34.8263781 38.462094-32.46778538c1.804281-1.52308393 4.089307-2.35859272 6.450496-2.35859272h57.288863c2.479646 0 4.870856.92126597 6.709516 2.58498845l38.847455 35.15134355c3.209426 2.9040671 4.186244 7.5432523 2.420347 11.4949138l-10.389685 23.2496754 28.553249 23.1911949h14.170483c2.256331 0 4.446349.7630625 6.214143 2.1651789l15.708549 12.459145c1.674132 1.327828 2.6501 3.347592 2.6501 5.484375v15.58402 11.710983l10.868045 7.168285c1.122265.740218 1.797621 1.994695 1.797621 3.339091v10.769797l-274.40681 21.131852-19.8893535-2.960859-16.9278009 19.247416-15.5999896-6.901233v-20.351277c0-2.741394 1.40369769-5.291884 3.7197058-6.75863l20.3709802-12.901104c1.7440239-1.104504 3.847496-1.492081 5.8706461-1.0817l7.5544829 1.532369 72.702083-45.73399v-41.538404l-6.333079-22.9046662c-1.040517-3.7632073.204342-7.7878498 3.187859-10.3063867z"
                        fill={currentColors.icon3}
                        filter="url(#house-3-a)"
                    />
                    <path
                        d="m16.3148515 178.996319 7.5999821-4.804356c2.6356549-1.666138 5.8976545-2.00749 8.8211398-.923088l5.1836894 1.922774 75.7837342-49.011684v-76.5161006c0-3.1811894 1.37721-6.2065986 3.776313-8.295682l28.736087-25.0227181c2.184611-1.9023087 4.983612-2.9501651 7.880386-2.9501651h50.822759c3.020408 0 5.929731 1.1390075 8.147293 3.1896862l28.923152 26.7465364c2.286777 2.1146855 3.569861 5.0996937 3.530783 8.2141306l-.510571 40.6916996 24.033718 15.954086h19.496428c1.720988 0 3.396098.55498 4.776643 1.582548l11.257541 8.379221c2.028173 1.509611 3.223358 3.88913 3.223358 6.417452v25.321261l5.298745 3.370246c1.617473 1.028786 2.646008 2.768505 2.767775 4.681564 2.157153 33.890496 4.11454 84.873725 5.872161 152.949689 1.811111 70.147773 2.960938 141.046152 3.449481 212.695136l-38.596807 9.549616-10.568073-2.172366-26.306093 4.506645v7.084008l-37.99091 8.287673c-1.400338.305482-2.829456.45955-4.262727.45955h-57.038594c-.544742 0-1.088573-.044511-1.626064-.13309l-29.385758-4.842766c-1.449034-.238801-2.512181-1.491494-2.512181-2.960073v-2.037407l-64.54203-12.432164h-4.8700607c-.2394891 0-.4781167-.028678-.7107868-.08542l-38.72357064-9.443622c-1.35419693-.330257-2.30253212-1.549738-2.28906753-2.94356.7514358-77.786765 1.65990606-142.266115 2.72541077-193.438049 1.04130963-50.009941 2.4106621-95.861433 4.1080574-137.554478.1069151-2.626176 1.4969913-5.0323 3.718657-6.436733z"
                        fill={currentColors.icon3}
                        filter="url(#house-3-b)"
                    />
                    <path
                        d="m244.377093 108.470961 4.760864 429.033138 26.277056-6.684931-6.96955-406.701966z"
                        fill={currentColors.icon3}
                        filter="url(#house-3-c)"
                    />
                    <path
                        d="m112.412808 154.556458 4.485434 388-64.54203-13.525672.060932-338.472152z"
                        fill={currentColors.icon3}
                        filter="url(#house-3-c)"
                    />
                    <g fill="#fff">
                        <path d="m208.877004 19.5503011 5.622996 5.4496989c2.253627 139.007111 3.380441 224.790275 3.380441 257.34949 0 32.559216-.793481 111.109385-2.380441 235.65051-2.410554-147.112082-3.615831-236.284789-3.615831-267.518123 0-31.233333-1.002388-108.210525-3.007165-230.9315759z" />
                        <path d="m289.289038 113.460378 3.520742 2.578307c1.79348 103.647186 2.69022 167.488838 2.69022 191.524956v170.896737c-2-106.346518-3-171.048435-3-194.10575 0-23.057316-1.070321-80.022066-3.210962-170.89425z" />
                        <path
                            d="m25.5 178h2.8643428c2 97.581694 3 157.442664 3 179.58291v157.41709c-2-97.958333-3-157.556823-3-178.79547s-.9547809-73.973491-2.8643428-158.20453z"
                            transform="matrix(-1 0 0 1 56.864342 0)"
                        />
                        <path
                            d="m143.5 25h2.864343c2 97.581694 3 157.442664 3 179.58291 0 22.140247-.977391 83.279276-2.932172 183.41709-.045219-115.291666-.067828-183.556823-.067828-204.79547s-.954781-73.973491-2.864343-158.20453z"
                            transform="matrix(-1 0 0 1 292.864342 0)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default memo(Streetview);
