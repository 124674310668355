import Linkify from "linkify-react";
import { type JSX, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import { formatYMD } from "../../shared/dateFns";
import t from "../../shared/translations";
import Modal from "../components/Modal";
import ShowError from "../components/ShowError";
import { type Dispatch, openModal } from "../state";
import CopiedText from "./CopiedText";
import PulseName from "./PulseName";
import QrButton from "./QrButton";
import { type IPulseArgs, type IPulseData, pulseQuery } from "./queries";
import { AnswerBox, AnswerGrid, ButtonAnchor, ButtonRow, CopyBox, CopyRow, Maw } from "./stylings";

interface IProps {
    refetch?: VoidFunction;
}

type IPulse = IPulseData["pulse"];

function assembleAnswers(pulse: IPulse): Map<string, string[]> {
    const result: Map<string, string[]> = new Map();
    result.set(pulse.question1, []);
    if (pulse.question2) {
        result.set(pulse.question2, []);
    }
    for (const ans of pulse.pulseAnswers) {
        result.get(pulse.question1).push(ans.answer1);
        if (pulse.question2) {
            result.get(pulse.question2).push(ans.answer2);
        }
    }
    return result;
}

function name(pulse: IPulse, extension: string): string {
    return `${pulse.name || "Pulse"} ${formatYMD(new Date())}.${extension}`;
}

function excelLink(pulse: IPulse): string {
    const filename = name(pulse, "xlsx");
    return `/pulse/export-answers/${pulse.id}/xlsx/${filename}`;
}

function wordLink(pulse: IPulse): string {
    const filename = name(pulse, "docx");
    return `/pulse/export-answers/${pulse.id}/docx/${filename}`;
}

function PulseResponse({ refetch }: IProps): JSX.Element {
    const { id } = useParams();
    const [result, queryExecute] = useQuery<IPulseData, IPulseArgs>({
        query: pulseQuery,
        requestPolicy: "cache-and-network",
        variables: { id },
    });
    const [copied, setCopied] = useState(false);
    const dispatch = useDispatch<Dispatch>();

    const showCopied = () => {
        setCopied(true);
    };

    useEffect(() => {
        const timer = setInterval(() => queryExecute(), 20000);
        return () => {
            clearInterval(timer);
        };
    }, [queryExecute]);

    useEffect(() => {
        if (result.data?.pulse?.pulseAnswers?.length > 0 && refetch) {
            refetch();
        }
    }, [result, refetch]);

    let main = null;
    if (result.fetching) {
        main = <Loader />;
    } else if (result.error) {
        main = <ShowError />;
    } else {
        const pulse = result.data.pulse;
        const answersMap = assembleAnswers(pulse);
        main = (
            <>
                <PulseName pulse={pulse} />
                <CopyBox>
                    <p>{t("player.pulse-results.info-copy")}</p>
                    <CopyRow>
                        <input value={pulse.link} readOnly />
                        <CopyToClipboard text={pulse.link} onCopy={showCopied}>
                            <button type="button">{t("player.pulse-results.button-copy-link")}</button>
                        </CopyToClipboard>
                        {copied && <CopiedText setCopied={setCopied} />}
                    </CopyRow>
                    <QrButton pulse={pulse} />
                </CopyBox>
                <p>{t("player.pulse-results.info-results")}</p>
                {pulse.pulseAnswers.length === 0 && <div>{t("player.pulse-results.info-no-answers")}</div>}
                {pulse.pulseAnswers.length > 0 && (
                    <>
                        <p>{t("player.pulse-results.info-answers-count", { count: pulse.pulseAnswers.length })}</p>
                        <AnswerGrid>
                            {Array.from(answersMap.keys()).map((question) => (
                                <AnswerBox key={question} $span={answersMap.size + 1}>
                                    <h3>{question}</h3>
                                    {answersMap.get(question).map((ans, idx) => (
                                        <Linkify options={{ nl2br: true }} tagName="div" key={idx}>
                                            {ans}
                                        </Linkify>
                                    ))}
                                </AnswerBox>
                            ))}
                        </AnswerGrid>
                        <button type="button" onClick={() => dispatch(openModal("Pulse/Download"))}>
                            {t("player.pulse-results.button-download-modal")}
                        </button>
                        <Modal modal="Pulse/Download">
                            <h3>{t("player.pulse-results.heading-download")}</h3>
                            <p>{t("player.pulse-results.info-download")}</p>
                            <ButtonRow>
                                <ButtonAnchor href={excelLink(pulse)}>
                                    {t("player.pulse-results.button-download-excel")}
                                </ButtonAnchor>
                                <ButtonAnchor href={wordLink(pulse)}>
                                    {t("player.pulse-results.button-download-word")}
                                </ButtonAnchor>
                            </ButtonRow>
                        </Modal>
                    </>
                )}
            </>
        );
    }
    return <Maw>{main}</Maw>;
}

export default PulseResponse;
