import { type JSX, useState } from "react";
import type { FormEvent } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { currentColors } from "../shared/colors";
import HamburgerIcon from "../shared/components/icons/HamburgerIcon";
import CalendarIcon from "../shared/components/icons/navIcons/CalendarIcon";
import HomeIcon from "../shared/components/icons/navIcons/HomeIcon";
import InspirationIcon from "../shared/components/icons/navIcons/InspirationIcon";
import PerformanceIcon from "../shared/components/icons/navIcons/PerformanceIcon";
import ProfileIcon from "../shared/components/icons/navIcons/ProfileIcon";
import useWindowSize from "../shared/hooks/useWindowSize";
import { loadJSData } from "../shared/jsData";
import t from "../shared/translations";
import { useCurrentPerson } from "./components/CurrentPerson";
import { useUnreadInspirations } from "./components/UnreadInspirations";
import { useUnreadMessages } from "./components/UnreadMessages";
import PathNav from "./selectPath/PathNav";

const MOBILE_CUTOFF = 768;
const LARGE_CUTOFF = 1200;

const HeaderWrapper = styled.header`
    padding: 5px 10px 0 10px;
    max-width: 1440px;
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    flex-wrap: wrap;
    @media (min-width: ${MOBILE_CUTOFF}px) {
        flex-direction: row;
        margin-bottom: 0.75rem;
    }
`;

const ImgTainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: column;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    color: ${currentColors.grey4};
    > * {
        margin: 0;
    }
`;

const MainNav = styled.nav`
    display: grid;
    grid-template-columns: repeat(5, auto);
    align-items: center;
    max-width: 1100px;
    @media (min-width: ${MOBILE_CUTOFF}px) and (max-width: ${LARGE_CUTOFF}px) {
        margin-bottom: 1rem;
    }
`;

interface IMainLink {
    $selected: boolean;
}

const MainLink = styled(Link)<IMainLink>`
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 40px;
    max-height: 60px;
    margin: 0 5px 0.2rem 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-bottom: 4px solid ${({ $selected }) => ($selected ? currentColors.navActive : "white")};
    svg {
        height: 60px;
        width: 60px;
        padding-top: 5px;
        fill: ${({ $selected }) => ($selected ? currentColors.navActive : currentColors.grey4)};

        text {
            fill: 'white';
            font-family: 'Proxima Nova', sans-serif;
        }
    }
    @media (min-width: ${MOBILE_CUTOFF}px) {
        justify-content: flex-start;
    }
`;

const NavText = styled.span`
    font-size: 1.3rem;
    color: ${currentColors.text};
    display: none;
    @media (min-width: ${MOBILE_CUTOFF}px) {
        display: inline;
    }
`;

const ThirdNav = styled.nav`
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    margin-bottom: 0.25rem;
    @media (min-width: ${MOBILE_CUTOFF}px) {
        align-items: flex-start;
        justify-content: space-around;
    }
    @media (min-width: ${LARGE_CUTOFF}px) {
        align-items: flex-end;
        flex-direction: column;
    }
`;

const ButtonAnchor = styled.a`
    display: inline-block;
    background-color: transparent;
    border: 1px solid ${currentColors.text};
    border-radius: 0;
    color: ${currentColors.text};
    margin: 0;
    font-size: 0.9rem;
    padding: 0.25rem 0.85rem;
    transition: none;
    line-height: 22px;

    &:hover, &:focus, &:active, &:visited {
        background-color: transparent;
        color: ${currentColors.text};
    }
`;

const LogoImg = styled.img`
    height: 60px;
    width: 270px;
    min-width: 0;
    object-fit: scale-down;
    object-position: left center;
`;

const H3 = styled.h3`
    text-align: center;
    flex-grow: 1;
    margin: 0 5px 0.2rem 5px;
    @media (min-width: ${MOBILE_CUTOFF}px) {
        align-self: flex-end;
    }
`;

const Space = styled.div`
    flex-grow: 1;
`;

const SecondaryNav = styled.nav`
    width: 270px;
`;

const Select = styled.select`
    display: inline-block;
    width: auto;
    line-height: 22px;
    position: relative;
    border: 1px solid ${currentColors.grey4};
    margin: 0;
    display: inline-block;
    width: auto;
    background-color: transparent;
    border-radius: 0;
    color: ${currentColors.text};
    font-size: 0.9rem;
    padding: 0.25rem 0.85rem;
    height: 32px;
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;

    &:focus, &:active, &:visited {
        background-color: white;
        border: 1px solid ${currentColors.grey4};
    }
`;

function Header(): JSX.Element {
    const { width } = useWindowSize();
    const person = useCurrentPerson();
    const unreadMessages = useUnreadMessages();
    const unreadInspirations = useUnreadInspirations();
    const location = useLocation();
    const path = location.pathname;
    const showAdmin = person.user.role.name === "admin";
    const showLearningCoach =
        loadJSData().features.learning_coach &&
        (person.user.role.name === "admin" || person.user.role.name === "learning-coach");
    const showTracking =
        loadJSData().features.tracking &&
        (person.user.role.name === "admin" ||
            person.user.role.name === "organisation-manager" ||
            person.user.role.name === "team-manager");
    const showViewSwitch = showAdmin || showLearningCoach || showTracking;
    const [open, setOpen] = useState(width >= MOBILE_CUTOFF);

    const isProfile = path.startsWith("/profile") || path.startsWith("/messages") || path.startsWith("/my-history");

    const onViewSwitch = (event: FormEvent<HTMLSelectElement>) => {
        const newPath = event.currentTarget.value;
        if (newPath) {
            window.location.href = newPath;
        }
    };

    const mainNav = (
        <MainNav>
            <MainLink to="/landing" $selected={path.startsWith("/landing")}>
                <HomeIcon />
                <NavText>{t("player.header.main-nav-home")}</NavText>
            </MainLink>
            <MainLink to="/performance" $selected={path.startsWith("/performance")}>
                <PerformanceIcon />
                <NavText>{t("player.header.main-nav-performance")}</NavText>
            </MainLink>
            {loadJSData().features.inspiration && (
                <MainLink to="/inspiration" $selected={path.startsWith("/inspiration")}>
                    <InspirationIcon unread={unreadInspirations} />
                    <NavText>{t("player.header.main-nav-inspiration")}</NavText>
                </MainLink>
            )}
            <MainLink to="/calendar" $selected={path.startsWith("/calendar")}>
                <CalendarIcon />
                <NavText>{t("player.header.main-nav-my-calendar")}</NavText>
            </MainLink>
            <MainLink to="/my-history" $selected={isProfile}>
                <ProfileIcon unread={unreadMessages} />
                <NavText>{person.firstName}</NavText>
            </MainLink>
        </MainNav>
    );

    const secondaryNav = (
        <SecondaryNav>
            <PathNav currentTool="path" />
        </SecondaryNav>
    );

    const thirdNav = (
        <ThirdNav>
            <ButtonAnchor href="/logout">{t("player.header.secondary-nav-log-out")}</ButtonAnchor>
            {showViewSwitch && (
                <Select onChange={onViewSwitch}>
                    <option value="">{t("player.header.secondary-nav-switch-view")}</option>
                    {showAdmin && <option value="/admin">{t("player.header.secondary-nav-admin")}</option>}
                    {showLearningCoach && (
                        <option value="/learning-coach">{t("player.header.secondary-nav-learning-coach")}</option>
                    )}
                    {showTracking && (
                        <option value={`/tracking/${person.organisation.id}`}>
                            {t("player.header.secondary-nav-tracking")}
                        </option>
                    )}
                </Select>
            )}
        </ThirdNav>
    );

    if (width < MOBILE_CUTOFF) {
        return (
            <HeaderWrapper>
                <Row>
                    <ImgTainer>
                        <LogoImg src={loadJSData().player_logo_src} alt="logo" />
                        <HamburgerIcon onClick={() => setOpen((o) => !o)} />
                    </ImgTainer>
                    <Space />
                </Row>
                {open && (
                    <>
                        {thirdNav}
                        {secondaryNav}
                    </>
                )}
                {mainNav}
                <H3>{person.currentPath?.pathname ?? person.organisation.name}</H3>
            </HeaderWrapper>
        );
    }

    if (width < LARGE_CUTOFF) {
        return (
            <HeaderWrapper>
                <Row>
                    <ImgTainer>
                        <LogoImg src={loadJSData().player_logo_src} alt="logo" />
                    </ImgTainer>
                    <Space />
                    {thirdNav}
                </Row>
                {mainNav}
                <Row>
                    {secondaryNav}
                    <H3>{person.currentPath?.pathname ?? person.organisation.name}</H3>
                </Row>
            </HeaderWrapper>
        );
    }

    return (
        <HeaderWrapper>
            <Row>
                <ImgTainer>
                    <LogoImg src={loadJSData().player_logo_src} alt="logo" />
                </ImgTainer>
                <Space />
                {mainNav}
                <Space />
                {thirdNav}
            </Row>
            <Row>
                {secondaryNav}
                <H3>{person.currentPath?.pathname ?? person.organisation.name}</H3>
            </Row>
        </HeaderWrapper>
    );
}

export default Header;
