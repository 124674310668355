import type { JSX } from "react";
import { NavLink, Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { currentColors } from "../shared/colors";
import MessagesIcon from "../shared/components/icons/navIcons/MessagesIcon";
import MyPathsIcon from "../shared/components/icons/navIcons/MyPathsIcon";
import NavArrow from "../shared/components/icons/navIcons/NavArrow";
import ProfileIcon from "../shared/components/icons/navIcons/ProfileIcon";
import { loadJSData } from "../shared/jsData";
import t from "../shared/translations";
import { useUnreadMessages } from "./components/UnreadMessages";
import Messages from "./messages/Messages";
import MyHistory from "./myHistory/MyHistory";
import Profile from "./profile/Profile";

const COL_CUTOFF = 840;

const Wrapper = styled.main`
    padding: 0 15px;
    max-width: 1440px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    flex-grow: 1;

    @media (min-width: ${COL_CUTOFF}px) {
        grid-template-columns: 270px 1fr;
        grid-template-rows: auto;
    }
`;

const Nav = styled.nav`
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    border: 1px solid lightgray;
    justify-self: start;
    @media (min-width: ${COL_CUTOFF}px) {
        align-self: start;
        grid-template-columns: 270px;
        grid-template-rows: auto auto auto;
        width: 270px;
    }
`;

const StyledNavLink = styled(NavLink)`
    padding: 10px;
    margin: 0;
    font-size: 0.9rem;
    color: ${currentColors.text};
    display: grid;
    justify-items: center;
    z-index: 1;
    border-right: 1px solid ${currentColors.grey3};
    justify-self: start;

    &:active, &:hover, &:focus, &:visited {
        color: ${currentColors.text};
    }

    &:last-child {
        border-right: none;
    }

    @media (min-width: ${COL_CUTOFF}px) {
        grid-template-columns: auto 1fr auto;
        justify-items: start;
        align-items: center;
        padding: 2rem 10px;
        position: relative;
        font-size: 1.1rem;
        border-right: none;
        border-bottom: 1px solid ${currentColors.grey3};
        justify-self: stretch;

        & > span {
            flex-grow: 1;
            padding-left: 0.5rem;
        }
        &:last-child {
            border-bottom: none;
        }
    }

    .nav-icon {
        width: 50px;
        height: 50px;
        fill: ${currentColors.grey4};
    }

    &.active {
        svg {
            fill: ${currentColors.navActive};
        }
    }
`;

const StyledNavArrow = styled(NavArrow)`
    display: none;
    @media (min-width: ${COL_CUTOFF}px) {
        display: block;
        width: 14px;
        height: 14px;
        fill: ${currentColors.grey4};
    }
`;

function NavBar(): JSX.Element {
    const unreadMessages = useUnreadMessages();
    return (
        <Nav>
            <StyledNavLink to="../my-history">
                <MyPathsIcon />
                <span>{t("player.profile-nav.my-history", { webapp_title: loadJSData().settings.title })}</span>
                <StyledNavArrow />
            </StyledNavLink>
            <StyledNavLink to="../profile">
                <ProfileIcon />
                <span>{t("player.profile-nav.profile")}</span>
                <StyledNavArrow />
            </StyledNavLink>
            <StyledNavLink to="../messages">
                <MessagesIcon unread={unreadMessages} />
                <span>{t("player.profile-nav.messages")}</span>
                <StyledNavArrow />
            </StyledNavLink>
        </Nav>
    );
}

function ProfileNav(): JSX.Element {
    return (
        <Wrapper>
            <Routes>
                <Route
                    path="my-history"
                    element={
                        <>
                            <NavBar />
                            <MyHistory />
                        </>
                    }
                />
                <Route
                    path="profile"
                    element={
                        <>
                            <NavBar />
                            <Profile />
                        </>
                    }
                />
                <Route
                    path="messages/*"
                    element={
                        <>
                            <NavBar />
                            <Messages />
                        </>
                    }
                />
                <Route path="/" element={<Navigate to="my-history" replace />} />
            </Routes>
        </Wrapper>
    );
}

export default ProfileNav;
