import type { JSX } from "react";
import { Navigate } from "react-router-dom";
import type { IPulse } from "../query";

interface IProps {
    pulse: IPulse | null;
    taskId: string;
}

function Redirector({ pulse }: IProps): JSX.Element {
    if (pulse) {
        return <Navigate to={`pulse/${pulse.id}/results`} />;
    }
    return <Navigate to="pulse/setup" />;
}

export default Redirector;
