import { type ReactNode, createContext, useContext, useEffect, useState } from "react";
import type { Exchange, OperationResult } from "urql";
import { pipe, share, tap } from "wonka";
import { loadJSData } from "../../shared/jsData";

interface ISelectedIds {
    pathId: string | null;
    pmId: string | null;
    pathGroupId: string | null;
}

const defaultSelectedIds: ISelectedIds = {
    pathId: null,
    pmId: null,
    pathGroupId: null,
};
const SelectedIdsContext = createContext<ISelectedIds>(defaultSelectedIds);
SelectedIdsContext.displayName = "SelectedIdsContext";

interface IProps {
    children: ReactNode;
}

export function SelectedIdsProvider({ children }: IProps): JSX.Element {
    const jsData = loadJSData();
    const [selectedIds, setSelectedIds] = useState<ISelectedIds>(() => ({
        pathId: jsData.selected.path_id,
        pmId: jsData.selected.pm_id,
        pathGroupId: jsData.selected.path_group_id,
    }));
    useEffect(() => {
        const listener = (event: CustomEvent) => {
            const { path_id, pm_id, path_group_id } = event.detail;
            setSelectedIds((prev) => {
                // Only update if the ids have changed
                if (prev.pathId === path_id && prev.pmId === pm_id && prev.pathGroupId === path_group_id) {
                    return prev;
                }
                return {
                    pathId: path_id,
                    pmId: pm_id,
                    pathGroupId: path_group_id,
                };
            });
        };
        const body = document.querySelector("body");
        body?.addEventListener("tm-update-selected-ids", listener);
        return () => {
            body?.removeEventListener("tm-update-selected-ids", listener);
        };
    }, []);
    return <SelectedIdsContext.Provider value={selectedIds}>{children}</SelectedIdsContext.Provider>;
}

export function useSelectedIds(): ISelectedIds {
    return useContext(SelectedIdsContext);
}

function parseCookies(cookieHeader: string): Record<string, string> {
    return cookieHeader.split(";").reduce<Record<string, string>>((acc, cookieStr) => {
        const [rawKey, ...rawValue] = cookieStr.split("=");
        const key = rawKey?.trim();
        const value = rawValue.join("=").trim();
        if (key) {
            acc[key] = value;
        }
        return acc;
    }, {});
}

// Use a CustomEvent to inject ids into react context
function dispatchIdUpdate(path_id: string | null, pm_id: string | null, path_group_id: string | null) {
    const event = new CustomEvent("tm-update-selected-ids", {
        detail: {
            path_id,
            pm_id,
            path_group_id,
        },
    });
    document.querySelector("body").dispatchEvent(event);
}

function readIdsFromCookies() {
    if (document.cookie) {
        const cookies = parseCookies(document.cookie);
        const selectedPathId = cookies.selected_path_id || null;
        const selectedPmId = cookies.selected_pm_id || null;
        const selectedPathGroupId = cookies.selected_path_group_id || null;
        dispatchIdUpdate(selectedPathId, selectedPmId, selectedPathGroupId);
    }
}

export const selectedIdsExchange: Exchange =
    ({ forward }) =>
    (operations$) => {
        // Share the stream in case there are multiple subscribers.
        const sharedOps$ = share(operations$);

        return pipe(
            forward(sharedOps$),
            tap((result: OperationResult) => {
                readIdsFromCookies();
            }),
        );
    };
