import type { ComponentPropsWithoutRef, JSX } from "react";
import { currentColors } from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function HammerIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg
            {...props}
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="m57.828 35.5v40.5c0 3.036-1.994 5.5-4.45 5.5s-4.45-2.464-4.45-5.5v-40.5z" fill="white" />
            <path
                d="m28.73 25.025c.367-.351 1.031-.96 1.668-1.405 2.373-1.659 5.946-3.549 8.311-4.534.79-.329 1.387-.586 1.683-.586h29.216c1.044 0 1.892.848 1.892 1.892v10.216c0 1.044-.848 1.892-1.892 1.892h-29.165c-.256 0-.956-.326-1.658-.666-2.368-1.143-5.946-3.329-8.321-5.189-.668-.523-1.362-1.227-1.734-1.62z"
                fill={active ? currentColors.icon1 : "white"}
            />
            <path
                d="m46.5 35.5h-6.108c-1.286.002-1.574-.318-2.912-.964-2.523-1.219-6.335-3.547-8.866-5.529-1.206-.944-2.132-1.837-2.591-2.512-.385-.567-.523-1.09-.523-1.495 0-.422.146-.949.548-1.507.465-.646 1.405-1.475 2.631-2.332 2.535-1.772 6.351-3.792 8.877-4.844 1.307-.545 2.076-.825 2.836-.817h29.216c2.7 0 4.892 2.192 4.892 4.892v10.216c0 2.7-2.192 4.892-4.892 4.892h-9.352v40.5c0 4.691-3.082 8.5-6.878 8.5s-6.878-3.809-6.878-8.5zm11.328 0h-8.9v40.5c0 3.036 1.994 5.5 4.45 5.5s4.45-2.464 4.45-5.5zm-29.098-10.475c.372.393 1.066 1.097 1.734 1.62 2.375 1.86 5.953 4.046 8.321 5.189.702.34 1.402.666 1.658.666h29.165c1.044 0 1.892-.848 1.892-1.892v-10.216c0-1.044-.848-1.892-1.892-1.892h-29.216c-.296 0-.893.257-1.683.586-2.365.985-5.938 2.875-8.311 4.534-.637.445-1.301 1.054-1.668 1.405z"
                fill={currentColors.text}
            />
        </svg>
    );
}

export default HammerIcon;
