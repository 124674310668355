import chunk from "lodash-es/chunk";
import { type JSX, memo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import { addDays, endOfMonth, endOfWeek, formatYMD, startOfMonth, startOfWeek } from "../../shared/dateFns";
import t from "../../shared/translations";
import type { State } from "../state";
import Day from "./Day";
import { breakpoints, fontSizes } from "./breakpoints";

const weeksSelector = createSelector(
    (state: State) => state.calendar.selectedMonth,
    (date) => {
        const firstDate = startOfWeek(startOfMonth(date));
        const lastDate = endOfWeek(endOfMonth(date));
        const dateRange: Date[] = [];
        for (let i = firstDate, iMax = lastDate; i <= iMax; i = addDays(i, 1)) {
            dateRange.push(i);
        }
        return chunk(dateRange, 7);
    },
);

const Wrapper = styled.div`
    border: 1px solid ${currentColors.text};
    border-radius: 8px;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
`;

const WeekHeading = styled.div`
    text-align: center;
    font-size: ${fontSizes.xs};
    border-bottom: 1px solid ${currentColors.text};
    border-right: 1px solid ${currentColors.text};
    padding: 3px;
    line-height: 1;
    overflow: hidden;
    white-space: nowrap;

    &:nth-child(7n + 7) {
        border-right: none;
    }

    @container (min-width: ${breakpoints.sm}) {
        font-size: ${fontSizes.sm};
    }
    @container (min-width: ${breakpoints.md}) {
        font-size: ${fontSizes.md};
    }
    @container (min-width: ${breakpoints.lg}) {
        font-size: ${fontSizes.lg};
    }
`;

function Month(): JSX.Element {
    const weeks = useSelector(weeksSelector);

    return (
        <Wrapper>
            <WeekHeading>{t("player.calendar.label-monday")}</WeekHeading>
            <WeekHeading>{t("player.calendar.label-tuesday")}</WeekHeading>
            <WeekHeading>{t("player.calendar.label-wednesday")}</WeekHeading>
            <WeekHeading>{t("player.calendar.label-thursday")}</WeekHeading>
            <WeekHeading>{t("player.calendar.label-friday")}</WeekHeading>
            <WeekHeading>{t("player.calendar.label-saturday")}</WeekHeading>
            <WeekHeading>{t("player.calendar.label-sunday")}</WeekHeading>
            {weeks.map((week) => week.flatMap((day) => <Day key={formatYMD(day)} day={day} />))}
        </Wrapper>
    );
}

export default memo(Month);
