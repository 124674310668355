import { type JSX, useCallback, useEffect, useState } from "react";
import { intervalToDuration, parseDate } from "../../shared/dateFns";

interface IProps {
    taskTime: string;
}

function PitstopTimer({ taskTime }: IProps): JSX.Element {
    const [timeText, setTimeText] = useState("");

    const updateTimeText = useCallback(() => {
        const duration = intervalToDuration({
            start: parseDate(taskTime),
            end: new Date(),
        });
        const days = duration.days ?? 0;
        const hours = duration.hours ?? 0;
        const minutes = duration.minutes ?? 0;
        const seconds = duration.seconds ?? 0;

        const timeText = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        setTimeText(timeText);
    }, [taskTime]);

    useEffect(() => {
        updateTimeText();
        const timer = setInterval(updateTimeText, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [updateTimeText]);

    return <span>{timeText}</span>;
}

export default PitstopTimer;
