import { type JSX, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import TaskDescription from "../../shared/components/TaskDescription";
import getRelativeUrl from "../../shared/getRelativeUrl";
import t from "../../shared/translations";
import LockedModal, { useLockedModal } from "../LockedModal";
import ShowError from "../components/ShowError";
import Thread from "../task/Thread";
import WithTasksSidebar from "../tasksSidebar";
import TaskForm, { type IFormValues, defaultValues } from "./TaskForm";
import {
    type IQueryArgs,
    type IQueryData,
    type IRejectedArgs,
    type IRejectedData,
    query,
    rejectedMutation,
} from "./query";
import taskData from "./taskData";

const EMPTY_LIST = [];

function RejectedTask(): JSX.Element {
    const { taskId } = useParams();
    const [_, executeMutation] = useMutation<IRejectedData, IRejectedArgs>(rejectedMutation);
    const [apiError, setApiError] = useState("");
    const [result] = useQuery<IQueryData, IQueryArgs>({
        query,
        variables: { id: taskId },
    });
    const { openLockedModal, ...lockedProps } = useLockedModal();
    const navigate = useNavigate();

    let main = <div />;
    if (result.fetching) {
        main = <Loader />;
    } else if (result.error) {
        main = <ShowError />;
    } else if (!result.data.submittedTask) {
        main = <Navigate to="/landing" />;
    } else {
        const task = result.data.submittedTask;

        const data = taskData(task.kind);

        const onSubmit = async (values: IFormValues) => {
            const result = await executeMutation({
                ...values,
                taskId,
            });
            if (result.error) {
                console.error(result.error.message);
                setApiError(t("player.error.error"));
            } else if (result.data.submitCustomRejectedTask.error) {
                setApiError(result.data.submitCustomRejectedTask.error);
            }
            if (result.data?.submitCustomRejectedTask?.redirect) {
                navigate(getRelativeUrl(result.data.submitCustomRejectedTask.redirect));
            }
        };

        main = (
            <>
                <TaskDescription
                    icon={task.icon}
                    shortTitle={task.shortTitle}
                    miles={null}
                    title={null}
                    taskVariants={EMPTY_LIST}
                    submittedVariant={task.submittedTaskVariant}
                    objectives={null}
                    media={EMPTY_LIST}
                    inspiration={null}
                    invert={false}
                />
                <Thread thread={result.data.submittedTask.thread} />
                <TaskForm
                    labels={data.placeholders}
                    errors={data.errors}
                    initialValues={defaultValues}
                    onSubmit={onSubmit}
                />
                {apiError && <div>{apiError}</div>}
            </>
        );
    }

    return (
        <>
            <WithTasksSidebar
                openLockedModal={openLockedModal}
                startTab="custom"
                activeItem={result.data?.submittedTask?.learningPathItem?.id}
                mainLabel={t("player.custom-task.tab-task")}
                big={true}
            >
                {main}
            </WithTasksSidebar>
            <LockedModal {...lockedProps} />
        </>
    );
}

export default RejectedTask;
