import { type JSX, memo } from "react";
import styled from "styled-components";
import { currentColors } from "../colors";

interface IProps {
    letter: string;
    selected: boolean;
    $small?: boolean;
}

interface ISvg {
    $small?: boolean;
}

const Svg = styled.svg<ISvg>`
    width: ${({ $small }) => ($small ? 48 : 64)}px;
    height: ${({ $small }) => ($small ? 48 : 64)}px;
    text {
        fill: white;
        font-size: ${({ $small }) => ($small ? 2.8 : 3.2)}rem;
        text-anchor: middle;
    }
`;

function QuizLetter({ letter, selected, $small }: IProps): JSX.Element {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" $small={$small}>
            <circle cx="50" cy="50" r="50" fill={selected ? currentColors.quizSelected : currentColors.quizOption} />
            <text x="50" y="66">
                {letter.toUpperCase()}
            </text>
        </Svg>
    );
}

export default memo(QuizLetter);
