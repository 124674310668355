import { type JSX, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { type UseQueryState, useMutation, useQuery } from "urql";
import { currentColors } from "../../shared/colors";
import Loader from "../../shared/components/Loader";
import getRelativeUrl from "../../shared/getRelativeUrl";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import ShowError from "../components/ShowError";
import { type IData, type ISelectArgs, type ISelectData, type ToolType, query, selectMutation } from "./query";

const Wrapper = styled.main`
    min-height: 100vh;
    margin: 0 auto;
    display: grid;
    place-items: center;
    place-content: center;
    flex-wrap: wrap;
    gap: .5rem;
`;

const Logo = styled.img`
    max-height: 100px;
    max-width: 290px;
    object-fit: scale-down;
`;

const Avatar = styled.img`
    display: inline-block;
`;

const Info = styled.div`
    font-size: 1.2rem;
`;

const ButtonList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: .3rem;
    padding: 0 .3rem;
`;

interface IButton {
    $path?: boolean;
}

const Button = styled.button<IButton>`
    border-radius: 1rem;
    margin-bottom: 0;
    border: 1px solid ${currentColors.button};
    background-color: ${({ $path }) => ($path ? currentColors.button : "white")};
    color: ${({ $path }) => ($path ? "white" : currentColors.button)};

    &:hover, &:focus, &:active {
        background-color: ${({ $path }) => ($path ? currentColors.button : "white")};
        color: ${({ $path }) => ($path ? "white" : currentColors.button)};
    }
`;

function useAutoNavigate(
    result: UseQueryState<IData>,
    handleSelect: (tool: ToolType, pathId: string | null) => Promise<void>,
): void {
    useEffect(() => {
        if (result.data?.currentPerson) {
            const person = result.data.currentPerson;
            const availableOptions: { tool: ToolType; pathId: string | null }[] = [];

            for (const path of person.ongoingPaths) {
                availableOptions.push({ tool: "path", pathId: path.id });
            }

            if (loadJSData().features.team_scan && person.teamScans.length > 0) {
                availableOptions.push({ tool: "teamScan", pathId: null });
            }

            if (loadJSData().features.pulse && person.user.pulseRights) {
                availableOptions.push({ tool: "pulse", pathId: null });
            }

            if (loadJSData().features.ai && person.standaloneAiChatbots.length > 0) {
                availableOptions.push({ tool: "standaloneAiChatbot", pathId: null });
            }

            if (availableOptions.length === 1) {
                const { tool, pathId } = availableOptions[0];
                handleSelect(tool, pathId);
            } else if (availableOptions.length === 0) {
                handleSelect("profile", null);
            }
        }
    }, [result.data, handleSelect]);
}

function SelectPath(): JSX.Element {
    const [result] = useQuery<IData>({ query, requestPolicy: "network-only" });
    const [selectResult, executeSelect] = useMutation<ISelectData, ISelectArgs>(selectMutation);
    const navigate = useNavigate();

    const handleSelect = useCallback(
        async (tool: ToolType, pathId: string | null) => {
            const result = await executeSelect({ tool, pathId });
            if (result.data?.currentPersonSelectPath.redirect) {
                navigate(getRelativeUrl(result.data.currentPersonSelectPath.redirect));
            }
        },
        [executeSelect, navigate],
    );

    useAutoNavigate(result, handleSelect);

    const onSelectPath = async (id: string) => {
        handleSelect("path", id);
    };
    const onSelectPulse = async () => {
        handleSelect("pulse", null);
    };
    const onSelectTeamScan = async () => {
        handleSelect("teamScan", null);
    };
    const onSelectAiChatbot = async () => {
        handleSelect("standaloneAiChatbot", null);
    };

    if (result.fetching) {
        return (
            <Wrapper>
                <Loader />
            </Wrapper>
        );
    }
    if (result.error) {
        return (
            <Wrapper>
                <ShowError />
            </Wrapper>
        );
    }
    const { currentPerson } = result.data!;
    const logoSrc = loadJSData().player_logo_src;
    const avatarSrc = `${loadJSData().avatar_path}${currentPerson.avatar}-walking.png`;

    return (
        <Wrapper>
            <Logo src={logoSrc} />
            <Avatar src={avatarSrc} />
            <Info>{t("player.select-path.heading")}</Info>
            <ButtonList>
                {currentPerson.ongoingPaths.map((path) => (
                    <Button
                        key={path.id}
                        $path
                        type="button"
                        disabled={selectResult.fetching}
                        onClick={() => onSelectPath(path.id)}
                    >
                        {path.pathname}
                    </Button>
                ))}
                {loadJSData().features.team_scan && currentPerson.teamScans.length > 0 && (
                    <Button type="button" disabled={selectResult.fetching} onClick={onSelectTeamScan}>
                        {t("player.select-path.button-team-scan")}
                    </Button>
                )}
                {loadJSData().features.pulse && currentPerson.user.pulseRights && (
                    <Button type="button" disabled={selectResult.fetching} onClick={onSelectPulse}>
                        {t("player.select-path.button-pulse")}
                    </Button>
                )}
                {loadJSData().features.ai && currentPerson.standaloneAiChatbots.length > 0 && (
                    <Button type="button" disabled={selectResult.fetching} onClick={onSelectAiChatbot}>
                        {t("player.select-path.button-ai-chatbot")}
                    </Button>
                )}
            </ButtonList>
        </Wrapper>
    );
}

export default SelectPath;
