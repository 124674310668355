import { type FormEvent, type JSX, type KeyboardEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { currentColors } from "../../colors";
import PlayButtonIcon from "../icons/taskIcons/PlayButtonIcon";
import AiLoader from "./AiLoader";

interface IProps {
    onSubmit: (content: string) => void;
    loading: boolean;
}

const Wrapper = styled.div`
    position: relative;
`;

const Button = styled.button`
    width: 37px;
    height: 37px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    margin: 0;
    padding: 0;
    border: 0;
`;

const Textarea = styled.textarea`
    padding-right: 38px;
    resize: none;
    overflow: hidden;
    height: 38px;
    min-height: 0;
    border: none;
    background-color: none;
    border-radius: 0.5rem;
    border: 1px solid ${currentColors.text};
    margin: 0;
`;

function AiInput({ onSubmit, loading }: IProps): JSX.Element {
    const [value, setValue] = useState("");
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "38px"; // Reset height to initial
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Adjust textarea height when value changes
    useEffect(() => {
        adjustTextareaHeight();
    }, [value]);

    const handleChange = (event: FormEvent<HTMLTextAreaElement>) => {
        setValue(event.currentTarget.value);
    };

    const handleOnKeyDown = (event: KeyboardEvent) => {
        if (!value) {
            return;
        }
        if (event.key === "Enter" && !event.shiftKey) {
            onSubmit(value);
            setValue("");
            event.preventDefault();
        }
    };

    const handleClick = () => {
        onSubmit(value);
        setValue("");
    };

    if (loading) {
        return <AiLoader />;
    }

    return (
        <Wrapper>
            <Textarea ref={textareaRef} value={value} onChange={handleChange} onKeyDown={handleOnKeyDown} />
            <Button onClick={handleClick}>
                <PlayButtonIcon white />
            </Button>
        </Wrapper>
    );
}

export default AiInput;
