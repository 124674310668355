import type { JSX } from "react";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import RenderMark from "../../shared/components/RenderMark";
import ShowError from "../components/ShowError";
import { type IData, query } from "./queries";

function Terms(): JSX.Element {
    const [result] = useQuery<IData>({ query });

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }
    return (
        <>
            <section>
                <RenderMark content={result.data.terms.settingsTerms} />
            </section>
            <section>
                <RenderMark content={result.data.terms.terms} />
            </section>
        </>
    );
}

export default Terms;
