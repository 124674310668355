import { memo, useState } from "react";
import type { ReactElement } from "react";
import styled from "styled-components";
import checkChar from "../checkChar";
import { currentColors } from "../colors";
import t from "../translations";
import QuizLetter from "./QuizLetter";
import RenderMark from "./RenderMark";

export interface IQuzAnswer {
    id: string;
    answer: string;
    answeredQuestion: {
        id: string;
        number: number;
        levelNumber: number;
        description: string;
        correctAnswer: string;
        explanation: string | null;
        quizOptions: {
            id: string;
            letter: string;
            content: string;
        }[];
    };
}

interface IProps {
    result: IQuzAnswer;
}

interface IContainer {
    $correct?: boolean;
}

const Container = styled.div<IContainer>`
    border-radius: 1rem;
    border: 3px solid ${({ $correct }) => ($correct ? currentColors.border : currentColors.error)};
    position: relative;
    padding: 0.6rem;
    padding-left: 3rem;
    margin-bottom: 1rem;
    display: grid;
    gap: 0.6rem;
    grid-template-columns: 1fr;
    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
`;

interface IOpenClose {
    $open?: boolean;
}

const OpenClose = styled.div<IOpenClose>`
    position: absolute;
    top: 0.7rem;
    left: 0.7rem;
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
    transform: ${({ $open }) => ($open ? "rotate(180deg)" : "rotate(0deg)")};
    transition: transform 0.4s ease;
`;

const H2 = styled.h2`
    font-size: 1.3rem;
    margin: 0;
    cursor: pointer;
    grid-column: 1 / -1;
`;

const H3 = styled.h3`
    font-size: 1.2rem;
    margin: 0;
`;

const OptionBox = styled.div`
    display: grid;
    gap: 1rem;
    grid-template-columns: auto 1fr;
    align-items: start;
    margin-bottom: 1rem;
`;

const Correct = styled.div`
    color: ${currentColors.quizSelected};
`;

const Incorrect = styled.div`
    color: ${currentColors.error};
`;

const OptionRenderMark = styled(RenderMark)`
    > p:last-child {
        margin-bottom: 0;
    }
`;

function QuizResult({ result }: IProps): ReactElement {
    const [open, setOpen] = useState(true);

    const toggle = () => setOpen((prevOpen) => !prevOpen);
    const correctOption = result.answeredQuestion.quizOptions.find(
        (o) => o.letter === result.answeredQuestion.correctAnswer,
    );
    const correct = result.answer === result.answeredQuestion.correctAnswer;
    const nr = result.answeredQuestion.number;

    return (
        <Container key={result.id} $correct={correct}>
            <OpenClose $open={open} onClick={toggle}>
                <svg viewBox="0 0 64 36" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <title>Open close icon</title>
                    <path d="M2 2 l30 30l30 -30" fill="none" stroke={currentColors.text} strokeWidth="5" />
                </svg>
            </OpenClose>
            <H2 onClick={toggle}>{t("shared.quiz-results.question-number", { nr })}</H2>
            {open && (
                <>
                    <div>
                        <RenderMark content={result.answeredQuestion.description} />
                        {result.answeredQuestion.explanation && (
                            <>
                                <H3>{t("shared.quiz-results.explanation")}</H3>
                                <RenderMark content={result.answeredQuestion.explanation} />
                            </>
                        )}
                    </div>
                    <div>
                        {result.answeredQuestion.quizOptions.map((option) => (
                            <OptionBox key={option.id}>
                                <QuizLetter letter={option.letter} selected={option.letter === result.answer} />
                                <div>
                                    <OptionRenderMark content={option.content} />
                                    {option.id === correctOption.id && (
                                        <Correct>
                                            {checkChar(true)} {t("shared.quiz-results.answer-correct")}
                                        </Correct>
                                    )}
                                    {option.id !== correctOption.id && option.letter === result.answer && (
                                        <Incorrect>
                                            {checkChar(false)} {t("shared.quiz-results.answer-incorrect")}
                                        </Incorrect>
                                    )}
                                </div>
                            </OptionBox>
                        ))}
                    </div>
                </>
            )}
        </Container>
    );
}

export default memo(QuizResult);
