import type { ComponentPropsWithoutRef, JSX } from "react";
import { currentColors } from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
    white?: boolean;
}

function PulseIcon({ active, white, ...props }: IProps): JSX.Element {
    return (
        <svg
            {...props}
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m93.811 28.557v32.886c0 9.904-8.04 17.944-17.943 17.944h-32.3c-3.681 5.982-9.196 10.915-15.431 13.72-.539.243-1.171.146-1.613-.247-.442-.394-.611-1.01-.432-1.574 1.196-3.769 1.534-7.726.535-11.899h-2.495c-9.903 0-17.943-8.04-17.943-17.944v-32.886c0-9.904 8.04-17.944 17.943-17.944h51.736c9.903 0 17.943 8.04 17.943 17.944zm-3 15.953v-15.953c0-8.248-6.696-14.944-14.943-14.944h-51.736c-8.247 0-14.943 6.696-14.943 14.944v15.953h5.753 9.75 7.623l3.726-3.716c.354-.353.86-.506 1.35-.409.491.097.9.432 1.093.893l2.894 6.921 7.187-23.636c.208-.686.873-1.129 1.587-1.056.713.073 1.275.64 1.342 1.354l3.25 35.084 5.653-14.48c.225-.576.779-.955 1.397-.955h15.774zm-81.622 3.004v13.929c0 8.248 6.696 14.944 14.943 14.944h3.647c.666 0 1.253.439 1.44 1.078 1.162 3.969 1.252 7.766.57 11.412 4.73-2.789 8.828-6.922 11.631-11.744.269-.462.763-.746 1.297-.746h33.151c8.247 0 14.943-6.696 14.943-14.944v-13.931h-5.724v-.002h-22.268l-7.586 19.432c-.253.648-.918 1.037-1.607.94s-1.22-.655-1.284-1.347l-3.13-33.786-6.175 20.308c-.184.605-.728 1.03-1.36 1.062-.631.031-1.215-.336-1.459-.92l-3.64-8.703-2.583 2.576c-.281.281-.662.438-1.059.438h-17.996v.004z"
                fill={white ? "white" : currentColors.text}
            />
            <path
                d="m90.811 44.51h-13.243-15.774c-.618 0-1.172.379-1.397.955l-5.653 14.48-3.25-35.084c-.067-.714-.629-1.281-1.342-1.354-.714-.073-1.379.37-1.587 1.056l-7.187 23.636-2.894-6.921c-.193-.461-.602-.796-1.093-.893-.49-.097-.996.056-1.35.409l-3.726 3.716h-7.623-9.75-5.753v-15.953c0-8.248 6.696-14.944 14.943-14.944h51.736c8.247 0 14.943 6.696 14.943 14.944z"
                fill={white ? "none" : "white"}
            />
            <path
                d="m9.189 47.514h5.751v-.004h17.996c.397 0 .778-.157 1.059-.438l2.583-2.576 3.64 8.703c.244.584.828.951 1.459.92.632-.032 1.176-.457 1.36-1.062l6.175-20.308 3.13 33.786c.064.692.595 1.25 1.284 1.347s1.354-.292 1.607-.94l7.586-19.432h22.268v.002h5.724v13.931c0 8.248-6.696 14.944-14.943 14.944h-33.151c-.534 0-1.028.284-1.297.746-2.803 4.822-6.901 8.955-11.631 11.744.682-3.646.592-7.443-.57-11.412-.187-.639-.774-1.078-1.44-1.078h-3.647c-8.247 0-14.943-6.696-14.943-14.944z"
                fill={active ? currentColors.icon3 : white ? "none" : "white"}
            />
        </svg>
    );
}

export default PulseIcon;
