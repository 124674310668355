import type { JSX } from "react";
import { useImmerReducer } from "use-immer";
import AvatarForm from "./AvatarForm";
import NavBar from "./NavBar";
import PasswordForm from "./PasswordForm";
import PictureForm from "./PictureForm";
import ProfileForm from "./ProfileForm";
import ReviewPage from "./ReviewPage";
import TermsForm from "./TermsForm";
import { type IValues, createInitialState, reducer } from "./state";

export type { IValues } from "./state";

interface IProps {
    initialValues: Partial<IValues>;
    onComplete: (values: IValues) => Promise<void>;
    terms: string;
    settingsTerms: string | null;
    avatarOptions: string[];
}

function AccountWizard({ initialValues, onComplete, terms, settingsTerms, avatarOptions }: IProps): JSX.Element {
    const [state, dispatch] = useImmerReducer(reducer, initialValues, createInitialState);

    let main = null;
    switch (state.page) {
        case "1-profile":
            main = <ProfileForm state={state} dispatch={dispatch} />;
            break;
        case "2-password":
            main = <PasswordForm state={state} dispatch={dispatch} />;
            break;
        case "3-terms":
            main = <TermsForm state={state} dispatch={dispatch} terms={terms} settingsTerms={settingsTerms} />;
            break;
        case "4-avatar":
            main = <AvatarForm state={state} dispatch={dispatch} avatarOptions={avatarOptions} />;
            break;
        case "5-picture":
            main = <PictureForm state={state} dispatch={dispatch} />;
            break;
        case "6-review":
            main = <ReviewPage state={state} dispatch={dispatch} onComplete={onComplete} />;
            break;
    }
    return (
        <div>
            <NavBar state={state} dispatch={dispatch} />
            {main}
        </div>
    );
}

export default AccountWizard;
