import { type JSX, memo } from "react";
import styled from "styled-components";
import { Bikers, Runners } from "../../shared/components/Runners";
import t from "../../shared/translations";
import WithDocs from "../components/WithDocs";
import HorizontalSpeedbar from "./HorizontalSpeedbar";
import type { IData } from "./query";
import { BorderBox, Box, Frame, StatsLabel, StatsText } from "./stylings";

interface IProps {
    data: IData;
}

const BikersHolder = styled.div`
    max-width: 800px;
`;

function PathGroupPerf({ data }: IProps): JSX.Element {
    const pm = data.pathMembership;
    const path = pm.learningPath;
    return (
        <>
            <Frame>
                {path.fixed && (
                    <>
                        <Box $align="left">
                            <WithDocs
                                modal="Performance/Info/Biking"
                                docPath="player/performance/path-group-running"
                                component="h4"
                                style={{ gridColumn: "span 12", justifyContent: "flex-start" }}
                            >
                                {t("player.performance.heading-path-group-running")}
                            </WithDocs>
                        </Box>
                        <Box $align="left">
                            <BikersHolder>
                                <Bikers
                                    goal={path.goal}
                                    checkpoint={path.checkpoint}
                                    miles={pm.pathGroupMilesAvg}
                                    pathGroupMiles={pm.allPathGroupMiles}
                                />
                            </BikersHolder>
                        </Box>
                        <Box $align="left">
                            <div style={{ maxWidth: 650 }}>
                                <Runners
                                    goal={path.goal}
                                    checkpoint={path.checkpoint}
                                    miles={pm.miles}
                                    memberMiles={pm.pathGroupMemberMiles}
                                />
                            </div>
                        </Box>
                    </>
                )}
            </Frame>
            <Frame $borderWidth="0" $skipPadding>
                <BorderBox $span={[12, 12, 4]}>
                    <WithDocs
                        modal="Performance/Info/PathGroupSpeedometer"
                        docPath="player/performance/path-group-speed"
                        component="h4"
                        style={{ justifyContent: "center" }}
                    >
                        {t("player.performance.heading-path-group-speedometer")}
                    </WithDocs>
                    <HorizontalSpeedbar groupSpeed={pm.pathGroupSpeed} ourSpeed={pm.ourSpeed} />
                </BorderBox>
                <BorderBox $span={[12, 6, 4]}>
                    <WithDocs
                        modal="Performance/Info/PathGroupMilesTotal"
                        docPath="player/performance/path-group-miles-total"
                        component="h4"
                        style={{ justifyContent: "center" }}
                    >
                        {t("player.performance.heading-path-group-miles-total")}
                    </WithDocs>
                    <div>
                        <StatsText>{pm.pathGroupMiles}</StatsText>
                        <StatsLabel>{t("player.performance.label-learning-miles")}</StatsLabel>
                    </div>
                </BorderBox>
                <BorderBox $span={[12, 6, 4]}>
                    <WithDocs
                        modal="Performance/Info/PathGroupMilesAvg"
                        docPath="player/performance/path-group-miles-avg"
                        component="h4"
                        style={{ justifyContent: "center" }}
                    >
                        {t("player.performance.heading-path-group-miles-avg")}
                    </WithDocs>
                    <div>
                        <StatsText>{pm.pathGroupMilesAvg}</StatsText>
                        <StatsLabel>{t("player.performance.label-learning-miles")}</StatsLabel>
                    </div>
                </BorderBox>
            </Frame>
        </>
    );
}

export default memo(PathGroupPerf);
