import type { ComponentPropsWithoutRef } from "react";
import { currentColors } from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function PuzzlePieceIcon({ active, ...props }: IProps) {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m49.9 88.7c-1.1 0-2.2-.4-3.1-1.3l-34.3-34.3c-1.7-1.7-1.7-4.4 0-6.1l12.6-12.6c1.4-1.4 2.6-1.1 3.1-.9s1.5.8 1.6 2.8c0 0 .1 1.4 1.4 2.7 1 1 2.4 1.6 3.9 1.6s2.9-.6 3.9-1.6 1.6-2.4 1.6-3.9-.6-2.9-1.6-3.9c-1.3-1.3-2.7-1.4-2.7-1.4-1.9 0-2.6-1.1-2.8-1.6s-.5-1.7.9-3.1l12.6-12.6c1.7-1.7 4.4-1.7 6.1 0l10.1 10.1c.2-1 .8-4.3 3.2-6.7 4.9-4.9 12.8-4.9 17.7 0 2.4 2.4 3.7 5.5 3.7 8.8s-1.3 6.5-3.7 8.8c-2.4 2.4-5.7 3.1-6.7 3.2l10.1 10.1c1.7 1.7 1.7 4.4 0 6.1l-12.7 12.8c-1.4 1.4-2.6 1.1-3.1.9s-1.5-.8-1.6-2.8c0 0-.1-1.4-1.4-2.7-1-1-2.4-1.6-3.9-1.6s-2.9.6-3.9 1.6-1.6 2.4-1.6 3.9.6 2.9 1.6 3.9c1.3 1.3 2.7 1.4 2.7 1.4 1.9 0 2.6 1.1 2.8 1.5.2.5.5 1.7-.9 3.1l-12.5 12.5c-.9.9-2 1.3-3.1 1.3z"
                fill={currentColors.text}
            />
            <path
                d="m26.7 36.9-12.1 12.2c-.5.5-.5 1.4 0 1.9l34.4 34.3c.5.5 1.4.5 1.9 0l12.1-12.1c-.9-.1-2.6-.6-4.1-2.2l0 0c-1.6-1.6-2.5-3.7-2.5-6s .9-4.4 2.5-6 3.7-2.5 6-2.5 4.4.9 6 2.5 2 3.2 2.2 4.1l12.2-12.2c.5-.5.5-1.4 0-1.9l-10.4-10.2c-.9-.9-1.3-2.1-.9-3.1s1.3-1.7 2.7-1.8c0 0 3.2-.4 5.2-2.4 1.8-1.8 2.8-4.2 2.8-6.7s-1-4.9-2.8-6.7-4.2-2.8-6.7-2.8-4.9 1-6.7 2.8c-2 2-2.4 5.2-2.4 5.2-.1 1.3-.8 2.3-1.8 2.7-1 .3-2.1 0-3.1-.9l-10.3-10.4c-.5-.5-1.4-.5-1.9 0l-12.2 12.1c.9.1 2.6.6 4.1 2.2 1.6 1.6 2.5 3.7 2.5 6s-.9 4.4-2.5 6-3.7 2.5-6 2.5-4.4-.9-6-2.5c-1.5-1.5-2-3.2-2.2-4.1z"
                fill={active ? currentColors.icon4 : "white"}
            />
        </svg>
    );
}

export default PuzzlePieceIcon;
