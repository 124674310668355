import type { CSSProperties, JSX, MouseEvent } from "react";
import { currentColors } from "../../shared/colors";
import { formatYMD, parseDate } from "../../shared/dateFns";
import useScrollIntoView from "../../shared/hooks/useScrollIntoView";
import t from "../../shared/translations";
import type { OpenLockedModal } from "../LockedModal";
import {
    ActiveLabel,
    Avatar,
    IconTainer,
    MilesCircle,
    MilesLabel,
    RedoLabel,
    StatusTainer,
    Task,
    fillPercent,
    fmtMiles,
} from "./CornerItem";
import type { PathItem } from "./StreetPath";
import { AllAnswersLabel, GroupTaskLabel, LockIcon, SizedTaskIcon } from "./stylings";

const ICON_SIZE = 70;
const ICON_OFFSET = 225;

interface IProps {
    item: PathItem;
    index: number;
    openLockedModal: OpenLockedModal;
}

function FlatItem({ item, index, openLockedModal }: IProps): JSX.Element {
    const style: CSSProperties = { top: ICON_OFFSET, left: ICON_SIZE * index + ICON_SIZE / 2, width: ICON_SIZE };
    const ref = useScrollIntoView<HTMLAnchorElement>(true, "auto", "nearest", "center");
    const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
        if (item.locked) {
            event.preventDefault();
            openLockedModal(item.lockedTaskId, item.lockedFeedbackId, item.lockedTaskChoiceId);
            event.stopPropagation();
        }
    };

    if (item.avatarSrc) {
        return (
            <Avatar href={item.url} style={style} $scale={1} ref={ref}>
                <img src={item.avatarSrc} alt="avatar" />
            </Avatar>
        );
    }
    return (
        <Task to={item.url} style={style} onClick={onClick}>
            <StatusTainer>
                {item.active && <ActiveLabel>{t("player.landing.active")}</ActiveLabel>}
                {item.undecided && <ActiveLabel>{t("player.landing.undecided")}</ActiveLabel>}
                {item.redo && <RedoLabel>{t("player.landing.redo")}</RedoLabel>}
            </StatusTainer>
            <IconTainer $size={ICON_SIZE}>
                <SizedTaskIcon icon={item.icon} active={item.complete} $size={`${ICON_SIZE}px`} />
                {item.type === "path-in-path" && (
                    <MilesCircle
                        color={currentColors.progress}
                        fillPercent={fillPercent(item)}
                        $size={ICON_SIZE}
                        thin
                    />
                )}
                {item.locked && <LockIcon $size={ICON_SIZE} $absolute={true} />}
                {item.grouped && <GroupTaskLabel active={item.complete} $scale={1.4} />}
                {item.shareAnswers && <AllAnswersLabel $scale={1.5} />}
            </IconTainer>
            {item.miles?.[0] > 0 && <MilesLabel $topM={false}>{fmtMiles(item)}</MilesLabel>}
            {item.type === "event" && <MilesLabel $topM={false}>{formatYMD(parseDate(item.eventDate))}</MilesLabel>}
        </Task>
    );
}

export default FlatItem;
