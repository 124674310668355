import type { ComponentPropsWithoutRef, JSX } from "react";
import { currentColors } from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function SailingBoatIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg
            {...props}
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="1.5"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m87.608 80.321c-.89 4.377-4.764 7.676-9.402 7.676s-8.512-3.299-9.402-7.676c-.89 4.377-4.764 7.676-9.402 7.676s-8.512-3.299-9.402-7.676c-.89 4.377-4.764 7.676-9.402 7.676s-8.512-3.299-9.402-7.676c-.89 4.377-4.764 7.676-9.402 7.676s-8.512-3.299-9.402-7.676"
                fill="none"
                stroke={currentColors.text}
                strokeWidth="3"
            />
            <path
                d="m75.034 85.851c-2.413-1.03-4.225-3.198-4.76-5.829-.142-.698-.757-1.201-1.47-1.201s-1.328.503-1.47 1.201c-.751 3.693-4.019 6.475-7.932 6.475s-7.181-2.782-7.932-6.475c-.142-.698-.757-1.201-1.47-1.201s-1.328.503-1.47 1.201c-.751 3.693-4.019 6.475-7.932 6.475s-7.181-2.782-7.932-6.475c-.142-.698-.757-1.201-1.47-1.201s-1.328.503-1.47 1.201c-.751 3.693-4.019 6.475-7.932 6.475-1.752 0-3.375-.558-4.701-1.505l-3.022-14.534h72.34z"
                fill="white"
            />
            <path
                d="m74.308 86.834 12.103-16.376h-72.34l3.358 16.148"
                fill="none"
                stroke={currentColors.text}
                strokeWidth="3"
            />
            <path
                d="m46.879 12.003c17.124 13.267 32.85 33.57 37.497 50.045l-36.16 2.529c6.512-13.31 2.689-34.729-1.337-52.574z"
                fill={active ? currentColors.icon2 : "white"}
            />
            <path
                d="m46.879 12.003c17.124 13.267 32.85 33.57 37.497 50.045l-36.16 2.529c6.512-13.31 2.689-34.729-1.337-52.574z"
                fill="none"
                stroke={currentColors.text}
                strokeWidth="3"
            />
            <path d="m42.441 65.011h-25.934l25.934-44.958z" fill="white" />
            <path d="m42.441 65.011h-25.934l25.934-44.958z" fill="none" stroke={currentColors.text} strokeWidth="3" />
        </svg>
    );
}

export default SailingBoatIcon;
