import { Fragment, memo } from "react";
import type { JSX } from "react";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import { nice_num } from "../../shared/components/Runners";
import t from "../../shared/translations";

type TickProps = {
    loc: number;
    miles: number;
};

function calcTicks(miles: number): TickProps[] {
    const maxTicks = 6;
    const tickRange = nice_num(miles, false);
    const tickSpacing = nice_num(tickRange / (maxTicks - 1), true);
    const niceMax = Math.ceil(miles / tickSpacing) * tickSpacing;
    const numTicks = Math.floor(niceMax / tickSpacing) + 1;
    const tickDistance = 100.0 / Math.floor(niceMax / tickSpacing);
    const ticks: TickProps[] = [];
    for (let i = 0, iMax = numTicks; i < iMax; i++) {
        const loc = i * tickDistance * 1.96 + 9;
        ticks.push({ loc, miles: i * tickSpacing });
    }
    return ticks;
}

function calcMinorTicks(ticks: TickProps[]): number[] {
    const minorTicks: number[] = [];
    for (let i = 1, iMax = ticks.length; i < iMax; i++) {
        const dist = (ticks[i].loc - ticks[i - 1].loc) / 2;
        minorTicks.push(ticks[i - 1].loc + dist);
    }
    return minorTicks;
}

function calculateSpeedPos(miles: number, ticks: TickProps[]): number {
    if (ticks.length === 0) {
        return 0;
    }

    if (miles <= ticks[0].miles) {
        return ticks[0].loc;
    }

    const lastTick = ticks[ticks.length - 1];
    if (miles >= lastTick.miles) {
        return lastTick.loc;
    }

    // Find the two ticks that the miles value falls between
    for (let i = 0; i < ticks.length - 1; i++) {
        const lowerTick = ticks[i];
        const upperTick = ticks[i + 1];

        if (miles >= lowerTick.miles && miles <= upperTick.miles) {
            // Calculate the position by linear interpolation
            const milesRange = upperTick.miles - lowerTick.miles;
            const locRange = upperTick.loc - lowerTick.loc;
            const ratio = (miles - lowerTick.miles) / milesRange;
            return lowerTick.loc + ratio * locRange;
        }
    }

    // Fallback (should not reach here if ticks are properly ordered)
    return ticks[0].loc;
}

const Column = styled.div`
    display: grid;
    grid-template-rows: auto 1fr auto;
    align-items: center;
    > div {
        min-width: 0;
        overflow-wrap: break-word;
        text-align: center;
        line-height: 1;
    }
`;

interface IProps {
    groupSpeed: number;
    ourSpeed: number;
}

function HorizontalSpeedbar({ groupSpeed, ourSpeed }: IProps): JSX.Element {
    const maxMiles = Math.max(groupSpeed, ourSpeed, 500);
    const ticks = calcTicks(maxMiles);
    const minorTicks = calcMinorTicks(ticks);
    const groupSpeedPos = calculateSpeedPos(groupSpeed, ticks);
    const ourSpeedPos = calculateSpeedPos(ourSpeed, ticks);
    let tickKey = 0;
    return (
        <Column>
            <div>{t("player.performance.label-speed-me")}</div>
            <svg
                clipRule="evenodd"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="1.5"
                viewBox="0 0 220 120"
                xmlns="http://www.w3.org/2000/svg"
                height="160"
                width="100%"
            >
                <defs>
                    <symbol id="speed-marker-path-group">
                        <path d="m8 31-8-15.5v-15.5l8 5.167 8-5.167v15.5z" fill={currentColors.performance} />
                    </symbol>
                    <symbol id="speed-marker-path-our">
                        <path d="m8 0 8 15.5v15.5l-8-5.167-8 5.167v-15.5z" fill={currentColors.performance} />
                    </symbol>
                </defs>
                <line y1="33" x1="9" y2="33" x2="205" stroke={currentColors.text} strokeWidth="3" />
                <line y1="88" x1="9" y2="88" x2="205" stroke={currentColors.text} strokeWidth="3" />
                <g fill="none" stroke={currentColors.text} strokeWidth="3">
                    {ticks.map((tick) => (
                        <Fragment key={tickKey++}>
                            <line x1={tick.loc} y1="33" x2={tick.loc} y2="43" />
                            <line x1={tick.loc} y1="78" x2={tick.loc} y2="88" />
                            <text
                                fontSize="16"
                                y="66"
                                x={tick.loc}
                                fill={currentColors.text}
                                stroke="none"
                                textAnchor="middle"
                            >
                                {tick.miles}
                            </text>
                        </Fragment>
                    ))}
                </g>
                <g fill="none" stroke={currentColors.text} strokeWidth="2.37">
                    {minorTicks.map((tick) => (
                        <Fragment key={tickKey++}>
                            <line x1={tick} y1="33" x2={tick} y2="38" />
                            <line x1={tick} y1="83" x2={tick} y2="88" />
                        </Fragment>
                    ))}
                </g>
                <use xlinkHref="#speed-marker-path-group" x={groupSpeedPos - 9} y="1" />
                <use xlinkHref="#speed-marker-path-our" x={ourSpeedPos - 9} y="89" />
            </svg>
            <div>{t("player.performance.label-speed-our")}</div>
        </Column>
    );
}

export default memo(HorizontalSpeedbar);
