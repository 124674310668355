import type { ComponentPropsWithoutRef } from "react";
import { currentColors } from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function ClockIcon({ active, ...props }: IProps) {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m71.2 27.6c1.3-.7 2.3-2.1 2.3-3.7 0-2.4-1.9-4.3-4.3-4.3-2.2 0-3.9 1.6-4.2 3.7-3.4-1.8-7.2-3-11.2-3.5.1-.3.1-.6.1-1 0-.5-.1-1-.3-1.4h7.7c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5h-22.7c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h8.3c-.2.4-.3.9-.3 1.4 0 .3 0 .6.1.9-4.2.4-8.2 1.7-11.8 3.6-.3-2.1-2.1-3.7-4.2-3.7-2.4 0-4.3 1.9-4.3 4.3 0 1.6.9 3 2.3 3.7-6.7 5.9-10.9 14.4-10.9 24 0 17.7 14.4 32.1 32.1 32.1s32.1-14.4 32.1-32.1c0-9.6-4.2-18.1-10.8-24z"
                fill={currentColors.text}
            />
            <path
                d="m49.9 80.7c-16 0-29.1-13-29.1-29.1s13-29.1 29.1-29.1 29.1 13.1 29.1 29.1-13 29.1-29.1 29.1z"
                fill={active ? currentColors.icon5 : "white"}
            />
            <path
                d="m65.2 64.8-9.7-9.7s-.1 0-.1-.1c.4-.7.6-1.6.6-2.5 0-2.7-1.8-4.9-4.3-5.6v-17.5c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v17.6c-2.5.7-4.3 2.9-4.3 5.6 0 3.2 2.6 5.8 5.8 5.8 1.2 0 2.4-.4 3.3-1l9.6 9.6c.3.3.7.4 1.1.4s.8-.1 1.1-.4c.5-.7.5-1.6-.1-2.2z"
                fill={currentColors.text}
            />
            <circle cx="50.2" cy="52.6" fill="white" r="2.8" />
        </svg>
    );
}

export default ClockIcon;
