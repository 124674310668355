import Linkify from "linkify-react";
import { type JSX, memo } from "react";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import { formatYMD, parseDate } from "../../shared/dateFns";
import type { ISentMessage } from "./query";

const MessageWrapper = styled.div`
    border: 1px solid ${currentColors.text};
    margin: 0 0 1px 0;
    border-radius: 0.5rem;
    padding: 0.5rem;
`;

const MessageHead = styled.div`
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
        "date"
        "subject";
    @container (min-width: 700px) {
        grid-template-columns: minmax(150px, auto) 1fr;
        grid-template-areas: "date subject";
    }
`;

const HeaderDate = styled.div`
    flex-basis: 100px;
    flex-grow: 1;
    padding: 4px;
`;

const HeaderSubject = styled.div`
    flex-basis: 400px;
    flex-grow: 1;
    padding: 4px;
`;

const Body = styled.div`
    margin: 0.5rem 0 0 0;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
`;

interface IProps {
    message: ISentMessage;
    open: boolean;
    toggleOpen: (id: string) => void;
}

function SentMessage({ message, open, toggleOpen }: IProps): JSX.Element {
    const clickHeader = () => {
        toggleOpen(message.id);
    };

    return (
        <MessageWrapper>
            <MessageHead onClick={clickHeader}>
                <HeaderDate>{formatYMD(parseDate(message.datetime))}</HeaderDate>
                <HeaderSubject>{message.subject}</HeaderSubject>
            </MessageHead>
            {open && (
                <Body>
                    <div>
                        <Linkify options={{ nl2br: true }}>{message.content}</Linkify>
                    </div>
                </Body>
            )}
        </MessageWrapper>
    );
}

export default memo(SentMessage);
