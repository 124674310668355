import type { ComponentPropsWithoutRef, JSX } from "react";
import { currentColors } from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function MirrorIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m84.1 38.4c0-.8-.7-1.5-1.5-1.5h-6.3c-.4-14.1-12-25.5-26.2-25.5-14.4 0-26.2 11.7-26.2 26.1v-.6h-6.3c-.8 0-1.5.7-1.5 1.5 0 18.3 14.5 33.2 32.6 34v13.2h-14.6c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h31.7c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5h-14.3v-13.2c18.1-.8 32.6-15.7 32.6-34zm-34.1 31.1c-16.6 0-30.2-13.1-31-29.6h4.8v-2.1c0 14.4 11.8 26.1 26.2 26.1 13.7 0 25-10.6 26.1-24h4.9c-.8 16.4-14.4 29.6-31 29.6z"
                fill={currentColors.text}
            />
            <path
                d="m50 14.4c12.8 0 23.2 10.4 23.2 23.2s-10.4 23.2-23.2 23.2-23.2-10.4-23.2-23.2 10.4-23.2 23.2-23.2z"
                fill={active ? currentColors.icon1 : "white"}
            />
            <path
                d="m64.2 39.2c-.8 0-1.5-.7-1.5-1.5 0-7.6-6.2-13.8-13.8-13.8-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5c9.3 0 16.8 7.5 16.8 16.8 0 .8-.7 1.5-1.5 1.5z"
                fill={currentColors.text}
            />
        </svg>
    );
}

export default MirrorIcon;
