import { type JSX, memo } from "react";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import t from "../../shared/translations";
import { SizedTaskIcon } from "../landing/stylings";
import type { IData } from "./query";
import { Current, IconHolder, TaskBox, TaskRow } from "./stylings";

type ICustomItem = IData["learningPath"]["customItems"][0];

interface IProps {
    path: IData["learningPath"];
    activeItem?: string;
}

const Wrapper = styled.div`
    margin-bottom: 1rem;
`;

type StatusReturn = [
    boolean, // complete
    string, // status text
    string, // status color
    string, // text color
    string, // border color
    number, // border radius
    boolean, // current
];

function itemStatus(item: ICustomItem, activeItem?: string): StatusReturn {
    let complete = false;
    let statusText = "";
    let statusColor = currentColors.taskFinished;
    let textColor = currentColors.text;
    let borderColor = currentColors.border;
    let radius = 0;
    const current = item.id === activeItem;
    if (item.complete) {
        complete = true;
        statusText = t("player.tasks-sidebar.status-accepted");
        textColor = currentColors.taskFinished;
        radius = 10;
    } else if (item.redo) {
        statusText = t("player.tasks-sidebar.status-redo");
        statusColor = currentColors.taskRedo;
        borderColor = currentColors.taskRedo;
    } else if (item.undecided) {
        statusText = t("player.tasks-sidebar.status-undecided");
    }
    return [complete, statusText, statusColor, textColor, borderColor, radius, current];
}

function CustomTasks({ path, activeItem }: IProps): JSX.Element {
    return (
        <Wrapper>
            {path.customItems.map((item) => {
                const [complete, statusText, statusColor, textColor, borderColor, radius, current] = itemStatus(
                    item,
                    activeItem,
                );
                return (
                    <TaskBox
                        to={item.url}
                        key={item.id}
                        $color={textColor}
                        $borderColor={borderColor}
                        $borderRadius={radius}
                    >
                        <TaskRow>
                            <IconHolder>
                                <SizedTaskIcon icon={item.icon} active={complete} $size="60px" />
                            </IconHolder>
                            <div style={{ flexGrow: 1 }}>
                                <div>{item.title}</div>
                                <div style={{ color: statusColor }}>{statusText}</div>
                                {item.complete && (
                                    <div>{t("player.tasks-sidebar.label-miles", { miles: item.earnedMiles })}</div>
                                )}
                            </div>
                            {current && <Current $borderRadius={radius} />}
                        </TaskRow>
                    </TaskBox>
                );
            })}
            <TaskBox
                to="/custom-task/insight"
                $color={currentColors.text}
                $borderColor={currentColors.grey4}
                $borderRadius={0}
            >
                <TaskRow>
                    <IconHolder>
                        <SizedTaskIcon icon={"bulb"} active={false} $size="60px" />
                    </IconHolder>
                    <div style={{ flexGrow: 1 }}>
                        <div>{t("player.tasks-sidebar.heading-customer-insight-short-title")}</div>
                    </div>
                    {activeItem === "insight" && <Current $borderRadius={0} />}
                </TaskRow>
            </TaskBox>
            <TaskBox
                to="/custom-task/course"
                $color={currentColors.text}
                $borderColor={currentColors.grey4}
                $borderRadius={0}
            >
                <TaskRow>
                    <IconHolder>
                        <SizedTaskIcon icon={"diploma"} active={false} $size="60px" />
                    </IconHolder>
                    <div style={{ flexGrow: 1 }}>
                        <div>{t("player.tasks-sidebar.heading-course-short-title")}</div>
                    </div>
                    {activeItem === "course" && <Current $borderRadius={0} />}
                </TaskRow>
            </TaskBox>
            <TaskBox
                to="/custom-task/custom"
                $color={currentColors.text}
                $borderColor={currentColors.grey4}
                $borderRadius={0}
            >
                <TaskRow>
                    <IconHolder>
                        <SizedTaskIcon icon={"puzzle-piece"} active={false} $size="60px" />
                    </IconHolder>
                    <div style={{ flexGrow: 1 }}>
                        <div>{t("player.tasks-sidebar.heading-custom-short-title")}</div>
                    </div>
                    {activeItem === "custom" && <Current $borderRadius={0} />}
                </TaskRow>
            </TaskBox>
        </Wrapper>
    );
}

export default memo(CustomTasks);
