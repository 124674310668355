import { type JSX, createContext, useCallback, useContext, useRef, useState } from "react";
import type { ReactNode } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { currentColors } from "../colors";
import t from "../translations";

export type IConfirmFunc = (message: string, confirmBtn?: string, cancelBtn?: string) => Promise<boolean>;

const ConfirmContext = createContext<IConfirmFunc>(null);
ConfirmContext.displayName = "ConfirmContext";

interface IConfirmMessages {
    message: string;
    confirmBtn: string;
    cancelBtn: string;
}

interface IConfirmDialogueProps {
    messages: IConfirmMessages;
    confirm: VoidFunction;
    cancel: VoidFunction;
}

const ButtonRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    @media (min-width: 400px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    button {
        margin-bottom: 0;
    }
`;

function ConfirmDialogue({ messages, confirm, cancel }: IConfirmDialogueProps): JSX.Element {
    return (
        <div>
            <h5>{messages.message}</h5>
            <ButtonRow>
                <button type="button" onClick={() => confirm()}>
                    {messages.confirmBtn}
                </button>
                <button type="button" onClick={() => cancel()}>
                    {messages.cancelBtn}
                </button>
            </ButtonRow>
        </div>
    );
}

const StyledModal = styled(Modal)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    max-width: 100%;
    overflow: auto;
    border-radius: 3px;
    padding: 1.5rem;
    border: solid 1px ${currentColors.grey4};
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
    background: white;
`;

const defaultConfirm = t("shared.confirm-dialogue.button-confirm");
const defaultCancel = t("shared.confirm-dialogue.button-cancel");

interface ConfirmProviderProps {
    children: ReactNode;
}
export function ConfirmProvider({ children }: ConfirmProviderProps): JSX.Element {
    const [messages, setMessages] = useState<IConfirmMessages | null>(null);
    const resolver = useRef<(value: boolean) => void>(null);

    const confirm = useCallback(() => {
        setMessages(null);
        resolver.current(true);
    }, []);

    const cancel = useCallback(() => {
        setMessages(null);
        resolver.current(false);
    }, []);

    const showConfirm: IConfirmFunc = useCallback((message, confirmBtn = defaultConfirm, cancelBtn = defaultCancel) => {
        setMessages({ message, confirmBtn, cancelBtn });
        return new Promise<boolean>((resolve) => {
            resolver.current = resolve;
        });
    }, []);

    return (
        <ConfirmContext.Provider value={showConfirm}>
            <StyledModal isOpen={messages != null} onRequestClose={() => cancel()}>
                {messages != null && <ConfirmDialogue messages={messages} confirm={confirm} cancel={cancel} />}
            </StyledModal>
            {children}
        </ConfirmContext.Provider>
    );
}

export function useConfirm(): IConfirmFunc {
    const func = useContext(ConfirmContext);
    if (func === null) {
        throw new Error("Missing ConfirmProvider");
    }
    return func;
}
