import type { JSX } from "react";
import styled from "styled-components";
import { currentColors } from "../colors";
import { Container, Grid, SliderLabel, Statement } from "./SliderInput";
import FatArrow from "./icons/FatArrow";

interface IProps {
    statement?: string;
    leftLabel: string;
    rightLabel: string;
    showValue?: boolean;
    value: number;
    precision?: number;
}

const Rail = styled.div`
    position: absolute;
    width: 100%;
    height: 4px;
    border: 2px solid ${currentColors.grey2};
`;

interface WithValue {
    $width: number;
}

const Track = styled.div<WithValue>`
    position: absolute;
    height: 4px;
    border: 2px solid ${currentColors.button};
    left: 0%;
    width: ${({ $width }) => $width}%;
`;

const Handle = styled.div<WithValue>`
    position: absolute;
    z-index: 1;
    border: solid 2px ${currentColors.button};
    border-radius: 50%;
    background-color: ${currentColors.button};
    opacity: 1;
    width: 28px;
    height: 28px;
    margin-top: -12px;
    color: white;
    text-align: center;
    user-select: none;
    transform: translateX(-50%);
    left: ${({ $width }) => $width}%;
    @media print {
        color: ${currentColors.button};
    }
`;

const SliderBox = styled.div`
    position: relative;
    grid-area: slider;
    height: 14px;
    padding: 10px 0;
`;

interface ISliderArrow {
    $dir: "left" | "right";
    $pointer?: boolean;
}

const SliderArrowBox = styled.div<ISliderArrow>`
    cursor: ${({ $pointer }) => ($pointer ? "pointer" : "auto")};
    position: absolute;
    top: -3px;
    left: ${({ $dir }) => ($dir === "left" ? "-13px" : "auto")};
    right: ${({ $dir }) => ($dir === "left" ? "auto" : "-13px")};
    width: 30px;
    height: 30px;
    svg {
        width: 30px;
        height: 30px;
        path {
            stroke: ${currentColors.button};
        }
    }
`;

function SliderPreview({ statement, leftLabel, rightLabel, showValue, value, precision = 0 }: IProps): JSX.Element {
    return (
        <Container>
            <Grid>
                {statement && <Statement>{statement}</Statement>}
                <SliderBox>
                    <Rail />
                    <Track $width={value * 10} />
                    <SliderArrowBox $dir="left">
                        <FatArrow dir="left" />
                    </SliderArrowBox>
                    <SliderArrowBox $dir="right">
                        <FatArrow dir="right" />
                    </SliderArrowBox>
                    <Handle $width={value * 10}>{showValue && value.toFixed(precision)}</Handle>
                </SliderBox>
                <SliderLabel>{leftLabel}</SliderLabel>
                <SliderLabel $right>{rightLabel}</SliderLabel>
            </Grid>
        </Container>
    );
}

export default SliderPreview;
