import styled from "styled-components";
import { currentColors } from "../../shared/colors";

const MEDIUM_MEDIA = "(min-width: 641px)";
const LARGE_MEDIA = "(min-width: 800px)";

interface IFrame {
    $borderWidth?: string;
    $skipPadding?: boolean;
}

export const Frame = styled.section<IFrame>`
    border: ${({ $borderWidth }) => $borderWidth ?? "1px"} solid ${currentColors.text};
    padding: ${({ $skipPadding }) => ($skipPadding ? "0" : "1rem")};
    gap: 1rem;
    max-width: 1100px;
    margin: 1rem auto;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    border-radius: 1rem;
    @media (${MEDIUM_MEDIA}) {
        margin: 2rem auto;
    }
`;

interface IBox {
    $align?: "center" | "left";
    $justify?: "stretch" | "center" | "start";
    $span?: [number, number, number];
}

function getSpan(cols: [number, number, number] | undefined, media: "small" | "medium" | "large"): string {
    if (cols === undefined) {
        return "span 12";
    }
    if (media === "small") {
        return `span ${cols[0]}`;
    }
    if (media === "medium") {
        return `span ${cols[1]}`;
    }
    return `span ${cols[2]}`;
}

export const Box = styled.div<IBox>`
    justify-self: ${({ $justify }) => $justify ?? "stretch"};
    text-align: ${({ $align }) => $align ?? "center"};
    grid-column: ${({ $span }) => getSpan($span, "small")};
    @media (${MEDIUM_MEDIA}) {
        grid-column: ${({ $span }) => getSpan($span, "medium")};
    }
    @media (${LARGE_MEDIA}) {
        grid-column: ${({ $span }) => getSpan($span, "large")};
    }
`;

export const BorderBox = styled(Box)`
    border: 1px solid ${currentColors.text};
    border-radius: 1rem;
    padding: 0.5rem;
    gap: 1rem;
    padding-bottom: 1.5rem;
    display: grid;
    grid-template-rows: auto 1fr;

    > h3 {
        min-width: 0;
        overflow-wrap: break-word;
    }
`;

export const StatsText = styled.div`
    font-size: 1.6rem;
    text-align: center;
    place-self: center;
`;

export const StatsLabel = styled.div`
    font-size: 1rem;
    text-align: center;
    place-self: center;
`;
