import { type ComponentPropsWithoutRef, type JSX, memo } from "react";
import styled from "styled-components";
import { currentColors } from "../../../shared/colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    status: "unapproved" | "approved" | "submitted";
}
const Svg = styled.svg`
    margin-top: 0.4rem;
    width: 55px;
    height: 55px;

    circle {
        box-shadow: 5px 10px;
    }
`;

function ApprovCircle({ status, ...props }: IProps): JSX.Element {
    return (
        <Svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill={status === "submitted" ? currentColors.taskApprove : "white"} />
            {status !== "unapproved" && (
                <path
                    fill={status === "submitted" ? "white" : currentColors.taskApprove}
                    strokeLinejoin="round"
                    d="M8.763,57.059 c6.936,7.753,22.442,25.706,26.318,30.297c12.241-26.113,35.192-58.553,56.614-77.117C64.562,27.681,49.276,44.352,30.797,69.096 C26.303,66.641,21.713,63.57,8.763,57.059z"
                />
            )}
        </Svg>
    );
}

export default memo(ApprovCircle);
