import { type FormEvent, type JSX, type ReactNode, memo, useState } from "react";
import styled from "styled-components";
import { currentColors } from "../colors";
import getInt from "../getInt";
import t from "../translations";
import type { TaskIcon as TaskIconType, TimeEstimateType, VariantType } from "../types";
import FlexVideo from "./FlexVideo";
import PreviewAnswers, { type ITaskQuestion } from "./PreviewAnswers";
import RenderMark from "./RenderMark";
import TaskInfoCol from "./TaskInfoCol";

interface IMedia {
    id: string | number;
    mediaType: string;
    src: string;
    name: string;
    logo?: string | null;
}

type IStatus = "redo" | "deadline" | "finished" | null;

interface ITaskVariant<Q extends ITaskQuestion> {
    variantType: VariantType;
    variantTitle: string;
    description: string | null;
    hoursQuestions: string | null;
    eventStart: string | null;
    eventEnd: string | null;
    taskQuestions: Q[];
}

interface IPropsBase {
    shortTitle?: string;
    objectives: string | null;
    inspiration: {
        mediaType: string;
        src: string;
    } | null;
    title: string | null;
    description?: string;
    icon: TaskIconType;
    miles: number | null;
    status?: IStatus;
    deadline?: string;
    timeEstimate?: TimeEstimateType | null;
    finishDatetime?: string;
    media: IMedia[];
    eventStart?: string;
    eventEnd?: string;
    submitters?: string;
    children?: ReactNode;
    invert: boolean;
}

interface IPropsPreviewAnswerTrue<Q extends ITaskQuestion> extends IPropsBase {
    onVariantSelect?: (variant: ITaskVariant<Q>) => void;
    previewAnswer: true;
    taskVariants: ITaskVariant<Q>[];
    submittedVariant?: ITaskVariant<Q>;
}

interface IPropsPreviewAnswerFalse extends IPropsBase {
    onVariantSelect?: (variant: ITaskVariant<any>) => void;
    previewAnswer?: false;
    taskVariants: ITaskVariant<any>[];
    submittedVariant?: ITaskVariant<any>;
}

type IProps<Q extends ITaskQuestion = ITaskQuestion> = IPropsPreviewAnswerTrue<Q> | IPropsPreviewAnswerFalse;

const backgroupColor = currentColors.background;
const invertBackgroundColor = currentColors.pipBackground;
const borderColor = `1px solid ${currentColors.border}`;
const invertBorderColor = "none";
const textColor = currentColors.text;
const invertTextColor = "white";
const descBorderColor = currentColors.border;

const Wrapper = styled.div`
    margin: 0 auto;
    margin-bottom: 2rem;
    container-type: inline-size;
`;

interface Invertible {
    $invert?: boolean;
}

const ObjectivesSection = styled.section<Invertible>`
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    border-radius: 1rem;
    background-color: ${({ $invert }) => ($invert ? invertBackgroundColor : backgroupColor)};
    border: ${({ $invert }) => ($invert ? invertBorderColor : borderColor)};
    margin-bottom: 0.5rem;
    padding: 0.6rem;
    color: ${({ $invert }) => ($invert ? invertTextColor : textColor)};

    h1, h2, h3, h4, h5, h6 {
        color: ${({ $invert }) => ($invert ? invertTextColor : textColor)};
    }

    @container (min-width: 640px) {
        grid-template-columns: 1fr calc(270px - 1rem);
        margin-bottom: 1rem;
        padding: 1rem;
        gap: 1rem;
    }
    @container (min-width: 1000px) {
        grid-template-columns: 1fr calc(320px - 1rem);
    }
`;

const Objectives = styled.div``;

const Inspiration = styled.div``;

const MainRow = styled.div`
    display: grid;
    margin-bottom: 0.5rem;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    grid-template-areas: 
        "description"
        "info";

    @container (min-width: 640px) {
        grid-template-columns: 1fr 270px;
        margin-bottom: 1rem;
        gap: 1rem;
        grid-template-areas: 
            "description info";
    }
    @container (min-width: 1000px) {
        grid-template-columns: 1fr 320px;
    }
`;

const VariantNav = styled.nav`
    margin: 0.5rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    > * {
        margin: 0;
    }
`;

const Select = styled.select`
    display: inline-block;
    background-color: white;
    border: 1px solid ${currentColors.border};
    color: ${currentColors.text};
    margin: 0;
    font-size: 0.9rem;
    padding: 0.25rem 0.85rem;
    transition: none;
    width: auto;

    &:focus {
        background-color: white;
        border: 1px solid ${currentColors.border};
    }
`;

const DescriptionSection = styled.section`
    grid-area: description;
    border-radius: 1rem;
    border: 1px solid ${descBorderColor};
    padding: 0.6rem;

    @container (min-width: 640px) {
        padding: 1rem;
    }
`;

function TaskDescription<Q extends ITaskQuestion>({
    shortTitle,
    submitters,
    objectives,
    inspiration,
    title,
    description,
    icon,
    miles,
    status,
    deadline,
    timeEstimate,
    finishDatetime,
    media,
    eventStart,
    eventEnd,
    taskVariants,
    submittedVariant,
    onVariantSelect,
    previewAnswer,
    children,
    invert,
}: IProps<Q>): JSX.Element {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const switchVariant = (event: FormEvent<HTMLSelectElement>) => {
        const i = getInt(event.currentTarget.value);
        setSelectedIndex(i);
        if (onVariantSelect) {
            onVariantSelect(taskVariants[i]);
        }
    };

    const shownVariant = submittedVariant ?? taskVariants[selectedIndex];
    const showQuestions =
        !!previewAnswer && shownVariant?.variantType !== "unsubmittable" && shownVariant?.taskQuestions?.length > 0;
    return (
        <Wrapper>
            {shortTitle && <h1>{shortTitle}</h1>}
            {children}
            {submitters && <p>{submitters}</p>}
            {(objectives || inspiration) && (
                <ObjectivesSection $invert={invert}>
                    <Objectives>{objectives && <RenderMark content={objectives} />}</Objectives>
                    <Inspiration>
                        {inspiration &&
                            (inspiration.mediaType === "YouTube" || inspiration.mediaType === "Vimeo" ? (
                                <FlexVideo>
                                    <iframe
                                        title="Inspiration video"
                                        width="270"
                                        height="152"
                                        src={inspiration.src}
                                        frameBorder="0"
                                        allowFullScreen
                                    />
                                </FlexVideo>
                            ) : (
                                <img src={inspiration.src} />
                            ))}
                    </Inspiration>
                </ObjectivesSection>
            )}
            <MainRow>
                <DescriptionSection>
                    {title && <h3>{title}</h3>}
                    {!submittedVariant && taskVariants.length > 1 && (
                        <VariantNav>
                            <div>{t("shared.task-description.label-select-variant")}</div>
                            <Select onChange={switchVariant} value={selectedIndex}>
                                {taskVariants.map((variant, i) => (
                                    <option value={i} key={i}>
                                        {variant.variantTitle ?? i + 1}
                                    </option>
                                ))}
                            </Select>
                        </VariantNav>
                    )}
                    {description && <RenderMark content={description} />}
                    {shownVariant?.description && <RenderMark content={shownVariant.description} />}
                    {shownVariant?.hoursQuestions && <RenderMark content={shownVariant.hoursQuestions} />}
                </DescriptionSection>
                <TaskInfoCol
                    icon={icon}
                    miles={miles}
                    status={status}
                    deadline={deadline}
                    finishDatetime={finishDatetime}
                    media={media}
                    eventStart={eventStart}
                    eventEnd={eventEnd}
                    invert={invert}
                    variant={shownVariant}
                    timeEstimate={timeEstimate}
                />
            </MainRow>
            {previewAnswer && showQuestions && <PreviewAnswers taskQuestions={shownVariant.taskQuestions} />}
        </Wrapper>
    );
}

export default memo(TaskDescription);
