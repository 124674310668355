import type { ComponentPropsWithoutRef, JSX } from "react";
import { currentColors } from "../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
    thin?: boolean;
}

function TrophyIcon({ active, thin, ...props }: IProps): JSX.Element {
    return (
        <svg
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinecap="square"
            strokeLinejoin="round"
            strokeMiterlimit="1.5"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g stroke={currentColors.text} strokeWidth={thin ? "2" : "3"}>
                <path
                    d="m79 6s-3.409 42.191-29 42c-25.591.191-29-42-29-42z"
                    stroke={currentColors.text}
                    fill={active ? currentColors.rating : "white"}
                />
                <path
                    d="m80 77.5v13.5h-60v-13.5c0-2.484 2.016-4.5 4.5-4.5h51c2.484 0 4.5 2.016 4.5 4.5z"
                    stroke={currentColors.text}
                    fill={active ? currentColors.text : "white"}
                />
                <path
                    d="m32.672 71.482c8.011-1.133 11.317-23.481 11.317-23.481s4.01.525 6.011.525c2.002 0 6-.525 6-.525s3.318 22.347 11.328 23.481c-5.223-.001-17.328.043-17.328.043s-12.105-.044-17.328-.043z"
                    stroke={currentColors.text}
                    fill={active ? currentColors.rating : "white"}
                />
                <path
                    d="m22.632 15.217c-1.775-5.475-17.549-5.793-16.332 5.158 1.56 14.039 17.769 19.659 26.672 23 4.006 1.502 4.272 10.237-2.965 9.167"
                    fill="none"
                    strokeLinecap="round"
                />
                <path
                    d="m77.368 15.217c1.775-5.475 17.549-5.793 16.332 5.158-1.56 14.039-17.769 19.659-26.672 23-4.006 1.502-4.272 10.237 2.965 9.167"
                    fill="none"
                    strokeLinecap="round"
                />
            </g>
        </svg>
    );
}

export default TrophyIcon;
