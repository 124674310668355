import type { ComponentPropsWithoutRef, JSX } from "react";
import { currentColors } from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function StairsIcon({ active, ...props }: IProps): JSX.Element {
    return (
        <svg
            {...props}
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m26.25 76.75v2.5h-12.5v-2.5zm-7.5-5h12.5v2.5h-12.5zm15-15h12.5v2.5h-12.5zm10-10h12.5v2.5h-12.5zm5-5h12.5v2.5h-12.5zm-10 10h12.5v2.5h-12.5zm20-20h12.5v2.5h-12.5zm-35 35h12.5v2.5h-12.5zm5-5h12.5v2.5h-12.5zm35-35h12.5v2.5h-12.5zm17.5-6v3.5h-12.5v-3.5zm-27.5 16h12.5v2.5h-12.5z"
                fill="white"
            />
            <path
                d="m86.25 20.75v58.5h-57.5v-2.5h3.75c.69 0 1.25-.56 1.25-1.25v-3.75h3.75c.69 0 1.25-.56 1.25-1.25v-3.75h3.75c.69 0 1.25-.56 1.25-1.25v-3.75h3.75c.69 0 1.25-.56 1.25-1.25v-3.75h3.75c.69 0 1.25-.56 1.25-1.25v-3.75h3.75c.69 0 1.25-.56 1.25-1.25v-3.75h3.75c.69 0 1.25-.56 1.25-1.25v-3.75h3.75c.69 0 1.25-.56 1.25-1.25v-3.75h3.75c.69 0 1.25-.56 1.25-1.25v-3.75h3.75c.69 0 1.25-.56 1.25-1.25v-3.75h3.75c.69 0 1.25-.56 1.25-1.25v-4.75z"
                fill={active ? currentColors.icon3 : "white"}
            />
            <path
                d="m11.25 75.5c0-.69.56-1.25 1.25-1.25h3.75v-3.75c0-.69.56-1.25 1.25-1.25h3.75v-3.75c0-.69.56-1.25 1.25-1.25h3.75v-3.75c0-.69.56-1.25 1.25-1.25h3.75v-3.75c0-.69.56-1.25 1.25-1.25h3.75v-3.75c0-.69.56-1.25 1.25-1.25h3.75v-3.75c0-.69.56-1.25 1.25-1.25h3.75v-3.75c0-.69.56-1.25 1.25-1.25h3.75v-3.75c0-.69.56-1.25 1.25-1.25h3.75v-3.75c0-.69.56-1.25 1.25-1.25h3.75v-3.75c0-.69.56-1.25 1.25-1.25h3.75v-4.75c0-.69.56-1.25 1.25-1.25h20c.69 0 1.25.56 1.25 1.25 0 .178-.037.347-.104.5.067.153.104.322.104.5v60c0 .69-.56 1.25-1.25 1.25h-75c-.69 0-1.25-.56-1.25-1.25zm15 1.25h-12.5v2.5h12.5zm-7.5-5v2.5h12.5v-2.5zm5-5v2.5h12.5v-2.5zm5-5v2.5h12.5v-2.5zm5-5v2.5h12.5v-2.5zm5-5v2.5h12.5v-2.5zm5-5v2.5h12.5v-2.5zm5-5v2.5h12.5v-2.5zm5-5v2.5h12.5v-2.5zm5-5v2.5h12.5v-2.5zm5-5v2.5h12.5v-2.5zm17.5-6c-5.453 0-12.5 0-12.5 0v3.5h12.5zm5 0c-.645 0-1.505 0-2.5 0v4.75c0 .69-.56 1.25-1.25 1.25h-3.75v3.75c0 .69-.56 1.25-1.25 1.25h-3.75v3.75c0 .69-.56 1.25-1.25 1.25h-3.75v3.75c0 .69-.56 1.25-1.25 1.25h-3.75v3.75c0 .69-.56 1.25-1.25 1.25h-3.75v3.75c0 .69-.56 1.25-1.25 1.25h-3.75v3.75c0 .69-.56 1.25-1.25 1.25h-3.75v3.75c0 .69-.56 1.25-1.25 1.25h-3.75v3.75c0 .69-.56 1.25-1.25 1.25h-3.75v3.75c0 .69-.56 1.25-1.25 1.25h-3.75v3.75c0 .69-.56 1.25-1.25 1.25h-3.75v2.5h57.5z"
                fill={currentColors.text}
            />
        </svg>
    );
}

export default StairsIcon;
