import type { JSX } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import t from "../../shared/translations";
import PasswordForm from "./PasswordForm";

const Wrapper = styled.main`
    padding: 0 15px;
    flex-grow: 1;
    max-width: 1030px;
    width: 100%;
`;

function ReplacePassword(): JSX.Element {
    const [searchParams] = useSearchParams();
    const next = searchParams.get("next") || "/select-path";

    return (
        <Wrapper>
            <h1 className="small-only-text-center">{t("player.replace-password.heading")}</h1>
            <p>{t("player.replace-password.info-replace-password")}</p>
            <PasswordForm next={next} />
        </Wrapper>
    );
}

export default ReplacePassword;
