import { Fragment, type JSX, memo } from "react";
import styled from "styled-components";
import { currentColors } from "../colors";
import t from "../translations";
import type { PollChartType } from "../types";
import PollBarChart from "./PollBarChart";
import PollPieChart from "./PollPieChart";

interface IPollAnswer {
    id: string;
    answers: number[];
    question: {
        number: number;
        content: string;
        chartType: PollChartType;
        options: {
            id: string;
            number: number;
            content: string;
            answerCount: number;
        }[];
    };
}

interface IProps {
    answers: IPollAnswer[];
}

const Container = styled.div`
    border-radius: 1rem;
    border: 3px solid ${currentColors.border};
    position: relative;
    padding: 0.6rem;
    margin-bottom: 1rem;
    @media (min-width: 768px) {
        padding: 1rem;
    }
`;

const H2 = styled.h2`
    font-size: 1.3rem;
    margin: 0;
    margin-bottom: 1rem;
    @media (min-width: 768px) {
        font-size: 1.45rem;
    }
`;

function PollResults({ answers }: IProps): JSX.Element {
    return (
        <Fragment>
            {answers.map((answer) => {
                const { content, number, chartType, options } = answer.question;
                return (
                    <Container key={answer.id}>
                        <H2>
                            {t("shared.poll-results.question-number", { nr: number })}
                            {": "}
                            {content}
                        </H2>
                        {chartType === "bar" && <PollBarChart answers={answer.answers} options={options} />}
                        {chartType === "pie" && <PollPieChart answers={answer.answers} options={options} />}
                    </Container>
                );
            })}
        </Fragment>
    );
}

export default memo(PollResults);
