import { memo } from "react";
import type { JSX, ReactNode } from "react";

interface IProps {
    unread?: ReactNode;
}

function MessagesIcon({ unread }: IProps): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="nav-icon">
            <path d="M80.5,63.1c-0.3-0.1-7-3.6-7-14.5c0-12.6-7.4-23.2-17.5-26.3c1-1.3,1.6-2.9,1.6-4.6c0-4.2-3.4-7.7-7.7-7.7 s-7.7,3.4-7.7,7.7c0,1.7,0.6,3.3,1.6,4.6c-10,3.1-17.5,13.7-17.5,26.3c0,10.5-6.8,14.3-7.1,14.4c-1.9,1-3.3,3.4-3.3,5.5v4.8 c0,2.4,1.9,4.3,4.3,4.3h20.2c-0.1,0.2-0.2,0.5-0.2,0.7c0,5.3,4.3,9.6,9.6,9.6s9.6-4.3,9.6-9.6c0-0.2,0-0.5,0-0.7h20.1 c2.4,0,4.3-1.9,4.3-4.3v-4.8C83.9,66.4,82.4,64,80.5,63.1z M46.6,21c-0.9-0.9-1.4-2.1-1.4-3.3c0-2.6,2.1-4.7,4.7-4.7 s4.7,2.1,4.7,4.7c0,1.4-0.6,2.7-1.7,3.6c-0.1,0.1-0.2,0.2-0.2,0.2c-0.9-0.1-1.8-0.2-2.7-0.2c-1,0-2,0.1-3,0.2 C46.9,21.4,46.8,21.2,46.6,21z M56.5,78.4c0,3.6-3,6.6-6.6,6.6s-6.6-3-6.6-6.6c0-0.3-0.1-0.5-0.2-0.7h13.3 C56.5,77.9,56.5,78.2,56.5,78.4z M80.9,73.4c0,0.7-0.6,1.3-1.3,1.3H20.3c-0.7,0-1.3-0.6-1.3-1.3v-4.8c0-1,0.8-2.4,1.7-2.8 c0.4-0.2,8.7-4.7,8.7-17.1c0-13.4,9.2-24.3,20.6-24.3s20.6,10.9,20.6,24.3c0,12.8,8.3,17,8.7,17.2c0.9,0.4,1.7,1.7,1.7,2.7V73.4z" />
            {unread}
        </svg>
    );
}

export default memo(MessagesIcon);
