import Linkify from "linkify-react";
import type { JSX } from "react";
import styled from "styled-components";
import { currentColors } from "../../../shared/colors";
import LikertPreview from "../../../shared/components/LikertPreview";
import SliderPreview from "../../../shared/components/SliderPreview";
import t from "../../../shared/translations";
import type { IPollAnswerSet, IQuizAnswerSet, ISharedAnswer } from "../query";
import { Box, CheckboxAnswer, FileLogo, TextboxAnswer } from "./stylings";

interface IProps {
    sharedAnswers: ISharedAnswer[];
    openQuizResults?: (quizAnswerSet: IQuizAnswerSet) => void;
    openPollResults?: (pollAnswerSet: IPollAnswerSet) => void;
}

const Hr = styled.hr`
    border-color: ${currentColors.border};
`;

// Supported answer types: textbox, checkbox, quiz, poll, file

function fmtPeople(sharedAnswer: ISharedAnswer): string {
    if (sharedAnswer.people !== null) {
        return sharedAnswer.people.map((person) => `${person.firstName} ${person.lastName}`).join(", ");
    }
    return "";
}

function fmtSubmitter(sharedAnswer: ISharedAnswer): string {
    if (sharedAnswer.taskGroups !== null) {
        return sharedAnswer.taskGroups.join(", ");
    }
    if (sharedAnswer.pathGroups !== null) {
        return sharedAnswer.pathGroups.join(", ");
    }
    return fmtPeople(sharedAnswer);
}

function fmtSubSubmitters(sharedAnswer: ISharedAnswer): string {
    if (sharedAnswer.taskGroups !== null || sharedAnswer.pathGroups !== null) {
        return fmtPeople(sharedAnswer);
    }
    return "";
}

function UngroupedAnswers({ sharedAnswers, openQuizResults, openPollResults }: IProps): JSX.Element {
    return (
        <>
            {sharedAnswers.map((sharedAnswer) => {
                const count = sharedAnswer.taskAnswers.length;
                const submitter = fmtSubmitter(sharedAnswer);
                const subSubmitters = fmtSubSubmitters(sharedAnswer);
                return (
                    <Box key={sharedAnswer.id} $read={sharedAnswer.read}>
                        {submitter && (
                            <h4>
                                {t("player.submitted-task.heading-all-answers-submitted-answer", { submitter, count })}
                            </h4>
                        )}
                        {subSubmitters && <p>{subSubmitters}</p>}
                        {sharedAnswer.taskAnswers.map((answer, index) => {
                            const last = index + 1 === sharedAnswer.taskAnswers.length;
                            if (answer.answerType === "textbox") {
                                return (
                                    <div key={answer.id}>
                                        {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                        <TextboxAnswer>
                                            <Linkify options={{ nl2br: true }} tagName="p">
                                                {answer.answerContent}
                                            </Linkify>
                                        </TextboxAnswer>
                                        {!last && <Hr />}
                                    </div>
                                );
                            }
                            if (answer.answerType === "checkbox") {
                                return (
                                    <div key={answer.id}>
                                        {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                        {answer.checkboxAnswers.map((ans) => (
                                            <CheckboxAnswer $checked={ans.checked} key={ans.id}>
                                                <input type="checkbox" checked={ans.checked} disabled /> {ans.content}
                                            </CheckboxAnswer>
                                        ))}
                                        {!last && <Hr />}
                                    </div>
                                );
                            }
                            if (answer.answerType === "likert") {
                                return (
                                    <div key={answer.id}>
                                        {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                        {answer.taskLikertAnswers.map((ans) => (
                                            <LikertPreview
                                                key={ans.id}
                                                statement={ans.statement}
                                                leftLabel={ans.leftLabel}
                                                rightLabel={ans.rightLabel}
                                                selected={ans.value}
                                            />
                                        ))}
                                        {!last && <Hr />}
                                    </div>
                                );
                            }
                            if (answer.answerType === "impact-tracker") {
                                return (
                                    <div key={answer.id}>
                                        {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                        {answer.impactTrackerAnswerSet.impactLikertAnswers.map((ans) => (
                                            <SliderPreview
                                                key={ans.id}
                                                statement={ans.statement}
                                                leftLabel={ans.leftLabel}
                                                rightLabel={ans.rightLabel}
                                                value={ans.value}
                                                showValue
                                            />
                                        ))}
                                        {!last && <Hr />}
                                    </div>
                                );
                            }
                            if (answer.answerType === "quiz") {
                                const [correct, total] = answer.quizAnswerSet?.results ?? [0, 0];
                                return (
                                    <div key={answer.id}>
                                        {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                        <span>
                                            {t("player.submitted-task.info-all-answers-quiz-result", {
                                                correct,
                                                total,
                                            })}
                                        </span>
                                        {!!openQuizResults && (
                                            <>
                                                {" "}
                                                <button
                                                    onClick={() => openQuizResults(answer.quizAnswerSet)}
                                                    type="button"
                                                >
                                                    {t("player.submitted-task.button-quiz-results")}
                                                </button>
                                            </>
                                        )}
                                        {!last && <Hr />}
                                    </div>
                                );
                            }
                            if (answer.answerType === "poll") {
                                return (
                                    <div key={answer.id}>
                                        {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                        <span>{t("player.submitted-task.info-all-answers-poll")}</span>
                                        {!!openPollResults && answer.showPollResults && (
                                            <>
                                                {" "}
                                                <button
                                                    onClick={() => openPollResults(answer.pollAnswerSet)}
                                                    type="button"
                                                >
                                                    {t("player.submitted-task.button-poll-results")}
                                                </button>
                                            </>
                                        )}
                                        {!last && <Hr />}
                                    </div>
                                );
                            }
                            if (answer.answerType === "file") {
                                const taskAnswerFile = answer.taskAnswerFile;
                                return (
                                    <div key={answer.id}>
                                        {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                        {taskAnswerFile.src ? (
                                            <a href={taskAnswerFile.src} target="_blank" rel="noreferrer">
                                                {taskAnswerFile.logoSrc && <FileLogo src={taskAnswerFile.logoSrc} />}{" "}
                                                {taskAnswerFile.name}
                                            </a>
                                        ) : (
                                            <span>
                                                {taskAnswerFile.logoSrc && <FileLogo src={taskAnswerFile.logoSrc} />}{" "}
                                                {taskAnswerFile.name}
                                            </span>
                                        )}
                                        {!last && <Hr />}
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </Box>
                );
            })}
        </>
    );
}

export default UngroupedAnswers;
