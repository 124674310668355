import { sortBy } from "lodash-es";
import { type JSX, memo } from "react";
import { useResizeDetector } from "react-resize-detector";
import { CartesianGrid, ReferenceArea, Scatter, ScatterChart, XAxis, YAxis } from "recharts";
import styled from "styled-components";
import { currentColors } from "../../../shared/colors";

interface IProps {
    mean: number;
    memberMeans: number[];
    printing?: boolean;
    labelText: string;
}

type ShapeProps = {
    cx: number;
    cy: number;
};

function Shape({ cx, cy }: ShapeProps): JSX.Element {
    const width = 30;
    const height = 30;
    const x = cx - width / 2;
    const y = cy - height / 2;
    return <use href="#mean-chart-dude-icon" x={x} y={y} width={width} height={height} />;
}

function MeanShape({ cx, cy }: ShapeProps): JSX.Element {
    const width = 38;
    const height = 38;
    const x = cx - width / 2;
    const y = cy - height / 2;
    return <use href="#mean-chart-group-icon" x={x} y={y} width={width} height={height} />;
}

const Label = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    svg {
        margin-right: 0.3rem;
    }
`;

function MeanChart({ mean, memberMeans, labelText }: IProps): JSX.Element {
    let { width, ref } = useResizeDetector();
    const neededSpace = 600;
    const vertical = neededSpace > width;
    const data = sortBy(memberMeans).map((m, i) => ({
        idx: Math.sin(i) + (vertical ? 2.0 : 2.3),
        value: m * 100,
    }));
    const meanData = [{ value: mean * 100, idx: vertical ? 0.5 : 0.6 }];
    const height = vertical ? neededSpace : 260;
    width = vertical ? Math.min(340, width) : width;
    const margin = vertical
        ? { top: -20, right: 30, left: -10, bottom: 10 }
        : { top: 15, right: 35, left: -30, bottom: -5 };
    const label = (
        <Label>
            <svg width="30" height="30">
                <title>Group icon</title>
                <use href="#mean-chart-group-icon" x={0} y={0} width={30} height={30} />
            </svg>
            <span>
                {(mean * 100).toFixed(0)}% {labelText}
            </span>
        </Label>
    );
    return (
        <div ref={ref}>
            {vertical && label}
            <ScatterChart margin={margin} width={width} height={height}>
                <defs>
                    <symbol id="mean-chart-dude-icon" viewBox="0 0 100 100">
                        <path
                            d="M65.2,55c4.3-4.8,7.1-11.6,7.1-19.1c0-14.5-10-26.2-22.3-26.2c-12.3,0-22.4,11.8-22.4,26.2 c0,7.5,2.7,14.3,7.1,19.1c-14.8,4.7-24.5,15.7-24.5,28c0,0.6,0,1.2,0.1,1.9l0.1,1.4h79.2l0.1-1.4c0.1-0.7,0.1-1.3,0.1-1.9 C89.7,70.8,79.9,59.7,65.2,55z"
                            opacity={0.3}
                        />
                    </symbol>
                    <symbol id="mean-chart-group-icon" viewBox="0 0 100 100">
                        <circle cx="50" cy="50" fill={currentColors.teamScan} r="48" />
                        <g fill="white" fillRule="nonzero">
                            <path d="m36.535 40.4h26.93l.125-1.745v-.749c0-3.864-2.619-7.355-6.733-9.225.873-1.497 1.372-3.242 1.372-5.237 0-5.236-3.741-9.475-8.229-9.475s-8.229 4.239-8.229 9.475c0 1.87.499 3.74 1.372 5.237-4.114 1.87-6.733 5.361-6.733 9.225v.749zm13.465-22.566c2.494 0 4.488 2.618 4.488 5.735s-1.994 5.735-4.488 5.735-4.488-2.618-4.488-5.735 1.994-5.735 4.488-5.735zm-4.114 13.839c1.246.872 2.618 1.246 4.114 1.246s2.992-.498 4.239-1.371c2.867.997 4.862 2.868 5.486 4.987h-19.45c.624-1.995 2.619-3.865 5.611-4.862z" />
                            <path d="m56.857 74.187c.873-1.496 1.372-3.242 1.372-5.236 0-5.237-3.741-9.476-8.229-9.476s-8.229 4.239-8.229 9.476c0 1.87.499 3.74 1.372 5.236-4.114 1.87-6.733 5.361-6.733 9.226v.748l.125 1.745h26.93l.125-1.745v-.748c0-3.74-2.619-7.231-6.733-9.226zm-6.857-10.847c2.494 0 4.488 2.618 4.488 5.735s-1.994 5.735-4.488 5.735-4.488-2.618-4.488-5.735 1.994-5.735 4.488-5.735zm-9.725 18.826c.624-2.119 2.619-3.989 5.486-4.987 1.247.873 2.618 1.247 4.114 1.247s2.993-.499 4.239-1.371c2.868.997 4.863 2.867 5.486 4.987h-19.325z" />
                            <path d="m36.41 60.722c0-3.865-2.618-7.356-6.732-9.226.873-1.496 1.371-3.241 1.371-5.236 0-5.237-3.74-9.476-8.228-9.476-4.489 0-8.353 4.239-8.353 9.476 0 1.87.498 3.74 1.371 5.236-4.114 1.87-6.733 5.361-6.733 9.226v.748l.125 1.746h26.93l.125-1.746c.124-.249.124-.499.124-.748zm-13.589-20.197c2.493 0 4.488 2.618 4.488 5.735s-1.995 5.735-4.488 5.735c-2.494 0-4.613-2.494-4.613-5.735 0-3.242 2.119-5.735 4.613-5.735zm-9.725 18.95c.623-2.119 2.618-3.989 5.486-4.987 1.247.873 2.618 1.247 4.114 1.247s2.992-.499 4.239-1.371c2.868.997 4.862 2.867 5.486 4.987h-19.325z" />
                            <path d="m84.161 51.496c.873-1.496 1.371-3.241 1.371-5.236 0-5.237-3.74-9.476-8.228-9.476s-8.229 4.239-8.229 9.476c0 1.87.499 3.74 1.372 5.236-4.115 1.87-6.733 5.361-6.733 9.226v.748l.125 1.746h26.93l.125-1.746v-.748c0-3.74-2.619-7.356-6.733-9.226zm-6.982-10.971c2.494 0 4.489 2.618 4.489 5.735s-1.995 5.735-4.489 5.735c-2.493 0-4.488-2.618-4.488-5.735s1.995-5.735 4.488-5.735zm-9.724 18.95c.623-2.119 2.618-3.989 5.485-4.987 1.247.873 2.618 1.247 4.115 1.247 1.496 0 2.992-.499 4.239-1.371 2.867.997 4.862 2.867 5.485 4.987h-19.324z" />
                        </g>
                    </symbol>
                    <linearGradient id="mean-chart-gradient" x1="0" x2={vertical ? 0 : 1} y1={vertical ? 1 : 0} y2="0">
                        <stop stopColor={currentColors.teamScanRed} offset="0%" />
                        <stop stopColor={currentColors.teamScanYellow} offset="40%" />
                        <stop stopColor={currentColors.teamScanYellow} offset="60%" />
                        <stop stopColor={currentColors.teamScanGreen} offset="1000%" />
                    </linearGradient>
                </defs>
                <CartesianGrid />
                <XAxis
                    type="number"
                    domain={vertical ? [0.0, 3.5] : [0, 100]}
                    interval={vertical ? undefined : 0}
                    tickCount={11}
                    dataKey={vertical ? "idx" : "value"}
                    tick={!vertical}
                    axisLine={!vertical}
                    orientation={vertical ? "top" : "bottom"}
                    tickFormatter={(val: number) => `${val.toFixed(0)}%`}
                />
                <YAxis
                    type="number"
                    domain={vertical ? [0, 100] : [0.0, 3.5]}
                    interval={vertical ? 0 : undefined}
                    tickCount={11}
                    dataKey={vertical ? "value" : "idx"}
                    tick={vertical}
                    axisLine={vertical}
                    tickFormatter={(val: number) => `${val.toFixed(0)}%`}
                />
                <Scatter data={data} isAnimationActive={false} shape={Shape} />
                <Scatter data={meanData} isAnimationActive={false} shape={MeanShape} />
                <ReferenceArea
                    x1={0}
                    y1={vertical ? 0 : 0.2}
                    x2={vertical ? 0.2 : 100}
                    y2={vertical ? 100 : 0}
                    fill="url(#mean-chart-gradient)"
                    fillOpacity={1}
                    stroke={currentColors.grey4}
                />
            </ScatterChart>
            {!vertical && label}
        </div>
    );
}

export default memo(MeanChart);
