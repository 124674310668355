import { type FormEvent, type JSX, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "urql";
import * as API from "../../api/Upload";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import { type Dispatch, closeModal } from "../state";
import { Button } from "./stylins";

const editPictureMutation = `
    mutation($filename:String!) {
        currentPersonEditPicture(filename:$filename) {
            error
            currentPerson {
                __typename
            }
        }
    }
`;

interface IEditArgs {
    filename: string;
}

function PictureForm(): JSX.Element {
    const [file, setFile] = useState<File | null>(null);
    const dispatch = useDispatch<Dispatch>();
    const [apiError, setApiError] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [_, executeMutation] = useMutation<any, IEditArgs>(editPictureMutation);

    const onChange = (event: FormEvent<HTMLInputElement>) => {
        setFile(event.currentTarget.files?.[0] ?? null);
    };

    const onClick = async () => {
        if (submitting) {
            return;
        }
        setApiError("");
        setSubmitting(true);
        try {
            // biome-ignore lint/style/noVar: Use var to host type inference outside the block.
            // biome-ignore lint/correctness/noInnerDeclarations: Use var to host type inference outside the block.
            var filename = await API.uploadFile(file, "image");
        } catch (err) {
            setApiError(err.message);
            setSubmitting(false);
            return;
        }
        const result = await executeMutation({ filename });
        setSubmitting(false);
        if (result.error) {
            setApiError(result.error.message);
        } else if (result.data.currentPersonEditPicture.error) {
            setApiError(result.data.currentPersonEditPicture.error);
        } else {
            dispatch(closeModal());
        }
    };

    return (
        <div>
            <h3>{t("player.profile.heading-upload-profile-picture")}</h3>
            <input type="file" accept={loadJSData().formats.image_formats.join(",")} onChange={onChange} />
            <Button onClick={onClick} disabled={!file || submitting}>
                {t("player.profile.form-picture-submit")}
            </Button>
            <p>{t("player.profile.info-profile-picture-limit")}</p>
            {apiError && <div className="error">{apiError}</div>}
        </div>
    );
}

export default PictureForm;
