import { memo } from "react";
import type { JSX } from "react";
import { currentColors } from "../../../shared/colors";

interface IProps {
    percent: number;
}

function DensityChart({ percent }: IProps): JSX.Element {
    const degrees = 237 * (percent / 100);
    return (
        <svg
            width="244"
            height="280"
            clipRule="evenodd"
            fillRule="evenodd"
            viewBox="0 0 471 441"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>Desnity chart</title>
            <linearGradient
                id="density-gradient"
                gradientUnits="userSpaceOnUse"
                x1="18.3576"
                x2="453.7786"
                y1="193.674"
                y2="193.674"
            >
                <stop offset="5%" stopColor={currentColors.teamScanRed} />
                <stop offset="25%" stopColor={currentColors.teamScanYellow} />
                <stop offset="75%" stopColor={currentColors.teamScanYellow} />
                <stop offset="100%" stopColor={currentColors.teamScanGreen} />
            </linearGradient>
            <g fillRule="nonzero">
                <g transform={`rotate(${degrees} 234 246.5)`}>
                    <path
                        d="m127.407 301.638c-1.905 2.584-2.208 6.2-.401 9.156 2.266 3.738 6.984 5.15 10.938 3.191l164.235-83.151c1.184-.604 2.151-1.515 2.856-2.472.564-.765 1.033-1.601 1.31-2.578.6-2.216.319-4.638-.814-6.507l-12.318-20.748c-1.158-2.035-3.142-3.35-5.358-3.95s-4.588.013-6.527 1.242l-151.916 103.899c-.685.529-1.441 1.153-2.005 1.918z"
                        fill={currentColors.text}
                    />
                    <path d="m134.104 306.576 151.986-103.995 12.319 20.748z" fill={currentColors.teamScanRed} />
                    <path
                        d="m252.472 260.198c-2.116 2.87-4.902 5.247-8.217 6.938-11.388 5.633-25.096.99-30.729-10.399-2.708-5.54-3.139-11.765-1.214-17.582 1.926-5.816 6.072-10.439 11.517-13.217 5.541-2.708 11.766-3.14 17.582-1.214 5.817 1.925 10.439 6.071 13.218 11.517 3.845 8.003 2.851 17.164-2.157 23.957z"
                        fill={currentColors.text}
                    />
                    <path
                        d="m240.556 259.535c7.198-3.554 10.192-12.422 6.638-19.62-1.716-3.481-4.758-6.166-8.429-7.396s-7.71-.959-11.191.758c-3.481 1.716-6.166 4.757-7.396 8.429-1.23 3.671-.959 7.71.758 11.191 3.579 7.364 12.351 10.287 19.62 6.638z"
                        fill={currentColors.teamScanRed}
                    />
                </g>
                <path
                    d="m236.078 97.116c-89.861 0-162.843 73.101-162.843 162.843 0 25.436 5.824 49.447 16.284 70.961l22.822-10.222c-1.783-3.685-3.447-7.489-4.992-11.411l-15.333 6.656c-6.062-15.452-9.628-32.093-10.342-49.447h16.879c-.119-2.021-.119-4.041-.119-6.062 0-2.258 0-4.517.119-6.775h-16.879c.595-15.809 3.685-31.023 8.796-45.287l15.69 6.3c1.427-4.042 2.972-8.083 4.755-11.887l-15.809-6.299c7.013-15.334 16.403-29.359 27.814-41.602l11.886 11.886c2.853-3.09 5.943-6.181 9.153-9.034l-11.887-11.886c11.055-10.103 23.654-18.661 37.442-25.199l6.657 15.452c3.803-1.902 7.726-3.566 11.767-5.111l-6.656-15.452c15.095-5.705 31.38-9.153 48.377-9.866v16.641c2.377-.119 4.636-.119 7.013-.119 1.902 0 3.922 0 5.824.119v-16.641c15.928.713 31.261 3.685 45.644 8.915l-6.062 15.215c4.041 1.426 8.082 2.971 11.886 4.754l6.062-15.214c14.977 6.894 28.765 16.046 40.77 27.1l-11.411 11.411c3.209 2.853 6.181 5.825 9.153 9.034l11.292-11.292c10.46 11.411 19.255 24.367 26.031 38.511l-14.621 6.3c1.902 3.804 3.566 7.726 4.993 11.768l14.501-6.3c5.705 14.977 9.034 31.142 9.747 47.902h-15.69c.119 2.258.119 4.517.119 6.775 0 2.021 0 4.041-.119 6.062h15.69c-.595 15.452-3.566 30.31-8.44 44.336l-14.501-5.824c-1.426 4.041-2.971 8.082-4.754 11.886l14.501 5.824v.119l7.726 3.09c8.915-20.087 13.907-42.434 13.907-65.731 0-90.098-72.982-163.199-162.842-163.199z"
                    fill={currentColors.text}
                />
                <path
                    d="m236.078 34c-124.688 0-226.078 101.39-226.078 226.077 0 34.233 7.726 66.802 21.395 95.923l4.279-1.902v.119l23.06-10.222v.119l7.607-3.328c-11.767-24.486-18.305-51.825-18.305-80.709 0-103.529 84.274-187.803 187.804-187.803s187.804 84.274 187.804 187.803c0 27.458-5.944 53.608-16.522 77.143l2.02.832 29.954 12.837v-.119l3.209 1.426c12.6-28.17 19.731-59.431 19.731-92.237.119-124.569-101.39-225.959-225.958-225.959z"
                    fill={currentColors.text}
                />
                <path
                    d="m438.026 341.38-19.85-8.558c9.033-22.465 14.026-47.07 14.026-72.745 0-108.165-87.959-196.124-196.124-196.124-108.166 0-196.124 87.959-196.124 196.124 0 27.101 5.586 52.895 15.452 76.311l-19.732 8.677c-11.173-26.15-17.354-54.915-17.354-85.106 0-120.052 97.706-217.758 217.758-217.758 120.051 0 217.757 97.706 217.757 217.758 0 28.764-5.706 56.222-15.809 81.421z"
                    fill="url(#density-gradient)"
                />
            </g>
            <text fontSize="38" x="235.5" y="390.84" textAnchor="middle">
                {percent.toFixed(0)}%
            </text>
        </svg>
    );
}

export default memo(DensityChart);
