import { format, startOfDay } from "./dateFns";

export default function eventDateStrs(start: Date, end: Date | null): { startStr: string; endStr: string | null } {
    // Format the start string based on whether the time component exists.
    const startDay = startOfDay(start);
    const startStr =
        start.getTime() !== startDay.getTime() ? format(start, "yyyy-MM-dd HH:mm") : format(start, "yyyy-MM-dd");

    let endStr: string | null = null;
    if (end) {
        // Check if start and end are on the same day.
        const isSameDay = startOfDay(end).getTime() === startDay.getTime();
        endStr = isSameDay ? format(end, "HH:mm") : format(end, "yyyy-MM-dd HH:mm");
    }

    return { startStr, endStr };
}
