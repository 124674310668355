import type { JSX } from "react";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import { useSelectedIds } from "../components/SelectedIds";
import ShowError from "../components/ShowError";
import InspList from "./InspList";
import { type IArgs, type IData, query } from "./query";

function Inspirations(): JSX.Element {
    const { pathId } = useSelectedIds();
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: { id: pathId },
    });
    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }
    if (!result.data.learningPath) {
        return null;
    }
    return <InspList inspirations={result.data.learningPath.inspirations} />;
}

export default Inspirations;
