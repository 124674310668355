// Data query

export const query = `
    query {
        currentPerson {
            id
            avatar
            user {
                pulseRights
            }
            ongoingPaths: learningPaths(filter:"ongoing") {
                id
                pathname
            }
            teamScans {
                id
            }
            standaloneAiChatbots {
                id
            }
        }
    }
`;

export interface IData {
    currentPerson: {
        id: string;
        avatar: string;
        user: {
            pulseRights: boolean;
        };
        ongoingPaths: {
            id: string;
            pathname: string;
        }[];
        teamScans: {
            id: string;
        }[];
        standaloneAiChatbots: {
            id: string;
        }[];
    };
}

// Select mutation

export type ToolType = "path" | "teamScan" | "standaloneAiChatbot" | "pulse" | "profile";

export interface ISelectArgs {
    tool: ToolType;
    pathId: string | null;
}

export const selectMutation = `
    mutation($tool: String!, $pathId: String) {
        currentPersonSelectPath(tool: $tool, pathId: $pathId) {
            currentPerson {
                id
            }
            redirect
        }
    }
`;

export interface ISelectData {
    currentPersonSelectPath: {
        currentPerson: {
            id: string;
        };
        redirect: string;
    };
}
