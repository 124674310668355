import type { JSX } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import t from "../../shared/translations";
import { type Dispatch, openModal } from "../state";
import type { ICurrentPerson } from "./Profile";
import { Button as OrgiButton } from "./stylins";

interface IProps {
    person: ICurrentPerson;
}

export const COL_CUTOFF = 600;

const Data = styled.div`
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    @container (min-width: ${COL_CUTOFF}px) {
        grid-column: 1/2;
        grid-row: 1/2;
        grid-template-columns: 1fr 1fr;
    }
`;

const FakeLabel = styled.span`
    display: block;
    font-size: 0.875rem;
    color: ${currentColors.grey4};
    text-align: center;
    @container (min-width: ${COL_CUTOFF}px) {
        text-align: right;
    }
`;

const StaticData = styled.div`
    text-align: center;
    @container (min-width: ${COL_CUTOFF}px) {
        text-align: left;
    }
`;

const Button = styled(OrgiButton)`
    margin: 0;
    align-self: start;
    @container (min-width: ${COL_CUTOFF}px) {
        grid-column: 2/3;
    }
`;

function InfoSection({ person }: IProps): JSX.Element {
    const dispatch = useDispatch<Dispatch>();

    const open = () => {
        dispatch(openModal("Profile/ChangePassword"));
    };

    return (
        <>
            <Button onClick={open}>{t("player.profile.button-change-password")}</Button>
            <Data>
                <FakeLabel>{t("player.profile.label-organisation")}</FakeLabel>
                <StaticData>{person.organisation.name}</StaticData>
                <FakeLabel>{t("player.profile.label-team")}</FakeLabel>
                <StaticData>{person.team ? person.team.name : ""}</StaticData>
            </Data>
        </>
    );
}

export default InfoSection;
