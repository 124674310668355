import { type FormEvent, type JSX, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import HomeIcon from "../../shared/components/icons/navIcons/HomeIcon";
import ProfileIcon from "../../shared/components/icons/navIcons/ProfileIcon";
import TheoryIcon from "../../shared/components/icons/navIcons/TheoryIcon";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import { useCurrentPerson } from "../components/CurrentPerson";
import { useUnreadMessages } from "../components/UnreadMessages";
import PathNav from "../selectPath/PathNav";

const HeaderWrapper = styled.header`
    padding: 0 10px 0 10px;
    max-width: 1440px;
    width: 100%;
`;

const MainNav = styled.nav`
    background-color: ${currentColors.header};
    margin-bottom: 0.2rem;
    svg {
        width: 60px;
        height: 60px;
        fill: white;
        margin: 0.2rem 1rem;
    }
`;

const SecondaryNav = styled.nav`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    gap: 4px;
    form {
        min-width: 0;
    }
`;

const Select = styled.select`
    display: inline-block;
    width: auto;
    line-height: 22px;
    position: relative;
    border: 1px solid ${currentColors.grey4};
    padding: 0;
    margin: 0;
    display: inline-block;
    width: auto;
    background-color: transparent;
    border-radius: 0;
    color: ${currentColors.text};
    font-size: 0.9rem;
    padding: 0.25rem 0.85rem;
    padding-right: 1.2rem;
    height: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:focus, &:active, &:visited {
        background-color: white;
        border: 1px solid ${currentColors.grey4};
    }
`;

const ButtonAnchor = styled.a`
    display: inline-block;
    background-color: transparent;
    border: 1px solid ${currentColors.text};
    border-radius: 0;
    color: ${currentColors.text};
    margin: 0;
    font-size: 0.9rem;
    padding: 0.25rem 0.85rem;
    transition: none;
    line-height: 22px;

    &:hover, &:focus, &:active, &:visited {
        background-color: transparent;
        color: ${currentColors.text};
    }
`;

function Header(): JSX.Element {
    const person = useCurrentPerson();
    const unreadMessages = useUnreadMessages();
    const showAdmin = person.user.role.name === "admin";
    const showLearningCoach =
        loadJSData().features.learning_coach &&
        (person.user.role.name === "admin" || person.user.role.name === "learning-coach");
    const showTracking =
        loadJSData().features.tracking &&
        (person.user.role.name === "admin" ||
            person.user.role.name === "organisation-manager" ||
            person.user.role.name === "team-manager");
    const showViewSwitch = showAdmin || showLearningCoach || showTracking;

    const onViewSwitch = (event: FormEvent<HTMLSelectElement>) => {
        const path = event.currentTarget.value;
        if (path) {
            window.location.href = path;
        }
    };

    return (
        <HeaderWrapper>
            <MainNav>
                <Link to="/team-scans">
                    <HomeIcon />
                </Link>
                <Link to="/team-scans/theory">
                    <TheoryIcon />
                </Link>
                <Link to="/team-scans/profile">
                    <ProfileIcon unread={unreadMessages} />
                </Link>
            </MainNav>
            <SecondaryNav>
                <PathNav currentTool="teamScan" />
                {showViewSwitch && (
                    <Select onChange={onViewSwitch}>
                        <option value="">{t("player.header.secondary-nav-switch-view")}</option>
                        {showAdmin && <option value="/admin">{t("player.header.secondary-nav-admin")}</option>}
                        {showLearningCoach && (
                            <option value="/learning-coach">{t("player.header.secondary-nav-learning-coach")}</option>
                        )}
                        {showTracking && (
                            <option value={`/tracking/${person.organisation.id}`}>
                                {t("player.header.secondary-nav-tracking")}
                            </option>
                        )}
                    </Select>
                )}
                <ButtonAnchor href="/logout">{t("player.header.secondary-nav-log-out")}</ButtonAnchor>
            </SecondaryNav>
        </HeaderWrapper>
    );
}

export default Header;
