import type { JSX } from "react";
import { useSelectedIds } from "../components/SelectedIds";
import Chat from "./Chat";

function ChatHolder(): JSX.Element {
    const { pathGroupId } = useSelectedIds();
    if (!pathGroupId) {
        return null;
    }
    return <Chat pathGroupId={Number.parseInt(pathGroupId, 10)} />;
}

export default ChatHolder;
