import { type JSX, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "urql";
import TaskDescription from "../../shared/components/TaskDescription";
import getRelativeUrl from "../../shared/getRelativeUrl";
import t from "../../shared/translations";
import LockedModal, { useLockedModal } from "../LockedModal";
import WithTasksSidebar from "../tasksSidebar";
import TaskForm, { type IFormValues, defaultValues } from "./TaskForm";
import { type ISubmitArgs, type ISubmitData, submitMutation } from "./query";
import taskData from "./taskData";

const EMPTY_LIST = [];

function CustomTask(): JSX.Element {
    const { kind } = useParams();
    const data = taskData(kind);
    const [_, executeMutation] = useMutation<ISubmitData, ISubmitArgs>(submitMutation);
    const [apiError, setApiError] = useState("");
    const { openLockedModal, ...lockedProps } = useLockedModal();
    const navigate = useNavigate();

    const onSubmit = async (values: IFormValues) => {
        const result = await executeMutation({
            ...values,
            kind,
        });
        if (result.error) {
            console.error(result.error.message);
            setApiError(t("player.error.error"));
        } else if (result.data?.submitCustomTask?.error) {
            setApiError(result.data.submitCustomTask.error);
        }
        if (result.data?.submitCustomTask?.redirect) {
            navigate(getRelativeUrl(result.data.submitCustomTask.redirect));
        }
    };

    return (
        <>
            <WithTasksSidebar
                openLockedModal={openLockedModal}
                startTab="custom"
                activeItem={kind}
                mainLabel={t("player.custom-task.tab-task")}
                big={true}
            >
                <TaskDescription
                    icon={data.task.icon}
                    shortTitle={data.task.shortTitle}
                    miles={null}
                    title={null}
                    taskVariants={data.task.taskVariants}
                    objectives={null}
                    media={EMPTY_LIST}
                    inspiration={null}
                    invert={false}
                />
                <TaskForm
                    labels={data.placeholders}
                    errors={data.errors}
                    initialValues={defaultValues}
                    onSubmit={onSubmit}
                />
                {apiError && <div>{apiError}</div>}
            </WithTasksSidebar>
            <LockedModal {...lockedProps} />
        </>
    );
}

export default CustomTask;
