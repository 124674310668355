import type { JSX } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { useQuery } from "urql";
import { useImmer } from "use-immer";
import { currentColors } from "../shared/colors";
import Loader from "../shared/components/Loader";
import LockIconOrig from "../shared/components/icons/LockIcon";
import TaskIcon from "../shared/components/icons/TaskIcon";
import { formatYMD, parseDate } from "../shared/dateFns";
import t from "../shared/translations";
import type { TaskIcon as TaskIconType, TaskLockedType } from "../shared/types";
import ShowError from "./components/ShowError";

const MOBILE_CUTOFF = 768;

interface ITaskArgs {
    id: string;
}

const taskQuery = `
    query($id:String!) {
        task(id:$id) {
            id
            lockedType
            shortTitle
            icon
            miles
            deadline
            unlockDate
            unlockItem {
                id
                title
                url
            }
        }
    }
`;

interface ITask {
    id: string;
    lockedType: TaskLockedType;
    shortTitle: string;
    icon: TaskIconType;
    miles: number;
    deadline: string | null;
    unlockDate: string | null;
    unlockItem: {
        id: string;
        title: string;
        url: string;
    };
}

interface ITaskData {
    task: ITask;
}

interface IFeedbackArgs {
    id: string;
}

const feedbackQuery = `
    query($id:String!) {
        feedbackTask(id:$id) {
            id
            shortTitle
            icon
            miles
            deadline
            task {
                id
                shortTitle
                url
            }
        }
    }
`;

interface IFeedbackTask {
    id: string;
    shortTitle: string;
    icon: TaskIconType;
    miles: number;
    deadline: string;
    task: {
        id: string;
        shortTitle: string;
        url: string;
    };
}

interface IFeedbackData {
    feedbackTask: IFeedbackTask;
}

interface ITaskChoiceArgs {
    id: string;
}

const taskChoiceQuery = `
    query($id:String!) {
        taskChoice(id:$id) {
            id
            lockedType
            name
            icon
            deadline
            unlockDate
            unlockItem {
                id
                title
                url
            }
        }
    }
`;

interface ITaskChoice {
    id: string;
    lockedType: TaskLockedType;
    name: string;
    icon: TaskIconType;
    deadline: string;
    unlockDate: string | null;
    unlockItem: {
        id: string;
        title: string;
        url: string;
    };
}

interface ITaskChoiceData {
    taskChoice: ITaskChoice;
}

interface IInnerProps {
    id: string;
}

interface IState {
    taskId: string;
    feedbackId: string;
    taskChoiceId: string;
}

export type OpenLockedModal = (taskId: string, feedbackId: string, taskChoiceId: string) => void;

type ReturnProps = {
    state: IState;
    open: boolean;
    openLockedModal: OpenLockedModal;
    close: VoidFunction;
};

export function useLockedModal(): ReturnProps {
    const [state, setLockedModalState] = useImmer<IState>({ taskId: "", feedbackId: "", taskChoiceId: "" });
    const openLockedModal = (taskId: string, feedbackId: string, taskChoiceId: string) => {
        setLockedModalState((draft) => {
            draft.taskId = taskId;
            draft.feedbackId = feedbackId;
            draft.taskChoiceId = taskChoiceId;
        });
    };
    const close = () => {
        setLockedModalState((draft) => {
            draft.taskId = "";
            draft.feedbackId = "";
            draft.taskChoiceId = "";
        });
    };
    const open = !!state.taskId || !!state.feedbackId || !!state.taskChoiceId;
    return { state, open, openLockedModal, close };
}

const H2 = styled.h2`
    margin: 0;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    gap: 1rem;
    @media (min-width: ${MOBILE_CUTOFF}px) {
        grid-template-columns: 1fr 270px;
    }
`;

const Description = styled.section`
    border-radius: 1rem;
    border: 1px solid ${currentColors.border};
    padding: 0.6rem;
    display: grid;
    align-content: start;
    justify-items: center;
    gap: 1rem;
    @media (min-width: 640px) {
        padding: 1rem;
        gap: 2rem;
    }
`;

const DescriptionText = styled.div`
    text-align: center;
    width: 100%;
`;

const LockIcon = styled(LockIconOrig)`
    width: 150px;
    height: 150px;
`;

const InfoBox = styled.section`
    width: 100%;
    max-width: 100%;
    border-radius: 1rem;
    padding: 0.6rem;
    background-color: ${currentColors.background};
    border: 1px solid ${currentColors.border};
    color: ${currentColors.text};
    display: grid;
    align-content: start;
    justify-items: center;
    gap: 0.5rem;
    & > svg {
        width: 8rem;
        height: 8rem;
    }

    @media (min-width: 640px) {
        width: 270px;
        padding: 1rem;
    }
`;

const MilesBox = styled.div`
    display: grid;
    justify-items: center;
    & > div:first-child {
        font-size: 4rem;
        line-height: 1;
    }
    & > div:last-child {
        font-size: 1.5rem;
    }
`;

const Deadline = styled.span`
    background-color: ${currentColors.taskDeadline};
    color: white;
    padding: 0.2rem 1.5rem;
    display: inline-block;
`;

const UnlockDate = styled.span`
    background-color: ${currentColors.calendarUnlock};
    color: white;
    padding: 0.4rem 1.6rem;
    display: inline-block;
    margin: 1rem 0;
`;

const ButtonAnchor = styled.a`
    background-color: ${currentColors.button};
    color: white;
    padding: 0.4rem 1.6rem;
    display: inline-block;
    &:hover, &:focus, &:active, &:visited {
        color: white;
    }
`;

function TaskContent({ id }: IInnerProps): JSX.Element {
    const [result] = useQuery<ITaskData, ITaskArgs>({
        query: taskQuery,
        variables: { id },
    });

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }

    const task = result.data.task;
    const infoText =
        task.lockedType === "item"
            ? t("player.landing.info-locked-for-item", {
                  locked_task: task.shortTitle,
                  unlock_task: task.unlockItem.title,
              })
            : t("player.landing.info-locked-for-date", { locked_task: task.shortTitle });
    return (
        <>
            <H2>{task.shortTitle}</H2>
            <Row>
                <Description>
                    <LockIcon />
                    <DescriptionText>{infoText}</DescriptionText>
                    {task.lockedType === "item" && (
                        <ButtonAnchor href={task.unlockItem.url}>
                            {t("player.landing.button-unlock-item", { unlock_task: task.unlockItem.title })}
                        </ButtonAnchor>
                    )}
                    {task.lockedType === "date" && <UnlockDate>{formatYMD(parseDate(task.unlockDate))}</UnlockDate>}
                </Description>
                <InfoBox>
                    <TaskIcon icon={task.icon} active={false} />
                    <MilesBox>
                        <div>{task.miles}</div>
                        <div>{t("shared.task-description.label-miles")}</div>
                    </MilesBox>
                    {task.deadline && (
                        <div>
                            {t("shared.task-description.label-deadline")}{" "}
                            <Deadline>{formatYMD(parseDate(task.deadline))}</Deadline>
                        </div>
                    )}
                </InfoBox>
            </Row>
        </>
    );
}

function FeedbackContent({ id }: IInnerProps): JSX.Element {
    const [result] = useQuery<IFeedbackData, IFeedbackArgs>({
        query: feedbackQuery,
        variables: { id },
    });

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }
    const feedbackTask = result.data.feedbackTask;
    const locked_task = feedbackTask.shortTitle;
    const unlock_task = feedbackTask.task.shortTitle;
    const infoText = t("player.landing.info-locked-for-feedback", { locked_task, unlock_task });
    return (
        <>
            <H2>{feedbackTask.shortTitle}</H2>
            <Row>
                <Description>
                    <LockIcon />
                    <DescriptionText>{infoText}</DescriptionText>
                    <ButtonAnchor href={feedbackTask.task.url}>
                        {t("player.landing.button-unlock-item", { unlock_task })}
                    </ButtonAnchor>
                </Description>
                <InfoBox>
                    <TaskIcon icon={feedbackTask.icon} active={false} />
                    <MilesBox>
                        <div>{feedbackTask.miles}</div>
                        <div>{t("shared.task-description.label-miles")}</div>
                    </MilesBox>
                    {feedbackTask.deadline && (
                        <div>
                            {t("shared.task-description.label-deadline")}{" "}
                            <Deadline>{formatYMD(parseDate(feedbackTask.deadline))}</Deadline>
                        </div>
                    )}
                </InfoBox>
            </Row>
        </>
    );
}

function TaskChoiceContent({ id }: IInnerProps): JSX.Element {
    const [result] = useQuery<ITaskChoiceData, ITaskChoiceArgs>({
        query: taskChoiceQuery,
        variables: { id },
    });

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError />;
    }
    const taskChoice = result.data.taskChoice;
    const infoText =
        taskChoice.lockedType === "item"
            ? t("player.landing.info-locked-for-item", {
                  locked_task: taskChoice.name,
                  unlock_task: taskChoice.unlockItem.title,
              })
            : t("player.landing.info-locked-for-date", { locked_task: taskChoice.name });
    return (
        <>
            <H2>{taskChoice.name}</H2>
            <Row>
                <Description>
                    <LockIcon />
                    <DescriptionText>{infoText}</DescriptionText>
                    {taskChoice.lockedType === "item" && (
                        <ButtonAnchor href={taskChoice.unlockItem.url}>
                            {t("player.landing.button-unlock-item", { unlock_task: taskChoice.unlockItem.title })}
                        </ButtonAnchor>
                    )}
                    {taskChoice.lockedType === "date" && (
                        <UnlockDate>{formatYMD(parseDate(taskChoice.unlockDate))}</UnlockDate>
                    )}
                </Description>
                <InfoBox>
                    <TaskIcon icon={taskChoice.icon} active={false} />
                    {taskChoice.deadline && (
                        <div>
                            {t("shared.task-description.label-deadline")}{" "}
                            <Deadline>{formatYMD(parseDate(taskChoice.deadline))}</Deadline>
                        </div>
                    )}
                </InfoBox>
            </Row>
        </>
    );
}

Modal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,0.45)";

const StyledModal = styled(Modal)`
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    max-height: calc(100vh - 8vw);
    overflow: auto;
    border-radius: 3px;
    padding: 2rem 1rem 1rem 1rem;
    border: solid 1px ${currentColors.grey4};
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
    background: white;
    @media (min-width: ${MOBILE_CUTOFF}px) {
        width: 710px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const CloseCross = styled.span`
    color: ${currentColors.grey3};
    cursor: pointer;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1;
    position: absolute;
    top: .5rem;
    right: 1rem;
    text-decoration: none;
    content: '×';
`;

interface IProps {
    state: IState;
    open: boolean;
    close: VoidFunction;
}

function LockedModal({ state, open, close }: IProps): JSX.Element {
    return (
        <StyledModal isOpen={open} onRequestClose={close}>
            {open && !!state.taskId && <TaskContent id={state.taskId} />}
            {open && !!state.feedbackId && <FeedbackContent id={state.feedbackId} />}
            {open && !!state.taskChoiceId && <TaskChoiceContent id={state.taskChoiceId} />}
            <CloseCross onClick={close}>×</CloseCross>
        </StyledModal>
    );
}

export default LockedModal;
