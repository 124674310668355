import type { JSX } from "react";
import styled from "styled-components";
import { currentColors } from "../shared/colors";

interface IProps {
    heading: string;
}

const Head = styled.header`
    background-color:${currentColors.header};
    padding: 0.5rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
    border-radius: 2rem;
    h1 {
        color: white;
        margin: 0;
        text-align: center;
    }
`;

function Header({ heading }: IProps): JSX.Element {
    return (
        <Head>
            <h1>{heading}</h1>
        </Head>
    );
}

export default Header;
