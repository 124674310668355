import type { JSX } from "react";
import styled from "styled-components";
import NoteIcon from "../../../shared/components/icons/NoteIcon";
import t from "../../../shared/translations";
import type { ShareAnswersType } from "../../../shared/types";
import { Box } from "../stylings";

interface IProps {
    shareType: ShareAnswersType;
    anonymous: boolean;
    pathname: string;
}

const H3 = styled.h3`
    display: grid;
    grid-template-columns: 26px 1fr;
    align-items: center;
    svg {
        width: 26px;
        height: 26px;
    }
`;

function ShareAnswersInfo({ shareType, anonymous, pathname }: IProps): JSX.Element {
    if (shareType === "no_one") {
        return null;
    }
    return (
        <Box>
            <H3>
                <NoteIcon />
                {t("player.task.heading-share-answers")}
            </H3>
            {shareType === "group" && <p>{t("player.task.info-share-answers-group")}</p>}
            {shareType === "path" && <p>{t("player.task.info-share-answers-path", { pathname })}</p>}
            <p>{t("player.task.info-share-answers-see-answers-after-submit")}</p>
            <p>
                <strong>
                    {anonymous
                        ? t("player.task.info-share-answers-anonymous")
                        : t("player.task.info-share-answers-not-anonymous")}
                </strong>
            </p>
        </Box>
    );
}

export default ShareAnswersInfo;
