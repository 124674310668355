import { type JSX, memo } from "react";
import styled from "styled-components";
import { formatYMD, parseDate } from "../../shared/dateFns";
import t from "../../shared/translations";
import WithDocs from "../components/WithDocs";
import PitstopTimer from "./PitstopTimer";
import type { IData } from "./query";
import { BorderBox, Frame, StatsText } from "./stylings";

interface IProps {
    data: IData;
}

function Stats({ data }: IProps): JSX.Element {
    const pm = data.pathMembership;
    const path = pm.learningPath;
    return (
        <Frame $borderWidth="0" $skipPadding>
            <BorderBox $span={[12, 6, 4]}>
                <WithDocs
                    modal="Performance/Info/TotalMiles"
                    docPath={"player/performance/total-miles"}
                    component="h4"
                    style={{ justifyContent: "center" }}
                >
                    {t("player.performance.heading-miles")}
                </WithDocs>
                <StatsText>{pm.miles}</StatsText>
            </BorderBox>
            <BorderBox $span={[12, 6, 4]}>
                <WithDocs
                    modal="Performance/Info/Activity"
                    docPath={"player/performance/activity"}
                    component="h4"
                    style={{ justifyContent: "center" }}
                >
                    {t("player.performance.heading-activity")}
                </WithDocs>
                <StatsText>
                    <PitstopTimer taskTime={pm.taskTime || pm.startDate} />
                </StatsText>
            </BorderBox>
            <BorderBox $span={[12, 6, 4]}>
                <WithDocs
                    modal="Performance/Info/EndDate"
                    docPath={"player/performance/end-date"}
                    component="h4"
                    style={{ justifyContent: "center" }}
                >
                    {t("player.performance.heading-end-date")}
                </WithDocs>
                <StatsText>{formatYMD(parseDate(pm.endDate))}</StatsText>
            </BorderBox>
        </Frame>
    );
}

export default memo(Stats);
