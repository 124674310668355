import type { ComponentPropsWithoutRef } from "react";
import { currentColors } from "../../../colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    active?: boolean;
}

function BinocularsIcon({ active, ...props }: IProps) {
    return (
        <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="27.5" cy="69" r="15" fill="white" />
            <circle cx="72" cy="69" r="15" fill="white" />
            <path
                d="m28.5 79.6c-.7 0-1.3-.5-1.5-1.1-.2-.8.3-1.6 1.1-1.8 4-1 6.9-4.4 7.3-8.5.1-.8.8-1.4 1.6-1.4.8.1 1.4.8 1.4 1.6-.5 5.4-4.3 9.8-9.6 11.1-.1.1-.2.1-.3.1z"
                fill={currentColors.text}
            />
            <path
                d="m88.4 68.9c0-1.2-.1-2.4-.4-3.5l-4.6-41.3v-.1c-.1-5.6-5.2-10.1-11.5-10.1h-2.9c-5.9 0-10.8 4-11.5 9.1-2.1-1.6-4.7-2.4-7.5-2.4s-5.4.9-7.5 2.4c-.6-5.1-5.5-9.1-11.5-9.1h-2.9c-6.3 0-11.5 4.5-11.5 10.1l-4.4 39.8c-.4 1.4-.6 2.9-.6 4.5 0 8.9 7.3 16.2 16.2 16.2s16.2-7.3 16.2-16.2c0-.5 0-.9-.1-1.4 1.8 1 3.9 1.6 6.2 1.6 2.2 0 4.3-.6 6.1-1.6-.2.7-.2 1.3-.2 2 0 8.9 7.3 16.2 16.2 16.2 8.6 0 15.7-6.8 16.1-15.3 0-.3.1-.6.1-.9zm-60.7 12.6c-7.3 0-13.2-5.9-13.2-13.2s5.9-13.2 13.2-13.2 13.2 5.9 13.2 13.2-5.9 13.2-13.2 13.2zm44.5.6c-7.3 0-13.2-5.9-13.2-13.2s5.9-13.2 13.2-13.2 13.2 5.9 13.2 13.2-6 13.2-13.2 13.2z"
                fill={currentColors.text}
            />
            <path
                d="m57.6 61.5c-1.5 2.5-4.4 4-7.6 4s-6-1.5-7.5-3.8c-2.5-5.6-8.2-9.6-14.8-9.6-4.7 0-8.8 2-11.8 5.1l3.7-32.9c0-.1 0-.1 0-.2 0-4 3.8-7.2 8.5-7.2h2.9c4.7 0 8.5 3.2 8.5 7.2v2.7c0 .7.5 1.3 1.1 1.4.7.2 1.3-.1 1.7-.7 1.5-2.5 4.4-4 7.6-4s6.2 1.5 7.6 4c .3.6 1 .9 1.7.7s1.1-.8 1.1-1.4v-2.7c0-4 3.8-7.2 8.5-7.2h2.9c4.7 0 8.5 3.2 8.5 7.2v.2l3.9 33.7c-3-3.3-7.2-5.3-12-5.3-6.1 0-11.4 3.4-14.2 8.4-.1.1-.2.2-.3.4z"
                fill={active ? currentColors.icon3 : "white"}
            />
            <path
                d="m72.8 80.2c-.7 0-1.3-.5-1.5-1.1-.2-.8.3-1.6 1.1-1.8 4-1 6.9-4.4 7.3-8.5.1-.8.8-1.4 1.6-1.4.8.1 1.4.8 1.4 1.6-.5 5.4-4.3 9.8-9.6 11.1 0 .1-.1.1-.3.1z"
                fill={currentColors.text}
            />
            <circle cx="50" cy="50.9" fill={currentColors.text} r="8.5" />
            <circle cx="50" cy="50.9" fill="white" r="5.5" />
        </svg>
    );
}

export default BinocularsIcon;
