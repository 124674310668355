import type { AppRatingType, LearningPathItemType, TaskIcon } from "../../shared/types";

export interface IArgs {
    id: string;
    hurray: boolean;
}

export const query = `
    query($id:String!,$hurray:Boolean!) {
        learningPath(id:$id) {
            id
            pathname
            info
            avatarSrc(hurray:$hurray)
            goal
            appRatingConfig
            showRatingAfterTasks
            showParticipants
            pathGroup {
                id
                name
                number
                members {
                    id
                    person {
                        id
                        firstName
                        lastName
                        profilePicSrc
                    }
                }
            }
            learningCoaches {
                id
                firstName
                lastName
                profilePicSrc
            }
            participants {
                id
                firstName
                lastName
                profilePicSrc
            }
            learningPathItems {
                id
                complete
                earnedMiles
                finishDatetime
                type
                miles
                icon
                url
                active
                undecided
                redo
                pathInPath {
                   id
                }
                event {
                    id
                    eventStart
                    eventEnd   
                }
                locked
                taskChoice {
                   id
                }
                tasks {
                   id
                }
                feedbackTask {
                   id
                }
                grouped
                shareAnswers
            }
            customItems: learningPathItems(filter:"custom") {
                id
                earnedMiles
                complete
                icon
            }
        }
    }
`;

export interface IData {
    learningPath: {
        id: string;
        pathname: string;
        info: string;
        goal: number;
        avatarSrc: string;
        appRatingConfig: AppRatingType;
        showRatingAfterTasks: number | null;
        showParticipants: boolean;
        pathGroup: {
            id: string;
            name: string | null;
            number: number;
            members: {
                id: string;
                person: {
                    id: string;
                    firstName: string;
                    lastName: string;
                    profilePicSrc: string;
                };
            }[];
        } | null;
        learningCoaches: {
            id: string;
            firstName: string;
            lastName: string;
            profilePicSrc: string;
        }[];
        participants:
            | {
                  id: string;
                  firstName: string;
                  lastName: string;
                  profilePicSrc: string;
              }[]
            | null;
        learningPathItems: {
            id: string;
            complete: boolean;
            earnedMiles: number | null;
            finishDatetime: string | null;
            type: LearningPathItemType;
            miles: [number, number];
            icon: TaskIcon;
            url: string;
            active: boolean;
            undecided: boolean;
            redo: boolean;
            pathInPath: {
                id: string;
            } | null;
            event: {
                id: string;
                eventStart: string;
                eventEnd: string;
            } | null;
            locked: boolean;
            taskChoice: {
                id: string;
            } | null;
            tasks: {
                id: string;
            }[];
            feedbackTask: {
                id: string;
            } | null;
            grouped: boolean;
            shareAnswers: boolean;
        }[];
        customItems: {
            id: string;
            complete: boolean;
            earnedMiles: number | null;
            icon: TaskIcon;
        }[];
    };
}
