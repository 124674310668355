import { type JSX, memo } from "react";
import styled from "styled-components";
import { Runners } from "../../shared/components/Runners";
import t from "../../shared/translations";
import WithDocs from "../components/WithDocs";
import Speedbar from "./Speedbar";
import Status from "./Status";
import type { IData } from "./query";
import { Box, Frame } from "./stylings";

interface IProps {
    data: IData;
}

const Position = styled.div`
    font-size: 2rem;
`;

function MyPerf({ data }: IProps): JSX.Element {
    const pm = data.pathMembership;
    const path = pm.learningPath;
    return (
        <Frame>
            {path.fixed && (
                <>
                    <Box $align="left" $justify="start">
                        <WithDocs
                            modal="Performance/Info/Running"
                            docPath={"player/performance/running"}
                            component="h4"
                            style={{ gridColumn: "span 12", justifyContent: "flex-start" }}
                        >
                            {t("player.performance.heading-running")}
                        </WithDocs>
                    </Box>
                    <Box $span={[12, 12, 9]}>
                        <Runners
                            goal={path.goal}
                            miles={pm.miles}
                            memberMiles={pm.memberMiles}
                            checkpoint={path.checkpoint}
                        />
                    </Box>
                    <Box $span={[12, 12, 3]}>
                        <h5>{t("player.performance.heading-position")}</h5>
                        <Position>
                            {t("player.performance.info-rank", {
                                rank: pm.rank,
                                num_members: path.numMembers,
                            })}
                        </Position>
                    </Box>
                </>
            )}
            <>
                <Box $span={[12, 6, 7]} $align="left">
                    <WithDocs modal="Performance/Info/Status" docPath={"player/performance/status"} component="h4">
                        {t("player.performance.heading-status")}
                    </WithDocs>
                    <Status miles={pm.miles} greenGoal={pm.greenGoal} yellowGoal={pm.yellowGoal} />
                </Box>
                <Box $span={[12, 6, 5]}>
                    <WithDocs
                        modal="Performance/Info/Speed"
                        docPath={"player/performance/speed"}
                        component="h4"
                        style={{ justifyContent: "center" }}
                    >
                        {t("player.performance.heading-speed")}
                    </WithDocs>
                    <Speedbar mySpeed={pm.mySpeed} ourSpeed={pm.ourSpeed} />
                </Box>
            </>
        </Frame>
    );
}

export default memo(MyPerf);
