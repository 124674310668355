import type { JSX } from "react";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import t from "../../shared/translations";
import OpenChatButton from "../chat/OpenChatButton";
import SendButton from "../messages/SendButton";

interface IMember {
    id: string;
    person: {
        id: string;
        profilePicSrc: string;
        firstName: string;
        lastName: string;
    };
}

interface IProps {
    members: IMember[];
}

const buttonColor = "white";
const backgroupColor = currentColors.pipBackground;
const borderColor: string | null = null;
const textColor = "white";

const Wrapper = styled.div`
    margin-bottom: 0.5rem;
    padding: 0.6rem;
    background-color: ${backgroupColor};
    border: ${borderColor ? `1px solid ${borderColor}` : "none"};
    border-radius: 1rem;
    color: ${textColor};
    container-type: inline-size;
    

    @media (min-width: 640px) {
        margin-bottom: 1rem;
        padding: 1rem;
    }
    h3 {
        color: ${textColor};
    }
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    @container (min-width: 600px) {
        grid-template-columns: 1fr auto;
    }
`;

const PplCol = styled.div`
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(auto-fit, 80px);
    gap: 20px;
`;

const BtnCol = styled.div`
    text-align: right;
    display: grid;
    gap: 10px;
    justify-items: end;
    > button {
        color: ${currentColors.pipBackground};
        border: 3px solid ${currentColors.pipBackground};
        margin: 0;
    }
`;

const MemberBox = styled.div`
    width: 80px;
    text-align: center;
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
    @media (min-width: 640px) {
        margin-bottom: 1rem;
        font-size: 1rem;
        width: 90px;
    }
`;

const ImgTainer = styled.div`
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 80px;
        height: 80px;
        object-fit: cover;

        @media (min-width: 640px) {
            font-size: 1rem;
            width: 90px;
        }
    }
`;

function YourGroup({ members }: IProps): JSX.Element {
    return (
        <Wrapper>
            <div>
                <h3>{t("player.pip.heading-your-group")}</h3>
                <Row>
                    <PplCol>
                        {members.map((m) => (
                            <MemberBox key={m.id}>
                                <ImgTainer>
                                    <img
                                        src={m.person.profilePicSrc || "/static/images/profile-silouette.png"}
                                        alt="profile picture"
                                    />
                                </ImgTainer>
                                <div>
                                    {m.person.firstName} {m.person.lastName}
                                </div>
                            </MemberBox>
                        ))}
                    </PplCol>
                    <BtnCol>
                        <SendButton color={buttonColor} />
                        <OpenChatButton color={buttonColor} />
                    </BtnCol>
                </Row>
            </div>
        </Wrapper>
    );
}

export default YourGroup;
