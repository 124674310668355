import { type JSX, type MouseEvent, useState } from "react";
import styled from "styled-components";
import { currentColors } from "../../shared/colors";
import PlusMinus from "../../shared/components/icons/PlusMinus";
import t from "../../shared/translations";
import type { OpenLockedModal } from "../LockedModal";
import { AllAnswersLabel, GroupTaskLabel, LockIcon, SizedTaskIcon } from "../landing/stylings";
import type { IData } from "./query";
import { Current, IconHolder, TaskBox, TaskRow } from "./stylings";

type ILearningPathItem = IData["learningPath"]["learningPathItems"][0];

interface IProps {
    item: ILearningPathItem;
    openLockedModal: OpenLockedModal;
    activeItem?: string;
}

type StatusReturn = [
    boolean, // item is complete
    string, // status text
    string, // status color
    string, // text color
    string, // border color
    number, // border radius
    boolean, // current item
];

function itemStatus(item: ILearningPathItem, activeItem?: string): StatusReturn {
    let complete = false;
    let statusText = "";
    let statusColor = currentColors.text;
    let textColor = currentColors.text;
    let borderColor = currentColors.grey4;
    let borderRadius = 0;
    const current = item.id === activeItem;
    if (item.complete) {
        complete = true;
        statusText = t("player.tasks-sidebar.status-accepted");
        statusColor = currentColors.taskFinished;
        textColor = currentColors.taskFinished;
        borderColor = currentColors.taskFinished;
        borderRadius = 10;
    } else if (item.redo) {
        statusText = t("player.tasks-sidebar.status-redo");
        statusColor = currentColors.taskRedo;
        borderColor = currentColors.taskRedo;
    } else if (item.active) {
        statusText = item.deadline ? item.deadline : "";
        borderColor = currentColors.taskActive;
    } else if (item.deadline) {
        statusText = item.deadline;
    } else if (item.type === "event") {
        statusText = item.callToAction;
    }
    return [complete, statusText, statusColor, textColor, borderColor, borderRadius, current];
}

const PipTasks = styled.div`
    width: 100%;
`;

const PipTask = styled.div`
    display: grid;
    align-items: center;
    padding-left: 20px;
    margin-top: 5px;
    font-size: 0.85rem;
    grid-template-columns: auto 1fr;
`;

function fmtMiles(item: ILearningPathItem): string {
    if (!item.miles?.[0]) {
        return "";
    }
    if (item.miles[0] === item.miles[1]) {
        return item.miles[0].toString();
    }
    return `${item.miles[0]}-${item.miles[1]}`;
}

function Task({ item, openLockedModal, activeItem }: IProps): JSX.Element {
    const [open, setOpen] = useState(false);
    const toggleOpen = (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setOpen((o) => !o);
    };
    const requiresGroup = item.tasks.some(
        (task) => task.taskVariants.some((v) => v.variantType === "peer-feedback") || task.doInGroup !== "none",
    );
    const [complete, statusText, statusColor, color, borderColor, radius, current] = itemStatus(item, activeItem);
    const locked = item.locked && status !== "complete";
    const showLock = item.lockedType !== "none";
    const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
        if (item.locked) {
            event.preventDefault();
            openLockedModal(item.taskChoice ? null : item.tasks[0]?.id, item.feedbackTask?.id, item.taskChoice?.id);
            event.stopPropagation();
        }
    };
    return (
        <TaskBox
            to={item.url}
            key={item.id}
            $color={color}
            $borderColor={borderColor}
            $borderRadius={radius}
            onClick={onClick}
        >
            <TaskRow>
                <IconHolder>
                    <SizedTaskIcon icon={item.icon} active={complete} $size="60px" />
                    {requiresGroup && <GroupTaskLabel active={item.active} />}
                    {item.shareAnswers && <AllAnswersLabel />}
                </IconHolder>
                <div>
                    <div>{item.title}</div>
                    <div style={{ color: statusColor }}>
                        {statusText}
                        {item.active && (
                            <span style={{ color: currentColors.taskActive }}> {t("player.landing.active")}</span>
                        )}
                        {item.undecided && (
                            <span style={{ color: currentColors.taskActive }}> {t("player.landing.undecided")}</span>
                        )}
                    </div>
                    {item.miles?.[0] != null && (
                        <div>{t("player.tasks-sidebar.label-miles", { miles: fmtMiles(item) })}</div>
                    )}
                </div>
                {showLock && <LockIcon $size={50} $absolute={false} locked={locked} />}
                {(item.type === "path-in-path" || item.type === "task-choice") && (
                    <PlusMinus open={open} onClick={toggleOpen} />
                )}
                {current && <Current $borderRadius={radius} />}
            </TaskRow>
            {(item.type === "path-in-path" || item.type === "task-choice") && open && (
                <PipTasks>
                    {item.tasks.map((task) => (
                        <PipTask key={task.id}>
                            <SizedTaskIcon
                                icon={task.icon}
                                active={task.submittedTask?.status === "accepted"}
                                $size="44px"
                            />
                            <div>
                                <div>{task.shortTitle}</div>
                                <div>{t("player.tasks-sidebar.label-miles", { miles: task.miles })}</div>
                            </div>
                        </PipTask>
                    ))}
                </PipTasks>
            )}
        </TaskBox>
    );
}

export default Task;
