import { memo } from "react";
import type { JSX } from "react";
import { useResizeDetector } from "react-resize-detector";
import { currentColors } from "../../shared/colors";
import t from "../../shared/translations";

interface IProps {
    miles: number;
    greenGoal: number;
    yellowGoal: number;
}

function calculateStatusRel(miles: number, greenGoal: number, yellowGoal: number): number {
    const midRange = yellowGoal - greenGoal;
    const greenTop = greenGoal + midRange;
    const redBottom = greenGoal - midRange;
    if (miles >= greenTop) {
        return 1.0;
    }
    if (miles <= redBottom) {
        return 0.0;
    }
    const fullRange = greenTop - redBottom;
    return (miles - redBottom) / fullRange;
}

function statusText(miles: number, greenGoal: number, yellowGoal: number): string {
    if (miles >= greenGoal) {
        return t("player.performance.info-status-green");
    }
    if (miles >= yellowGoal) {
        return t("player.performance.info-status-yellow");
    }
    return t("player.performance.info-status-red");
}

function Status({ miles, greenGoal, yellowGoal }: IProps): JSX.Element {
    const { width, ref } = useResizeDetector();
    const statusRel = calculateStatusRel(miles, greenGoal, yellowGoal);
    const barWidth = width - 24;
    const statusPos = barWidth * statusRel || 0;
    return (
        <div ref={ref} style={{ marginTop: "1rem" }}>
            <svg width={width} height="60">
                <defs>
                    <linearGradient id="status-gradient" x1="0" x2="1" y1="0" y2="0">
                        <stop stopColor={currentColors.statusRed} offset="15%" />
                        <stop stopColor={currentColors.statusYellow} offset="38%" />
                        <stop stopColor={currentColors.statusYellow} offset="62%" />
                        <stop stopColor={currentColors.statusGreen} offset="85%" />
                    </linearGradient>
                    <symbol id="status-marker">
                        <path d="m12 48-12-24v-24l12 8 12-8v24z" fill={currentColors.performance} />
                    </symbol>
                </defs>
                <rect x="0" y="36" width={width} height="24" fill="url(#status-gradient)" />
                <use xlinkHref="#status-marker" x={statusPos} y="3" />
            </svg>
            <div>{statusText(miles, greenGoal, yellowGoal)}</div>
        </div>
    );
}

export default memo(Status);
