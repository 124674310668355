import Linkify from "linkify-react";
import type { JSX } from "react";
import styled from "styled-components";
import { currentColors } from "../../../shared/colors";
import LikertPreview from "../../../shared/components/LikertPreview";
import SliderPreview from "../../../shared/components/SliderPreview";
import t from "../../../shared/translations";
import type { IPollAnswerSet, IQuizAnswerSet, ISharedAnswer, ITaskAnswer } from "../query";
import { Box, CheckboxAnswer, FileLogo, TextboxAnswer } from "./stylings";

interface IProps {
    sharedAnswers: ISharedAnswer[];
    openQuizResults?: (quizAnswerSet: IQuizAnswerSet) => void;
    openPollResults?: (pollAnswerSet: IPollAnswerSet) => void;
}

// Supported answer types: textbox, checkbox, quiz, poll, file

function maxAnswerCount(sharerdAnswers: ISharedAnswer[]): number {
    return Math.max(...sharerdAnswers.map((ans) => ans.taskAnswers.length));
}

function groupAnswers(sharedAnswers: ISharedAnswer[]): ITaskAnswer[][] {
    const groupedAnswers: ITaskAnswer[][] = [...Array(maxAnswerCount(sharedAnswers))].map(() => []);

    for (const sharedAnswer of sharedAnswers) {
        for (let i = 0, iMax = sharedAnswer.taskAnswers.length; i < iMax; i++) {
            groupedAnswers[i].push(sharedAnswer.taskAnswers[i]);
        }
    }

    return groupedAnswers;
}

const Hr = styled.hr`
    border-color: ${currentColors.border};
`;

function GroupedAnswers({ sharedAnswers, openQuizResults, openPollResults }: IProps): JSX.Element {
    const groupedAnswers = groupAnswers(sharedAnswers);
    return (
        <>
            {groupedAnswers.map((answerList, index) => {
                return (
                    <Box key={index} $read={true}>
                        {answerList.map((answer, index) => {
                            const last = index + 1 === answerList.length;
                            if (answer.answerType === "textbox") {
                                return (
                                    <div key={answer.id}>
                                        {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                        <TextboxAnswer>
                                            <Linkify options={{ nl2br: true }} tagName="p">
                                                {answer.answerContent}
                                            </Linkify>
                                        </TextboxAnswer>
                                        {!last && <Hr />}
                                    </div>
                                );
                            }
                            if (answer.answerType === "checkbox") {
                                return (
                                    <div key={answer.id}>
                                        {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                        {answer.checkboxAnswers.map((ans) => (
                                            <CheckboxAnswer $checked={ans.checked} key={ans.id}>
                                                <input type="checkbox" checked={ans.checked} disabled /> {ans.content}
                                            </CheckboxAnswer>
                                        ))}
                                        {!last && <Hr />}
                                    </div>
                                );
                            }
                            if (answer.answerType === "likert") {
                                return (
                                    <div key={answer.id}>
                                        {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                        {answer.taskLikertAnswers.map((ans) => (
                                            <LikertPreview
                                                key={ans.id}
                                                statement={ans.statement}
                                                leftLabel={ans.leftLabel}
                                                rightLabel={ans.rightLabel}
                                                selected={ans.value}
                                            />
                                        ))}
                                        {!last && <Hr />}
                                    </div>
                                );
                            }
                            if (answer.answerType === "impact-tracker") {
                                return (
                                    <div key={answer.id}>
                                        {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                        {answer.impactTrackerAnswerSet.impactLikertAnswers.map((ans) => (
                                            <SliderPreview
                                                key={ans.id}
                                                statement={ans.statement}
                                                leftLabel={ans.leftLabel}
                                                rightLabel={ans.rightLabel}
                                                value={ans.value}
                                                showValue
                                            />
                                        ))}
                                        {!last && <Hr />}
                                    </div>
                                );
                            }
                            if (answer.answerType === "quiz") {
                                const [correct, total] = answer.quizAnswerSet?.results ?? [0, 0];
                                return (
                                    <div key={answer.id}>
                                        {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                        <span>
                                            {t("player.submitted-task.info-all-answers-quiz-result", {
                                                correct,
                                                total,
                                            })}
                                        </span>
                                        {!!openQuizResults && (
                                            <>
                                                {" "}
                                                <button
                                                    onClick={() => openQuizResults(answer.quizAnswerSet)}
                                                    type="button"
                                                >
                                                    {t("player.submitted-task.button-quiz-results")}
                                                </button>
                                            </>
                                        )}
                                        {!last && <Hr />}
                                    </div>
                                );
                            }
                            if (answer.answerType === "poll") {
                                return (
                                    <div key={answer.id}>
                                        {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                        <span>{t("player.submitted-task.info-all-answers-poll")}</span>
                                        {!!openPollResults && answer.showPollResults && (
                                            <>
                                                {" "}
                                                <button
                                                    onClick={() => openPollResults(answer.pollAnswerSet)}
                                                    type="button"
                                                >
                                                    {t("player.submitted-task.button-poll-results")}
                                                </button>
                                            </>
                                        )}
                                        {!last && <Hr />}
                                    </div>
                                );
                            }
                            if (answer.answerType === "file") {
                                const taskAnswerFile = answer.taskAnswerFile;
                                return (
                                    <div key={answer.id}>
                                        {answer.questionContent && <h5>{answer.questionContent}</h5>}
                                        {taskAnswerFile.src ? (
                                            <a href={taskAnswerFile.src} target="_blank" rel="noreferrer">
                                                {taskAnswerFile.logoSrc && <FileLogo src={taskAnswerFile.logoSrc} />}{" "}
                                                {taskAnswerFile.name}
                                            </a>
                                        ) : (
                                            <span>
                                                {taskAnswerFile.logoSrc && <FileLogo src={taskAnswerFile.logoSrc} />}{" "}
                                                {taskAnswerFile.name}
                                            </span>
                                        )}
                                        {!last && <Hr />}
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </Box>
                );
            })}
        </>
    );
}

export default GroupedAnswers;
