import type { JSX } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { loadJSData } from "../../shared/jsData";
import CustomTask from "./CustomTask";
import RejectedTask from "./RejectedTask";

function CustomTaskRoutes(): JSX.Element {
    if (!loadJSData().features.custom_tasks) {
        return <Navigate to="/landing" replace />;
    }
    return (
        <Routes>
            <Route path="rejected/:taskId" element={<RejectedTask />} />
            <Route path=":kind" element={<CustomTask />} />
        </Routes>
    );
}

export default CustomTaskRoutes;
