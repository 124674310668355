import { type JSX, memo } from "react";
import styled from "styled-components";
import TrophyIcon from "../../shared/components/icons/TrophyIcon";
import t from "../../shared/translations";
import WithDocs from "../components/WithDocs";
import Banner from "./Banner";
import type { IData } from "./query";
import { Box, Frame } from "./stylings";

interface IProps {
    data: IData;
}

const ProgressText = styled.div`
    font-size: 1.1rem;
`;

const StyledTrophyIcon = styled(TrophyIcon)`
    width: 8rem;
    height: 8rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
`;

function Goals({ data }: IProps): JSX.Element {
    const pm = data.pathMembership;
    const path = pm.learningPath;
    const checkpointText =
        pm.miles >= path.checkpoint
            ? t("player.performance.info-reached-checkpoint")
            : t("player.performance.info-checkpoint-remaining", { miles: path.checkpoint - pm.miles });
    const goalText =
        pm.miles >= path.goal
            ? t("player.performance.info-reached-goal")
            : t("player.performance.info-goal-remaining", { miles: path.goal - pm.miles });
    return (
        <Frame>
            <Box $span={[12, 6, 6]}>
                <WithDocs
                    modal="Performance/Info/Checkpoint"
                    docPath={"player/performance/checkpoint"}
                    component="h4"
                    style={{ justifyContent: "center" }}
                >
                    {t("player.performance.heading-checkpoint")}
                </WithDocs>
                <h5>
                    {t("player.performance.heading-checkpoint-miles", {
                        miles: path.checkpoint,
                    })}
                </h5>
                <Banner active={pm.miles >= path.checkpoint} />
                <ProgressText>
                    {checkpointText}
                    <br />
                    {t("player.performance.info-past-checkpoint", {
                        count: path.membersPastCheckpoint,
                    })}
                </ProgressText>
            </Box>
            <Box $span={[12, 6, 6]}>
                <WithDocs
                    modal="Performance/Info/Goal"
                    docPath={"player/performance/goal"}
                    component="h4"
                    style={{ justifyContent: "center" }}
                >
                    {t("player.performance.heading-goal")}
                </WithDocs>
                <h5>{t("player.performance.heading-goal-miles", { miles: path.goal })}</h5>
                <StyledTrophyIcon thin active={pm.miles >= path.goal} />
                <ProgressText>
                    {goalText}
                    <br />
                    {t("player.performance.info-past-goal", { count: path.membersPastGoal })}
                </ProgressText>
            </Box>
        </Frame>
    );
}

export default memo(Goals);
