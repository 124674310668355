import styled from "styled-components";
import { currentColors } from "../../shared/colors";

export const Box = styled.div`
    border-radius: 1rem;
    padding: 0.6rem;
    border: 1px solid ${currentColors.border};
    margin-bottom: 0.5rem;

    @media (min-width: 600px) {
        padding: 1rem;
        margin-bottom: 1rem;
    }

    > *:last-child {
        margin-bottom: 0;
    }
`;

export const ErrorBox = styled.div`
    color: white;
    background-color: ${currentColors.error};
    border-radius: 1rem;
    padding: 0.6rem;
    margin-bottom: 0.5rem;

    @media (min-width: 600px) {
        padding: 1rem;
        margin-bottom: 1rem;
    }
`;
