// export const primaryColor = loadJSData().settings.primary_color;
// export const secondaryColor = loadJSData().settings.secondary_color;

// export const altPrimaryColor = loadJSData().settings.alt_primary_color;
// export const altSecondaryColor = loadJSData().settings.alt_secondary_color;

// export const text = "rgb(34, 34, 34)";
// export const altText = "white";

// export const deadline = "#ce4a99";
// export const rejected = "#ce4a99";
// export const active = primaryColor;
// export const finished = primaryColor;

// export const primaryDarker = lightenColor(primaryColor, -15);
// export const primaryLighter = lightenColor(primaryColor, 25);
// export const unlight = "#5C5C5C";
// export const alert = "#FF4F57";
// export const success = "#1F9B1D";
// export const alertDarker = lightenColor(alert, -15);

// export const answer = primaryColor;
// export const answerBackground = secondaryColor;
// export const feedback = primaryColor;
// export const response = answer;

// export const star = "#f4c359";

// export const status = {
//     green: "#52FB52",
//     yellow: "#FAFA29",
//     red: "#FF0103",
//     white: "#F0F0F0",
// };

export const adminCalendarColors = {
    callToAction: {
        background: "#AADEBD",
        border: "#26633D",
    },
    feedbackCallToAction: {
        background: "#AADEBD",
        border: "#26633D",
    },
    deadline: {
        background: "#FFE8E9",
        border: "#E8000B",
    },
    feedbackDeadline: {
        background: "#FFE8E9",
        border: "#E8000B",
    },
    taskNotification: {
        background: "#8CD2FF",
        border: "#00568C",
    },
    taskChoiceNotification: {
        background: "#8CD2FF",
        border: "#00568C",
    },
    pathNotification: {
        background: "#8CD2FF",
        border: "#00568C",
    },
    taskEvent: {
        background: "#d7dbe2",
        border: "#3d4655",
    },
    event: {
        background: "#d7dbe2",
        border: "#3d4655",
    },
    unlock: {
        background: "#f2d1e5",
        border: "#ce4a99",
    },
    publish: {
        background: "#ECD7FB",
        border: "#911AE5",
    },
};

// export const runner = "#8c8c8c";

// export const lightBorder = "#a2aaad";
// export const tableHeader = "#e8e8e8"; // #fafafa #d6d6d6
// export const tableHeaderFocus = lightenColor(tableHeader, -15);

// export const white = "#ffffff";
// export const black = "#000000";

// export const checkmarkGreen = "#00bd00";
// export const checkmarkGrey = "#929292";
// export const checkmarkRed = "#ff0000";

// export const talentmiles = {
//     primaryBlue: "#0075be",
//     otherBlue: "#009ad2",
//     paleBlue: "#dffaff",
//     pink: "#ce4a99",
//     darkGrey: "#3d4655",
// };

// export const quiz = {
//     selectedColor: "#00B8F1",
//     currentLevelColor: "#0075BF",
//     levelColor: "#33A8F2",
//     rightAnswerColor: "#06AF06",
//     wrongAnswerColor: "#FF0103",
// };

// export const builder = {
//     miles: "#009AD2",
// };

// export const theme = loadJSData().task_data.theme_colors;

// export const chartLines = {
//     checkpoint: unlight,
//     goal: rejected,
//     deadline: alert,
// };

// export const chartColors = [
//     "#CE4B99",
//     "#00487C",
//     "#2E725A",
//     "#A6533D",
//     "#84C54D",
//     "#F17105",
//     "#FB444B",
//     "#0075BF",
//     "#A57204",
//     "#9465AB",
//     "#FDC243",
//     "#FFBBF6",
//     "#6D164D",
//     "#9E0031",
//     "#86BBD8",
//     "#52B79F",
//     "#F08B66",
// ];

export function chartColor(i: number): string {
    return currentColors.chartColors[i % currentColors.chartColors.length];
}

export type CurrentColorType = {
    theme1: string;
    theme2: string;
    theme3: string;
    theme4: string;
    theme5: string;
    navActive: string;
    link: string;
    button: string;
    buttonDisabled: string;
    unread: string;
    error: string;
    warning: string;
    success: string;
    header: string;
    rating: string;
    heart: string;
    performance: string;
    calendarEvent: string;
    calendarStartDate: string;
    calendarDeadline: string;
    calendarUnlock: string;
    calendarCurrentDay: string;
    border: string;
    background: string;
    taskDeadline: string;
    taskFinished: string;
    taskActive: string;
    taskRedo: string;
    taskLocked: string;
    taskUnlocked: string;
    taskApprove: string;
    taskQuestionNumber: string;
    pipNav: string;
    pipBackground: string;
    pipButton: string;
    pipButtonDisabled: string;
    pipFinished: string;
    progress: string;
    icon1: string;
    icon2: string;
    icon3: string;
    icon4: string;
    icon5: string;
    quizOption: string;
    quizSelected: string;
    quizCorrect: string;
    quizIncorrect: string;
    pollSelected: string;
    likert: string;
    strategyStar: string;
    statusGreen: string;
    statusYellow: string;
    statusRed: string;
    completionsFinished: string;
    completionsNotFinished: string;
    completionsOverdue: string;
    text: string;
    teamScan: string;
    teamScanRed: string;
    teamScanYellow: string;
    teamScanGreen: string;
    builder: string;
    grey1: string;
    grey2: string;
    grey3: string;
    grey4: string;
    chartColors: string[];
};

export const currentColors = JSON.parse(document.getElementById("js-colors").innerHTML.trim()) as CurrentColorType;

export const builderThemeColor = {
    customer: currentColors.chartColors[0],
    leadership: currentColors.chartColors[1],
    onboarding: currentColors.chartColors[2],
    "self-leadership": currentColors.chartColors[3],
    strategy: currentColors.chartColors[4],
    teamwork: currentColors.chartColors[5],
};

// Icon colors:
// #ff855d = icon1
// #0076c2 = icon2
// #00ba94 = icon3
// #ffc232 = icon4
// #ff4e57 = icon5
